import React, { useState, useEffect, useCallback, useRef } from "react"
import ReactMapGL, {
    Marker,
    NavigationControl,
    ScaleControl,
    GeolocateControl,
} from 'react-map-gl';
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import Geocoder from "../Components/Geocoder"
import * as api from '../../../v2/services/agent'
import { parseContext } from '../../../mapbox/parseContext'
import { MAPBOX_TOKEN } from '../../../constants'
import { PropertyStatusBadge } from "../Shared/PropertyStatusBadge"
import { generatePath } from "react-router";
import Select from 'react-select'
import * as Constants from '../../../constants'
import Alert from '../Shared/Alert'
import Error from '../Shared/Error'
import Confirm from '../Shared/Confirm'
import StatusInfo from '../Shared/StatusInfo'
import { Translate, TranslateWithLang } from "../../translate/Translate";
import { PhotoManager } from "../Shared/PhotoManager";
import SideNavBar from "../SideNavBar";
import TopSearchbar from "../TopSearchbar";
import moment from "moment";
import { useDropzone } from 'react-dropzone'
import CreatableSelect from 'react-select/creatable';

export default function PropertyDetail(props) {
    const mapRef = useRef()

    const [viewport, setViewport] = useState({
        latitude: 36.3418518,
        longitude: 138.6179432,
        zoom: 18
    })
    const [showAlert, setShowAlert] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showPhotoManager, setShowPhotoManager] = useState(false);
    const [showStatusInfo, setShowStatusInfo] = useState(false);
    const [statusWithInfo, setStatusWithInfo] = useState({});

    const [creatableFeatureList, setCreatableFeatureList] = useState([]);

    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState("");

    const selectStyles = {
        control: base => ({
            ...base,
            border: 0,
            // This line disable the blue border
            boxShadow: 'none'
        })
    };

    let { id } = useParams();
    const [showMap, setShowMap] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [saving, setSaving] = useState(false);
    const [updated, setUpdated] = useState(false);

    const [floors, setFloors] = useState(0);
    const [apartments, setApartments] = useState([]);

    const geolocateStyle = {
        bottom: 125,
        right: 0,
        padding: '10px'
    };

    const navStyle = {
        bottom: 30,
        right: 0,
        padding: '10px'
    };

    const scaleControlStyle = {
        bottom: 24,
        left: 0,
        padding: '10px'
    };

    const loadApartments = (area) => {
        api.apartments(area)
            .then(response => {
                for (let i = 0; i < response.length; i++) {
                    response[i].name = TranslateWithLang(props.language, response[i].name)
                }
                setApartments(response)
            })
            .catch(error => {
                // setShowError(true)
                // setErrorMessage(error.data.message)
            })
    }

    const [data, setData] = useState({
        "id": "",
        "area": {
            "id": "karuizawa"
        },
        "apartment": "",
        "reference_id": "",
        "property_id": "",
        "property_type": "house",
        "transaction_type": "brokerage_general",
        "layout": {
            "name": "",
            "bedrooms": 0,
            "bathrooms": 0
        },
        "sqm": "",
        "address": {},
        "latlng": {
            "latitude": 36.3418518,
            "longitude": 138.6179432
        },
        "land": {
            "sqm": ""
        },
        "images": [],
        "vr": {
            "url": ""
        },
        "features": [],
        "built": {
            "month": 11,
            "year": 2020
        },
        "name": "",
        "overview": "",
        "listing_type": "sell",
        "price": {
            "amount": "",
            "currency": "JPY",
            "updated_at": "2020-12-02T06:13:58.769377Z"
        },
        "other_costs": [],
        "status": "draft",
        "listed_by": {},
        "remarks": "",
        "regulations": "",
        "privacy_mode": false,
        "is_private": false,
        "archived_images": [],
        "floor": "",
        "room_number": "",
        "document": {
            "data": { "message": "" },
            "files": [],
            "archived_files": [],
        },
    });

    const onGeocoderResult = (result) => {
        if (result == null) return
        if (result.features.length !== 0) {
            const feature = result.features[0]
            const { geometry } = feature
            const location = { latitude: geometry.coordinates[1], longitude: geometry.coordinates[0] }
            setMarker(location)
            setViewport((v) => ({ ...v, ...location }))
            setData({
                ...data,
                latlng: {
                    latitude: geometry.coordinates[1],
                    longitude: geometry.coordinates[0],
                },
                address: {
                    ...data.address,
                    ...parseContext(feature)
                }
            })
        }

    }

    const [marker, setMarker] = useState({
        "latitude": 0,
        "longitude": 0
    })

    const [optionsVisible, setOptionsVisible] = useState(false);

    const toggleOptionsMenu = (e) => {
        setOptionsVisible(!optionsVisible)
    }

    const filterByReference = (arr1, arr2) => {
        let res = [];
        res = arr1.filter(el => {
            return !arr2.find(element => {
                return element && el ? element.title === el.title : false;
            });
        });
        return res;
    }

    React.useEffect(() => {
        if (data.property_type == "apartment" && apartments && data.apartment) {
            var apartment = apartments.find(({ id }) => id === data.apartment)
            if (apartment) {
                var features = filterByReference(data.features, apartment.features)
                setData({ ...data, features: features })
            }
        }
    }, [apartments, data.apartment]);

    function loadPropertyDetail(id) {
        api.fetchPropertyDetailForEdit(id)
            .then(response => {
                //this line here is to mask amount with thousand separator comma. e.g. 18000000 to 18,000,000
                response.price.amount = response.price.amount.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                response.features = response.features ? response.features : [];
                setData(response);
                loadApartments(response.area.id)
                setFloors(Number(response.amount_of_floors ?? 1))
                setDataLoaded(true)
                setMarker((data) => ({ ...data, ...response.latlng }))
                setViewport({
                    ...viewport,
                    ...response.latlng,
                })

            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error.data.message)
            })
    }

    function saveSurvey(want) {
        let survey = { property_id: data.property_id, data: { request: want } }
        api.createNewSurvey(survey)
            .then(response => {
                setShowPopup(false)
                setShowAlert(true)
                setMessage("We will contact you shortly.")
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error.data.message)
            })
    }

    // Similar to componentDidMount and componentDidUpdate:
    React.useEffect(() => {
        if (id !== undefined) {
            loadPropertyDetail(id);
        }
    }, []);

    //this is here to set data field to each feature to make select option works
    React.useEffect(() => {
        if (props.featureList.length > 0 && dataLoaded === true) {
            for (let i = 0; i < data.features.length; i++) {
                data.features[i].data = featureDataByKey(data.features[i].title);
            }
            setData({ ...data, features: data.features })
        }
    }, [dataLoaded, props.featureList]);

    function tr(text, search, replace) {
        // Make the search string a regex.
        var regex = RegExp('[' + search + ']', 'g');
        var t = text.replace(regex,
            function (chr) {
                // Get the position of the found character in the search string.
                var ind = search.indexOf(chr);
                // Get the corresponding character from the replace string.
                var r = replace.charAt(ind);
                return r;
            });
        return t;
    }

    const handleInputBlur = (e) => {
        var { name, value, min, max, selectionStart, selectionEnd, oldSelectionStart, oldSelectionEnd } = e.target

        oldSelectionStart = selectionStart;
        oldSelectionEnd = selectionEnd;

        value = e.target.dataset.type == "float" ? value.normalize('NFKC').replace(/。/g, ".").replace(/[^0-9.]/g, "") : value.normalize('NFKC').replace(/[^0-9]/g, "")

        if (value.lastIndexOf(".") !== value.indexOf("."))
            value = value.slice(0, value.indexOf("."))

        if (value && max && e.target.dataset.type == "int" && parseFloat(value) > max) {
            value = max
        }

        if (value && min && e.target.dataset.type == "int" && parseFloat(value) < min) {
            value = min
        }

        value = e.target.dataset.type == "int" ? value ? parseFloat(value) : 0 : value

        if ((e.target.dataset.type == "float") && isNaN(value))
            value = ""

        //if name is set like "land.sqm" then we assign it like below
        if (name.includes(".")) {
            let fields = name.split(".")
            let object = fields[0];
            let fieldName = fields[1];
            setData({ ...data, [object]: { ...data[object], [fieldName]: value } })
            return;
        }
        setData({ ...data, [name]: value })

        //e.target.setSelectionRange(oldSelectionStart, oldSelectionEnd);

    }

    const handleMapClick = (e) => {
        let location = {
            latitude: e.lngLat.lat,
            longitude: e.lngLat.lng,
        }
        setMarker(location)
        setData(data => ({ ...data, latlng: location }));
        reverseGeocoding({ lat: location.latitude, lng: location.longitude })
    }

    const onViewportChange = (evt) => {
        setViewport(evt.viewState)
    }

    useEffect(() => {
        if (data.latlng.latitude === undefined || data.latlng.longitude === undefined) {
            return
        }
        if (data.latlng.latitude == "" || data.latlng.longitude == "") {
            return
        }
        if (data.latlng.latitude != marker.latitude || data.latlng.longitude != marker.longitude) {
            setMarker(data.latlng)
        }
    }, [data.latlng])


    const handleInputChange = (e) => {
        var { name, value } = e.target

        //if name is set like "land.sqm" then we assign it like below
        if (name.includes(".")) {
            let fields = name.split(".")
            let object = fields[0];
            let fieldName = fields[1];
            setData({ ...data, [object]: { ...data[object], [fieldName]: value } })
            return;
        }
        setData({ ...data, [name]: value })
    }

    const handleDataChange = (e) => {
        var { name, value } = e.target
        if (e.target.dataset.type === "number") {
            if (e.type === "change") {
                value = parseFloat(value)
            } else if (e.type === 'blur' || e.key === 'Enter') {
                value = parseFloat(value)
            }
        }
        //if name is set like "land.sqm" then we assign it like below
        if (name.includes(".")) {
            let fields = name.split(".")
            let object = fields[0];
            let fieldName = fields[1];
            setData({ ...data, [object]: { ...data[object], [fieldName]: value } })
            return;
        }

        setData({ ...data, [name]: value })
    }

    const handlePrivacyModeChange = (e) => {
        setData({ ...data, privacy_mode: e.target.checked })
    }

    const handlePrivatePropertyChange = (e) => {
        setData({ ...data, is_private: e.target.checked })
    }

    const handlePriceChange = (e) => {
        var price = parseFloat(e.target.value.trim().replaceAll(",", "").normalize('NFKC').replace(/[^0-9]/g, ""))

        if (!isNaN(price))
            price = price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        else
            price = ""

        setData({ ...data, ["price"]: { ...data["price"], ["amount"]: price } })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (saving === false && updated === false) {
            updateProperty()
        }
    }

    const updateProperty = () => {
        setSaving(true)

        if (data.sqm)
            data.sqm = parseFloat(data.sqm)
        else
            data.sqm = 0

        if (data.land && data.land.sqm)
            data.land.sqm = parseFloat(data.land.sqm)
        else
            data.land = { sqm: 0 }

        if (data.latlng && data.latlng.latitude)
            data.latlng.latitude = parseFloat(data.latlng.latitude)

        if (data.latlng && data.latlng.longitude)
            data.latlng.longitude = parseFloat(data.latlng.longitude)

        if (data.price && data.price.amount)
            data.price.amount = data.price.amount.trim().replaceAll(",", "")

        api.updateProperty(data)
            .then(d => {


                setSaving(false)
                setUpdated(true)
                setShowAlert(true)
                setData(data)
                setMessage("")
                window.location.reload()

                document.getElementById("btn-view-on-villa").scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
            })
            .catch(error => {
                setSaving(false)
                setShowError(true)
                console.log(error)
                setErrorMessage(error.data.message)
            })
    }

    React.useEffect(() => {
        if (updated === true) {
            setTimeout(() => {
                setUpdated(false)
            }, 2000);
        }
    }, [updated])

    const markAsPrivate = (is_private) => {
        let property = {
            id: data.id,
            is_private: is_private
        }
        api.updateProperty(property)
            .then(response => {
                setData({ ...data, is_private: is_private })
                props.history.replace({
                    pathname: generatePath(props.match.path, { id: response }),
                });
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error.data.message)
            })
    }

    const confirmStatus = (status) => {
        let property = {
            id: data.id,
            status: status,
            status_info: {}
        }

        setStatusWithInfo(property)
        setShowStatusInfo(true)
    }

    const markAs = (property) => {
        api.updateProperty(property)
            .then(response => {
                setData({ ...data, status: property.status })
                if (property.status == "sold")
                    props.history.replace(`/v2/properties/${response}`);
                else
                    props.history.replace({
                        pathname: generatePath(props.match.path, { id: response }),
                    });
                setShowStatusInfo(false)
                setShowAlert(true)
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error.data.message)
            })
    }

    const publishProperty = () => {

        let property = {
            id: data.id,
            status: 'active'
        }
        api.updateProperty(property)
            .then(response => {
                setData({ ...data, ...property })
                props.history.replace({
                    pathname: generatePath(props.match.path, { id: response }),
                });
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error.data.message)
            })
    }

    const addFeature = () => {
        let feature = { index: 0, title: "", value: "" }
        //default data to first in the list of feature list
        let first = props.featureList[0];
        feature.data = first
        feature.key = first.key;
        feature.title = first.key;
        feature.index = first.index;
        feature.type = first.type;
        if (first.type === "list") {
            feature.value = first.values[0].value;
        }

        if (data.feature === null) {
            data.feature = []
        }
        data.features.push(feature)
        setData({ ...data, features: data.features })
    }

    const removeFeature = (index, feature) => {
        data.features.splice(index, 1)
        setData({ ...data, features: data.features })
    }

    const handleFeatureValueChange = (index, e) => {
        var { value } = e.target;
        data.features[index] = { ...data.features[index], value }
        setData({ ...data, features: data.features })
    }

    const handleFeatureArrayValueChange = (index, e) => {
        var { value } = e.target;
        var arr = data.features[index].value.split(',')
        if (arr.length > 0){
            let i = arr.indexOf(value);
            if (i !== -1) 
                arr.splice(i, 1);
             else 
                arr.push(value)
            value = String([...new Set(arr)]);
        }
        data.features[index] = { ...data.features[index], value }
        setData({ ...data, features: data.features })
    }

    const handleFeatureTitleChange = (feature, index) => {
        let featureData = featureDataByKey(feature.key ? feature.key : feature.title)
        if (featureData !== null) {
            data.features[index].key = featureData.key
            data.features[index].title = featureData.key
            data.features[index].index = featureData.index
            data.features[index].type = featureData.type
            data.features[index].data = featureData;
            if (featureData.type === "list") {
                data.features[index].value = featureData.values[0].value;
            }
        }
        setData({ ...data, features: data.features })
    }

    const addCost = () => {
        let item = {
            title: "",
            description: "",
            type: "one-time",
            price: {
                amount: "",
                currency: "JPY"
            }
        }
        if (data.other_costs === null) {
            data.other_costs = []
        }
        data.other_costs.push(item)
        setData({ ...data, other_costs: data.other_costs })
    }

    const removeCost = (index) => {
        data.other_costs.splice(index, 1)
        setData({ ...data, other_costs: data.other_costs })
    }

    const handleCostChange = (index, e) => {
        var { name, value } = e.target;
        data.other_costs[index] = { ...data.other_costs[index], [name]: value }
        setData({ ...data, other_costs: data.other_costs })
    }

    const handleCostPriceChange = (index, e) => {
        data.other_costs[index].price.amount = e.target.value.trim().normalize('NFKC').replace(/[^0-9]/g, "")
        setData({ ...data, other_costs: data.other_costs })
    }


    const RenderActionButtons = (props) => {
        const { status, publishProperty, confirmStatus } = props

        switch (status) {
            case "draft":
                return parseFloat(data.price.amount) === 0 ? <><button type="button" className="btn text-sm bg-gray-500 text-white" >{Translate("Publish this property")}</button> <span className="text-red-500">* {Translate("Price required")}</span></>
                    : <><button type="button" className="btn text-sm bg-green-500 text-white" onClick={(e) => { publishProperty() }}>{Translate("Publish this property")}</button></>;

            case "inactive":
                return parseFloat(data.price.amount) === 0 ? <><button type="button" className="btn text-sm bg-gray-500 text-white" >{Translate("Mark as Active")}</button> <span className="text-red-500">* {Translate("Price required")}</span></>
                    : <><button type="button" className="btn text-sm bg-green-500 text-white" onClick={(e) => confirmStatus("active")}>{Translate("Mark as Active")}</button></>;
            case "active":
                return <>
                    <button type="button" className="btn text-sm bg-white text-red-600 mr-2" onClick={(e) => confirmStatus("inactive")}>{Translate("Delist this property")}</button>
                    <button type="button" className="btn text-sm bg-red-500 text-white mr-2" onClick={(e) => confirmStatus("sold")}>{Translate("Mark as Sold")}</button>
                    <button type="button" className="btn text-sm bg-yellow-500 text-white" onClick={(e) => confirmStatus("business_negotiation")}>{Translate("Mark as Negotiation")}</button>
                </>;
            case "sold":
                return <>
                    <button type="button" className="btn text-sm bg-white text-red-600  mr-2" onClick={(e) => confirmStatus("inactive")}>{Translate("Delist this property")}</button>
                    <button type="button" className="btn text-sm bg-yellow-500 text-white mr-2" onClick={(e) => confirmStatus("business_negotiation")}>{Translate("Mark as Negotiation")}</button>
                    <button type="button" className="btn text-sm bg-green-500 text-white" onClick={(e) => confirmStatus("active")}>{Translate("Mark as Active")}</button>
                </>;
            case "business_negotiation":
                return <>
                    <button type="button" className="btn text-sm bg-white text-red-600  mr-2" onClick={(e) => confirmStatus("inactive")}>{Translate("Delist this property")}</button>
                    <button type="button" className="btn text-sm bg-red-500 text-white mr-2" onClick={(e) => confirmStatus("sold")}>{Translate("Mark as Sold")}</button>
                    <button type="button" className="btn text-sm bg-green-500 text-white" onClick={(e) => confirmStatus("active")}>{Translate("Mark as Active")}</button>
                </>;
            default:
                return '';
        }
    }

    const reverseGeocoding = ({ lat, lng }) => {
        if (lat === 0 || lng === 0) {
            return
        }
        let text = `${lng},${lat}`
        let url = `https://api.mapbox.com/search/v1/reverse/${text}?language=ja&access_token=${MAPBOX_TOKEN}`
        fetch(url)
            .then(response => response.json())
            .then(resp => {

                if (resp.features.length === 0) return;

                setData({ ...data, address: parseContext(resp.features[0]), latlng: { latitude: lat, longitude: lng } })
            });
    }

    // const fetchAddress = (location) => {
    //     if (location.lat == 0 || location.lng == 0) {
    //         return
    //     }
    //     new window.google.maps.Geocoder()
    //         .geocode({ location: location })
    //         .then((response) => {
    //             if (response.results[0]) {
    //                 let address = {}
    //                 var address_components = response.results[0].address_components
    //                 for (var i in address_components) {
    //                     for (var j in address_components[i].types) {
    //                         switch (address_components[i].types[j]) {
    //                             case "postal_code":
    //                                 address.zip_code = address_components[i].short_name
    //                                 break;
    //                             case "administrative_area_level_2":
    //                                 address.district = address_components[i].short_name
    //                                 break;
    //                             case "administrative_area_level_1":
    //                                 address.prefecture = address_components[i].short_name
    //                                 break;
    //                             case "route":
    //                                 address.address_2 = address_components[i].short_name
    //                                 break;
    //                             case "premise":
    //                                 address.address_1 = address.address_1 ? address.address_1 + " " + address_components[i].short_name : address_components[i].short_name
    //                                 break;
    //                             case "sublocality_level_2":
    //                                 address.address_1 = address.address_1 ? address.address_1 + " " + address_components[i].short_name : address_components[i].short_name
    //                                 break;
    //                             case "sublocality_level_3":
    //                                 address.address_1 = address.address_1 ? address.address_1 + " " + address_components[i].short_name : address_components[i].short_name
    //                                 break;
    //                             case "locality":
    //                                 address.city = address_components[i].short_name
    //                                 break;
    //                             case "country":
    //                                 address.country_code = address_components[i].short_name
    //                                 break;
    //                         }
    //                     }
    //                 }
    //                 setData({ ...data, address: address })
    //             } else {
    //                 console.log("No results found");
    //             }
    //         })
    //         .catch((e) => console.log("Geocoder failed due to: " + e));
    // }

    const handleZipCodeChange = (e) => {
        let address = data.address
        address.zip_code = e.target.value
        setData({ ...data, address: address })
    }
    const handleCityChange = (e) => {
        let address = data.address
        address.city = e.target.value
        setData({ ...data, address: address })
    }
    const handleDistrictChange = (e) => {
        let address = data.address
        address.district = e.target.value
        setData({ ...data, address: address })
    }
    const handlePrefectureChange = (e) => {
        let address = data.address
        address.prefecture = e.target.value
        setData({ ...data, address: address })
    }
    const handleAddress2Change = (e) => {
        let address = data.address
        address.address_2 = e.target.value
        setData({ ...data, address: address })
    }

    const handleAddress1Change = (e) => {
        let address = data.address
        address.address_1 = e.target.value
        setData({ ...data, address: address })
    }

    const onMarkerDrag = useCallback((event) => {
        setMarker({ latitude: event.lngLat.lat, longitude: event.lngLat.lng })
    }, []);

    //this is when a user drag marker
    const onMarkerDragEnd = useCallback((event) => {
        let location = {
            latitude: event.lngLat.lat,
            longitude: event.lngLat.lng,
        }
        setMarker({ latitude: event.lngLat.lat, longitude: event.lngLat.lng })
        setData(d => ({ ...d, latlng: { latitude: event.lngLat.lat, longitude: event.lngLat.lng } }))
        reverseGeocoding({ lat: location.latitude, lng: location.longitude })
    }, []);

    const featureDataByKey = (value) => {
        var feature = props.featureList.find((f) => { return f.key === value });
        if (feature) return feature;
        return { key: value, type: "text", title: value, index: 0, value: "" };
    }

    const RenderFeatureValue = (feature, index) => {
        const id = `feature-${feature.title.replaceAll(" ", "-").toLowerCase()}-value`
        if (feature.data === null || feature.data === undefined) {
            return <input required name={id} id={id} onChange={(e) => handleFeatureValueChange(index, e)} value={feature.value} type="text" placeholder={Translate("Wooden, 2 Stories")} className="rounded w-full" />;
        }

        if (feature.data.type === "multiline_text") {
            return <textarea required name={id} id={id} rows="3" onChange={(e) => handleFeatureValueChange(index, e)} value={feature.value} placeholder={feature.data.placeholder} className="rounded w-full" />
        }

        if (feature.data.type === "list_text") {
            return (
                <CreatableSelect
                    styles={selectStyles}
                    className="text-base border border-gray-600 rounded "
                    name={id} id={id}
                    options={toCreatableList(feature.data.values)}
                    onChange={({ value, label }) => handleFeatureValueChange(index, { target: { value: value } })}
                    value={{ value: feature.value, label: Translate(feature.value) }} />
            )
        }

        if (feature.data.type === "list_multiple_value") {
            return (<div className="grid grid-cols-2 gap-1 w-full">
                {feature.data.values.map(({ value }, vIndex) => (
                    <label className="inline-flex items-start">
                        <input type="checkbox"
                            name={id} 
                            key={`feature-option-${(feature.key || feature.title)}-${value}`} 
                            id={`feature-option-${(feature.key || feature.title)}-${value}`} 
                            value={value}
                            onChange={(e) => handleFeatureArrayValueChange(index, e)} 
                            checked={Array.isArray(feature.value) ? feature.value.includes(value) : feature.value.split(',').includes(value)}
                        />
                        <span className="ml-2">{Translate(value)}</span>
                    </label>
                ))}
            </div>)
        }
     
        if (feature.data.type === "list") {
            return (<div className="grid grid-cols-2 gap-1 w-full">
                {feature.data.values.map(({title, value}, vIndex) => (<label key={vIndex}>
                    <input type="radio" 
                    name={id} 
                    key={`feature-option-${(feature.key || feature.title)}-${value}`} 
                    id={`feature-option-${(feature.key || feature.title)}-${value}`} 
                    value={value} 
                    onChange={(e) => handleFeatureValueChange(index, e)} 
                    checked={value === feature.value} />
                    <span className="ml-2">{title}</span>
                </label>))}
            </div>)
        }

        return <input required name={id} id={id} onChange={(e) => handleFeatureValueChange(index, e)} value={feature.value} type="text" placeholder={feature.data.placeholder} className="rounded w-full" />
  
    }

    const onDrop = React.useCallback((acceptedFiles, fileRejections) => {
        acceptedFiles.map((file) => {
            var reader = new FileReader();
            reader.onload = function (event) {
                var imageData = event.target.result;
                var base64Data = imageData.split("base64,")[1];
                var fileName = file.name.replace(/\.[^/.]+$/, "")
                api.uploadImage(base64Data, fileName)
                    .then(response => {
                        var document = { ...data.document }
                        var list = document.files ? [...document.files] : [];
                        list.push({
                            uploaded: true,
                            url: response.data,
                        })
                        document.files = list
                        setData({ ...data, document: document })
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
            reader.readAsDataURL(file);
        });

        fileRejections.forEach((file) => {
            file.errors.forEach((err) => {
                if (err.code === "file-too-large") {
                    alert("file size is exceed limit (10MB)")
                }
            })
        })
    }, [data])

    const dropMessageAttachments = useDropzone({
        onDrop: onDrop,
        accept: 'image/jpeg, image/png, application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        multiple: false,
        maxSize: 10 * 1024 * 1024, // 10MB
    });

    const removeFileAt = (index) => {
        var document = { ...data.document }
        var list = document.files ? [...document.files] : [];
        var archived = document.archived_files ? [...document.archived_files] : [];
        let removed = list.splice(index, 1)
        archived.push(...removed)
        document.archived_files = archived
        document.files = list
        setData({ ...data, document: document })
    }

    useEffect(function () {
        var list = []
        for (var i in props.featureList) {
            var feature = props.featureList[i]
            if (feature.property_type === "all" || feature.property_type === data.property_type)
                list.push({ value: feature.key, label: feature.title })
        }
        setCreatableFeatureList(list)
    }, [props.featureList, data.property_type])


    const toCreatableList = (f) => {
        var list = []
        for (var i in f) {
            list.push({ value: f[i].value, label: f[i].title })
        }
        return list
    }

    return (

        <div className="w-full flex bg-slate-50">
            <SideNavBar {...props} isPresented={true} />
            <main className="w-full h-full flex flex-col">
                <TopSearchbar {...props} />

                <div className="flex-1 flex relative w-full ">
                    <div className="container mx-auto">
                        <PhotoManager setData={setData} data={data} images={data.images} archive_images={data.archive_images} isPresented={showPhotoManager} dismiss={(e) => { setShowPhotoManager(false) }}></PhotoManager>
                        <div className="mb-16 w-full container mx-auto py-8 px-4">

                            <div className="mb-8 flex justify-between items-center">
                                <div>
                                    <div className="flex flex-row mb-1">
                                        <Link to="/v2/properties" className="text-xs uppercase font-medium text-gray-600 inline-flex items-center">
                                            <svg className="fill-current text-gray-600 mr-2" width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.890625 5.76953C0.890625 5.91602 0.943359 6.04492 1.05469 6.15625L5.70117 10.6973C5.80078 10.8027 5.92969 10.8555 6.08203 10.8555C6.38672 10.8555 6.62109 10.627 6.62109 10.3223C6.62109 10.1699 6.55664 10.041 6.46289 9.94141L2.19727 5.76953L6.46289 1.59766C6.55664 1.49805 6.62109 1.36328 6.62109 1.2168C6.62109 0.912109 6.38672 0.683594 6.08203 0.683594C5.92969 0.683594 5.80078 0.736328 5.70117 0.835938L1.05469 5.38281C0.943359 5.48828 0.890625 5.62305 0.890625 5.76953Z" />
                                            </svg>
                                            <span>{Translate("Back")}</span>
                                        </Link>
                                    </div>

                                    <p className="text-2xl font-semibold">{Translate("Update property")}</p>
                                </div>

                                <div className="py-2 flex justify-end">
                                    <a id="btn-view-on-villa" className="btn text-sm rounded bg-blue-600 text-white inline-flex items-center" target="_blank" href={`${Constants.BASE_URL}/${data.area.id}/${data.name}/${data.property_id}`}>
                                        <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                        </svg>
                                        <span className="ml-2">{Translate("View on Viila")}</span>
                                    </a>
                                </div>
                            </div>
                            <form onSubmit={onSubmit}>
                                <div className="w-full mt-4">
                                    <div className="grid grid-cols-1 divide-y text-sm shadow rounded-lg bg-white">

                                        <div className="col-span-1 py-6  px-6">
                                            <div className="flex items-center">
                                                <div>
                                                    <p className="text-xl font-medium">{Translate("Property info")}</p>
                                                </div>
                                                <div className="ml-auto">
                                                    <div className="hidden lg:block">
                                                        <div>
                                                            <RenderActionButtons status={data.status} publishProperty={publishProperty} confirmStatus={confirmStatus}></RenderActionButtons>
                                                        </div>
                                                    </div>
                                                    <div className="block lg:hidden">
                                                        <div className="relative inline-block text-left">
                                                            <div>
                                                                <button type="button" onClick={(e) => { toggleOptionsMenu() }} className="focus:outline-none inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50  focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500" id="options-menu" aria-haspopup="true" aria-expanded="true">
                                                                    Options
                                                                    <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                                        <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                                                                    </svg>
                                                                </button>
                                                            </div>

                                                            <div className={"origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 " + (optionsVisible === true ? "" : "hidden")}>
                                                                <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                                                                    <button type="button" onClick={(e) => { confirmStatus("inactive"); toggleOptionsMenu() }} className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{Translate("Delist this property")}</button>
                                                                    <button type="button" onClick={(e) => { confirmStatus("active"); toggleOptionsMenu() }} className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{Translate("Mark as Active")}</button>
                                                                    <button type="button" onClick={(e) => { confirmStatus("business_negotiation"); toggleOptionsMenu() }} className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{Translate("Mark as Negotiation")}</button>
                                                                    <button type="button" onClick={(e) => { confirmStatus("sold"); toggleOptionsMenu() }} className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900" role="menuitem">{Translate("Mark as Sold")}</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-span-1 py-4 lg:py-4 px-6">
                                            <div className="grid grid-cols-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Property ID")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <div className="inline-flex items-center space-x-2 w-full"><p>{data.property_id}</p> </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-span-1 py-4 lg:py-4 px-6">
                                            <div className="grid grid-cols-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Area")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <Select
                                                        required
                                                        styles={selectStyles}
                                                        className="text-base border border-gray-600 rounded "
                                                        name="area"
                                                        id="area"
                                                        options={props.areas}
                                                        getOptionLabel={({ name }) => name}
                                                        getOptionValue={({ id }) => id}
                                                        onChange={e => {

                                                            setData({ ...data, area: e });
                                                            if (!data.latlng.latitude || !data.latlng.longitude) {
                                                                setViewport(e.latlng)
                                                            }
                                                            loadApartments(e.id)
                                                        }}
                                                        value={props.areas.filter(({ id }) => id === data.area.id)}
                                                    />
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-span-1 py-4 lg:py-4 px-6">
                                            <div className="grid grid-cols-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Status")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <PropertyStatusBadge status={data.status}></PropertyStatusBadge>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-span-1 py-4 lg:py-4 px-6">

                                            <div className="grid grid-cols-3 items-center">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Transaction Type")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <div className="flex flex-col lg:flex-row space-y-1 lg:space-y-0">
                                                        <label className="inline-flex items-center mr-6">
                                                            <input type="radio" name="transaction_type" id="transaction_type-seller" value="seller" onChange={handleDataChange} checked={data.transaction_type === "seller"} />
                                                            <span className="ml-2">{Translate("Seller")}</span>
                                                        </label>
                                                        <label className="inline-flex items-center mr-6">
                                                            <input type="radio" name="transaction_type" id="transaction_type-brokerage_general" value="brokerage_general" onChange={handleDataChange} checked={data.transaction_type === "brokerage_general"} />
                                                            <span className="ml-2">{Translate("Brokerage (General)")}</span>
                                                        </label>
                                                        <label className="inline-flex items-center mr-6">
                                                            <input type="radio" name="transaction_type" id="transaction_type-brokerage_exclusive" value="brokerage_exclusive" onChange={handleDataChange} checked={data.transaction_type === "brokerage_exclusive"} />
                                                            <span className="ml-2">{Translate("Brokerage (Exclusive)")}</span>
                                                        </label>
                                                        <label className="inline-flex items-center mr-6">
                                                            <input type="radio" name="transaction_type" id="transaction_type-brokerage_privileged_exclusive" value="brokerage_privileged_exclusive" onChange={handleDataChange} checked={data.transaction_type === "brokerage_privileged_exclusive"} />
                                                            <span className="ml-2">{Translate("Brokerage (Privileged Exclusive)")}</span>
                                                        </label>
                                                        <label className="inline-flex items-center mr-6">
                                                            <input type="radio" name="transaction_type" id="transaction_type-proxy" value="proxy" onChange={handleDataChange} checked={data.transaction_type === "proxy"} />
                                                            <span className="ml-2">{Translate("Proxy")}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-span-1 py-4 lg:py-4 px-6">
                                            <div className="grid grid-cols-3 items-center">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Property Type")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <div className="flex">
                                                        <label className="inline-flex items-center mr-6">
                                                            <input type="radio" name="property_type" id="property_type-house" value="house" onChange={handleDataChange} checked={data.property_type === "house"} />
                                                            <span className="ml-2">{Translate("House")}</span>
                                                        </label>
                                                        <label className="inline-flex items-center mr-6">
                                                            <input type="radio" name="property_type" id="property_type-lot" value="lot" onChange={handleDataChange} checked={data.property_type === "lot"} />
                                                            <span className="ml-2">{Translate("Land")}</span>
                                                        </label>
                                                        <label className="inline-flex items-center">
                                                            <input type="radio" name="property_type" id="property_type-apartment" value="apartment" onChange={handleDataChange} checked={data.property_type === "apartment"} />
                                                            <span className="ml-2">{Translate("Apartment")}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-span-1 py-4 lg:py-4 px-6">
                                            <div className="grid grid-cols-3 items-center">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Property title")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <input required name="name" id="name" value={data.name} onChange={handleDataChange} type="text" placeholder={Translate("Tsurudamari Residence")} className="rounded w-full" />
                                                </div>
                                            </div>
                                        </div>
                                        {data.property_type == "apartment" ?
                                            <>
                                                <div className="col-span-1 py-4 lg:py-4 px-6">
                                                    <div className="grid grid-cols-3 items-center">
                                                        <div className="col-span-3 lg:col-span-1">
                                                            <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Apartment")}</p>
                                                        </div>
                                                        <div className="col-span-3 lg:col-span-2">
                                                            <Select
                                                                required
                                                                styles={selectStyles}
                                                                className="text-base border border-gray-600 rounded "
                                                                name="apartment"
                                                                id="apartment"
                                                                options={apartments}
                                                                getOptionLabel={({ name }) => name}
                                                                getOptionValue={({ id }) => id}
                                                                onChange={e => {
                                                                    setFloors(Number(e.amount_of_floors))
                                                                    setData({ ...data, apartment: e.id, floor: "" })
                                                                }}
                                                                value={apartments.filter(({ id }) => id === data.apartment)} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-span-1 py-4 lg:py-4 px-6">
                                                    <div className="grid grid-cols-3 items-center">
                                                        <div className="col-span-3 lg:col-span-1">
                                                            <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Floor")}</p>
                                                        </div>
                                                        <div className="col-span-3 lg:col-span-2">

                                                        <Select
                                                            required
                                                            styles={selectStyles}
                                                            className="text-base border border-gray-600 rounded "
                                                            name="floor"
                                                            id="floor"
                                                            options={Array.from(Array(floors), (x, i) => { return { value: (i + 1).toString(), label: (i + 1).toString() } })}
                                                            onChange={e => {
                                                                setData({ ...data, floor: e.value })
                                                            }}
                                                            value={{ value: data.floor, label: data.floor }} />
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="col-span-1 py-4 lg:py-4 px-6">
                                                    <div className="grid grid-cols-3 items-center">
                                                        <div className="col-span-3 lg:col-span-1">
                                                            <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Room number")}</p>
                                                        </div>
                                                        <div className="col-span-3 lg:col-span-2">
                                                            <input required name="room_number" id="room_number" value={data.room_number} onChange={handleDataChange} type="text" placeholder={Translate("Room number")} className="rounded w-full" />
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                            : null}
                                        <div className="col-span-1 py-4 lg:py-4 px-6">
                                            <div className="grid grid-cols-3 items-center">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Reference ID")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <input name="reference_id" id="reference_id" value={data.reference_id} onChange={handleDataChange} type="text" placeholder={Translate("Property ID in your system")} className="rounded w-full" />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-span-1 py-4 lg:py-4 px-6">
                                            <div className="grid grid-cols-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Photos")}</p>

                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <button type="button" id="manage-photos-button" className="px-3 py-2 rounded text-sm bg-blue-600 text-white inline-flex items-center mr-3" onClick={e => setShowPhotoManager(true)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                                                        </svg>
                                                        <span>{Translate("Manage Photos")} ({data.images.length})</span>
                                                    </button>

                                                    <button type="button" id="request-take-photo-button" className="px-2 py-2 rounded text-sm bg-blue-100 text-blue-600 inline-flex items-center" onClick={e => setShowPopup(true)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z" />
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z" />
                                                        </svg>
                                                        <span>{Translate("Request taking photos")}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-span-1 py-4 lg:py-4 px-6">
                                            <div className="grid grid-cols-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Video URL")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2 inline-flex items-center">
                                                <input name="video.url" id="video-url" value={data.video?.url || ""} onChange={handleDataChange} type="text" placeholder="https://youtube.com/..." className="rounded flex-1" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-1 py-4 lg:py-4 px-6">
                                            <div className="grid grid-cols-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Virtual Tour URL")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2 inline-flex items-center">
                                                    <input name="vr.url" id="vr-url" value={data.vr === null ? "" : data.vr.url} onChange={handleDataChange} type="text" placeholder="https://..." className="rounded flex-1" />
                                                    <button type="button" id="request-3d-scan-button" className="ml-2 flex-none h-full px-2 py-2 rounded text-sm bg-blue-100 text-blue-600 inline-flex items-center" onClick={e => setShowPopup(true)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 10l-2 1m0 0l-2-1m2 1v2.5M20 7l-2 1m2-1l-2-1m2 1v2.5M14 4l-2-1-2 1M4 7l2-1M4 7l2 1M4 7v2.5M12 21l-2-1m2 1l2-1m-2 1v-2.5M6 18l-2-1v-2.5M18 18l2-1v-2.5" />
                                                        </svg>
                                                        <span>{Translate("Request 3D scan")}</span>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-span-1 py-4 lg:py-4 px-6">
                                            <div className="grid grid-cols-3 items-top">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Size")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <div className="grid grid-cols-2 gap-4 mb-2">
                                                        {
                                                            data.property_type === "house" || data.property_type === "apartment" ?

                                                                <div className="col-span-1">
                                                                    <label htmlFor="sqm" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Floor area in m²")}</label>
                                                                    <input required={data.property_type === "house" || data.property_type === "apartment" ? true : false} value={data.sqm === 0 ? "" : data.sqm}
                                                                        name="sqm"
                                                                        id="sqm"
                                                                        data-type="float"
                                                                        onChange={handleInputChange}
                                                                        onBlur={handleInputBlur}
                                                                        onKeyUp={handleInputChange}
                                                                        type="text"
                                                                        placeholder="94.3" className="rounded w-full" />
                                                                </div>

                                                                : null
                                                        }

                                                        {
                                                            data.property_type === "house" || data.property_type === "lot" ?
                                                                <div className="col-span-1">
                                                                    <label htmlFor="land.sqm" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Lot size in m²")}</label>
                                                                    <input required={data.property_type === "house" || data.property_type === "lot" ? true : false}
                                                                        name="land.sqm"
                                                                        id="land-sqm"
                                                                        value={data.land.sqm === 0 ? "" : data.land.sqm}
                                                                        data-type="float"
                                                                        onChange={handleInputChange}
                                                                        onBlur={handleInputBlur}
                                                                        onKeyUp={handleInputChange}
                                                                        type="text"
                                                                        step="0.01"
                                                                        placeholder="800.5" className="rounded w-full" />
                                                                </div>

                                                                : null
                                                        }


                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                        {
                                            data.property_type === "lot" || data.property_type === "apartment" ? null
                                                :
                                                <div className="col-span-1 py-4 lg:py-4 px-6">
                                                    <div className="grid grid-cols-3">
                                                        <div className="col-span-3 lg:col-span-1">
                                                            <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Built")}</p>
                                                        </div>
                                                        <div className="col-span-3 lg:col-span-2">
                                                            <div className="grid grid-cols-2 gap-4 mb-2">
                                                                <div className="col-span-1">
                                                                    <label htmlFor="built.month" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Month")}</label>
                                                                    <input required={data.property_type === "house" || data.property_type === "apartment" ? true : false} name="built.month" id="built-month" value={data.built.month === 0 ? "" : data.built.month} onChange={handleInputChange} onBlur={handleInputBlur} data-type="int" type="text" pattern="\d*" min="1" max="12" placeholder="10" className="rounded w-full" />
                                                                </div>
                                                                <div className="col-span-1">
                                                                    <label htmlFor="built.year" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Year")}</label>
                                                                    <input required={data.property_type === "house" || data.property_type === "apartment" ? true : false} name="built.year" id="built-year" value={data.built.year === 0 ? "" : data.built.year} onChange={handleInputChange} onBlur={handleInputBlur} data-type="int" type="text" pattern="\d*" placeholder="2019" className="rounded w-full" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                        }
                                        <div className="col-span-1 py-4 lg:py-4 px-6">
                                            <div className="grid grid-cols-3 items-center">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Price")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <div className="relative">
                                                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                                            <span className="font-medium text-gray-600">
                                                                ¥
                                                            </span>
                                                        </div>
                                                        <input required
                                                            name="price.amount"
                                                            id="price-amount"
                                                            type="text"
                                                            data-type="price"
                                                            placeholder="50,000,000"
                                                            className="pl-7 rounded w-full"
                                                            value={data.price.amount}
                                                            onMouseOver={event => {
                                                                var text = event.target.value.replaceAll(",", "")
                                                                setData({ ...data, ["price"]: { ...data["price"], ["amount"]: text } })
                                                            }}
                                                            onChange={event => {
                                                                setData({ ...data, ["price"]: { ...data["price"], ["amount"]: event.target.value } })
                                                            }}
                                                            onBlur={handlePriceChange}
                                                            onMouseOut={handlePriceChange}
                                                        />
                                                    </div>
                                                    {
                                                        data.previous_prices ? data.previous_prices.map((price, index) =>
                                                            <div className="mt-2 font-medium text-gray-600" key={`previous-prices-${index}`} id={`previous-prices-${index}`}>
                                                                ¥{price.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} {moment(price.updated_at).fromNow()}
                                                            </div>
                                                        ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-1 py-4 lg:py-4 px-6" style={{ display: data.property_type == "apartment" ? "none" : "block" }}>
                                            <div className="grid grid-cols-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Location")}</p>
                                                    <button type="button" id="open-mapbox" onClick={(e) => { setShowMap(!showMap) }} className="focus:outline-none text-xs font-medium text-indigo-600 mb-2">{Translate("Open Maps to pin exact location")}</button>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">

                                                    <div className="grid grid-cols-2 gap-4 mb-2">
                                                        <div className="col-span-1">
                                                            <label htmlFor="latlng.latitude" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Latitude")}</label>
                                                            {data.property_type == "apartment" ? null : <input
                                                                required
                                                                name="latlng.latitude"
                                                                id="latlng-latitude"
                                                                value={data.latlng.latitude}
                                                                data-type="float"
                                                                onChange={handleInputChange}
                                                                onBlur={handleInputBlur}
                                                                onKeyUp={handleInputChange}
                                                                type="text"
                                                                placeholder="36.35733" className="rounded w-full" />}
                                                        </div>
                                                        <div className="col-span-1">
                                                            <label htmlFor="latlng.longitude" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Longitude")}</label>
                                                            {data.property_type == "apartment" ? null : <input
                                                                required
                                                                name="latlng.longitude"
                                                                id="latlng-longitude"
                                                                value={data.latlng.longitude}
                                                                data-type="float"
                                                                onChange={handleInputChange}
                                                                onBlur={handleInputBlur}
                                                                onKeyUp={handleInputChange}
                                                                type="text"
                                                                placeholder="138.579028" className="rounded w-full" />}
                                                        </div>

                                                        <div className={"col-span-2 " + (showMap === false ? "hidden" : "block")}>
                                                            <div className="h-96 w-full bg-gray-300 rounded relative">
                                                                <ReactMapGL
                                                                    ref={mapRef}
                                                                    doubleClickZoom={false}
                                                                    onClick={handleMapClick}
                                                                    //onDblClick={handleMapClick}

                                                                    mapStyle="mapbox://styles/apisitviila/ckwqdi01c0pjz14pdgapgheaw"
                                                                    mapboxAccessToken={MAPBOX_TOKEN}
                                                                    {...viewport}
                                                                    onMove={onViewportChange}
                                                                    style={{ width: "100%", height: "100%" }}
                                                                >
                                                                    {marker.longitude != 0 && marker.latitude != 0 ? (<Marker
                                                                        color="#ef4444"
                                                                        longitude={marker.longitude}
                                                                        latitude={marker.latitude}
                                                                        draggable
                                                                        onDrag={onMarkerDrag}
                                                                        onDragEnd={onMarkerDragEnd}
                                                                    ></Marker>) : null}

                                                                    <GeolocateControl style={geolocateStyle} />
                                                                    <NavigationControl style={navStyle} />
                                                                    <ScaleControl style={scaleControlStyle} />
                                                                </ReactMapGL>
                                                                <div className="absolute top-3 left-1/2 transform -translate-x-1/2 w-2/3 flex flex-col">
                                                                    <Geocoder {...props} 
                                                                        selectedLatLng={{ lat: 0, lng: 0 }}
                                                                        onResult={onGeocoderResult}
                                                                    ></Geocoder>
                                                                </div>
                                                            </div>
                                                            <p className="text-sm mt-2 text-gray-600">{Translate("Click on the map to assign property location")}</p>
                                                        </div>

                                                        <div className={"inline-flex items-center mb-2 " + (showMap === false ? "hidden" : "block")}>
                                                            <input id="privacy_mode" checked={data.privacy_mode} onChange={handlePrivacyModeChange} name="privacy_mode" type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                                                            <label htmlFor="privacy_mode" className="ml-2 block text-sm text-gray-900"> {Translate("Show estimated area instead of an exact location on the map")}</label>
                                                        </div>


                                                    </div>

                                                    <div className={"mt-4 grid grid-cols-1 lg:grid-cols-2 gap-4 " + (showMap === false ? "hidden" : "block")}>
                                                        <div className="lg:col-span-2">
                                                            <p>{Translate("Address")}</p>
                                                            <p className="text-xs text-gray-600">{Translate("address_remark")}</p>
                                                            <button type="button" id="reload-address" className="underline text-blue-700" onClick={e => reverseGeocoding({ lat: parseFloat(data.latlng.latitude), lng: parseFloat(data.latlng.longitude) })}>{Translate("Reload address")}</button>
                                                        </div>
                                                        <div className="lg:col-span-1">
                                                            <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("Zipcode")}</label>
                                                            <input type="text" name="zip_code" id="zip_code" placeholder="" onChange={handleZipCodeChange} value={data.address.zip_code} className="rounded w-full" />
                                                        </div>

                                                        <div className="lg:col-span-1">
                                                            <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("Prefecture")}</label>
                                                            <input type="text" name="prefecture" id="prefecture" placeholder="" onChange={handlePrefectureChange} value={data.address.prefecture} className="rounded w-full" />
                                                        </div>

                                                        <div className="lg:col-span-1">
                                                            <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("District")}</label>
                                                            <input type="text" name="district" id="district" placeholder="" onChange={handleDistrictChange} value={data.address.district} className="rounded w-full" />
                                                        </div>

                                                        <div className="lg:col-span-1">
                                                            <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("City")}</label>
                                                            <input type="text" name="city" id="city" placeholder="" onChange={handleCityChange} value={data.address.city} className="rounded w-full" />
                                                        </div>

                                                        <div className="lg:col-span-1">
                                                            <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("Address 1")}</label>
                                                            <input type="text" name="address_1" id="address_1" placeholder="" onChange={handleAddress1Change} value={data.address.address_1} className="rounded w-full" />
                                                        </div>

                                                        <div className="lg:col-span-1">
                                                            <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("Address 2")}</label>
                                                            <input type="text" name="address_2" id="address_2" placeholder="" onChange={handleAddress2Change} value={data.address.address_2} className="rounded w-full" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-1 py-4 lg:py-4 px-6">
                                            <div className="grid grid-cols-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Overview")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <textarea required rows="6" name="overview" id="overview" value={data.overview} onChange={handleDataChange} placeholder={Translate("Describe this property")} className="rounded w-full" >
                                                    </textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-span-1 py-4 lg:py-4 px-6">
                                            <div className="grid grid-cols-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Remarks")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <textarea rows="2" name="remarks" id="remarks" value={data.remarks} onChange={handleDataChange} placeholder={Translate("Remarks")} className="rounded w-full" >
                                                    </textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-span-1 py-4 lg:py-4 px-6">
                                            <div className="grid grid-cols-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Regulations")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <textarea rows="2" name="regulations" id="regulations" value={data.regulations} onChange={handleDataChange} placeholder={Translate("Restrictions or regulations")} className="rounded w-full" >
                                                    </textarea>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-span-1 py-4 pt-8 lg:pb-4 px-6">
                                            <p className="text-lg font-medium">{Translate("Home Facts and Features")}</p>
                                            <p className="text-sm text-gray-700">{Translate("Facts and features of this property. e.g. Architecture, Zoning, Terrain, etc.")}</p>
                                        </div>
                                        {
                                            data.property_type === "lot" ? null
                                                :
                                                <div className="col-span-1 py-4 lg:py-4 px-6">
                                                    <div className="grid grid-cols-3">
                                                        <div className="col-span-3 lg:col-span-1">
                                                            <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Layout")}</p>
                                                        </div>
                                                        <div className="col-span-3 lg:col-span-2">
                                                            <div className="grid grid-cols-2 gap-4 mb-2">
                                                                <div className="col-span-2">
                                                                    <label htmlFor="layout.name" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Layout title")}</label>
                                                                    <input required={data.property_type === "house" || data.property_type === "apartment" ? true : false} name="layout.name" id="layout-name" value={data.layout.name} onChange={handleDataChange} type="text" placeholder={Translate("3 bedrooms with storage")} className="rounded w-full" />
                                                                </div>
                                                                <div className="col-span-1">
                                                                    <label htmlFor="layout.bedrooms" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Bedrooms")}</label>
                                                                    <input required={data.property_type === "house" || data.property_type === "apartment" ? true : false} name="layout.bedrooms" id="layout-bedrooms" value={data.layout.bedrooms === 0 ? "" : data.layout.bedrooms} onChange={handleInputChange} onBlur={handleInputBlur} data-type="int" type="text" pattern="\d*" placeholder="3" className="rounded w-full" />
                                                                </div>
                                                                <div className="col-span-1">
                                                                    <label htmlFor="layout.bedrooms" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Bathrooms")}</label>
                                                                    <input required={data.property_type === "house" || data.property_type === "apartment" ? true : false} name="layout.bathrooms" id="layout-bathrooms" value={data.layout.bathrooms === 0 ? "" : data.layout.bathrooms} onChange={handleInputChange} onBlur={handleInputBlur} data-type="int" type="text" pattern="\d*" placeholder="1" className="rounded w-full" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                        }
                                        <div className="col-span-1 py-4 lg:py-4 px-6">
                                            <div className="grid grid-cols-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Facts and Features")}</p>
                                                </div>

                                                <div className="col-span-3 lg:col-span-2">
                                                    {
                                                        apartments.find(({ id }) => id === data.apartment) && data.property_type === "apartment" ? apartments.find(({ id }) => id === data.apartment).features.map((feature, index) => (
                                                            <div key={`apartment-feature-${index}`} className="grid grid-cols-2 gap-4 gap-y-0 mb-2 items-center">
                                                                <div className="col-span-1">
                                                                    <input id={`apartment-${feature.title}-key`} value={Translate(feature.title)} type="text" className="rounded w-full text-gray-400 border-gray-200" disabled />
                                                                </div>

                                                                <div className="col-span-1">
                                                                    <div className="flex items-center">
                                                                        <div className="w-full">
                                                                            <input id={`apartment-${feature.title}-value`} value={Translate(feature.value)} type="text" className="rounded w-full text-gray-400 border-gray-200" disabled />
                                                                        </div>
                                                                        <div className="ml-auto pl-2">
                                                                            <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">&nbsp;</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )) : null
                                                    }

                                                    {data.features === null ? null : data.features.map((feature, index) => (

                                                        <div key={`f-${index}`} className="grid grid-cols-2 gap-4 gap-y-0 mb-2 items-start">

                                                            <div className="col-span-1">
                                                                <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700"></label>

                                                                {
                                                                    feature.data === null ?
                                                                        <input required name="title" onChange={(e) => handleFeatureTitleChange(e.target.value, index)} value={feature.title} type="text" className="rounded w-full" />
                                                                        :
                                                                        // <Select
                                                                        //     styles={selectStyles}
                                                                        //     className="text-base border border-gray-600 rounded "
                                                                        //     name={`feature-${feature.title.replaceAll(" ", "-").toLowerCase()}`}
                                                                        //     id={`feature-${feature.title.replaceAll(" ", "-").toLowerCase()}`}
                                                                        //     options={props.featureList}
                                                                        //     getOptionLabel={({ title }) => title}
                                                                        //     getOptionValue={({ key }) => key}
                                                                        //     onChange={(value) => handleFeatureTitleChange(value, index)}
                                                                        //     value={props.featureList.filter(({ key }) => key === feature.title)}
                                                                        // />
                                                                        <CreatableSelect
                                                                            styles={selectStyles}
                                                                            className="text-base border border-gray-600 rounded "
                                                                            name={`feature-${(feature.key ? feature.key : feature.title).replaceAll(" ", "-").toLowerCase()}`}
                                                                            id={`feature-${(feature.key ? feature.key : feature.title).replaceAll(" ", "-").toLowerCase()}`}
                                                                            options={creatableFeatureList}
                                                                            onChange={({ value, label }) => handleFeatureTitleChange({ key: value, title: label }, index)}
                                                                            value={{ value: feature.title, label: Translate(feature.title) }}
                                                                        />
                                                                }

                                                            </div>

                                                            <div className="col-span-1">
                                                                <div className="flex items-center">
                                                                    <div className="w-full">
                                                                        <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700"></label>
                                                                        {RenderFeatureValue(feature, index)}
                                                                    </div>
                                                                    <div className="ml-auto pl-2">
                                                                        <button type="button" id={`remove-feature-${feature.title.replaceAll(" ", "-").toLowerCase()}`} onClick={(e) => removeFeature(index, feature)} className="focus:outline-none text-xs font-medium text-red-500">
                                                                            <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    ))}
                                                    <button type="button" id="add-feature" className="focus:outline-none text-xs font-medium text-indigo-600 mb-2" onClick={(e) => addFeature()}>+ {Translate("Add feature")}</button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-span-1 py-4 pt-8 lg:pb-4 px-6">
                                            <p className="text-lg font-medium">{Translate("Other Costs")}</p>
                                            <p className="text-sm text-gray-700">{Translate("Beside the standard taxes and brokerage fees, Please list other necessary costs which buyer needs to pay. e.g. Management fee, Water supply reserve, etc.")}</p>
                                        </div>
                                        <div className="col-span-1 py-4 lg:py-4 px-6">
                                            <div className="grid grid-cols-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Costs")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">

                                                    {data.other_costs === null ? null : data.other_costs.map((cost, index) => (
                                                        <div key={`cost-${index}`} className="grid grid-cols-3 gap-2 items-center mb-6">

                                                            <div className="col-span-3 lg:col-span-1">
                                                                <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Cost title")}</label>
                                                                <input required name="title" id={`cost-${index}-title`} onChange={(e) => handleCostChange(index, e)} value={cost.title} type="text" placeholder={Translate("Management fee")} className="rounded w-full" />
                                                            </div>

                                                            <div className="col-span-2 lg:col-span-1">
                                                                <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Amount")}</label>
                                                                <input required
                                                                    value={cost.price.amount.trim().replaceAll(",", "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                                                    data-type="price"
                                                                    onChange={(e) => handleCostPriceChange(index, e)}
                                                                    id={`cost-${index}-value`}
                                                                    name={`cost-${index}-value`}
                                                                    type="text"
                                                                    placeholder="50,000" className="rounded w-full" />
                                                            </div>

                                                            <div className="col-span-1 lg:col-span-1">
                                                                <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Pay")}</label>
                                                                <select name="type" id={`cost-${index}-type`} onChange={(e) => handleCostChange(index, e)} value={cost.type} className="rounded w-full">
                                                                    <option value="monthly">{Translate("Monthly")}</option>
                                                                    <option value="yearly">{Translate("Yearly")}</option>
                                                                    <option value="one-time">{Translate("One time")}</option>
                                                                </select>
                                                            </div>

                                                            <div className="col-span-3 lg:col-span-3">

                                                                <div className="flex items-center">
                                                                    <div className="w-full">
                                                                        <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Remark")}</label>
                                                                        <input id={`cost-${index}-description`} name="description" onChange={(e) => handleCostChange(index, e)} value={cost.description} type="text" placeholder={Translate("Mitsui Villa management")} className="rounded w-full" />
                                                                    </div>
                                                                    <div className="ml-auto">
                                                                        <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">&nbsp;</label>
                                                                        <button type="button" id={`remove-cost-${index}`} onClick={(e) => removeCost(index)} className="focus:outline-none text-xs font-medium text-red-500 ml-2">
                                                                            <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                    <button type="button" id="add-cost" onClick={(e) => addCost()} className="focus:outline-none text-xs font-medium text-indigo-600 mb-2">+ {Translate("Add cost")}</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span-1 py-4 pt-8 lg:pb-4 px-6">
                                            <p className="text-lg font-medium">{Translate("Property Materials")}</p>
                                            <p className="text-sm text-gray-700">{Translate("Viila users will immediately receive property materials via email.")}</p>
                                        </div>

                                        <div className="col-span-1 py-4 lg:py-4 px-6">
                                            <div className="grid grid-cols-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Property Materials")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    {data.document.files === null ? null : data.document.files.map((file, index) => (
                                                        <div className="flex mb-2">
                                                            <a href={file.url} id={`file-${index}`} className="w-full" target="_blank" > <p>{file.url.substring(file.url.lastIndexOf('/') + 1)}</p></a>
                                                            <button type="button" id={`remove-file-${index}`} onClick={(e) => removeFileAt(index)} className="ml-2 focus:outline-none text-xs font-medium text-red-500">
                                                                <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                </svg>
                                                            </button>
                                                        </div>

                                                    ))}

                                                    <div {...dropMessageAttachments.getRootProps()} className="mt-4 w-full flex items-center text-center mx-auto h-24 border-dashed border-2 rounded border-gray-400 bg-white">
                                                        <div className="mx-auto text-xs">

                                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto mb-2 stroke-current text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                                            </svg>
                                                            <input className="w-full h-full" {...dropMessageAttachments.getInputProps()} />
                                                            <p><button type="button" className="focus:outline-none text-indigo-600 font-medium">{Translate("Upload files")}</button> {Translate("or drag and drop")}</p>
                                                            <p className="text-xs">{Translate("JPG, PNG, PDF, XLS, DOC, DOCX")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-3">
                                                <div className="col-span-3 lg:col-span-1 pr-4">
                                                    <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Your auto-reply message.")}</p>
                                                    <p className="text-xs text-gray-700 mb-2 lg:mb-0">{Translate("When a Viila user requests property materials, this message will be appeared in the automated email we send to the user.")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <textarea rows="3" onChange={(e) => {
                                                        var document = data.document
                                                        document.data = { message: e.target.value }
                                                        setData({ ...data, document: document })
                                                    }} value={data.document.data.message} type="text" placeholder={Translate("Reply message")} className="rounded w-full mt-4" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-1 py-4 pt-8 lg:pb-4 px-6">
                                            <div className="flex">
                                                <input id="private_property" checked={data.is_private} onChange={handlePrivatePropertyChange} name="is_private" type="checkbox" className="m-4 h-6 w-6 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                                                <div className="ml-8">
                                                    <p className="text-lg font-medium">{Translate("exclude_from_viila_map_checkbox_label")}</p>
                                                    <p className="text-sm text-gray-700">{Translate("exclude_from_viila_map_description")}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-span1 py-2 bg-gray-200 border-t px-6 flex items-center">
                                            <div className="py-2 ml-auto">
                                                <button id="update-button" className={`btn btn-primary inline-flex items-center ${updated === true ? "bg-green-500" : ""}`} type="submit">
                                                    {
                                                        saving ?
                                                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>
                                                            : null
                                                    }

                                                    {
                                                        saving ? <span id="loading-message">{Translate("Saving...")}</span>
                                                            : updated ? <span id="done-message">{Translate("Done")}</span> : <span>{Translate("Update")}</span>
                                                    }
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <Alert {...props} isPresented={showAlert} message={message} dismiss={(e) => { setShowAlert(false) }}></Alert>
                    <Confirm {...props} isPresented={showPopup} confirm={data => saveSurvey(data)} dismiss={(e) => { setShowPopup(false) }}></Confirm>
                    <StatusInfo {...props} property={data} isPresented={showStatusInfo} statusWithInfo={statusWithInfo} confirm={data => markAs(data)} dismiss={(e) => { setShowStatusInfo(false) }}></StatusInfo>
                    <Error {...props} isPresented={showError} errorMessage={errorMessage} dismiss={(e) => { setShowError(false) }}></Error>
                </div>
            </main>
        </div>
    )
}