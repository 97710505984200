import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

export default function TransactionDetail(props) {

    const { isPresented, onClose, data, Translate } = props
    
    if (!data) {
        return (<></>)
    }


    const sample = { "id": "b5ef2fde-dbb8-4d4d-8e12-563457625f91", "land_type": "宅地(土地と建物)", "zone_area": "商業地", "city_plan_zone": "商業地域", "official_city_id": 13113, "prefecture": "東京都", "city": ["渋谷区"], "nearest_station": "渋谷", "distance": 3, "transaction_price": 1700000000, "area": 175, "is_approximated_area": false, "layout": "", "landshape": "不整形", "frontage": 9, "is_approximated_frontage": false, "floor_area": 1200, "is_approximated_floor_area": false, "year": 1984, "is_approximated_year": false, "building_structure": ["ＳＲＣ"], "usage": ["事務所、倉庫、店舗"], "frontage_road_direction": "西", "frontage_road_breadth": 70229, "maximum_floor_area_ratio": 80, "transaction_period_year": 2017, "transaction_period_quarter": 3, "is_renovated": false, "remarks": "" }

    return (
        <>
            <Transition appear show={isPresented} as={Fragment}>
                <Dialog as="div" className="relative z-[50]" onClose={() => onClose()}>
                    <Transition

                        enter="ease-out duration-300 "
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-100"
                    >

                        <Dialog.Overlay className="fixed inset-0 w-full h-full bg-black/60" />
                    </Transition>

                    <div className="fixed top-0 left-0 inset-0 w-full h-full outline-none bg-transparent">
                        <div className="flex items-center justify-end h-full">
                            <Transition.Child
                                as={Fragment}
                                enter="ease duration-300 transition transform"
                                enterFrom="opacity-0 translate-x-full"
                                enterTo="opacity-100 translate-x-0"
                                leave="ease duration-200 transition transform"
                                leaveFrom="opacity-100 translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="border-none shadow-lg relative flex flex-col lg:w-3/5 w-full h-full pointer-events-auto bg-white bg-clip-padding  outline-none text-current">
                                    <Dialog.Title
                                        as="div"
                                        className="flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200"
                                    >
                                        <h3 className='text-lg font-medium leading-6'>
                                        Q{data.transaction_period_quarter}&nbsp;{data.transaction_period_year} · {data.city} · {data.land_type} · {data.nearest_station} · {data.area}m²
                                        </h3>
                                        <button type="button" id="close" onClick={() => onClose()}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </Dialog.Title>
                                    <div className="relative flex-1 overflow-hidden overflow-y-auto px-4">
                                        {/* {JSON.stringify(data)} */}
                                        <div className="flex flex-col divide-y">
                                            <div className="grid grid-cols-3 items-start py-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-500 mb-2 lg:mb-0">{Translate("Period")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                Q{data.transaction_period_quarter}&nbsp;{data.transaction_period_year} 
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-3 items-start py-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-500 mb-2 lg:mb-0">{Translate("Land type")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    {data.land_type}
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-3 items-start py-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-500 mb-2 lg:mb-0">{Translate("city")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    {data.city}
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-3 items-start py-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-500 mb-2 lg:mb-0">{Translate("Zoning")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    {data.zone_area ? data.zone_area : "–"}
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-3 items-start py-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-500 mb-2 lg:mb-0">{Translate("Nearest station")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                {data.nearest_station} · {data.distance} {Translate("minutes")}
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-3 items-start py-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-500 mb-2 lg:mb-0">{Translate("land")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2 grid grid-col-1 lg:grid-cols-2 gap-4">
                                                    <div className="flex flex-col">
                                                        <p className="text-sm text-gray-500 font-medium">{Translate("Total transaction")}</p>
                                                        <p>{new Intl.NumberFormat('ja-JP',{ style: 'currency', currency: "JPY" }).format(Number(data.transaction_price))}</p>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <p className="text-sm text-gray-500 font-medium">{Translate("Area Size")}</p>
                                                        <p>{data.area.toLocaleString()}m²</p>
                                                    </div>

                                                    <div className="flex flex-col">
                                                        <p className="text-sm text-gray-500 font-medium">{Translate("Frontage")}</p>
                                                        <p>{data.frontage ? data.frontage + "m" : "–"}</p>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <p className="text-sm text-gray-500 font-medium">{Translate("Land shape")}</p>
                                                        <p>{data.landshape}</p>
                                                    </div>

                                                    <div className="flex flex-col">
                                                        <p className="text-sm text-gray-500 font-medium">{Translate("Price/m²")}</p>
                                                        <p>{new Intl.NumberFormat('ja-JP',{ style: 'currency', currency: "JPY" }).format(Number(Math.round(data.transaction_price/data.area)))}</p>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <p className="text-sm text-gray-500 font-medium">{Translate("price per tsubo")}</p>
                                                        <p>{new Intl.NumberFormat('ja-JP',{ style: 'currency', currency: "JPY" }).format(Number(Math.round(data.transaction_price/data.area* 3.306)))}</p>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="grid grid-cols-3 items-start py-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-500 mb-2 lg:mb-0">{Translate("Purpose of Use")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    {(data.usage|| []).join(",")}
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-3 items-start py-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-500 mb-2 lg:mb-0">{Translate("Frontage")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2 grid grid-col-1 lg:grid-cols-2 gap-4">
                                                    <div className="flex flex-col">
                                                        <p className="text-sm text-gray-500 font-medium">{Translate("Front road (m)")}</p>
                                                        <p>{data.frontage_road_breadth ? data.frontage_road_breadth + "m" : "–"}</p>
                                                    </div>
                                                    <div className="flex flex-col">
                                                        <p className="text-sm text-gray-500 font-medium">{Translate("Frontage Classification")}</p>
                                                        <p>{data.frontage_road_classification ? data.frontage_road_classification : "–"}</p>
                                                    </div>

                                                    <div className="flex flex-col">
                                                        <p className="text-sm text-gray-500 font-medium">{Translate("Road Direction")}</p>
                                                        <p>{data.frontage_road_direction ? data.frontage_road_direction : "–"}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-3 items-start py-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-500 mb-2 lg:mb-0">{Translate("City planning")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    {data.city_plan_zone ? data.city_plan_zone : "–"}
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-3 items-start py-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-500 mb-2 lg:mb-0">{Translate("Maximum Building Coverage Ratio")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                {data.maximum_floor_area_ratio}%
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-3 items-start py-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-500 mb-2 lg:mb-0">{Translate("Maximus Floor-area Ratio")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    {data.maximum_floor_area_ratio}%
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="mt-auto flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                                        <div className="ml-auto">
                                            Action button
                                        </div>
                                    </div> */}

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}