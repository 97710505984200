import React, { useState, useCallback, useRef, useMemo, useEffect } from "react"
import { Tab } from '@headlessui/react'
import clsx from "clsx";

import * as api from '../../../../v2/services/agent'
import { parseContext } from '../../../../mapbox/parseContext'
import { reverseGeocoding } from '../../../../mapbox/api'
import InputField from "./InputField";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import MarkerMap from "./MarkerMap"
import LotMap from "./LotMap"

export default function AddressForm(props) {
    const { data, setData, useFormReturn, className, language, Translate } = props
    const { formState: { errors }, control, setValue, getValues, watch, clearErrors } = useFormReturn
    const [mapMode, setMapMode] = useState("-")
    const _data = watch()

    useEffect(() => {
        if (mapMode === "-") {
            setMapMode((data.area?.parent == "karuizawa" || data.area?.id == "karuizawa") && data.lotnumber.features.length > 0 ? "lot": "map")
        }
    }, [mapMode, data])

    const apartmentDetail = data.apartment_detail

    const onViewportChange = ({latitude, longitude, zoom}) => {
        setValue("viewport", {latitude, longitude, zoom})
    }

    const getAddress = ({ lat, lng }) => {
        if (lat === 0 || lng === 0) {
            return
        }
        reverseGeocoding({ lat, lng })
            .then(resp => {
                if (resp.features.length === 0) return;

                const context = parseContext(resp.features[0])
                console.log("getAddress", context)
                // getLandboundaryCity(context)
                setValue("address", context, {
                    shouldTouch: true,
                })

                setValue("latlng", { latitude: lat, longitude: lng })
            })
    }

    // const getLandboundaryCity = (address) => {
    //     api.landboundaryCity(address.prefecture,address.city ?? address.district)
    //         .then(resp => {
    //             console.log(address.prefecture,address.city ?? address.district, resp)
    //             if (resp.data && resp.data.id) {
    //                 let mapID = resp.data.id.padStart(5, "0")
    //                 let newSource = `apisitviila.lotnumbers-${prefectures[mapID.slice(0, 2)]}-${mapID}`
    //                 setMapID(mapID)
    //                 setSource(newSource)
    //                 setSources((sources) => ({...sources, [newSource]: true}))
    //                 console.log("set source", newSource)
    //             } else {
    //                 console.log("not found",address.prefecture,address.city ?? address.district)
    //             }
    //         })
    // }


    const onLotSearchResult = (location) => {
        reverseGeocoding({ lat:location?.latitude, lng:location?.longitude })
            .then(resp => {
                if (resp.features.length === 0) return;

                const context = parseContext(resp.features[0])
                setValue("address", {
                    ...getValues("address"),
                    ...context
                }, {
                    shouldTouch: true
                })
            })
    }

    const onGeocoderResult = (feature, location) => {
        setValue("address", {
            ...getValues("address"),
            ...parseContext(feature)
        }, {
            shouldTouch: true
        })
        setValue("latlng", location)
    }

    const monthName = (month) => {
        const date = new Date();
        date.setMonth(month - 1);
        return date.toLocaleString(language === "en" ? "en-US" : "ja-JP", {
            month: 'short',
        });
    }

    const setLatlng = (lots = [], center) => {
        if (center) {
            const {latitude,longitude, zoom} = center
            setValue("latlng", { latitude,longitude })
            setValue("viewport", { latitude,longitude, zoom })
            getAddress({ lat: latitude, lng: longitude })
        }
        setValue("lotnumber", {type:"FeatureCollection", features: lots })
    }

    return (
        <div className={clsx("container mx-auto text-sm rounded-md bg-white h-full overflow-y-scroll", className)}>
            <div className="p-4">
                <div className="flex items-center">
                    <div>
                        <p className="text-xl font-semibold">{Translate(data.property_type === "apartment" ? "Apartment" : "Address")}</p>
                        {data.property_type === "apartment" ? <>
                            <p className="text-xs">{Translate(apartmentDetail?.name)}</p>
                            <p className="text-xs">{apartmentDetail?.apartment_no}</p>
                            {apartmentDetail?.built ? <p className="text-xs">{Translate("Built")}: {apartmentDetail?.built?.month !== "" ? `${monthName(apartmentDetail.built?.month)} ` : ""} {apartmentDetail.built?.year !== "" ? apartmentDetail.built?.year : ""}</p> : null}
                        </> : <p className="text-xs">{Translate("find your property information by input address")}</p>}
                    </div>
                    <div className="ml-auto">
                        <div className="w-full mt-8 border-b space-x-4 h-10">
                            <button type="button" className={clsx("focus:outline-none text-sm font-medium pb-4 px-2 text-gray-500", mapMode === "map" && "border-b-2 border-primary text-primary")} onClick={() => { 
                                setMapMode("map") 
                            }}>{Translate("map_mode_button")}</button>
                            {(data.area?.parent == "karuizawa" || data.area?.id == "karuizawa") && (<button type="button" className={clsx("focus:outline-none text-sm font-medium pb-4 px-2 text-gray-500", mapMode === "lot" && "border-b-2 border-primary text-primary")} onClick={() => { 
                                setMapMode("lot") 
                            }}>{Translate("lotnumber_mode_button")}</button>)}
                        </div>
                    </div>
                </div>
            </div>

            {mapMode === "map" && (<div className="p-4">
                <MarkerMap {...props}
                    data={data}
                    _data={_data}
                    control={control}
                    setLatlng={setLatlng}
                    onGeocoderResult={onGeocoderResult}
                    onViewportChange={onViewportChange} />
            </div>)}
            {(data.area?.parent == "karuizawa" || data.area?.id == "karuizawa" || data.area?.id == "miyota") && mapMode === "lot" && (<div className="p-4">
                <LotMap {...props}
                    data={data}
                    _data={_data}
                    control={control}
                    setLatlng={setLatlng}
                    onLotSearchResult={onLotSearchResult}
                    onViewportChange={onViewportChange} />
            </div>)}

            <div className={clsx("p-4")}>

                {data.property_type === "apartment" && data.apartment !== undefined && data.apartment !== "" ? null :
                    <div className="flex">
                        <div>
                            <p className="font-semibold">{Translate("Address")}</p>
                            <p className="text-xs text-gray-600 mb-4">{Translate("address_remark")}</p>
                        </div>
                        <div className="ml-auto flex items-center">

                        </div>
                    </div>}

                <div className="grid lg:grid-cols-3 gap-4">

                    <div className="lg:col-span-1">
                        <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("Zipcode")}</label>
                        <InputField
                            control={control}
                            errors={errors} clearErrors={clearErrors}
                            name="address.zip_code"
                            id="zip_code"
                            className="rounded w-full"
                            disabled={data.property_type === "apartment" && data.apartment !== undefined && data.apartment !== ""}
                        />
                    </div>

                    <div className="lg:col-span-1">
                        <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("Prefecture")}</label>
                        <InputField
                            control={control}
                            errors={errors} clearErrors={clearErrors}
                            name="address.prefecture"
                            id="prefecture"
                            className="rounded w-full"
                            disabled={data.property_type === "apartment" && data.apartment !== undefined && data.apartment !== ""}
                        />
                    </div>

                    <div className="lg:col-span-1">
                        <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("City")}</label>
                        <InputField
                            control={control}
                            errors={errors} clearErrors={clearErrors}
                            name="address.city"
                            id="city"
                            className="rounded w-full"
                            disabled={data.property_type === "apartment" && data.apartment !== undefined && data.apartment !== ""}
                        />
                    </div>

                    <div className="lg:col-span-1">
                        <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("District")}</label>
                        <InputField
                            control={control}
                            errors={errors} clearErrors={clearErrors}
                            name="address.district"
                            id="district"
                            className="rounded w-full"
                            disabled={data.property_type === "apartment" && data.apartment !== undefined && data.apartment !== ""}
                        />
                    </div>

                    <div className="lg:col-span-1">
                        <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("Address 1")}</label>
                        <InputField
                            control={control}
                            errors={errors} clearErrors={clearErrors}
                            name="address.address_1"
                            id="address_1"
                            className="rounded w-full"
                            disabled={data.property_type === "apartment" && data.apartment !== undefined && data.apartment !== ""}
                        />
                    </div>

                    <div className="lg:col-span-1">
                        <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("Address 2")}</label>
                        <InputField
                            control={control}
                            errors={errors} clearErrors={clearErrors}
                            name="address.address_2"
                            id="address_2"
                            className="rounded w-full"
                            disabled={data.property_type === "apartment" && data.apartment !== undefined && data.apartment !== ""}
                        />
                    </div>

                    <div className="col-span-3">
                        <button type="button" id="reload-address" className="px-2 py-2 border text-green-500 border-green-500 text-sm font-semibold rounded flex items-center" onClick={e => {
                            getAddress({ lat: parseFloat(_data.latlng.latitude), lng: parseFloat(_data.latlng.longitude) })
                            // getPlaceNearby({lat: marker.latitude, lng: marker.longitude})
                        }}>
                            <ArrowPathIcon className="w-4 h-4 mr-1" />
                            {Translate("Reload address")}
                        </button>
                    </div>
                </div>

                <div className="col-span-1 p-4">
                    {/* {nearbyPlaces.places.length !== 0 ? (<div className="mt-4">
                        <div className="flex items-center mb-4">
                            <div className="text-xl font-bold">Nearby</div>
                        </div>
                        <div className="flex overflow-x-auto mb-4">
                            <div className={`cursor-pointer p-4 border-b-4 text-center ${nearbyPlaces.filter === "" ? "border-blue-500" : "border-gray-300"}`} onClick={() => {
                                setPlaces({ ...nearbyPlaces, filter: '' })
                            }}>
                                <p>All</p>
                                <p>({nearbyPlaces.places.length})</p>
                            </div>
                            {nearbyPlaces.category.map((c, index) => (<div className={`cursor-pointer p-4 border-b-4 text-center ${nearbyPlaces.filter === c ? "border-blue-500" : "border-gray-300"}`} key={index} onClick={() => {
                                setPlaces({ ...nearbyPlaces, filter: c })
                            }}>
                                <p>{c}</p>
                                <p>({nearbyPlaces.places.filter((p) => p.category.name.includes(c)).length})</p>
                            </div>))}
                        </div>
                        <div className="grid grid-cols-4 gap-4">
                            {nearbyPlaces.places.filter((place) => nearbyPlaces.filter === "" ? true : place.category.name.includes(nearbyPlaces.filter)).map((place, index) => {
                                return (
                                    <div className="mb-4 w-full" key={index}>
                                        <div className="flex items-center">
                                            <div className="px-2">
                                                <p className="cursor-pointer place-button font-medium">{place.name}</p>
                                                <p className="cursor-pointer place-button font-medium">{place.category.name.split(",")[0]}</p>
                                                <p className="text-sm text-gray-600">
                                                    <span className="">
                                                        <svg className="fill-current " width="15" height="24" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M7.42383 5.54688C8.69336 5.54688 9.71875 4.49219 9.71875 3.27148C9.71875 1.99219 8.69336 0.966797 7.42383 0.966797C6.1543 0.966797 5.14844 1.99219 5.14844 3.27148C5.14844 4.49219 6.1543 5.54688 7.42383 5.54688ZM10.6953 23.6914C11.2031 23.6914 11.6816 23.3105 11.6816 22.6367C11.6816 22.3828 11.6035 22.1484 11.4668 21.875L9.45508 17.8027C9.28906 17.4609 9.07422 17.1094 8.87891 16.8359L7.59961 15.0195L7.6875 14.7559C8.03906 13.6816 8.17578 13.0176 8.25391 11.9629L8.45898 8.96484C8.55664 7.53906 7.73633 6.36719 6.26172 6.36719C5.14844 6.36719 4.41602 6.99219 3.40039 7.97852L1.82812 9.53125C1.29102 10.0684 1.125 10.498 1.07617 11.1621L0.880859 13.6133C0.832031 14.2578 1.19336 14.6777 1.76953 14.7168C2.31641 14.7559 2.72656 14.4238 2.78516 13.7402L3.00977 11.0352L3.9375 10.2148C4.11328 10.0586 4.35742 10.1367 4.33789 10.3906L4.16211 12.8125C4.08398 13.9648 4.57227 14.8047 5.13867 15.5176L7.17969 18.0957C7.39453 18.3496 7.41406 18.4473 7.50195 18.623L9.67969 23.0176C9.93359 23.5254 10.3047 23.6914 10.6953 23.6914ZM11.0957 12.4023H13.3418C13.9863 12.4023 14.4062 12.041 14.4062 11.4551C14.4062 10.8789 13.9863 10.498 13.3418 10.498H10.959L9.28906 8.68164L9.11328 11.2402L9.83594 11.9629C10.1875 12.3047 10.5098 12.4023 11.0957 12.4023ZM1.4668 23.6914C1.79883 23.6914 2.0918 23.5449 2.27734 23.3203L5.33398 19.6777C5.64648 19.3066 5.68555 19.2188 5.8125 18.8477C5.91016 18.5645 5.97852 18.3008 6.04688 18.0273L4.33789 15.9082L3.83008 18.3496L0.958984 21.748C0.685547 22.0605 0.490234 22.3145 0.490234 22.7246C0.490234 23.3008 0.910156 23.6914 1.4668 23.6914Z"></path>
                                                        </svg>
                                                        walking {place.walking_distance_matrix.distance.text}&nbsp;·&nbsp;{place.walking_distance_matrix.duration.text}
                                                    </span>
                                                    <span className="">
                                                        <svg className="fill-current text-gray-900" width="19" height="15" viewBox="0 0 19 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1.29688 14.8984H2.21094C2.79688 14.8984 3.25 14.4531 3.25 13.875V12.5625C4.98438 12.6719 7.49219 12.75 9.5 12.75C11.5078 12.75 14.0234 12.6719 15.7578 12.5625V13.875C15.7578 14.4531 16.2031 14.8984 16.7891 14.8984H17.7109C18.2969 14.8984 18.7422 14.4531 18.7422 13.875V9.36719C18.7422 8.05469 18.4766 7.3125 17.7578 6.375L17.0938 5.52344C16.8125 4.11719 16.2891 2.64062 16.0234 2.0625C15.6016 1.17969 14.7969 0.65625 13.7578 0.515625C13.2344 0.453125 11.5234 0.40625 9.5 0.40625C7.48438 0.40625 5.77344 0.460938 5.25 0.515625C4.21094 0.640625 3.39844 1.17969 2.98438 2.0625C2.71094 2.64062 2.19531 4.11719 1.90625 5.52344L1.25 6.375C0.523438 7.3125 0.257812 8.05469 0.257812 9.36719V13.875C0.257812 14.4531 0.710938 14.8984 1.29688 14.8984ZM3.48438 4.875C3.66406 4.01562 4.04688 2.89844 4.30469 2.44531C4.51562 2.07812 4.74219 1.92188 5.16406 1.86719C5.75781 1.78125 7.08594 1.73438 9.5 1.73438C11.9219 1.73438 13.25 1.76562 13.8359 1.86719C14.2578 1.92969 14.4766 2.07812 14.6953 2.44531C14.9609 2.89062 15.3203 4.01562 15.5234 4.875C15.5938 5.16406 15.4688 5.27344 15.1719 5.25C13.875 5.17188 12.3281 5.08594 9.5 5.08594C6.67969 5.08594 5.13281 5.17188 3.83594 5.25C3.53125 5.27344 3.41406 5.16406 3.48438 4.875ZM3.86719 10.6406C3.15625 10.6406 2.60938 10.1016 2.60938 9.38281C2.60938 8.67188 3.15625 8.13281 3.86719 8.13281C4.57812 8.13281 5.125 8.67188 5.125 9.38281C5.125 10.1016 4.57812 10.6406 3.86719 10.6406ZM15.1328 10.6406C14.4219 10.6406 13.8828 10.1016 13.8828 9.38281C13.8828 8.67188 14.4219 8.13281 15.1328 8.13281C15.8516 8.13281 16.3906 8.67188 16.3906 9.38281C16.3906 10.1016 15.8516 10.6406 15.1328 10.6406ZM7.4375 10.3359C6.89844 10.3359 6.53125 9.96094 6.53125 9.42969C6.53125 8.89844 6.89844 8.52344 7.4375 8.52344H11.5703C12.1016 8.52344 12.4688 8.89844 12.4688 9.42969C12.4688 9.96094 12.1016 10.3359 11.5703 10.3359H7.4375Z"></path>
                                                        </svg>
                                                        driving {place.driving_distance_matrix.distance.text}&nbsp;·&nbsp;{place.driving_distance_matrix.duration.text}
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>)
                            })}
                        </div>
                    </div>) : null} */}
                </div>
            </div>
        </div>
    )
}