import React from "react"
import { Link } from 'react-router-dom';
import { Translate } from "../../translate/Translate";
import * as Account from '../../../account'

export default function SettingsNavBar(props) {
  return (
    <div className="w-full my-8 border-b space-x-4 h-10">

      <Link to="/settings" className={"focus:outline-none text-sm font-medium pb-4 px-2 text-gray-500 " + (props.active === 'general' ? 'border-b-2 border-indigo-600 text-indigo-600' : '')}>{Translate("General")}</Link>
      <Link to="/settings/profile" className={"focus:outline-none text-sm font-medium pb-4 px-2 text-gray-500 " + (props.active === 'profile' ? 'border-b-2 border-indigo-600 text-indigo-600' : '')}>{Translate("Profile")}</Link>
      <Link to="/settings/password" className={"focus:outline-none text-sm font-medium pb-4 px-2 text-gray-500 " + (props.active === 'password' ? 'border-b-2 border-indigo-600 text-indigo-600' : '')}>{Translate("Password")}</Link>
      {
        Account.getUserAccount() && Account.getUserAccount().user && (Account.getUserAccount().user.role == 'owner' ||  Account.getUserAccount().user.role == 'admin') ? <Link to="/settings/team" className={"focus:outline-none text-sm font-medium pb-4 px-2 text-gray-500 " + (props.active === 'team' ? 'border-b-2 border-indigo-600 text-indigo-600' : '')}>{Translate("Team members")}</Link> : null
      }
    </div>
  )
}
