import React from "react"
import { Link } from 'react-router-dom';
import * as auth from '../../v2/services/auth'
import { Translate } from "../../v2/translate/Translate";
import ViilaSolid from '../../assets/logo/solid.svg'
import LearnMore from "../../Components/LearnMore";

export default function ResetPassword(props) {

    const [data, setData] = React.useState({
        email: ""
    })

    const [error, setError] = React.useState(null)

    const handleSubmit = (e) => {
        e.preventDefault();
        auth.resetPassword(data.email)
            .then(response => {
                console.log(response)
                setData({
                    email: ""
                })
                window.location.href = "/login"
            })
            .catch(e => {
                setError("Something went wrong.")
                setData({
                    email: ""
                })
            })
    }

    const handleDataChange = (e) => {
        var { name, value } = e.target
        setData({ ...data, [name]: value })
    }

    const renderError = () => {
        if (error === null) {
            return <div className="font-medium text-sm">&nbsp;</div>;
        }
        return (
            <div className=" text-red-500 font-medium text-sm">
                {error.message}
            </div>
        )
    }
    return (


        <div className="absolute inset-0 w-full h-full overflow-hidden flex">
            <div className="hidden w-full lg:flex">
                <LearnMore/>
            </div>
           
            <div className="flex items-center justify-center px-4 lg:px-28  w-full">

            <div className="flex flex-col w-full lg:w-96 bg-white">

                    <div className="flex flex-col gap-4 ">

                        <div className="flex items-center mb-8">
                            <img src={ViilaSolid} className="w-10 h-10 mr-3" />
                            <span className="text-xl font-semibold">Viila Agent Dashboard</span>
                        </div>

                        <div className="mb-4">
                            <p className="text-lg font-medium mb-2">Having trouble signing in?</p>
                            <p>Please enter your registered email address to reset your password.</p>
                        </div>

                        {/* form here */}
                        
                        <form className="flex flex-col gap-4 w-full " onSubmit={handleSubmit}>
                            <label className="block">
                                <span className="text-gray-700 text-sm font-medium">{Translate("Email address")}</span>
                                <input
                                    name="email"
                                    type="email"
                                    className="mt-1 rounded w-full "
                                    placeholder=""
                                    required
                                    value={data.email}
                                    onChange={handleDataChange}
                                />
                            </label>
                            <div>
                                <button disabled={data.email.trim().length === 0} type="submit" className="group relative btn btn-primary w-full disabled:opacity-50">
                                    {Translate("Reset password")}
                                </button>
                            </div>
                        </form>


                        <div className="">
                            {renderError()}
                        </div>

                        <div className="">
                        <p>Don't have an account? <Link to="/signup" className="text-primary">Sign up</Link> for a free trial.</p>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    );
}