import React from "react"
import { Translate } from "../translate/Translate";
import { Link } from 'react-router-dom';

export default function TopSearchbar(props) {
    const countNewMessage = () => {
        var sum = 0
        for (var i in props.customers) {
            sum += props.customers[i].unreplied ?? 0
        }
        return sum
    }

    return (
        <div className="flex-none border-b-2 px-4 items-center flex bg-white">
            <div className="mr-14 flex-none lg:hidden flex items-center">

            </div>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
            <input className="border-none focus:border-none focus:ring-0 py-4 w-full" type="text" placeholder={Translate("search_title_placeholder")}></input>
            <div className="ml-auto flex items-center gap-4">
                <Link className="relative" to={`/v2/customers`}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M8 12h.01M12 12h.01M16 12h.01M21 12c0 4.418-4.03 8-9 8a9.863 9.863 0 01-4.255-.949L3 20l1.395-3.72C3.512 15.042 3 13.574 3 12c0-4.418 4.03-8 9-8s9 3.582 9 8z" />
                    </svg>
                    <span className="absolute top-0 right-0 -mt-1 -mr-1  bg-red-600 text-white text-xs font-medium rounded-full text-center w-4 h-4 flex items-center justify-center leading-none">{countNewMessage()}</span>
                </Link>
            </div>
        </div>
    )

}