
import InputField from "./InputField";
import MoneyInputField from "./MoneyInputField";
import CreateableSelectControl from "./CreateableSelectControl";
import { Translate } from "../../../translate/Translate";

export default function TaxField(props) {
    const { index, useFormReturn, removeTaxHistories, data } = props
    const { control, formState: { errors }, clearErrors } = useFormReturn
    const yearOptions = () => {
        var list = []
        var start_year = new Date().getFullYear();
        for (var i = start_year; true; i--) {
            if (data.find(d => d.year === i.toString()))
                continue;

            list.push({ label: i.toString(), value: i.toString() })
            if (list.length === 10)
                break;
        }
        return list
    }

    return (<div key={index} className="grid grid-cols-3 gap-2 items-center mb-6">
        <div className="col-span-3 lg:col-span-1">
            <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{Translate("tax_history_table_year_title")}</label>
            <CreateableSelectControl
                control={control} errors={errors} clearErrors={clearErrors}
                required={true}
                name={`tax_histories.${index}.year`}
                id={`tax_histories-${index}-year`}
                options={yearOptions()}
                placeholder={Translate("tax_year_placeholder")}
                getOptionLabel={({ label }) => label}
                getOptionValue={({ value }) => value}
            />
        </div>

        <div className="col-span-3 lg:col-span-2">
            <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{Translate("tax_history_table_amount_title")}</label>
            <MoneyInputField control={control} errors={errors} clearErrors={clearErrors}
                required={true}
                name={`tax_histories.${index}.price.amount`}
                id={`tax_histories-${index}-value`}
                className=""
                placeholder="50,000"
            />
        </div>


        <div className="col-span-3 lg:col-span-3">
            <div className="flex items-center">
                <div className="w-full">
                    <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{Translate("tax_history_table_remark_title")}</label>
                    <InputField control={control} errors={errors}
                        clearErrors={clearErrors}
                        name={`tax_histories.${index}.remarks`}
                        id={`tax_histories-${index}-remarks`}
                        placeholder={Translate("tax_remarks_placeholder")}
                    />
                </div>
                <div className="ml-auto">
                    <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">&nbsp;</label>
                    <button type="button" id={`remove-tax-${index}`} onClick={(e) => removeTaxHistories(index)} className="focus:outline-none text-xs font-medium text-red-500 ml-2">
                        <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>

    </div>)
}