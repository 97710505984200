import React, {useState,useEffect} from "react"
import TopNavBar from '../Shared/TopNavBar'
import InviteTeamDialog from "./InviteTeamDialog"
import SettingsNavBar from './SettingsNavBar'
import * as api from '../../services/agent'
export default function SettingsTeam(props) {
    const {Translate} = props
    const [showInviteDialog, setShowInviteDialog] = useState(false);
    const [data, setData] = useState([]);
    const [events, setEvents] =  useState([]);

    const onInviteFormSubmit = (data) => {
        console.log(data)
        api.inviteTeamMember(data.email,window.location.href)
            .then(response => {
                console.log(response)
                window.location.reload()
            })
            .catch(error => {
                console.log(error)
            })
        setShowInviteDialog(false)
    }
    const listTeam = () => {
        if (data === null) {
            return []
        }
        return data
    }


    function deleteTeam(code) {
        api.deleteTeam(code)
            .then(response => {
                window.location.reload()
            })
            .catch(error => {

            })
    }


    function loadTeam() {
        api.team()
            .then(data => {
                setData(data.data);
            })
            .catch(error => {
                console.log(error);
            })
    }

    function loadEmailEvents() {
        api.emailEvents()
          .then(response => {
            setEvents(response.data);
          })
          .catch(error => {
            console.log(error);
          })
      }

    const handleEventChecked = (e,user_id,event) => {
        if (e.target.checked) {
            api.addEmailEvent(user_id,event)
            .then(data => {
                console.log(data);
            })
            .catch(error => {
                console.log(error);
            })
        } else {
            api.removeEmailEvent(user_id,event)
            .then(data => {
                console.log(data);
            })
            .catch(error => {
                console.log(error);
            })
        }
    };



    //uncomment this to fetch data when api is deployed
    useEffect(() => {
        loadEmailEvents()
        loadTeam()
    }, [])

    return (
        <div className="">
            <TopNavBar active="settings"></TopNavBar>
            <div className="mt-16 w-full container mx-auto py-8 px-4">
                <div className="mb-4">

                    <p className="text-2xl font-semibold">{Translate("Settings")}</p>
                </div>

                <SettingsNavBar active="team"></SettingsNavBar>

                <div className="w-full">
                    <form className="w-full">
                        <div className="grid grid-cols-1 divide-y text-sm shadow rounded-md overflow-hidden bg-white">
                            <div className="col-span-1 p-4 lg:p-8 flex">
                                <div>
                                    <p className="text-xl font-medium">{Translate("Team members")}</p>
                                    <p className="text-sm text-gray-500"></p>
                                </div>
                                <div className="ml-auto">
                                    <button type="button" onClick={(e) => { setShowInviteDialog(!showInviteDialog) }} className="btn btn-primary">{Translate("Invite team")}</button>
                                </div>

                            </div>

                            <div className="col-span-1 ">

                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-100">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">{Translate("Email")}</th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">{Translate("Get mail")}</th>
                                            <th scope="col" className="px-6 py-3 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">{Translate("Status")}</th>
                                            <th scope="col" className="relative px-6 py-3">
                                                <span className="sr-only">{Translate("Edit")}</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {
                                            listTeam().filter(c => c.role != "owner").map((team, index) => (
                                                <tr>
                                                    <td className="px-6 py-4 whitespace-no-wrap">
                                                        <p className="font-medium">{team.first_name} {team.last_name}</p>
                                                        <p className="text-xs text-gray-500">{team.email}</p>
                                                    </td>
                                                    <td className="px-6 py-4  text-left">

                                                        { team.user_id ?
                                                            events.map((event, i) => (
                                                                <div className="inline-flex items-center w-30 mr-8">
                                                                    <input onChange={e=>handleEventChecked(e,team.user_id,event)} checked={team.events.find(e => e == event)} id={`${team.code}_${i}`} type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
                                                                    <label htmlFor={`${team.code}_${i}`} className="ml-2 block text-sm text-gray-900">{Translate(event)}</label>
                                                                </div>
                                                            )) : null
                                                        }
                                                    </td>
                                                    <td className="px-6 py-4  text-left">
                                                        {team.accepted_at ? <span className="bg-green-400 text-white font-medium text-xs px-2 py-1 rounded">{Translate("Accepted")}</span> : <span className="bg-yellow-400 text-white font-medium text-xs px-2 py-1 rounded">{Translate("Pending")}</span>}
                                                    </td>
                                                    <td className="px-6 py-4  text-right">
                                                        <button className="px-2 py-2 text-xs font-medium text-red-500" onClick={(e) => { deleteTeam(team.code) }} >
                                                            <svg className="fill-current text-gray-500" width="12" height="14" viewBox="0 0 12 14" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M3.31055 13.7754H8.68359C9.52148 13.7754 10.084 13.2305 10.125 12.3926L10.5293 3.87305H11.2324C11.4727 3.87305 11.6719 3.66797 11.6719 3.42773C11.6719 3.1875 11.4727 2.98828 11.2324 2.98828H8.55469V2.0918C8.55469 1.24219 8.00391 0.726562 7.10156 0.726562H4.88086C3.97852 0.726562 3.42773 1.24219 3.42773 2.0918V2.98828H0.761719C0.527344 2.98828 0.322266 3.1875 0.322266 3.42773C0.322266 3.67383 0.527344 3.87305 0.761719 3.87305H1.46484L1.86914 12.3984C1.91016 13.2363 2.4668 13.7754 3.31055 13.7754ZM4.35938 2.15039C4.35938 1.81641 4.58789 1.59961 4.93945 1.59961H7.04297C7.39453 1.59961 7.62305 1.81641 7.62305 2.15039V2.98828H4.35938V2.15039ZM3.39258 12.8906C3.05859 12.8906 2.80664 12.6387 2.78906 12.293L2.37891 3.87305H9.58594L9.20508 12.293C9.1875 12.6445 8.93555 12.8906 8.58984 12.8906H3.39258ZM4.25977 11.8887C4.48242 11.8887 4.62891 11.748 4.62305 11.543L4.44727 5.27344C4.44141 5.06836 4.28906 4.93359 4.07812 4.93359C3.85547 4.93359 3.70898 5.07422 3.71484 5.2793L3.89648 11.543C3.90234 11.7539 4.04297 11.8887 4.25977 11.8887ZM6 11.8887C6.22266 11.8887 6.375 11.748 6.375 11.543V5.2793C6.375 5.07422 6.22266 4.93359 6 4.93359C5.77734 4.93359 5.61914 5.07422 5.61914 5.2793V11.543C5.61914 11.748 5.77734 11.8887 6 11.8887ZM7.73438 11.8887C7.94531 11.8887 8.0918 11.7539 8.09766 11.543L8.2793 5.2793C8.28516 5.07422 8.13281 4.93359 7.91016 4.93359C7.70508 4.93359 7.55273 5.06836 7.54688 5.2793L7.37109 11.543C7.36523 11.748 7.51172 11.8887 7.73438 11.8887Z" />
                                                            </svg>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))
                                        }


                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </form>
                </div>
                {
                    showInviteDialog === true ?
                        <InviteTeamDialog dismiss={() => { setShowInviteDialog(false) }} onSubmit={onInviteFormSubmit}></InviteTeamDialog>
                        : null
                }

            </div>
        </div>
    )
}