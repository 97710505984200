import React, { Fragment } from "react"
import MapView from "../MapView";
import SideNavBar from "../SideNavBar";
import SelectedPropertiesPanel from "../Components/SelectedPropertiesPanel"
import * as api from '../../services/agent'
import PropertyRequestBuilder from "../Components/PropertyRequestBuilder";
import Geocoder from "../Components/Geocoder"
import AppContext from "../../../AppContext"
import ScreenLoading from "../Components/ScreenLoading";
import ImportPropertyDialog from "../Components/ImportPropertyDialog";


export default function Map(props) {
    const {Translate} = props
    const [isLoading, setLoading] = React.useState(false)
    const [showError, setShowError] = React.useState(false);;
    const [errorMessage, setErrorMessage] = React.useState("");
    const { account, language } = React.useContext(AppContext)
    const query = new URLSearchParams(window.location.search);
    const [isOpen, setIsOpen] = React.useState(false)
    const [property, setProperty] = React.useState({});

    const [propertiesResponse, setPropertiesResponse] = React.useState({
        type: 'FeatureCollection',
        features: [],
    });

    const [apartmentsResponse, setApartmentsResponse] = React.useState(null);

    const [agents, setAgents] = React.useState([]);

    const defaultPropertyRequest = {
        list_status: ["active"],
        property_types: ["house", "lot", "apartment"],
        transaction_type: "",
        page_size: 1000,
        price: {
            min: "",
            max: ""
        },
        sqm: {
            min: "",
            max: "",
        },
        lot: {
            min: "",
            max: "",
        },
        agents: [],
    }

    const queryState = query.get("q")

    const [propertyRequest, setPropertyRequest] = React.useState(JSON.parse(queryState) ? JSON.parse(queryState) : defaultPropertyRequest);

    React.useEffect(() => {
        props.history.replace({
            pathname: window.location.pathname,
            search: `?q=${JSON.stringify(propertyRequest)}`
        })
    }, [propertyRequest]);

    React.useEffect(() => {
        let value = decodeURIComponent(query.toString()).toString()
        localStorage.setItem("mapview_query_string", value)
    }, [query]);

    function loadProperties(request) {
        api.propertiesGeoJSON(request, language)
            .then(data => {
                if (request.property_types.find(c => c === "apartment") || request.property_type === "apartment") {
                    data.features = data && data.features ? data.features.filter((p) => !p.properties.has_building_info) : null
                }
                setPropertiesResponse(data);

            })
            .catch(error => {
                console.log(error);
            })
    }

    function loadApartments(area) {
        api.apartmentsCompact(area, language)
            .then(data => {
                setApartmentsResponse(data);
            })
            .catch(error => {
                console.log(error);
            })
    }

    function loadAgents(request) {
        request["has_property"] = true
        api.agents(request, language)
            .then(data => {
                setAgents(data);
            })
            .catch(error => {
                console.log(error);
            })
    }

    const onPropertyRequestChange = (key, value) => {
        setPropertyRequest({ ...propertyRequest, [key]: value })
    }

    const onSetPropertyRequest = (request) => {
        setPropertyRequest({ ...request })
    }

    React.useEffect(() => {
        loadAgents(propertyRequest)
    }, []);

    React.useEffect(() => {
        // loadAgents(propertyRequest)
        loadProperties(propertyRequest)
        if (propertyRequest.property_types.find(c => c === "apartment") || propertyRequest.property_type === "apartment") {
            loadApartments("")
        } else {
            setApartmentsResponse(null);
        }
    }, [propertyRequest]);


    const emptyCollection = {
        properties: [],
    }
    const [collection, setCollection] = React.useState(emptyCollection);

    const onAddedToCollection = (property) => {
        let { properties } = collection;

        const matchPropertyID = (p) => p.id == property.id;
        const i = properties.findIndex(matchPropertyID);
        if (i < 0) {
            properties.push(property)
        }

        setCollection({ ...collection, properties: properties })
    }

    const onRemovedFromCollection = (property) => {
        let { properties } = collection;

        const matchPropertyID = (p) => p.id == property.id;
        const i = properties.findIndex(matchPropertyID);
        if (i > -1) {
            properties.splice(i, 1);
        }
        setCollection({ ...collection, properties: properties })
    }

    const onCloseCollection = () => {
        setCollection(emptyCollection)
    }

    const [selectedGeocoderResult, setSelectedGeocoderResult] = React.useState(null)
    const onGeocoderResult = (result) => {
        setSelectedGeocoderResult(result)
    }

    const [selectedLatLng, setSelectedLatLng] = React.useState({ lat: 0, lng: 0 })
    const onSelectedLatLng = (lat, lng) => {
        setSelectedLatLng({ lat: lat, lng: lng })
    }

    const copyPropertyAsDraft = (id) => {
        if (id.length === 0)
            return

        setIsOpen(false)
        setLoading(true)
        api.copyPropertyAsDraft(id)
            .then(response => {
                window.location.href = `/v2/properties/wizard/${response}/summary`
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error && error.data ? error.data.message : error)
            }).finally(e => {
                setLoading(false)
            })
    }

    function closeModal() {
        setIsOpen(false)
    }

    function openModel(property) {
        setProperty(property)
        setIsOpen(true)
    }

    return (
        <div className="w-full flex bg-slate-50">
            <SideNavBar {...props} isPresented={true} />
            <div className="flex flex-col w-full relative">

                <div className="bg-white">
                    <div className="w-full container mx-auto lg:px-8 px-4">

                        <div className="py-8">
                            <p className="text-3xl font-bold">{Translate("top_navbar_menu_map")}</p>
                        </div>

                    </div>
                </div>
                <div className="flex items-center mx-auto h-full w-full">
                    <div className=" w-full h-full flex flex-col bg-blue-200">

                        <main className="w-full h-full flex flex-col">
                            <div className="flex flex-col w-full h-full relative overflow-hidden">
                                <div className=" flex items-center w-full h-full">

                                    <div className="h-full w-full relative">
                                        <MapView
                                            version={2}
                                            openModel={openModel}
                                            onSelectedLatLng={onSelectedLatLng}
                                            history={props.history}
                                            selectedGeocoderResult={selectedGeocoderResult}
                                            onAddedToCollection={onAddedToCollection}
                                            propertyRequest={propertyRequest}
                                            apartments={apartmentsResponse}
                                            onSetPropertyRequest={onSetPropertyRequest}
                                            properties={propertiesResponse}
                                            onPropertyRequestChange={onPropertyRequestChange}
                                            setSelectedGeocoderResult={setSelectedGeocoderResult}></MapView>
                                        {
                                            collection.properties.length > 0 ?
                                                <div className="absolute top-0 right-0 h-full p-3">
                                                    <div className="shadow-xl rounded-lg overflow-hidden h-full">
                                                        <SelectedPropertiesPanel onClose={onCloseCollection} onRemovedFromCollection={onRemovedFromCollection} collection={collection}></SelectedPropertiesPanel>
                                                    </div>
                                                </div>
                                                : null
                                        }
                                        <div className="absolute top-3 left-1/2 transform -translate-x-1/2 w-1/3 flex flex-col">
                                            <Geocoder {...props}  selectedLatLng={selectedLatLng} onResult={onGeocoderResult}></Geocoder>
                                        </div>

                                        <div className="absolute top-0 left-0 h-full p-3">
                                            <div className="shadow-xl rounded-lg overflow-y-auto overflow-hidden h-full bg-white">
                                                <PropertyRequestBuilder agents={agents} propertyRequest={propertyRequest} properties={propertiesResponse} onSetPropertyRequest={onSetPropertyRequest} onPropertyRequestChange={onPropertyRequestChange} />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
            {isLoading && (<ScreenLoading {...props} />)}
            <ImportPropertyDialog isOpen={isOpen} closeModal={closeModal} copyPropertyAsDraft={copyPropertyAsDraft} property={property}></ImportPropertyDialog>
        </div>
    );

}