import React, { useEffect } from "react"
import SelectControl from "../Components/StepWizard/SelectControl"
import AreaField from "../Components/StepWizard/AreaField";
import * as api from '../../services/agent'
import StepSummaryCard from "./StepSummaryCard"

export default function StepPrefectureArea(props) {
    const { prefectures, language, data, setData, areas, setArea, subareas, setSubareas, useFormReturn, Translate } = props
    const { reset, formState: { errors }, control, setValue, watch, getValues, clearErrors } = useFormReturn
    const { prefecture, city, area } = watch()

    const _data = watch()

    useEffect(() => {
        reset({ ...data });
    }, []);

    const fetchArea = (prefecture, area) => {
        if (areas[prefecture.id]) {
            if (area?.id) {
                return Promise.resolve()
            }
            setValue("area.id", area?.id || prefecture.id)
            setValue("prefecture", prefecture)
            // setData({ ...data, viewport: { ...prefecture.latlng, zoom: data.viewport?.zoom || 14 }, latlng: { ...prefecture.latlng } })
            setValue("viewport", { ...prefecture.latlng, zoom: data.viewport?.zoom || 14 })
            setValue("latlng", prefecture.latlng)
            return Promise.resolve()
        }
        return new Promise((resolve, reject) => {
            api.areaByPrefectures(language, prefecture.id)
                .then((res) => {
                    setTimeout(() => {
                        setArea({
                            ...areas,
                            [prefecture.id]: res.data.filter((d) => d.level < 2),
                        })
                        var sa = {}
                        res.data.filter((d) => d.level >= 2).forEach(function (a, i) {
                            if (!sa.hasOwnProperty(a.parent))
                                sa[a.parent] = []
                            sa[a.parent].push(a)
                        });

                        setSubareas({
                            ...subareas, ...sa
                        })

                        if (area?.id) {
                            return resolve()
                        }

                        setValue("area.id", res.data.find((d) => d.id === area?.id)?.id || prefecture.id)
                        setValue("prefecture", prefecture)
                        setValue("viewport", { ...prefecture.latlng, zoom: data.viewport?.zoom || 14 })
                        setValue("latlng", prefecture.latlng)
                        // setData({ ...data, viewport: { ...prefecture.latlng, zoom: data.viewport?.zoom || 14 }, latlng: { ...prefecture.latlng } })
                        resolve(res.data.filter((d) => d.level < 2))
                    }, 500)
                }).catch((err) => {
                    reject(err)
                })
        })
    }

    useEffect(() => {
        if (prefectures.length && prefecture?.id) {
            fetchArea(prefectures.find((p) => p.id === prefecture.id), area)
        }
    }, [prefectures, prefecture.id, area])

    return (
        <div className="flex w-full overflow-y-auto p-4 h-full">

            <form className="w-full container mx-auto" noValidate={true}>
                <div className="flex w-full lg:flex-row flex-col gap-6">
                    <div className="flex-1 w-full pb-4">
                        <div className="shadow rounded-lg bg-white">

                            <div className="p-4 border-b">
                                <div className="flex items-center">
                                    <p className="text-xl font-medium">{Translate("Area")}</p>
                                </div>
                            </div>

                            <div className="flex flex-col gap-4 p-4">

                                <div className="grid grid-cols-3">
                                    <div className="col-span-3 lg:col-span-1">
                                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Prefecture")}</p>
                                    </div>
                                    <div className="col-span-3 lg:col-span-2">
                                        <div className="grid grid-cols-3 items-center">
                                            <div className="col-span-3 lg:col-span-3">
                                                <SelectControl control={control} errors={errors} clearErrors={clearErrors}
                                                    name="prefecture.id"
                                                    options={prefectures}
                                                    getOptionLabel={(option => option.name)}
                                                    getOptionValue={(option => option.id)}
                                                    onChangeCallback={(prefecture) => {
                                                        setValue("city", {})
                                                        setValue("prefecture", prefecture)
                                                        setValue("area", prefecture)
                                                    }}
                                                    className={prefecture?.id == area?.id ? "w-full border-0 ring-4 rounded" : "w-full"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {prefecture?.id ? (
                                    <div className="col-span-1">
                                        <div className="grid grid-cols-3">
                                            <div className="col-span-3 lg:col-span-1">
                                                <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Area")} </p>
                                            </div>
                                            <div className="col-span-3 lg:col-span-2">
                                                {!areas[prefecture?.id] ? (
                                                    <div className="flex items-center" >
                                                        <svg className="animate-spin mr-3 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                        </svg>
                                                        Loading...
                                                    </div>) : (
                                                    <SelectControl control={control} errors={errors} clearErrors={clearErrors}
                                                        name="city.id"
                                                        options={areas[prefecture?.id] || []}
                                                        getOptionLabel={(option => option.name)}
                                                        getOptionValue={(option => option.id)}
                                                        deselectable={true}
                                                        onChangeCallback={(city) => {
                                                            setValue("area", city)
                                                            setValue("city", city)
                                                            setValue("viewport", { ...city.latlng, zoom: data.viewport?.zoom || 12 })
                                                            setValue("latlng", city.latlng)
                                                            setValue("apartments", [])
                                                            setValue("apartment_detail", null)
                                                            setValue("apartment", "")
                                                            setValue("floor", "")
                                                            setValue("room_number", "")
                                                        }}
                                                        className={city?.id == area?.id ? "w-full border-0 ring-4 rounded" : "w-full"}
                                                    />)}
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                                {subareas[city?.id] && subareas[city?.id].length > 0 ?
                                    <div className="col-span-1">
                                        <div className="grid grid-cols-3">
                                            <div className="col-span-3 lg:col-span-1">
                                                <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Neighborhood")}</p>
                                            </div>
                                            <div className="col-span-3 lg:col-span-2">
                                                <AreaField control={control}
                                                    area={area}
                                                    grid={true}
                                                    deselectable={true}
                                                    name="area.id"
                                                    parentOptions={areas[prefecture?.id] || []}
                                                    options={subareas[city?.id] || []}
                                                    onChangeCallback={(area) => {
                                                        // setData({ ...data, area: area, viewport: { ...area.latlng, zoom: data?.viewport?.zoom || 12 }, latlng: area.latlng, apartment: "", address: {} })
                                                        setValue("viewport", { ...area.latlng, zoom: data.viewport?.zoom || 12 })
                                                        setValue("latlng", area.latlng)
                                                        setValue("area", area)
                                                        setValue("apartments", [])
                                                        setValue("apartment_detail", null)
                                                        setValue("apartment", "")
                                                        setValue("floor", "")
                                                        setValue("room_number", "")
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div> : null}
                            </div>
                        </div>
                    </div>
                    <div className="flex-none w-full lg:w-80 ml-auto">
                        <div className="sticky top-0 pb-4">
                            <StepSummaryCard {...props} data={_data} />
                        </div>
                    </div>
                </div>
            </form>
        </div>

    )
}