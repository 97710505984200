import clsx from "clsx";
import { Link } from "react-router-dom";

export default function StatsCard({title, value, newValue, children, bgClassName}) {
    return (
    <div className="border rounded-lg p-4 flex flex-row justify-between items-center">
        <div>
            <div className="flex items-center mb-4">
                <div className="flex items-center gap-2">
                    {children}
                    <p className="text-sm font-semibold text-slate-500">{title}</p>
                </div>
                <div className="ml-auto">

                </div>
            </div>
            <div className="flex flex-row justify-between items-center">
                <div className="flex items-center gap-2">
                    <div className="flex items-center gap-4">
                        <p className="text-2xl font-bold">{new Intl.NumberFormat('en-US').format(Number(value ? value : 0))}</p>
                        {newValue ? <Link to="/v2/customers" className="flex items-center">
                            <span className="rounded-full text-xs font-medium px-2 py-0.5 bg-green-500 text-white">{newValue}</span>
                        </Link>
                        : null}
                    </div>
                    
                </div>
            </div>
        </div>
        <div>
            {/* <div className="ml-auto">
                <button type="button" onClick={() => {
                }} className="px-2 py-2 rounded bg-primary/10 flex items-center font-semibold text-primary">
                    View
                </button>
            </div> */}
        </div>
    </div>)
}