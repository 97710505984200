import React, { useMemo, useEffect, useState } from "react"
import SideNavBar from "../SideNavBar";
import TopSearchbar from "../TopSearchbar";
import InviteTeamDialog from "../../../v1/views/Settings/InviteTeamDialog"
import SettingsNavBar from "./SettingsNavBar";
import * as api from '../../services/agent'
import moment from "moment";
import { Link, useParams } from 'react-router-dom';
import * as Account from '../../../account';

export default function SettingsBilling(props) {
    const {Translate} = props
    let { id } = useParams();
    const [showInviteDialog, setShowInviteDialog] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [detail, setDetail] = React.useState({});
    const [invoice, setInvoice] = React.useState({});
    const [invoices, setInvoices] = React.useState([]);
    const query = new URLSearchParams(window.location.search);

    function loadSubscriptions() {
        api.getSubscriptions()
            .then(data => {
                setData(data);
                if (!id) {
                    loadSubscription(data[0].id)
                    props.history.replace({
                        pathname: `${window.location.pathname}/${data[0].id}`
                    })
                }

            })
            .catch(error => {
                console.log(error);
            })
    }


    function loadSubscription(id) {
        api.getSubscription(id)
            .then(data => {
                setDetail(data.subscription);
                setInvoices(data.invoices);
            })
            .catch(error => {
                console.log(error);
            })
    }

    function loadInvoice(id) {
        api.getInvoce(id)
            .then(invoice => {
                setInvoice(invoice)
                window.open(invoice.invoice_pdf)
            })
            .catch(error => {
                console.log(error);
            })
    }

    function subscription() {
        api.startSubscription()
            .then(checkout_url => {
                window.open(checkout_url, "_self")
            })
            .catch(error => {
                console.log(error);
            })
    }

    function checkSubscription() {
        api.checkSubscription()
            .then(response => {
                console.log(response)
                Account.updateAccount(response)
            })
            .catch(error => {
                console.log(error);
            })
    }

    function unsubscription() {
        if (window.confirm("Would you like to unsubscribe?") == true) {
            api.unsubscription()
                .then(response => {
                    Account.updateAccount(response)
                    window.location.reload()
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }

    useEffect(() => {
        loadSubscriptions()
    }, [])

    useEffect(() => {
        if (id) {
            loadSubscription(id)
        }
    }, [id])

    return (

        <div className="w-full flex bg-slate-50">
            <SideNavBar {...props} isPresented={true} />
            <div className="flex flex-col w-full relative">

                <div className="bg-white">
                    <div className="w-full container mx-auto lg:px-8 px-4">

                        <div className="py-8">
                            <p className="text-3xl font-bold">{Translate("Settings")}</p>
                        </div>

                    </div>
                </div>
                <div className="lg:p-8 p-4 flex items-center container mx-auto">
                    {/* content starts here */}
                    <div className="w-full">
                        <div className="mb-4">

                            <p className="text-2xl font-semibold">{Translate("Settings")}</p>
                        </div>

                        <SettingsNavBar active="billing"></SettingsNavBar>

                        <div className="flex w-full shadow rounded-lg bg-white overflow-hidden">

                            <div className="flex-none w-72 border-r overflow-hidden overflow-y-auto h-auto relative hidden lg:block">
                                <div className="p-4 border-b sticky top-0 bg-white w-full">
                                    <p className="font-medium">{Translate("Subscription")} ({data.length})</p>

                                </div>
                                <div className="flex flex-col divide-y ">
                                    {
                                        data.map((subscription, index) => (
                                            <Link to={`/v2/settings/billing/${subscription.id}`} key={subscription.id} >
                                                <div key={index} className={`flex px-4 py-4 items-center cursor-pointer ${subscription.id == detail.id ? 'bg-gray' : 'bg-white'}`} onClick={e => loadSubscription(subscription.id)}>
                                                    <div className="pl-4">
                                                        <p className="inline-flex items-center text-xs text-blue-600"><span>{moment(subscription.created_at).format("LL")} <span className={`px-2 inline-flex text-xs leading-5 font-medium text-${subscription.status == 'active' ? "green" : "red"}-600 uppercase`}> {Translate(subscription.status)}</span></span></p>
                                                        <p className="font-medium text-sm">

                                                        </p>

                                                    </div>
                                                </div>
                                            </Link>
                                        ))
                                    }
                                </div>
                            </div>
                            {detail.id ? <div className="flex flex-col relative h-full overflow-hidden overflow-y-auto w-full">
                                <div className="container mx-auto  bg-white p-6">
                                    <p className="font-semibold text-xl mb-6">{Translate("Detail")} {detail.status == 'active' ? <button type="button" className="ml-4 btn text-sm bg-red-500 text-white mr-2" onClick={(e) => unsubscription()}>{Translate("Cancel")}</button> : ""}</p>
                                    <div className="grid">
                                        <div className=" flex flex-col gap-4">
                                            <div className="">
                                                <p className="font-medium text-lg">{Translate("Subscription period")} : {moment.unix(detail.current_period_start).format("LL")} - {moment.unix(detail.current_period_end).format("LL")}</p>
                                                <p className="font-medium text-md">{Translate("Next billing")} : {moment.unix(detail.billing_cycle_anchor).format("LL")}
                                                    <span className={`px-2 inline-flex text-xs leading-5 font-medium rounded-full bg-${detail.status == 'active' ? "green" : "red"}-100 text-${detail.status == 'active' ? "green" : "red"}-600 uppercase`}> {Translate(detail.status)}
                                                    </span>
                                                </p>



                                                <table className="table-auto w-full mt-4">
                                                    <thead>
                                                        <tr className="border-b bg-gray-100 text-center ">
                                                            <th className="px-6 py-4 w-1/6">{Translate("Number")}</th>
                                                            <th className="px-6 py-4 w-1/6">{Translate("Detail")}</th>
                                                            <th className="px-6 py-4 w-1/6"></th>
                                                            <th className="px-6 py-4 w-1/6"></th>
                                                            <th className="px-6 py-4 w-1/6"></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            invoices.map((invoice, index) => (
                                                                <tr key={`inv-${index}`} className="border-b text-center">
                                                                    <td className=""><span>{invoice.number}</span></td>
                                                                    <td className="font-medium text-sm">{invoice.lines && invoice.lines.data && invoice.lines.data[0] ? invoice.lines.data[0].description : ""}</td>
                                                                    <td className="uppercase"><span>{Translate(invoice.status)}</span></td>
                                                                    <td className="text-xs"><button onClick={e => window.open(invoice.invoice_pdf)} className="ml-2 bg-blue-100 text-blue-600 px-4 py-2 rounded inline-flex items-center">{Translate("PDF")}</button></td>
                                                                    <td className="text-xs"><button onClick={e => window.open(invoice.hosted_invoice_url)} className="ml-2 bg-blue-100 text-blue-600 px-4 py-2 rounded inline-flex items-center">{Translate("Detail")}</button></td>
                                                                </tr>
                                                            ))
                                                        }
                                                    </tbody>
                                                </table>

                                            </div>
                                            {/* <div className="grid grid-cols-2 gap-6 text-xs">
                                                <textarea rows={30} value={JSON.stringify(data, undefined, 4)}></textarea>
                                                <textarea rows={30} value={JSON.stringify(invoices, undefined, 4)}></textarea>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div> : null}
                        </div>

                    </div>

                    {/* content ends here */}
                </div>
            </div>
        </div>
    )
}