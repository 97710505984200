import React from "react"
import { Translate } from "../../translate/Translate";


export default function ApartmentFeatureList(props) {


    return (
        <div className="font-medium text-xs">
            {
            props.features.map((item,index)=> (
                <div className="grid grid-cols-2">
                <div className="text-left">{Translate(item.title)}</div>
                <div className="text-right">{item.value}</div>
               </div>
            ))
            }
        </div>

    )
}