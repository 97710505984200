import React from "react"
import { Link } from "react-router-dom"
import { PropertyStatusBadge } from "./PropertyStatusBadge"
export function PinnedProperty(props) {
    return (
        <Link to={`/listing/${props.data.id}`}>
        <div className="flex overflow-hidden items-center">
            <div className="bg-gray-600 h-24 w-24 flex-none">
                {
                    props.data.images.length === 0 ?
                    null
                    :
                    <img src={props.data.images[0].url} className="object-cover w-full h-full rounded"/>
                }
                
            </div>
            <div className="px-3 py-3 w-9/12">
                <div className="">
                    <PropertyStatusBadge status={props.data.status}></PropertyStatusBadge>
                    <p className="truncate text-sm mt-1 w-40">{props.data.name}</p>
                </div>
                <div className="">
                    <p className="text-sm text-gray-600 font-medium">{new Intl.NumberFormat('ja-JP',{ style: 'currency', currency: props.data.price.currency }).format(Number(props.data.price.amount)) }</p>
                </div>
                
            </div>
        </div>
        </Link>
    )
}


