import clsx from 'clsx';
import { useState } from "react"
import { usePopper } from 'react-popper'
import { Controller } from "react-hook-form";
import { Translate } from "../../../translate/Translate"

export default function NumberInputField(props) {
    const {id, name, placeholder, required = false, className, control, clearErrors } = props
    const [referenceRef, setReferenceRef] = useState(null);
    const [popperRef, setPopperRef] = useState(null);

    const { styles, attributes } = usePopper(referenceRef, popperRef, {
        placement: "bottom-start",
        modifiers: [
        {
            name: "offset",
            enabled: true,
            options: {
            offset: [0, 10]
            }
        },
        ]
    });

    const borderStyle = (error, touched) => {
        if (error) {
            return "error border-red-600 border-1 focus:border-red-600 focus:ring-red-600 focus:focus:ring-1"
        }
        // if (touched) {
        //     return "border-green-600 border-2"
        // }
        return ""
    }
    
    return (<Controller 
        name={name} 
        control={control} 
        defaultValue={''}
        render={({ field: {value, onChange, onBlur}, fieldState: { invalid, isTouched, error } }) => {
            return (<>
                <input ref={setReferenceRef}
                    className={clsx("rounded w-full placeholder-gray-200", className, borderStyle(error, isTouched))}
                    required={required}
                    type="text"
                    name={name}
                    id={id || name}
                    placeholder={placeholder}
                    onChange={onChange}
                    onFocus={() => {
                        clearErrors(name)
                    }}
                    onBlur={() => {
                        if (value == "") {
                            onBlur()
                            return;
                        }

                        value = `${value}`.trim().replaceAll(",", "").normalize('NFKC').replace(/。/g, ".").replace(/[^0-9.]/g, "")
                        if (value.lastIndexOf(".") !== value.indexOf(".")){
                            value = value.slice(0, value.indexOf("."))
                        }
                        let price = parseFloat(value)
                        if (!isNaN(price)){            
                            onChange(price)
                            onBlur()
                            price = price.toString()
                        } else {
                            price = ""
                        }
                        onChange(price)
                    }}
                    value={value} />
                <div ref={setPopperRef} className={`bg-red-600 text-white rounded-lg p-1 z-10 ${error ? "block":"hidden"}`} style={styles.popper} {...attributes.popper}>
                    <p className="text-white p-1 font-semibold">{Translate(error?.message)}</p>
                    <div className={`absolute left-12 -top-1 w-3 h-3 bg-red-600 -z-10 ${error ? "block":"hidden"}`} style={{"transform": "rotate(45deg)"}}></div>
                </div>
            </>)
        }}
     />)
}