import React from "react"
import SideNavBar from "./SideNavBar";
import TopSearchbar from "./TopSearchbar";
import MapView from "./MapView";
import SelectedPropertiesPanel from "./Components/SelectedPropertiesPanel"
import * as api from '../services/agent'
import PropertyRequestBuilder from "./Components/PropertyRequestBuilder";
import Geocoder from "./Components/Geocoder"
import { Link } from 'react-router-dom';

export default function V2Index(props) {
    const {Translate} = props
    const query = new URLSearchParams(window.location.search);

    const [propertiesResponse, setPropertiesResponse] = React.useState({
        type: "FeatureCollection",
        features: [],
    });
    const [agents, setAgents] = React.useState([]);

    const [propertyRequest, setPropertyRequest] = React.useState(
        JSON.parse(query.get("query")) ??
        {
            list_status: ["active"],
            property_types: ["house", "lot", "apartment"],
            transaction_type: "",
            page_size: 500,
            price: {
                min: "",
                max: ""
            },
            sqm: {
                min: "",
                max: "",
            },
            lot: {
                min: "",
                max: "",
            },
            agents: [],
        }
    );

    React.useEffect(() => {
        props.history.replace({
            pathname: window.location.pathname,
            search: `?query=${JSON.stringify(propertyRequest)}`
        })
    }, [propertyRequest]);

    function loadProperties(request) {
        api.propertiesGeoJSON(request)
            .then(data => {
                console.log(data);
                setPropertiesResponse(data);
            })
            .catch(error => {
                console.log(error);
            })
    }

    function loadAgents(request) {
        request["has_property"] = true
        api.agents(request)
            .then(data => {
                setAgents(data);
            })
            .catch(error => {
                console.log(error);
            })
    }

    const onPropertyRequestChange = (key, value) => {
        setPropertyRequest({ ...propertyRequest, [key]: value })
    }

    const onSetPropertyRequest = (request) => {
        setPropertyRequest({ ...request })
    }

    React.useEffect(() => {
        loadProperties(propertyRequest)
        loadAgents(propertyRequest)
    }, [propertyRequest]);


    const emptyCollection = {
        properties: [],
    }
    const [collection, setCollection] = React.useState(emptyCollection);

    const onAddedToCollection = (property) => {
        console.log(property)
        let { properties } = collection;

        const matchPropertyID = (p) => p.id === property.id;
        const i = properties.findIndex(matchPropertyID);
        console.log(i);
        if (i < 0) {
            properties.push(property)
        }

        setCollection({ ...collection, properties: properties })
    }

    const onRemovedFromCollection = (property) => {
        let { properties } = collection;

        const matchPropertyID = (p) => p.id === property.id;
        const i = properties.findIndex(matchPropertyID);
        if (i > -1) {
            properties.splice(i, 1);
        }
        setCollection({ ...collection, properties: properties })
    }

    const onCloseCollection = () => {
        setCollection(emptyCollection)
    }

    const [selectedGeocoderResult, setSelectedGeocoderResult] = React.useState(null)
    const onGeocoderResult = (result) => {
        setSelectedGeocoderResult(result)
    }

    const [selectedLatLng, setSelectedLatLng] = React.useState({ lat: 0, lng: 0 })
    const onSelectedLatLng = (lat, lng) => {
        setSelectedLatLng({ lat: lat, lng: lng })
    }

    return (
        <div className="w-full flex bg-slate-50">
            <SideNavBar {...props} isPresented={true} />
            <main className="w-full h-full flex flex-col">

                <TopSearchbar {...props} /> 

                <div className="flex-none flex items-center w-full  py-4 px-6 border-b">
                    <p className="text-gray-700 text-2xl font-semibold">{Translate("Dashboard")}</p>
                    <div className="ml-auto flex itesm-center gap-4">
                        <Link to="/v2/properties/steps" id="add-new-property-button" className="bg-blue-600 text-white font-semibold text-sm px-3 py-2 rounded shadow">
                        {Translate("Add new property")}
                        </Link>
                    </div>
                </div>

                <div className="flex flex-col w-full h-full relative overflow-hidden">
                    <div className=" flex items-center w-full h-full">

                        <div className="h-full w-full relative">
                            <MapView onSelectedLatLng={onSelectedLatLng} selectedLatLng={selectedLatLng} history={props.history} selectedGeocoderResult={selectedGeocoderResult} onAddedToCollection={onAddedToCollection} propertyRequest={propertyRequest} onSetPropertyRequest={onSetPropertyRequest} properties={propertiesResponse} onPropertyRequestChange={onPropertyRequestChange} setSelectedGeocoderResult={setSelectedGeocoderResult}></MapView>
                            {
                                collection.properties.length > 0 ?
                                    <div className="absolute top-0 right-0 h-full p-3">
                                        <div className="shadow-xl rounded-lg overflow-hidden h-full">
                                            <SelectedPropertiesPanel onClose={onCloseCollection} onRemovedFromCollection={onRemovedFromCollection} collection={collection}></SelectedPropertiesPanel>
                                        </div>
                                    </div>
                                    : null
                            }
                            <div className="absolute top-3 left-1/2 transform -translate-x-1/2 w-1/3 flex flex-col">
                                <Geocoder {...props}  onResult={onGeocoderResult}></Geocoder>
                            </div>

                            <div className="absolute top-0 left-0 h-full p-3">
                                <div className="shadow-xl rounded-lg overflow-y-auto overflow-hidden h-full bg-white">
                                    <PropertyRequestBuilder agents={agents} propertyRequest={propertyRequest} properties={propertiesResponse} onSetPropertyRequest={onSetPropertyRequest} onPropertyRequestChange={onPropertyRequestChange} />
                                </div>
                            </div>


                        </div>
                    </div>


                </div>


            </main>
        </div>
    )
}