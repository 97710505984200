import React from "react"
import { useParams, useLocation } from "react-router-dom";
import SideNavBar from "../SideNavBar";
import TopSearchbar from "../TopSearchbar";
import { Translate } from "../../translate/Translate";
import CustomerRow from "./CustomerRow";
import CustomerDetailMessages from "./CustomerDetailMessages";
import CustomerDetailOffers from "./CustomerDetailOffers";
import CustomerDetailProfile from "./CustomerDetailProfile";
import CustomerDetailInsights from "./CustomerDetailInsights";
import CustomerDetailNotes from "./CustomerDetailNotes";
import CustomerDetailHeader from "./CustomerDetailHeader";
import * as api from '../../services/agent'
import { Link } from 'react-router-dom';
import CustomerNewThread from "./CustomerNewThread";

export default function Customers(props) {
    let { customerId, tab, id } = useParams();
    const { account, query, history } = props
    const [searchText, setSearchText] = React.useState(query.get("search") ?? "");
    const { customers, loadCustomers } = props
    const [filteredCustomers, setFilteredCustomers] = React.useState([]);
    const [selectedCustomerData, setSelectedCustomerData] = React.useState(null);
    const [insights, setInsights] = React.useState([])
    const [preferences, setPreferences] = React.useState([])

    const messagesTab = "inbox"
    const offersTab = "offers"
    const profileTab = "profile"
    const insightsTab = "insights"
    const notesTab = "notes"

    const newmessage = "newmessage"
    const { hash } = useLocation();
    const [selectedTab, setSelectedTab] = React.useState(messagesTab)

    React.useEffect(() => {
        if (!customers)
            loadCustomers()
    }, [])

    React.useEffect(() => {
        if (tab === undefined) {
            setSelectedTab(messagesTab)
        }
        if (tab === messagesTab) {
            setSelectedTab(messagesTab)
        } else if (tab === profileTab) {
            setSelectedTab(profileTab)
        } else if (tab === offersTab) {
            setSelectedTab(offersTab)
        } else if (tab === insightsTab) {
            setSelectedTab(insightsTab)
        } else if (tab === notesTab) {
            setSelectedTab(notesTab)
        } else if (tab === newmessage) {
            setSelectedTab(newmessage)
        }
    }, [tab])

    const loadCustomerInsight = (id) => {
        if (id != undefined)
            api.customerInsight(id)
                .then((data) => {
                    setInsights(data)
                })
                .catch(error => {
                    console.log(error);
                })
    }

    const loadCustomerPreference = (id) => {
        if (id != undefined)
            api.customerPreferences(id)
                .then((data) => {
                    setPreferences(data)
                })
                .catch(error => {
                    console.log(error);
                })
    }

    const loadCustomerInfo = (id) => {
        if (id != undefined)
            api.customer(id)
                .then(data => {
                    setSelectedCustomerData(data);
                })
                .catch(error => {
                    console.log(error);
                })
    }

    const goTab = (tab, id) => {
        setSelectedTab(tab)
        history.replace(`/v2/customers/${customerId}/${tab}${id ? "/" + id : ""}${hash ? hash : ""}${query.get("search") ? "?search=" + query.get("search") : ""}`)
    }

    React.useEffect(() => {
        loadCustomerInfo(customerId)
        loadCustomerInsight(customerId)
        loadCustomerPreference(customerId)
    }, [customerId])

    React.useEffect(() => {
        setFilteredCustomers(customers.filter(({ first_name, last_name, email, tags }) => tags.findIndex(k => k.toLowerCase().includes(searchText.toLowerCase())) >= 0 || first_name.toLowerCase().includes(searchText.toLowerCase()) || last_name.toLowerCase().includes(searchText.toLowerCase()) || email.toLowerCase().includes(searchText.toLowerCase())))
        props.history.replace({
            pathname: window.location.pathname,
            search: !searchText ? "" : `?search=${searchText}`
        })

    }, [searchText, customers])

    return (
        <div className="absolute inset-0 h-full flex">
            <SideNavBar {...props} isPresented={true} />
            <main className="w-full h-full flex flex-col">

                {/* <TopSearchbar {...props} /> */}

                <div className="flex-none flex items-center w-full py-4 px-6 border-b ">
                    <p className="text-gray-700 text-2xl font-semibold">{Translate("Manage customers")}</p>
                    <div className="ml-auto flex itesm-center gap-4">
                        {/* <button className="bg-blue-600 text-white font-semibold text-sm px-3 py-2 rounded shadow">
                            Add customer
                        </button> */}
                    </div>
                </div>

                <div className="flex-1 flex relative w-full overflow-hidden">

                    <div className="flex-none w-72 border-r overflow-hidden overflow-y-auto h-auto relative hidden lg:block">
                        <div className="p-4 border-b sticky top-0 bg-white w-full z-10">
                            <p className="font-medium mb-4">{Translate("Customers")} ({filteredCustomers.length})</p>
                            <input type="text" className="rounded border-gray-300 text-sm w-full shadow-sm" placeholder="Search.." value={searchText} onChange={(e) => setSearchText(e.target.value)}></input>
                        </div>
                        <div className="flex flex-col divide-y ">
                            {filteredCustomers.map((c, index) =>
                                <Link to={`/v2/customers/${c.customer_no}${selectedTab ? `/${selectedTab}` : ""}${searchText ? "?search=" + searchText : ""}`} key={`${c.id}-${index}`} className={`${customerId === c.customer_no ? "bg-gray-100" : ""}`}>
                                    <CustomerRow customer={c} />
                                </Link>
                            )}
                        </div>
                    </div>

                    {
                        selectedCustomerData === null ? null
                            :

                            <div className="flex flex-col w-full relative">
                                <CustomerDetailHeader {...props} customer={selectedCustomerData} selectedTab={selectedTab} goTab={goTab} />
                                {
                                    selectedTab === messagesTab ?
                                        <CustomerDetailMessages {...props} customer={selectedCustomerData} account={account} goTab={goTab} loadCustomerInfo={loadCustomerInfo} loadCustomers={loadCustomers}></CustomerDetailMessages>
                                        : null
                                }
                                {
                                    selectedTab === offersTab ?
                                        <CustomerDetailOffers {...props} customer={selectedCustomerData} account={account} goTab={goTab}></CustomerDetailOffers>
                                        : null
                                }
                                {
                                    selectedTab === profileTab ?
                                        <CustomerDetailProfile {...props} customer={selectedCustomerData} account={account} goTab={goTab}></CustomerDetailProfile>
                                        : null
                                }

                                {
                                    selectedTab === insightsTab ?
                                        <CustomerDetailInsights {...props} customer={selectedCustomerData} account={account} insights={insights} preferences={preferences} goTab={goTab}></CustomerDetailInsights>
                                        : null
                                }
                                {
                                    selectedTab === notesTab ?
                                        <CustomerDetailNotes {...props} customer={selectedCustomerData} account={account} goTab={goTab}></CustomerDetailNotes>
                                        : null
                                }
                                {
                                    selectedTab === newmessage ?
                                        <CustomerNewThread {...props} customer={selectedCustomerData} account={account} goTab={goTab}></CustomerNewThread>
                                        : null
                                }

                                <div className="flex-none mt-auto border-t py-4 px-2 flex items-center justify-between bg-white">
                                    <Link to={`/v2/customers/${customerId}/inbox`} className={`px-4 flex flex-col items-center ${selectedTab === messagesTab || selectedTab === newmessage ? "text-blue-600" : "text-gray-500"}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6  mb-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z" />
                                        </svg>
                                        <p className="text-xs ">{Translate("Messages")}</p>
                                    </Link>
                                    <Link to={`/v2/customers/${customerId}/offers`} className={`px-4 flex flex-col items-center ${selectedTab === offersTab ? "text-blue-600" : "text-gray-500"}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mb-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                        </svg>
                                        <p className="text-xs ">{Translate("Offers")}</p>
                                    </Link>
                                    <Link to={`/v2/customers/${customerId}/profile`} className={`px-4 flex flex-col items-center ${selectedTab === profileTab ? "text-blue-600" : "text-gray-500"}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6  mb-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                        </svg>
                                        <p className="text-xs ">{Translate("Profile")}</p>
                                    </Link>
                                    <Link to={`/v2/customers/${customerId}/notes`} className={`px-4 flex flex-col items-center ${selectedTab === notesTab ? "text-blue-600" : "text-gray-500"}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mb-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                        </svg>
                                        <p className="text-xs ">{Translate("Notes")}</p>
                                    </Link>
                                    <Link to={`/v2/customers/${customerId}/insights`} className={`px-4 flex flex-col items-center ${selectedTab === insightsTab ? "text-blue-600" : "text-gray-500"}`}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mb-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122" />
                                        </svg>
                                        <p className="text-xs ">{Translate("Insights")}</p>
                                    </Link>
                                </div>
                            </div>
                    }
                </div>
            </main>
        </div>
    );
}
