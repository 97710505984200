import React, { useCallback } from "react"
import TopNavBar from '../Shared/TopNavBar'
import * as api from '../../services/agent'
import { PropertyStatusBadge } from "../Shared/PropertyStatusBadge"
import Select from 'react-select'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import { Translate } from "../../translate/Translate";
import Error from '../Shared/Error'
import { PhotoManager } from "../Shared/PhotoManager";


export default function ApartmentFeatureList(props) {


    return (
        <div className="font-medium text-xs">
            {
            props.features.map((item,index)=> (
                <div className="grid grid-cols-2">
                <div className="text-left">{Translate(item.title)}</div>
                <div className="text-right">{item.value}</div>
               </div>
            ))
            }
        </div>

    )
}