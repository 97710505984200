import React from "react"
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import * as auth from '../../v2/services/auth'
import { Translate } from "../../v2/translate/Translate";
import * as api from '../../v2/services/agent'

export default function AcceptInvitation(props) {

    let { code } = useParams();
    const [error, setError] = React.useState(null)
    const [data, setData] = React.useState({
        first_name: "",
        last_name: "",
        password: "",
        confirmPassword: "",
        code: code
    });

    const [invitation, setInvitation] = React.useState({
        email: "",
        agent: { first_name: "", last_name: "" },
        company: { name: "" },
    });


    function loadInvitation() {
        api.invitation(code)
            .then(data => {
                console.log(data)
                setInvitation(data.data);
            })
            .catch(error => {
                console.log(error);
            })
    }

    //uncomment this to fetch data when api is deployed
    React.useEffect(() => {
        loadInvitation()
    }, [])

    const handleSubmit = (e) => {
        e.preventDefault();
        if (data.password !== data.confirmPassword) {
            return;
        }
        auth.acceptInvitation(data)
            .then(response => {
                // window.location.href = "/app/login"
                window.location.href = "/login"
            })
            .catch(error => {
                setError(error.data)
                setTimeout(() => {
                    setError(null)
                }, 3000);
            })
    }

    const handleDataChange = (e) => {
        var { name, value } = e.target
        setData({ ...data, [name]: value })
    }

    const renderError = () => {
        if (error === null) {
            return "";
        }
        return (
            <div className="bg-red-100 text-red-500 mt-6 rounded py-2 text-center">
                {error.message}
            </div>
        )
    }
    return (
        <div className="">
            <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
                <div className="max-w-md w-full space-y-8">
                    <div>
                        <div className="flex items-center justify-center">
                            <div className="mx-auto inline-flex justify-center items-center ">
                                <svg className="fill-current text-indigo-600 w-20 h-20" viewBox="0 0 32 30"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0 15.5471C0 16.2122 0.523303 16.7877 1.36059 16.7877C1.76615 16.7877 2.13246 16.5703 2.45953 16.3145L3.95094 15.0867V26.9815C3.95094 28.8745 5.11529 30 7.11693 30H24.8177C26.8062 30 27.9836 28.8745 27.9836 26.9815V15.0227L29.5536 16.3145C29.8675 16.5703 30.2339 16.7877 30.6394 16.7877C31.4113 16.7877 32 16.3145 32 15.5727C32 15.1378 31.8299 14.7925 31.4898 14.5111L27.9836 11.6205V6.1719C27.9836 5.59634 27.6043 5.23822 27.0155 5.23822H25.2101C24.6345 5.23822 24.242 5.59634 24.242 6.1719V8.55087L17.8708 3.31969C16.7326 2.38601 15.2935 2.38601 14.1554 3.31969L0.523303 14.5111C0.170074 14.7925 0 15.1762 0 15.5471ZM7.8103 27.4548C6.99918 27.4548 6.55437 27.0071 6.55437 26.2013V12.9507L15.4374 5.66029C15.8038 5.35333 16.2224 5.35333 16.5887 5.66029L25.3671 12.874V26.2013C25.3671 27.0071 24.9223 27.4548 24.1112 27.4548H7.8103Z" />
                                    <path
                                        d="M28.1328 1.96457C28.1328 3.04957 27.2331 3.92914 26.1233 3.92914C25.0135 3.92914 24.1138 3.04957 24.1138 1.96457C24.1138 0.879569 25.0135 0 26.1233 0C27.2331 0 28.1328 0.879569 28.1328 1.96457Z" />
                                </svg>
                            </div>
                        </div>
                        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900 mb-4">
                            Accept invitation
                         </h2>
                        <p className="text-center">{invitation.agent.first_name} {invitation.agent.last_name}  invited you to help manage listing of {invitation.company.name}.</p>
                    </div>
                    {invitation.registered_email ?
                        <form className="mt-8 space-y-4  bg-white rounded-lg p-4 lg:p-8 shadow" onSubmit={handleSubmit}>
                            <label className="block">
                                <span className="text-gray-700 text-sm font-medium">{Translate("Email")}</span>
                                <input
                                    disabled
                                    type="text"
                                    className="mt-1 rounded w-full bg-gray-200 text-gray-600" d
                                    value={invitation.registered_email}
                                />
                                <span className="text-gray-700 text-sm font-medium">{Translate("Code")}</span>
                                <input
                                    disabled
                                    type="text"
                                    className="mt-1 rounded w-full bg-gray-200 text-gray-600"
                                    required
                                    value={code}
                                />

                            </label>
                            <div>
                                <button type="submit" className="group relative btn btn-primary w-full disabled:opacity-50">
                                    Accept
                     </button>
                            </div>
                        </form>
                        :
                        <form className="mt-8 space-y-4  bg-white rounded-lg p-4 lg:p-8 shadow" onSubmit={handleSubmit}>
                            <label className="block">
                                <span className="text-gray-700 text-sm font-medium">{Translate("Code")}</span>
                                <input
                                    disabled
                                    type="text"
                                    className="mt-1 rounded w-full bg-gray-200 text-gray-600"
                                    required
                                    value={code}
                                />
                            </label>


                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <label className="block">
                                        <span className="text-gray-700 text-sm font-medium">{Translate("First name")}</span>
                                        <input
                                            required
                                            name="first_name"
                                            onChange={handleDataChange}
                                            type="text"
                                            className="mt-1 rounded w-full"
                                            placeholder=""
                                        />
                                    </label>
                                </div>
                                <div>
                                    <label className="block">
                                        <span className="text-gray-700 text-sm font-medium">{Translate("Last name")}</span>
                                        <input
                                            required
                                            name="last_name"
                                            onChange={handleDataChange}
                                            type="text"
                                            className="mt-1 rounded w-full"
                                            placeholder=""
                                        />
                                    </label>
                                </div>
                            </div>

                            <label className="block">
                                <span className="text-gray-700 text-sm font-medium">{Translate("Password")}</span>
                                <input
                                    name="password"
                                    type="password"
                                    minLength="8"
                                    className="mt-1 rounded w-full"
                                    placeholder=""
                                    required
                                    value={data.password}
                                    onChange={handleDataChange}
                                />
                            </label>
                            <label className="block">
                                <span className="text-gray-700 text-sm font-medium">{Translate("Confirm password")}</span>
                                <input
                                    name="confirmPassword"
                                    minLength="8"
                                    type="password"
                                    className="mt-1 rounded w-full"
                                    placeholder=""
                                    required
                                    value={data.confirmPassword}
                                    onChange={handleDataChange}
                                />
                            </label>

                            <div>
                                <button disabled={data.password.trim().length < 8 || data.confirmPassword.trim().length < 8 || data.confirmPassword.trim() !== data.password.trim()} type="submit" className="group relative btn btn-primary w-full disabled:opacity-50">
                                    Sign up
                            </button>
                            </div>
                        </form>
                    }
                    {renderError()}
                    <div className="mx-auto text-center text-sm">
                        <Link to="/login">{Translate("Sign in to your account")}</Link>
                    </div>
                </div>
            </div>
        </div>
    );
}