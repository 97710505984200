import {MAPBOX_TOKEN} from '../constants'

export const reverseGeocoding = ({lng,lat}) => {
    let text = `${lng},${lat}`
    let url = `https://api.mapbox.com/search/v1/reverse/${text}?language=ja&access_token=${MAPBOX_TOKEN}`
    return fetch(url)
        .then(response => response.json())
        .then(resp => {
            return resp
        })
        .catch((e) => {
            console.log("Geocoder failed due to: " + e)
            return e
        });
}

export const isochrone = ({lng,lat, distance, color}) => {
    let text = `${lng},${lat}`

    let url = `https://api.mapbox.com/isochrone/v1/mapbox/walking/${text}?contours_minutes=5,10,15,30&contours_colors=08519c%2C3182bd%2C6baed6%2Cbdd7e7&polygons=true&denoise=1&generalize=10&access_token=${MAPBOX_TOKEN}`
    if (distance) {
        url = `https://api.mapbox.com/isochrone/v1/mapbox/walking/${text}?contours_minutes=${distance.max || distance.min}&contours_colors=${color ? color:"08519c"}&polygons=true&denoise=1&generalize=10&access_token=${MAPBOX_TOKEN}`
    }
    return fetch(url)
        .then(response => response.json())
        .then(resp => {
            return resp
        })
        .catch((e) => {
            console.log("Geocoder failed due to: " + e)
            return e
        });
}

export const isochroneWithMeters = ({lng,lat, meters, color}) => {
    let text = `${lng},${lat}`
    
    let url = `https://api.mapbox.com/isochrone/v1/mapbox/walking/${text}?contours_meters=${meters}&contours_colors=${color ? color:"08519c"}&polygons=true&denoise=1&generalize=10&access_token=${MAPBOX_TOKEN}`
    
    return fetch(url)
        .then(response => response.json())
        .then(resp => {
            return resp
        })
        .catch((e) => {
            console.log("Geocoder failed due to: " + e)
            return e
        });
}