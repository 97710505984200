
import React from "react"
import { Link } from "react-router-dom";
import { ChevronRightIcon, HomeIcon, BuildingOfficeIcon, UserGroupIcon } from "@heroicons/react/24/solid"
export default function EmptyStateOnActivityList(props) {

    const { statistics } = props

    return (

        <div className="mt-8 bg-white">
            <p className="font-semibold">Welcome to Viila</p>
            <p className="text-slate-500">You haven't completed your profile yet.</p>
            <hr className="mt-4" />
            <div className="flex flex-col divide-y w-full">
                <Link to="/v2/settings/profile" className="flex items-center gap-3 py-4 w-full">
                    <div className="w-10 h-10 rounded bg-green-500 flex items-center justify-center">
                        <BuildingOfficeIcon className="w-6 h-6 text-white" />
                    </div>
                    <div className="">
                        <p className=" font-medium leading-none mb-1">Update your profile</p>
                        <p className="text-xs text-slate-500">Company logo, Business hours, Contact info, etc.</p>
                    </div>
                    <div className="ml-auto w-6 h-6 text-slate-400">
                        <ChevronRightIcon />
                    </div>
                </Link>
                <Link to="/v2/settings/team" className="flex items-center gap-3 py-4 w-full">
                    <div className="w-10 h-10 rounded bg-orange-500 flex items-center justify-center">
                        <UserGroupIcon className="w-6 h-6 text-white" />
                    </div>
                    <div className="">
                        <p className=" font-medium leading-none mb-1">Add team members</p>
                        <p className="text-sm text-slate-500">Managing properties, inquiries, and other things</p>
                    </div>
                    <div className="ml-auto w-6 h-6 text-slate-400">
                        <ChevronRightIcon />
                    </div>
                </Link>

                {props.statistics.number_of_properties > 0 ? null :
                    <Link to="/v2/properties/wizard/steps" className="flex items-center gap-3 py-4 w-full">
                        <div className="w-10 h-10 rounded bg-blue-500 flex items-center justify-center">
                            <HomeIcon className="w-6 h-6 text-white" />
                        </div>
                        <div className="">
                            <p className=" font-medium leading-none mb-1">Add your first lising</p>
                            <p className="text-sm text-slate-500">Add your first listing info and publish on Viila</p>
                        </div>
                        <div className="ml-auto w-6 h-6 text-slate-400">
                            <ChevronRightIcon />
                        </div>
                    </Link>
                }
            </div>
        </div>
    )
}