import React from "react"
import AppContext from "../../../AppContext"

export default function Price(props) {

    const {language} = React.useContext(AppContext)   
    const {amount, currency} = props;
    return (
        <>
        {language === "ja"? 
        new Intl.NumberFormat('ja-JP', { style: 'currency', currency: currency }).format(Number(amount))
        :
        new Intl.NumberFormat('en-US', { style: 'currency', currency: currency }).format(Number(amount))
        }
        </>
    )
}