
import React, { Fragment, useEffect, useState, useMemo } from "react"
import Lottie from "lottie-react";
import SideNavBar from "../SideNavBar";

import { useHistory, useParams, Link } from 'react-router-dom';
import ProposalModal from "../Components/SellerRequest/ProposalModal";
import * as api from '../../services/agent'
import SubmitProposalBox from "../Components/SellerRequest/SubmitProposalBox";
import { SellRequestState } from "../Shared/SellRequestState";
import { SellRequestStatusBadge } from "../Shared/SellRequestStatusBadge"
import SellerRequestDetailBox from "../Components/SellerRequest/SellerRequestDetailBox";
import SellTransactionModal from "../Components/SellerRequest/SellTransactionModal";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import { InformationCircleIcon as InformationCircleIconWhite } from "@heroicons/react/24/outline";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import enableLocationAnimation from "../../../lotties/enable-location-animation.json"

export default function SellRequestProposalDetail(props) {
    const { Translate } = props
    let history = useHistory();
    const [loading, setLoading] = useState(false)
    const [estimateLoaded, setEstimateLoaded] = useState(false)
    const [dialogIsOpen, setDialogIsOpen] = useState(false)
    const [declineDialogIsOpen, setDeclineDialogIsOpen] = useState(false)
    // const [transactions, setTransactions] = useState([])
    const [estimateSummary, setEstimateSummary] = useState({
        trade_nearby_station_stats: [],
        trade_summary_stats: {
            counts: 0,
            avg_price_per_sqm: 0,
            max_price_per_sqm: 0,
            min_price_per_sqm: 0,
            trade_summary: []
        }
    })
    const [sellRequest, setSellRequest] = useState({
        "id": "",
        "user_id": "",
        "property_type": "",
        "contact": { "first_name": "", "last_name": "", "email": "", "phone": "", "address_1": "", "address_2": "", "city": "", "prefecture": "", "zip_code": "", "region": "", "country_code": "", "image_url": "", "role": "", "settings": { "language": "" }, "stripe_customer_id": "" },
        "data": { "floor_plan": "", "full_address": "", "lot_sqm": "", "property_owner": "", "reason_to_sell": [], "request_to_agents":[], "room_sqm": "", "same_address": false, "time_to_sell": "" },
        "latlng": { "latitude": 36.36351776123047, "longitude": 138.5756378173828 },
        "agents": [],
        "proposals": [],
        "created_at": new Date(),
        "approved_at": new Date(),
        "status": "pending",
        "proposal_amount": 0,
        "sell_request_no": ""
    })
    const propertyDetail = [
        "floor_plan",
        "lot_sqm",
        "apartment_name",
        "room_sqm",
        "landshape",
        "structure",
    ]
    const { requestId } = useParams();

    function loadDetail(requestId) {
        api.fetchSellRequestDetail(requestId)
            .then((res) => {
                setSellRequest(res.data)
                
            })
    }

    function loadEstimatePrice(requestId) {
        setLoading(true)
        api.fetchSellRequestEstimate(requestId)
            .then((res) => {
                setTimeout(() => { 
                    setEstimateSummary(res.data)
                    setEstimateLoaded(true)
                   
                }, 2000)
            }).finally((e)=>{
                setLoading(false) 
            })
    }

    useEffect(() => {
        loadDetail(requestId)
        // loadEstimatePrice(requestId)
    }, [requestId])

    const handleDecline = () => {
        api.declineSellRequest(requestId)
            .then((res) => {
                history.push(`/v2/sellers/request`);
            })
    }

    const estimatedPrice = useMemo(() => {
        if (estimateSummary.trade_nearby_station_stats.length === 0) return {
            station: "",
            total: 0,
            sqm: 0,
            minutes: 0,
        }

        const [nearest] = estimateSummary.trade_nearby_station_stats

        if (!nearest || !nearest.google_map_distance) return

        const minutes = Math.floor(nearest.google_map_distance.map_data.rows[0].elements[0].duration.value / 60)

        const sameStations = estimateSummary.trade_summary_stats.trade_summary.filter((item) => item.nearest_station === nearest.nearest_station)

        const exactMatch = sameStations.find((stats) => stats.distance == minutes)
        if (exactMatch) {
            return {
                station: nearest.nearest_station,
                total: exactMatch.avg_price_per_sqm * (+sellRequest.data.lot_sqm),
                sqm: exactMatch.avg_price_per_sqm,
                minutes: minutes,
                counts: sameStations.reduce((total, item) => total + item.counts, 0)
            } 
        }
        const greater = sameStations.filter((stats) => stats.distance > minutes)
        const lessThan = sameStations.filter((stats) => stats.distance < minutes)

        if (greater.length > 0) {
            return {
                station: nearest.nearest_station,
                total: greater[0].avg_price_per_sqm * (+sellRequest.data.lot_sqm),
                minutes: greater[0].distance,
                sqm: greater[0].avg_price_per_sqm,
                counts: sameStations.reduce((total, item) => total + item.counts, 0)
            }
        }

        if (lessThan.length > 0) {
            return {
                station: nearest.nearest_station,
                total: lessThan[0].avg_price_per_sqm * (+sellRequest.data.lot_sqm),
                minutes: lessThan[0].distance,
                sqm: lessThan[0].avg_price_per_sqm,
                counts: sameStations.reduce((total, item) => total + item.counts, 0)
            }
        }

        return {
            station: nearest.nearest_station,
            total: 0,
            minutes: minutes,
            sqm: 0,
            counts: sameStations.reduce((total, item) => total + item.counts, 0)
        }
    }, [estimateSummary])

    return (
        // <div className="absolute inset-0 w-full h-full flex bg-slate-50">
        <div className="w-full flex ">
            <SideNavBar {...props} isPresented={true} />

            <div className="flex flex-col w-full relative">

                <div className="border-b">
                    <div className="w-full container mx-auto lg:px-8 p-4">
                        <div className="items-center">
                            <div className="">
                                <div className="w-full flex items-center">
                                    <Link to={`/v2/sellers/request/${sellRequest.sell_request_no}`} type="button" className="text-xs uppercase font-medium text-gray-600 inline-flex items-center">
                                        <svg className="fill-current text-gray-600 mr-2" width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.890625 5.76953C0.890625 5.91602 0.943359 6.04492 1.05469 6.15625L5.70117 10.6973C5.80078 10.8027 5.92969 10.8555 6.08203 10.8555C6.38672 10.8555 6.62109 10.627 6.62109 10.3223C6.62109 10.1699 6.55664 10.041 6.46289 9.94141L2.19727 5.76953L6.46289 1.59766C6.55664 1.49805 6.62109 1.36328 6.62109 1.2168C6.62109 0.912109 6.38672 0.683594 6.08203 0.683594C5.92969 0.683594 5.80078 0.736328 5.70117 0.835938L1.05469 5.38281C0.943359 5.48828 0.890625 5.62305 0.890625 5.76953Z" />
                                        </svg>
                                        <span>{Translate("Back")}</span>
                                    </Link>
                                    <div className="ml-auto text-right">
                                    </div>
                                </div>
                                <div className="p-2">
                                    <p className="text-sm">{Translate("Request No.")}</p>
                                    <p className="text-xl font-bold">{sellRequest.sell_request_no}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="container mx-auto flex lg:flex-row flex-col items-start p-5 gap-5 ">
                    <div className="w-full lg:w-2/3">
                        {sellRequest.id && <SubmitProposalBox {...props} sellRequest={sellRequest} avgPricePerSqm={!estimatedPrice ? 0 : estimatedPrice.sqm} />}
                    </div>
                    <div className="w-full lg:w-1/3">
                        <div className="rounded-lg border bg-white mb-4 flex flex-col">
                            <div className="p-4 flex items-center">
                                <p className="text-xl font-bold">{Translate("AI-assisted Estimator")}</p>
                            </div>

                            {!loading && (<><div className="px-4 mx-auto">
                                <InformationCircleIconWhite className="cursor-pointer w-12 h-12"  />
                            </div>
                            <p className="px-4 pb-4 mx-auto">{Translate("Based on our data, we think this property is worth")}</p>
                            <div className="px-4 pb-4 mx-auto">
                                <button type="button" className="btn text-sm bg-blue-500 text-white" onClick={() => {
                                    loadEstimatePrice(requestId)
                                }}>Estimate</button>
                            </div></>)}
                            {loading && <Lottie animationData={enableLocationAnimation} className="w-1/3 mx-auto" />}
                            {!loading && estimateLoaded && estimatedPrice && (<>
                                <div className="px-4 pb-4">
                                    <p className="text-sm mb-2">{Translate("Based on our data, we think this property is worth")}</p>
                                    <p className="text-3xl font-bold text-primary">{new Intl.NumberFormat('ja-JP',{ style: 'currency', currency: "JPY" }).format(Number(!estimatedPrice ? 0 : estimatedPrice.total))}</p>
                                    <p className="text-xl font-bold ">{!estimatedPrice ? "" : estimatedPrice.station} · {Translate("withinXminutes").replace("{minutes}",`${!estimatedPrice ? 0 : estimatedPrice.minutes}`)}</p>
                                    <p className="text-xl font-bold text-primary">{new Intl.NumberFormat('ja-JP',{ style: 'currency', currency: "JPY" }).format(Number(!estimatedPrice ? 0 : estimatedPrice.sqm))}/m² · {new Intl.NumberFormat('ja-JP',{ style: 'currency', currency: "JPY" }).format(Math.round(!estimatedPrice ? 0 : estimatedPrice.sqm * 3.306))}/{Translate("tsubo")}</p>
                                    <button onClick={() => {
                                        setDialogIsOpen(!dialogIsOpen)
                                    }} className="flex items-center text-xs text-gray-500">
                                        <InformationCircleIcon className="cursor-pointer w-4 h-4 mr-2"  />
                                        {Translate("transactions_amount_prefix")} {!estimatedPrice ? 0 : estimatedPrice.counts}  {Translate("transactions_amount_suffix")}
                                    </button>
                                </div>
                                <div className="mt-auto border-t px-4 py-2">
                                    <Link to={`/v2/transactions/?query=${JSON.stringify({type: "address", address: sellRequest.data.full_address})}`} className="flex items-center text-gray-500 gap-2">
                                        <p className="text-sm">{Translate("Learn more about Viila Home Estimate")}</p>
                                        <ArrowTopRightOnSquareIcon className="w-4 h-4"/>
                                    </Link>
                                </div>
                            </>)}
                        </div>
                        <SellerRequestDetailBox {...props} data={sellRequest} />
                    </div>
                </div>
            </div>
            {dialogIsOpen && <SellTransactionModal {...props} isOpen={dialogIsOpen} setIsOpen={setDialogIsOpen} summary={estimateSummary.trade_summary_stats} station={estimateSummary.trade_nearby_station_stats[0].nearest_station} minutes={!estimatedPrice ? 0 : estimatedPrice.minutes} />}
        </div>)
}