import React, { useEffect, useState } from "react"
import SelectControl from "./SelectControl"
import AreaField from "./AreaField";

export default function EditAreaForm(props) {
    const { prefectures, areas, subareas, loadAreas, data, useFormReturn, Translate, setData } = props
    const { reset, formState: { errors }, control, setValue, watch, clearErrors } = useFormReturn

    const _data = watch()
    const { prefecture, city, area } = _data

    useEffect(() => {
        reset({ ...data });
    }, []);

    const fetchArea = (prefecture, area) => {
        if (areas[prefecture.id]) {
            // setValue("area.id", prefecture.id)
            if (area?.id) {
                return
            }
            setValue("area.id", area?.id || prefecture.id)
            setValue("prefecture", prefecture)
            return
        }
        loadAreas(prefecture.id)
            .then((areas) => {
                setTimeout(() => {
                    if (area?.id) {
                        return
                    }
                    setValue("area.id", areas[prefecture.id].find((d) => d.id === area?.id)?.id || prefecture.id)
                    setValue("prefecture", prefecture)

                }, 250)

            })
    }

    useEffect(() => {
        if (data.area && data.area.level) {
            if (data.area.level == 2)
                setValue("city", { id: data.area.parent })

            if (data.area.level == 1)
                setValue("prefecture", { id: data.area.parent })
        }
        if (prefectures.length && prefecture?.id != "" && !areas[prefecture?.id]) {
            fetchArea(prefectures.find((p) => p.id === prefecture.id), area)
        }
    }, [prefectures, prefecture, data.area])

    return (<>
        <div className="container mx-auto text-sm rounded-md bg-white h-full overflow-y-scroll">
            <div className="py-4 lg:py-4 px-6">
                <div className="grid grid-cols-3 items-center mt-4 py-4 lg:py-4 px-6">
                    <div className="col-span-3 lg:col-span-1">
                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Prefecture")}</p>
                    </div>
                    <div className="col-span-3 lg:col-span-2 w-full lg:w-2/3">
                        <SelectControl control={control} errors={errors} clearErrors={clearErrors}
                            name="prefecture.id"
                            options={prefectures}
                            getOptionLabel={(option => option.name)}
                            getOptionValue={(option => option.id)}
                            onChangeCallback={(prefecture) => {
                                setValue("city", {})
                                setValue("prefecture", prefecture)
                                setValue("area", prefecture)
                            }}
                            className={prefecture?.id == area?.id ? "border-0 ring-4 rounded" : ""} />

                    </div>
                </div>
                {prefecture?.id ? (<>
                    <div className="grid grid-cols-3 mt-4 py-4 lg:py-4 px-6">
                        <div className="col-span-3 lg:col-span-1">
                            <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Area")}</p>
                        </div>
                        <div className="col-span-3 lg:col-span-2 w-full lg:w-2/3">
                            {!areas[prefecture?.id] ? (
                                <button type="button" className="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-lg text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed" disabled="">
                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                    Loading...
                                </button>) : (
                                <SelectControl control={control} errors={errors} clearErrors={clearErrors}
                                    name="city.id"
                                    options={areas[prefecture?.id] || []}
                                    getOptionLabel={(option => option.name)}
                                    getOptionValue={(option => option.id)}
                                    deselectable={true}
                                    onChangeCallback={(city) => {
                                        setValue("area", city)
                                        setValue("city", city)
                                    }}
                                    className={city?.id == area?.id ? "border-0 ring-4 rounded" : ""}
                                />)}
                        </div>
                    </div>

                    {subareas[city?.id] && subareas[city?.id].length > 0 ?
                        <div className="grid grid-cols-3 mt-4 py-4 lg:py-4 px-6">
                            <div className="col-span-3 lg:col-span-1">
                                <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Neighborhood")}</p>
                            </div>
                            <div className="col-span-3 lg:col-span-2">
                                <AreaField control={control}
                                    area={area}
                                    grid={true}
                                    deselectable={true}
                                    name="area.id"
                                    parentOptions={areas[prefecture?.id] || []}
                                    options={subareas[city?.id] || []}
                                    onChangeCallback={(area) => {
                                        // setData({...data, area})
                                        setValue("area", area)
                                        // setData({ ...data, area: area, viewport: { ...data?.viewport, zoom: data?.viewport?.zoom || 12 }, apartment: "", address: {} })
                                    }}
                                />
                            </div>
                        </div> : null}
                </>) : null}
            </div>
        </div>
    </>)
}