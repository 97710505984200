import * as Yup from "yup";

export const stepPrefectureArea = Yup.object().shape({
    prefecture: Yup.object({
        id: Yup.string().required("stepwizard_prefecture_required"),
    }),
    area: Yup.object({
        id: Yup.string().required("stepwizard_area_required"),
    }),
})

export const editAreaSchema = Yup.object().shape({
    prefecture: Yup.object({
        id: Yup.string().required("stepwizard_prefecture_required"),
    }),
    area: Yup.object({
        id: Yup.string().required("stepwizard_area_required"),
    }),
})

export const stepAddress = Yup.object()

export const stepEssential = Yup.object().shape({
    transaction_type: Yup.string().required(),
    property_type: Yup.string().required(),
    reference_id: Yup.string().nullable(),
    price: Yup.object({
        amount: Yup.mixed().transform((_value, originalValue) => {
            if (_value === "") return undefined
            if (Number.isNaN(_value)) {
                return undefined
            }
            return Number(`${originalValue}`.replace(/,/g, ''))
        }).required("stepwizard_price_required"),
        currency: Yup.string().required()
    }),
    land: Yup.object()
        .when('property_type', {
            is: (value) => {
                return ['house', 'lot'].includes(value)
            },
            then: (schema) => schema.shape({
                sqm: Yup.mixed().transform((_value, originalValue) => {
                    if (_value === "") return undefined
                    if (Number.isNaN(_value)) {
                        return undefined
                    }
                    return Number(`${originalValue}`.replace(/,/g, ''))
                }).required("stepwizard_landsqm_required"),
            }),
            otherwise: (schema) => schema.shape({
                sqm: Yup.string().nullable(true),
            }),
        }),
    sqm: Yup.mixed().transform((_value, originalValue) => {
        if (_value === "") return undefined
        if (Number.isNaN(_value)) {
            return undefined
        }
        return Number(`${originalValue}`.replace(/,/g, ''))
    }).when('property_type', {
        is: (value) => {
            return ['house', 'apartment'].includes(value)
        },
        then: (schema) => schema.required("stepwizard_sqm_required"),
        otherwise: (schema) => schema.nullable(true),
    }),
    built: Yup.object().shape({
        year: Yup.string().when('built.month', {
            is: (month) => month !== "",
            then: (schema) => schema.nullable(true),
            otherwise: (schema) => schema.required("stepwizard_year_required"),
        }).nullable(),
        month: Yup.string().nullable(true)
    }),
    apartment: Yup.string().nullable(true),
    floor: Yup.string()
        .when(['property_type', 'apartment'], {
            is: (type, apartmentName) => {
                return type === "apartment" && apartmentName !== undefined && apartmentName !== ""
            },
            then: (schema) => schema.required("stepwizard_floor_required"),
            otherwise: (schema) => schema.nullable(true),
        }),
    room_number: Yup.string()
        .when('property_type', {
            is: (value) => value === "apartment",
            then: (schema) => schema.required("stepwizard_roomnumber_required"),
            otherwise: (schema) => schema.nullable(true),
        }),
})

export const stepPropertyDetail = Yup.object().shape({
    property_type: Yup.string(),
    layout: Yup.object().when('property_type', {
        is: (value) => value === "lot",
        then: (schema) => schema.shape({}),
        otherwise: (schema) => schema.shape({
            name: Yup.string().nullable(true),
            bedrooms: Yup.mixed().transform((_value, originalValue) => {
                if (_value === "") return undefined
                if (Number.isNaN(_value)) {
                    return undefined
                }
                return Number(`${originalValue}`.replace(/,/g, ''))
            }).required("stepwizard_bedrooms_required"),
            bathrooms: Yup.mixed().transform((_value, originalValue) => {
                if (_value === "") return undefined
                if (Number.isNaN(_value)) {
                    return undefined
                }
                return Number(`${originalValue}`.replace(/,/g, ''))
            }).required("stepwizard_bathrooms_required"),
        }),
    }),
    features: Yup.array().of(Yup.object().shape({
        key: Yup.string().required("stepwizard_feature_key_required"),
        value: Yup.string().transform((_value, originalValue) => {
            if (_value === null || _value === undefined || _value === "") return ""
            return String(_value)
        }).required("stepwizard_feature_value_required")
    })),
    other_costs: Yup.array().of(Yup.object().shape({
        title: Yup.string().required("stepwizard_others_cost_title_required"),
        price: Yup.object({
            amount: Yup.mixed().transform((_value, originalValue) => {
                if (_value === "") return undefined
                if (Number.isNaN(_value)) {
                    return undefined
                }
                return Number(`${originalValue}`.replace(/,/g, ''))
            }).required("stepwizard_others_cost_price_required"),
        }),
        type: Yup.string().required("stepwizard_others_cost_type_required"),
        description: Yup.string().nullable(),
    })),
    // apartment:
})
export const stepPropertyStory = Yup.object().shape({
    name: Yup.string().required("stepwizard_title_required"),
    // features:
    // other_costs:
    // apartment:
})

export const stepSummary = Yup.object().shape({
    price: Yup.object({
        amount: Yup.mixed().transform((_value, originalValue) => {
            if (_value === "") return undefined
            if (Number.isNaN(_value)) {
                return undefined
            }
            return Number(`${originalValue}`.replace(/,/g, ''))
        }).required("stepwizard_price_required"),
    }),
})

export const editStepSummary = Yup.object().shape({
    status: Yup.string(),
    price: Yup.object({
        amount: Yup.mixed().transform((_value, originalValue) => {
            if (_value === "") return undefined
            if (Number.isNaN(_value)) {
                return undefined
            }
            return Number(`${originalValue}`.replace(/,/g, ''))
        }).required("stepwizard_price_required"),
    }),
})

export const changeStatusSchema = Yup.object().shape({
    status: Yup.string().default(""),
    status_info: Yup.object().shape({
        remark: Yup.string().nullable(true),
    }).nullable(true)
})
export const changeSoldStatusSchema = Yup.object().shape({
    status: Yup.string().default(""),
    status_info: Yup.object().shape({
        sold_price: Yup.mixed().transform((_value, originalValue) => {
            if (_value === "") return undefined
            if (Number.isNaN(_value)) {
                return undefined
            }
            return Number(`${originalValue}`.replace(/,/g, ''))
        }).nullable(true),
        //.required("stepwizard_soldprice_required"),
        remark: Yup.string().nullable(true),
    }).nullable(true)
})

export const createNewPropertySchema = Yup.object().shape({
    transaction_type: Yup.string().required(),
    status: Yup.string().required(),
    property_type: Yup.string().required(),
    reference_id: Yup.string().nullable(),
    price: Yup.object({
        amount: Yup.mixed().transform((_value, originalValue) => {
            if (_value === "") return undefined
            if (Number.isNaN(_value)) {
                return undefined
            }
            return Number(`${originalValue}`.replace(/,/g, ''))
        }).required(),
        currency: Yup.string().required()
    }),
    land: Yup.object()
        .when('property_type', {
            is: (value) => {
                return ['house', 'lot'].includes(value)
            },
            then: (schema) => schema.shape({
                sqm: Yup.mixed().transform((_value, originalValue) => {
                    if (_value === "") return undefined
                    if (Number.isNaN(_value)) {
                        return undefined
                    }
                    return Number(`${originalValue}`.replace(/,/g, ''))
                }).required(),
            }),
            otherwise: (schema) => schema.shape({
                sqm: Yup.string().nullable(true),
            }),
        }),
    sqm: Yup.number().transform((_value, originalValue) => {
        if (_value === "") return undefined
        if (Number.isNaN(_value)) {
            return undefined
        }
        return Number(`${originalValue}`.replace(/,/g, ''))
    }).when('property_type', {
        is: (value) => {
            return ['house', 'apartment'].includes(value)
        },
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(true),
    }),
    built: Yup.object().shape({
        year: Yup.string().when('built.month', {
            is: (month) => month !== "",
            then: (schema) => schema.nullable(true),
            otherwise: (schema) => schema.required(),
        }).nullable(),
        month: Yup.string().nullable(true)
    })
})