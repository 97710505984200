import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Translate } from "../v2/translate/Translate"
export default function Modal(props) {
  const { isPresented, onClose, widthClass, zindex, hideBottomBar } = props

  return (

    <Transition
      show={isPresented}
      as={Fragment}>
      <Dialog as="div" className={`relative ${zindex ? `z-[${zindex}]`: "z-[100]"}`} onClose={() => { onClose && onClose() }}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300 "
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-100"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75"></div>
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease duration-300 transition transform"
          enterFrom="opacity-0 "
          enterTo="opacity-100 "
          leave="ease duration-200 transition transform"
          leaveFrom="opacity-100 "
          leaveTo="opacity-0"
        >

          <Dialog.Panel className={`fixed top-0 left-0 inset-0 w-full h-full outline-none flex items-center justify-center p-8`}>

            <div className={`inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl ${widthClass ? widthClass : "max-w-md w-full"}`} role="dialog" aria-modal="true" aria-labelledby="modal-headline">
              <div className="">


                {props.children}

              </div>
              {
                hideBottomBar === false ?
                  <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                    <button onClick={(e) => { onClose && onClose() }} type="button" className="btn-action">
                      {Translate("Dismiss")}
                    </button>
                  </div>
                  : null
              }


            </div>
          </Dialog.Panel>

        </Transition.Child>
      </Dialog>
    </Transition>
  );
}