import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition, Disclosure } from '@headlessui/react'
import Select from 'react-select'
import * as api from '../../services/agent'
import { Player } from '@lottiefiles/react-lottie-player';

import Typewriter from 'typewriter-effect';
import AIGeneratedContentSection from './AIGeneratedContentSection';


export default function AIGenerativeModal(props) {
    const { isOpen, setIsOpen, Translate, updateProperty, data, nearby } = props

    const [retryAgain, setRetryAgain] = useState(false)
    const [generating, setGenerating] = useState(false)
    const [brief, setBrief] = useState('')
    
    const [languages, setLanguages] = useState(['ja'])
    const [messages, setMessages] = useState([])
    const [selectedMessage, setSelectedMessage] = useState(null)
    const [showTypingForResult, setShowTypingForResult] = useState(false)
    const supportLanguages = [{ id: 'ja', title: 'Japanese' }, { id: 'en', title: 'English' }]

    function closeModal() {
        setIsOpen(false)
    }

    function handleRemove(data) {
        api.removeGenerativeDescription(data.id)
            .then(() => {
                setMessages(messages.filter(({ id }) => id !== data.id))
            })
            .catch((err) => {
                console.log("error", err)
            })
    }
    function formatProperty(p){
        if (p.sqm)
            p.sqm = parseFloat(p.sqm)
        else
            p.sqm = 0

        if (p.property_type == "lot") {
            p.layout = {}
        }

        if (p.land && p.land.sqm)
            p.land.sqm = parseFloat(p.land.sqm)
        else
            p.land = { sqm: 0 }

        if (p.latlng && p.latlng.latitude)
            p.latlng.latitude = parseFloat(p.latlng.latitude)

        if (p.latlng && p.latlng.longitude)
            p.latlng.longitude = parseFloat(p.latlng.longitude)

        if (p.built) {
            if (p.built.year === "") {
                delete p.built.year
            } else {
                p.built.year = +p.built.year
            }
            if (p.built.month === "") {
                delete p.built.month
            } else {
                p.built.month = +p.built.month
            }
        }

        if (p.price && Number.isFinite(p.price.amount))
            p.price.amount = `${p.price.amount}`

        if (p.status_info && p.status_info.sold_price)
            p.status_info.sold_price = `${p.status_info.sold_price}`

        if (p.other_costs) {
            p.other_costs = p.other_costs.map((c) => {
                c.price.amount = `${c.price.amount}`
                return c
            })
        }

        if (p.property_type == "apartments" && p.features) {
            p.features = [...(p.apartments || []), ...p.features]
        }
        return p
    }

    function handleGenerate() {
        setGenerating(true)

        let body = {
            brief: brief.trim(),
            languages: languages,
        }
        if (data.id) {
            body.property = {id:data.id}
        } else {
            body = {
                property: formatProperty(data), 
                ...body, 
                // walking:[],
                // driving:[],
                ...nearby,
            }
        }
        
        api.fetchGenerativeDescription(body).then((res) => {
            setGenerating(false)
            setShowTypingForResult(true)
            console.log("generated ", res)
            setBrief("")
            setMessages([res, ...messages])
        }).catch((err) => {
            console.log("generated ", err)
            // if (err.statusCode === 429) {
            setRetryAgain(true)
            // }
            setGenerating(false)
        })
    }

    function selectMessage(msg) {
        setSelectedMessage(msg)
    }

    function handleUpdateProperty(msg) {
        let lang = 'ja'
        if (msg.request?.languages.length > 0) {
            lang = msg.request?.languages[0]
        }
        setSelectedMessage(null)
        api.useGenerativeDescription(msg.id)
            .then(() => {
                updateProperty({ id: data.id, name: msg.response[lang].title, overview: msg.response[lang].description })
            })
    }

    useEffect(() => {
        if (isOpen && data.property_id) {
            api.fetchGenerativeDescriptions(data.property_id)
                .then((res) => {
                    console.log("fetchGenerativeDescriptions", res)
                    setMessages(res)
                })
        }
    }, [isOpen, data])

    const loadingMessages = [
        "ai_loading_geting_nearby",
        "ai_loading_looking_information",
        "ai_loading_checking_property_features",
        "ai_loading_write_title",
        "ai_loading_write_description",
        "ai_loading_finishing_up"
    ].map((txt) => Translate(txt))

    return (
        <Transition
            show={isOpen}
            as={Fragment}>
            <Dialog as="div" className="relative z-[50]" onClose={() => { }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300 "
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-100"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25"></div>
                </Transition.Child>

                <div className="fixed top-0 left-0 inset-0 w-full h-full outline-none">
                    <div className="flex items-center justify-end h-full">
                        <Transition.Child
                            as={Fragment}
                            enter="ease duration-300 transition transform"
                            enterFrom="opacity-0 translate-x-full"
                            enterTo="opacity-100 translate-x-0"
                            leave="ease duration-200 transition transform"
                            leaveFrom="opacity-100 translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <Dialog.Panel className="border-none shadow-lg relative flex flex-col lg:w-4/5 w-full h-full pointer-events-auto bg-white bg-clip-padding  outline-none text-current">
                                <Dialog.Title
                                    as="div"
                                    className="flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md"
                                >
                                    <h3 className='text-lg font-medium leading-6 text-gray-900'>
                                        {Translate("ai_generate_title")} · {data.property_id}
                                    </h3>
                                    <button type="button" id="close" onClick={closeModal}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </Dialog.Title>
                                <div className="relative flex-1  overflow-hidden">
                                    <div className="bg-white flex flex-row w-full h-full">

                                        {/* left col */}
                                        <div className="w-full h-full flex flex-col overflow-hidden border-r">

                                            <div className={`w-full overflow-y-auto ${messages.length > 0 ? "h-full" : ""} divide-y`}>

                                                {messages.map((msg, i) => {
                                                    let lang = 'ja'
                                                    if (msg.request?.languages.length > 0) {
                                                        lang = msg.request?.languages[0]
                                                    }
                                                    return (
                                                        <AIGeneratedContentSection key={msg.id} onTypingDone={()=>{setShowTypingForResult(false)}} typing={i == 0 && showTypingForResult ? true : false} generatedLang={lang} data={msg} onSelected={selectMessage} onDeleted={handleRemove}/>
                                                    )
                                                })}
                                            </div>

                                            <div className={`flex-none items-center w-full ${messages.length > 0 ? "mt-auto border-t" : ""}`}>
                                                <div className="p-4">
                                                    <p className='font-bold'>{Translate("Unique selling point")}</p>
                                                    <p className='text-xs mb-4'>{Translate("ups description")}</p>
                                                    <textarea className="rounded w-full border mt-2" rows="5" placeholder=''
                                                        onChange={(e) => setBrief(e.target.value)} value={brief} />
                                                </div>
                                                {/* <div className="px-4 flex">
                                                    <label className="block text-sm font-medium leading-6 text-gray-700">{Translate("Language")}</label>
                                                    <div className='ml-auto flex gap-2'>
                                                        <Select className="text-base border border-gray-600 rounded "
                                                            name="area"
                                                            id="area"
                                                            options={supportLanguages}
                                                            getOptionLabel={(l) => Translate(l.title)}
                                                            getOptionValue={(l) => l.id}
                                                            onChange={e => {
                                                                setLanguages([e.id])
                                                            }}
                                                            value={supportLanguages.filter(({ id }) => id === languages[0])}>
                                                        </Select>
                                                    </div>
                                                </div> */}
                                                <div className="flex p-4">
                                                    <button id="generate" type="button" className="btn btn-primary w-full flex items-center justify-center disabled:bg-slate-400" onClick={handleGenerate} disabled={generating || (brief.trim().length === 0)}>
                                                        {generating ? (<svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                        </svg>) : Translate("ai_generate_button")}
                                                    </button>
                                                </div>
                                            </div>

                                            {
                                                generating && (
                                                    <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-full z-[100] overflow-hidden bg-gray-700 bg-opacity-80 flex flex-col items-center justify-center">
                                                        <Player className='opacity-100'
                                                            autoplay
                                                            loop
                                                            src="https://assets3.lottiefiles.com/packages/lf20_QdH33DmN0r.json"
                                                            style={{ height: '100px', width: '100px' }}
                                                        >
                                                        </Player>
                                                        <h2 id="typing-loading-text" className="text-center text-white text-xl font-bold opacity-100">


                                                            <Typewriter
                                                                options={{
                                                                    strings: loadingMessages,
                                                                    autoStart: true,
                                                                    loop: true,
                                                                    delay: 50,
                                                                }}
                                                            />


                                                        </h2>
                                                        <p className="mt-4 text-center text-white text-md font-semibold opacity-100">{Translate("screen_loading_description")}</p>
                                                    </div>
                                                )}
                                        </div>
                                        {/* /left col */}
                                        {/* right col */}
                                        <div className="w-96 h-full  flex flex-col overflow-hidden">

                                            <div className="w-full overflow-y-auto h-full flex flex-col text-xs">

                                                <div className="flex flex-col">
                                                    <p className="p-2 bg-gray-100 block text-sm font-semibold text-gray-700">{Translate("Property Info")}</p>
                                                    <table className="divide-y divide-gray-200">
                                                        <tbody className="divide-y divide-gray-200">
                                                            <tr>
                                                                <td className="p-2 whitespace-no-wrap">
                                                                    {Translate("Property ID")}
                                                                </td>
                                                                <td className="p-2 text-left">
                                                                    {data.property_id}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="p-2 whitespace-no-wrap">
                                                                    {Translate("Property Type")}
                                                                </td>
                                                                <td className="p-2 text-left">
                                                                    {Translate(data.property_type)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="p-2 whitespace-no-wrap">
                                                                    {Translate("Transaction Type")}
                                                                </td>
                                                                <td className="p-2 text-left">
                                                                    {Translate(data.transaction_type)}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td className="p-2 whitespace-no-wrap">
                                                                    {Translate("Price")}
                                                                </td>
                                                                <td className="p-2 text-left">
                                                                    {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: data.price.currency }).format(Number(data.price.amount))}
                                                                </td>
                                                            </tr>
                                                            {data.property_type === "lot" ? null: (<tr>
                                                                <td className="p-2 whitespace-no-wrap">
                                                                    {Translate("Layout")}
                                                                </td>
                                                                <td className="p-2 text-left">
                                                                    {data.layout.name}
                                                                </td>
                                                            </tr>)}
                                                            {data.property_type === "house" || data.property_type === "lot" ? (<tr>
                                                                <td className="p-2 whitespace-no-wrap">
                                                                    {Translate("Lot size in m²")}
                                                                </td>
                                                                <td className="p-2 text-left">
                                                                    {(data.land.sqm || 0).toLocaleString()}m² ({Number(Math.round((data.land.sqm || 0) / 3.306).toFixed(2)).toLocaleString()}{Translate("Tsubo")})
                                                                </td>
                                                            </tr>):null}
                                                            {data.property_type === "house" || data.property_type === "apartment" ? (<tr>
                                                                <td className="p-2 whitespace-no-wrap">
                                                                    {Translate("Floor area in m²")}
                                                                </td>
                                                                <td className="p-2 text-left">
                                                                    {(data.sqm || 0).toLocaleString()}m² ({Number(Math.round((data.sqm || 0) / 3.306).toFixed(2)).toLocaleString()}{Translate("Tsubo")})
                                                                </td>
                                                            </tr>):null}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div className="flex flex-col">
                                                    <p className="p-2 bg-gray-100 block text-sm font-semibold leading-6 text-gray-700">{Translate("Features")}</p>

                                                    <table className="divide-y divide-gray-200">
                                                        <tbody className="divide-y divide-gray-200">
                                                            {data.features.map((f, i) => {
                                                                return (<tr key={i}>
                                                                    <td className="p-2 whitespace-no-wrap">{Translate(f.title)}</td>
                                                                    <td className="p-2 text-left">{(Array.isArray(f.value) ? f.value : f.value.split(",")).map((txt, i) => (<p key={i}>{Translate(txt)}</p>))}</td>
                                                                </tr>)
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/* <div className="">
                                                            <p className="p-2 bg-gray-100 text-sm font-medium leading-6 text-gray-700">{Translate("Nearby Places")}</p>
                                                            <div className='p-2'>
                                                                <p className="font-medium mb-2 text-gray-700">{Translate("Walking")}</p>
                                                                <div className='flex flex-col gap-1'>
                                                                {Object.keys(nearby['walking']).map((cate) => {
                                                                    return nearby['walking'][cate].map((place, i) => (
                                                                    <div className='flex flex-col' key={`cate-${i}`}>
                                                                        <p className='font-medium'>{place.name}</p> <p className='text-xs text-gray-500'>{place.distance_matrix.distance.text} ({place.distance_matrix.duration.text})</p></div>
                                                                    ))
                                                                })}
                                                                </div>
                                                            </div>

                                                            <div className='p-2'>
                                                                <p className="font-medium mb-2 text-gray-700">{Translate("Driving")}</p>
                                                                <div className='flex flex-col gap-1'>
                                                                {Object.keys(nearby['driving']).map((cate) => {
                                                                    return nearby['driving'][cate].map((place, i) => (
                                                                    <div className='flex flex-col' key={`cate-${i}`}>
                                                                        <p className='font-medium'>{place.name}</p> <p className='text-xs text-gray-500'>{place.distance_matrix.distance.text} ({place.distance_matrix.duration.text})</p></div>
                                                                    ))
                                                                })}
                                                                </div>
                                                            </div>
                                                        </div> */}
                                            </div>
                                        </div>
                                        {/* /right col */}


                                    </div>

                                </div>


                                {selectedMessage && (
                                    <div className="fixed z-10 inset-0 p-4 w-full h-full">

                                        <div className="flex items-center justify-center h-full max-w-4xl mx-auto">

                                            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                            </div>

                                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                                                <div className="bg-white p-4 ">
                                                    <div className="sm:flex sm:items-start">
                                                        <div className="">
                                                            <h3 className="text-lg font-bold" id="modal-headline">
                                                                {Translate("using_this_description_confirm_title")}
                                                            </h3>
                                                            <div className="mt-2">
                                                                <p className='font-semibold mb-2'>
                                                                    {Translate("Name")}:
                                                                </p>
                                                                <div className='flex items-center justify-center gap-4'>
                                                                    {data.id ? (<>
                                                                        <div className='flex-1 p-4 border bg-white line-through'>{data.name}</div>
                                                                        <div className='flex'>➔</div>
                                                                        <div className='flex-1 p-4 border bg-white '>{selectedMessage.response[selectedMessage.request?.languages[0]].title}</div>
                                                                    </>):(<div className='flex-1 p-4 border bg-white '>{selectedMessage.response[selectedMessage.request?.languages[0]].title}</div>)}
                                                                    
                                                                </div>
                                                                <p className='font-semibold mb-2 mt-4'>
                                                                    {Translate("Overview")}:
                                                                </p>
                                                                <div className='flex items-center justify-center gap-4'>
                                                                    {data.id ? (<>
                                                                        <p className='flex-1 p-4 border bg-white line-through h-80 overflow-y-auto whitespace-pre-wrap'>{data.overview}</p>
                                                                        <div className='flex'>➔</div>
                                                                        <p className='flex-1 p-4 border bg-white  h-80 overflow-y-auto whitespace-pre-wrap'>{selectedMessage.response[selectedMessage.request?.languages[0]].description}</p>
                                                                    </>): (<p className='flex-1 p-4 border bg-white  h-80 overflow-y-auto whitespace-pre-wrap'>{selectedMessage.response[selectedMessage.request?.languages[0]].description}</p>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">

                                                    <button onClick={() => {
                                                        handleUpdateProperty(selectedMessage)
                                                    }} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                                                        {Translate("Confirm")}
                                                    </button>
                                                    <button onClick={() => {
                                                        setSelectedMessage(null)
                                                    }} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                                                        {Translate("Cancel")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)}
                                {retryAgain && (
                                    <div className="fixed z-10 inset-0 p-4 w-full h-full">

                                        <div className="flex items-center justify-center h-full max-w-4xl mx-auto">

                                            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                                                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                                            </div>

                                            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                                            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
                                                <div className="bg-white p-4 ">
                                                    <div className="sm:flex sm:items-start">
                                                        <div className="">
                                                            <h3 className="text-lg font-bold" id="modal-headline">
                                                            {Translate("ai_retry_title")}
                                                            </h3>
                                                            <div className="mt-2">
                                                            {Translate("ai_retry_description")}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="px-4 py-3 sm:px-6 sm:flex justify-center">
                                                    <button onClick={() => {
                                                        setRetryAgain(false)
                                                    }} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                                                        {Translate("close_button")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>)}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}
