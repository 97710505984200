import * as Yup from "yup";

export const submitProposal = Yup.object().shape({
    min_price: Yup.object({
        amount: Yup.mixed().transform((_value, originalValue) => {
            if (_value === "") return undefined
            if (Number.isNaN(_value)) {
                return undefined
            }
            return Number(`${originalValue}`.replace(/,/g, ''))
        }).required("submitproposal_price_required"),
    }),
    max_price: Yup.object({
        amount: Yup.mixed().transform((_value, originalValue) => {
            if (_value === "") return undefined
            if (Number.isNaN(_value)) {
                return undefined
            }
            return Number(`${originalValue}`.replace(/,/g, ''))
        }).required("submitproposal_price_required"),
    }),
    remarks: Yup.string().required("submitproposal_remark_required"),
    commission: Yup.mixed().transform((_value, originalValue) => {
        if (_value === "") return undefined
        if (Number.isNaN(_value)) {
            return undefined
        }
        return Number(`${originalValue}`.replace(/,/g, ''))
    }).required("submitproposal_commission_required"),
})