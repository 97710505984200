import React, {useState, useEffect, useCallback} from "react"
import SideNavBar from "../SideNavBar";
import TopSearchbar from "../TopSearchbar";
import { Translate } from "../../translate/Translate";



export default function Maps(props) {
    return (
    <div className="w-full flex bg-slate-50">
        <SideNavBar {...props} isPresented={true} />

        <div className="flex flex-col w-full h-full overflow-hidden">


            <TopSearchbar {...props} />

            <div className="flex-none flex items-center w-full py-3 px-6 border-b">
                <p className="text-gray-700 text-2xl font-semibold">{Translate("Maps")}</p>
                <div className="ml-auto flex item-center gap-4"></div>
            </div>

            <div className="flex flex-col relative w-full h-full mx-auto overflow-hidden">
                
                <div className="w-full">map area</div>

                <div className="bg-gray-100 mt-auto w-full h-40 border-t flex items-center overflow-hidden">
                    <div className="p-3 flex-none">
                        <div className="w-full h-full">
                            <p className="font-bold text-lg">40 properties</p>
                        </div>
                    </div>
                    <div className="p-3 h-full w-full grid grid-rows-2 grid-flow-col gap-2 overflow-x-scroll ">
                        {
                           [...Array(50)].map((elementInArray, index) => ( 
                                <div className="w-80 p-2 bg-white rounded-lg shadow-lg border">
                                    AN001-PN002
                                </div>
                           ))
                        }
                    </div>
                </div>
            </div>

        </div>
    </div>
    )
}