
import { ArrowUpIcon} from "@heroicons/react/24/solid";
import { Translate } from "../../translate/Translate";
export default function BackToTopButton(props) {

    return (
        <button onClick={(e) => {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }} className="text-primary border-b border-dashed border-primary flex items-center ml-auto text-sm">
            <ArrowUpIcon className="w-4 h-4 mr-1" /><span>{Translate("button_go_to_top")}</span>
        </button>
    )
}