
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import DateRange from "./DateRange"
import PercentChange from "./PercentChange"

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


const barChartOptions = {
    responsive: true,
    interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false
      },
    scales: {
        y: {
            display: false,
            beginAtZero: true,
            grid: {
                drawBorder: false,
                display:false,
            }
        },
        x: {
            display:true,
            grid: {
                drawBorder: true,
                display:false,
                borderColor:'rgba(20, 90, 255, 0.2)',
            },
            ticks: {
                // Include a dollar sign in the ticks
                callback: function(value, index, ticks) {
                    return  this.getLabelForValue(value);
                },
                color:"#94a3b8",
                font:{
                    weight:500,
                    size:10,
                }
            }
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            enabled: true,
            displayColors: false,
            position: 'average',
            bodyFont: { weight: "bold", size: 16 },
            callbacks: {
                label: function (context) {
                    let label = context.dataset.label || '';

                    if (context.parsed.y !== null) {
                        label = new Intl.NumberFormat('en-US').format(context.parsed.y);
                    }
                    return label;
                }
            }
        }
    },
}

export default function StatsCardWithRange({
    onChange, title, value, diff, children, since,
    dateRange, datasets = [], labels = [], Translate, language,
}) {

    const barChartData = {
        labels: labels.map((l) => l.toLocaleString(language === "en" ? "en-US" : "ja-JP", { day: "numeric", month: "short" })),
        datasets: datasets,
    }

    return (
        <div className="border rounded-lg p-4 bg-white">
            <div className="flex items-center mb-4">
                <div className="flex items-center gap-2 text-sm font-semibold text-slate-500">
                    {children}
                    <p>{title}</p>
                </div>
                <div className="ml-auto">
                    <DateRange since={since} dateRange={dateRange} onChange={onChange} Translate={Translate} />
                </div>
            </div>
            <div className="flex items-end gap-2">
                <div className="w-1/4 flex-none flex flex-col">
                    <div className={`flex items-center gap-2`}>
                        <div>
                            <p className="text-2xl leading-tight font-bold">
                                {new Intl.NumberFormat('en-US').format(Number(value ? value : 0))}
                            </p>
                            <PercentChange value={diff} />
                        </div>
                    </div>
                </div>
                <div className="w-3/4">
                    <Bar options={barChartOptions} data={barChartData} />
                </div>
            </div>
        </div>)
}




