import React, { useState } from "react"
import Message from "./Message"
import { useDropzone } from 'react-dropzone'
import { LockClosedIcon, PaperClipIcon } from "@heroicons/react/24/solid"
import { TrashIcon } from "@heroicons/react/24/outline"

export default function MessageBox(props) {
    const { Translate, messages, onSubmit, disabled } = props
    const [message, setMessage] = useState("")
    const [files, setFiles] = useState([])
    const [filesName, setFilesName] = useState([])
    const handleSubmit = () => {
        var fileList = []
        for (var i = 0; i < files.length; i++) {
            fileList.push({ base64: files[i], name: filesName[i].substr(0, filesName[i].lastIndexOf(".")) })
        }
        onSubmit({ message: message, files: fileList })
            .then(() => {
                setMessage("")
                setFiles([])
                setFilesName([])
            })
    }

    const onDrop = React.useCallback((acceptedFiles, fileRejections) => {
        acceptedFiles.forEach((file) => {
            var reader = new FileReader();
            reader.onload = function (event) {
                var imageData = event.target.result;
                var base64Data = imageData.split("base64,")[1];
                var fileName = file.name.replace(/\.[^/.]+$/, "")

                var attachments = [...files]
                var list = attachments ? [...attachments] : [];
                list.push(base64Data)
                setFiles(list)

                var listFilesName = [...filesName]
                var listName = listFilesName ? [...listFilesName] : [];
                listName.push(file.name)
                setFilesName(listName)

            }
            reader.readAsDataURL(file);
        });

        fileRejections.forEach((file) => {
            file.errors.forEach((err) => {
                if (err.code === "file-too-large") {
                    alert("file size is exceed limit (10MB)")
                }
            })
        })
    }, [files])

    const dropMessageAttachments = useDropzone({
        onDrop: onDrop,
        accept: 'image/jpeg, image/png, application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        multiple: false,
        maxSize: 10 * 1024 * 1024, // 10MB
    });

    const removeFileAt = (index) => {
        var attachments = [...files]
        var list = attachments ? [...attachments] : [];
        list.splice(index, 1)
        setFiles(list)

        var listFilesName = [...filesName]
        var listName = listFilesName ? [...listFilesName] : [];
        listName.splice(index, 1)
        setFilesName(listName)
    }


    return (
        <div className="flex flex-col">

            <div className="p-5 border-b w-full">
                <div>
                    <p className="font-medium text-lg">{Translate("Messages")}</p>
                    <p className="text-xs text-gray-500">{Translate("message_box_your_conversation")}</p>
                </div>
            </div>
            {
                messages.length > 0 ?
                <div className="p-5 flex flex-col gap-8">
                {messages.map((a, key) => (<Message data={a} key={key} />))}
                </div>
            :
            null
            }
            
            <div className="bg-gray-100 p-5 flex flex-col">
                <div className="flex flex-col mb-3">
                    <p className="font-medium">{Translate("private_message_title")}</p>
                    <p className="text-sm">{Translate("private_message_subtitle")}</p>
                </div>
                <div className="flex items-start gap-4">
                    {/* <div>
                        <div className="rounded-full w-10 h-10 bg-gray-300 overflow-hidden">
                            <img src={props.account.user.image_url} alt="agent-profile" />
                        </div>
                    </div> */}
                    <div className=" w-full">
                        <textarea id="message" className="w-full rounded border border-gray-300 shadow-sm disabled:bg-gray-200" rows="3" value={message} onChange={(e) => {
                            setMessage(e.target.value)
                        }} disabled={disabled}></textarea>
                        <div className="flex items-start mt-1">
                            {disabled ? null : 
                            <div className="flex items-center" {...dropMessageAttachments.getRootProps()}>
                                <input className="w-full h-full" {...dropMessageAttachments.getInputProps()} />
                                <button className="text-gray-500 font-meidum flex items-center mr-4">
                                    <PaperClipIcon className="w-5 h-5 mr-1"/>
                                    <span className="text-sm italic">{Translate("Attach a file")}</span>
                                </button>
                            </div> }
                            <div className="flex gap-5">
                            {filesName.map((file, index) => (
                                <div className="flex items-center gap-1 text-gray-500" key={`att-${index}`}>
                                   <PaperClipIcon className="w-4 h-4"/>
                                    <span className="text-sm">{file}</span>
                                    <button type="button" id={`remove-file-${index}`} onClick={(e) => removeFileAt(index)} className="focus:outline-none text-xs font-medium text-red-500">
                                        <TrashIcon className="w-4 h-4"/>
                                    </button>
                                </div>

                            ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-2 flex items-center">
                    
                    <div className="flex items-center text-sm">
                        <LockClosedIcon className="text-green-500 w-5 h-5 mr-1"/><span className="text-green-500">{Translate("message_is_private_title")}</span>
                    </div>
                    <div className="ml-auto">
                    <button className="bg-primary text-white font-medium px-3 py-2 rounded disabled:bg-gray-300" onClick={handleSubmit} disabled={disabled || (files.length == 0 && message.length == 0)}>{Translate("message_box_send")}</button>
                    </div>
                </div>
            </div>
        </div>)
}