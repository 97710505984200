
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import React, { useContext, useState,useEffect } from "react"

import * as api from './services/agent'
import * as Account from "../account";
import moment from "moment";
import "moment/locale/ja"
import { TranslateWithLang } from "./translate/Translate";
import AppContext from "../AppContext";
import DocumentTitle from "./views/Components/DocumentTitle";
import v2Routes from "./views/routes"
import { createBrowserHistory } from 'history'

function App() {
  const history = createBrowserHistory();
  const query = new URLSearchParams(window.location.search);
  const [properties, setProperties] = useState({ items: [] });

  const [pinnedProperties, setPinnedProperties] = useState({ items: [] });

  const [keywords, setKeywords] = useState("");
  const [filters, setFilters] = useState(JSON.parse(query.get("query") ?? '{"list_status":["active"]}'));

  const [featureList, setFeatureList] = useState([]);
  const [defaultFeatures, setDefaultFeatures] = useState({ apartment: [], house: [], lot: [] });
  const [areas, setAreas] = useState([]);
  const [prefectures, setPrefectures] = useState([]);
  const [customers, setCustomers] = useState([]);

  const [statistics, setStatistics] = useState({ number_of_new_messages: 0, number_of_properties: 0, total_views: 0, number_of_offers: 0, number_of_properties_by_status: [], must_be_updated_properties:0 });


  const { account, language } = useContext(AppContext)

  moment.locale(language);

  function loadProperties(query) {
    api.fetchProperties(query)
      .then(data => {
        setProperties(data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  function loadPinnedProperties() {
    let query = JSON.parse('{"is_pinned":true}')
    api.fetchProperties(query)
      .then(data => {
        setPinnedProperties(data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  function loadFeatures(lang) {
    api.features(lang)
      .then(response => {
        setFeatureList(response.data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  function loadDefaultFeatures() {
    api.defaultFeatures()
      .then(response => {
        setDefaultFeatures(response.data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  function loadPrefecture(lang) {
    api.prefectures(lang)
      .then(response => {
        setPrefectures(response.data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  function loadAreas(lang) {
    api.areas(lang)
      .then(response => {
        for (let i = 0; i < response.data.length; i++) {
          response.data[i].name = TranslateWithLang(lang, response.data[i].name)
        }
        setAreas(response.data);
      })
      .catch(error => {
        console.log(error);
      })
  }

  function loadStatistics() {
    api.statistics()
      .then(response => {
        setStatistics(response);
      })
      .catch(error => {
        console.log(error);
      })
  }

  function loadCustomers() {
    api.customers()
      .then(data => {
        setCustomers(data);
      })
      .catch(error => {
        console.log(error);
      })
  }


  useEffect(() => {
    if (account === null) {
      return;
    }
    loadAreas(language)
    loadFeatures(language)
    loadPrefecture(language)
  }, [language]);

  useEffect(() => {
    if (account === null) {
      return;
    }

    if (window.gtag) {
      window.gtag('set', {
        'user_id': account.user.id
      });
    }
    loadStatistics()
    loadAreas(language)
    loadFeatures(language)
    loadDefaultFeatures()
    // loadThreds()
    loadPinnedProperties()
    loadAgentProfile()
    loadCustomers()
  }, [account]);

  useEffect(() => {
    if (account === null) {
      return;
    }
    loadProperties(filters);
  }, [filters, account]);


  const handleFilterChange = (e) => {
    const { name, value } = e.target
    setFilters({ ...filters, [name]: value })
  }

  const handlePropertyRequestChange = (key, value) => {
    setFilters({ ...filters, [key]: value })
  }

  const handleFilterKeyword = (e) => {
    setKeywords(e.target.value.trim())
  }

  const search = (e) => {
    setFilters({ ...filters, keywords: keywords, page: 1 })
  }

  const [agentProfile, setAgentProfile] = useState({
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    image_url: "",
    company: {
      name: "",
      url: "",
      image_url: "",
    },
    agent: {
      info: "",
      license: "",
      service_languages: [],
      agent_data: {
        place_id: "",
      }
    },
  });

  function loadAgentProfile() {
    api.profile()
      .then(response => {
        setAgentProfile(response);
      })
      .catch(error => {
        console.log(error)
      })
  }

  if (account === null) {
    return (
      <Router>
        <Switch>
          <Redirect to="/" />
        </Switch>
      </Router>
    )
  }



  return (<>
    <Router>
      <Switch>
        <Redirect from="/v2/login" to="/v2" />
        {v2Routes.map(({ path, Component, title }) =>
          <Route
            key={path}
            path={path}
            render={(props) => (<>
              {title && <DocumentTitle title={title} />}
              <Component {...props}
                statistics={statistics}
                query={query}
                history={history}
                areas={areas}
                account={account}
                filters={filters}
                keywords={keywords}
                pinnedProperties={pinnedProperties}
                properties={properties}
                featureList={featureList}
                language={language}
                customers={customers}
                prefectures={prefectures}
                loadCustomers={loadCustomers}
                onPropertyRequestChange={handlePropertyRequestChange}
                reloadPinnedProperties={loadPinnedProperties}
                handleFilterChange={handleFilterChange}
                handleFilterKeyword={handleFilterKeyword}
                setFilters={setFilters}
                search={search}
                defaultFeatures={defaultFeatures}
                Translate={(key) => TranslateWithLang(language, key)}
              />
            </>)} />
        )}
      </Switch>
    </Router>
  </>);
}

export default App;
