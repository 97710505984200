import clsx from "clsx";
import React from "react"
import { Translate } from "../../../translate/Translate";
import InputField from "../../Components/StepWizard/InputField";
import TextAreaField from "../../Components/StepWizard/TextAreaField";
import { SparklesIcon } from "@heroicons/react/24/solid"

export default function OverviewForm(props) {
    const { useFormReturn, className, setAIDialogIsOpen } = props
    const { control, formState: { errors }, clearErrors } = useFormReturn
    return (
        <div className={clsx("grid grid-cols-1 text-sm rounded-t-md bg-white", className)}>
            <div className="py-6  px-6">
                <div className="flex items-center">
                    <div>
                        <p className="text-xl font-medium">{Translate("Property story")}</p>
                        <p className="text-sm text-gray-700">{Translate("Details of this property. e.g. Architecture, Zoning, Terrain, etc.")}</p>
                    </div>
                    <div className="ml-auto flex items-center gap-2">
                    {setAIDialogIsOpen && (<button type="button" id="ai-generate"className="flex items-center gap-2 p-2 font-medium h-8 text-sm text-white transition-colors duration-150 bg-green-600 rounded-lg cursor-pointer focus:shadow-outline hover:bg-green-800" onClick={() => {
                        setAIDialogIsOpen(true)
                    }}>
                        <SparklesIcon className="w-5 h-5" />
                        {Translate("ai_write_button")}
                    </button>)}
                    </div>
                </div>
            </div>

            <div className="col-span-1 py-4 lg:py-4 px-6">
                <div className="col-span-1 py-4 lg:py-4 px-6">
                    <div className="grid grid-cols-3 mt-4">
                        <div className="col-span-3 lg:col-span-1">
                            <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Property title")}</p>
                        </div>
                        <div className="col-span-3 lg:col-span-2">
                            <div className="grid grid-cols-3 items-center">
                                <div className="col-span-3 lg:col-span-3">
                                    <InputField control={control}
                                        errors={errors} clearErrors={clearErrors}
                                        required={true}
                                        name="name"
                                        id="name"
                                        placeholder={Translate("Tsurudamari Residence")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-span-1 py-4 lg:py-4 px-6">
                    <div className="grid grid-cols-3">
                        <div className="col-span-3 lg:col-span-1">
                            <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Overview")}</p>
                        </div>
                        <div className="col-span-3 lg:col-span-2">
                            <TextAreaField
                                control={control} errors={errors} clearErrors={clearErrors}
                                required rows="6"
                                id="overview" name="overview"
                                placeholder={Translate("Describe this property")}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-span-1 py-4 lg:py-4 px-6">
                    <div className="grid grid-cols-3">
                        <div className="col-span-3 lg:col-span-1">
                            <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Remarks")}</p>
                        </div>
                        <div className="col-span-3 lg:col-span-2">
                            <TextAreaField
                                control={control} errors={errors} clearErrors={clearErrors}
                                required rows="2"
                                id="remarks" name="remarks"
                                placeholder={Translate("Remarks")}
                            />
                        </div>
                    </div>
                </div>

                <div className="col-span-1 py-4 lg:py-4 px-6">
                    <div className="grid grid-cols-3">
                        <div className="col-span-3 lg:col-span-1">
                            <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Regulations")}</p>
                        </div>
                        <div className="col-span-3 lg:col-span-2">
                            <TextAreaField
                                control={control} errors={errors} clearErrors={clearErrors}
                                required rows="2"
                                id="regulations" name="regulations"
                                placeholder={Translate("Restrictions or regulations")}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>)
}