import React from "react"
import { Translate } from "../../translate/Translate";
import { TimelineStepDone } from "./TimelineStepDone";
import { TimelineStepInprogress } from "./TimelineStepInprogress";
import { TimelineStepNotYet } from "./TimelineStepNotYet";
import { TimelineStepDeclined } from "./TimelineStepDeclined";
export function SellRequestState(props) {



    return <div className="flex items-center justify-evenly text-xs lg:text-sm font-medium -ml-4 -mr-4 lg:-ml-16 lg:-mr-16">
        {props.state === "seller_declined_proposal" ? <>
            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepDone is_first={true}></TimelineStepDone>
                <div>
                    <p className="text-primary">{Translate("sell_request_state_reviewed")}</p>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepDone is_first={false} last_color={"bg-red-400"}></TimelineStepDone>
                <div>
                    <p className="text-primary">{Translate("sell_request_state_submitted")}</p>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepDeclined is_first={false} is_last={true}></TimelineStepDeclined>
                <div>
                    <p className="text-red-400">{Translate("sell_request_state_seller_declined")}</p>
                </div>
            </div>



        </> : null
        }
        {props.state == "waiting_for_review" ? <>
            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepInprogress is_first={true}></TimelineStepInprogress>
                <div>
                    <p className="text-primary">{Translate("sell_request_state_waiting_for_review")}</p>
                </div>
            </div>
            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepNotYet is_first={false}></TimelineStepNotYet>
                <div>
                    <p className="text-gray-400">{Translate("sell_request_state_waiting_for_submit")}</p>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-2 w-full">

                <TimelineStepNotYet is_first={false} is_last={true}></TimelineStepNotYet>
                <div>
                    <p className="text-gray-400">{Translate("sell_request_state_seller_review")}</p>
                </div>
            </div>



        </> : null
        }
        {props.state == "expired_for_submit" ? <>
            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepDone is_first={true}></TimelineStepDone>
                <div>
                    <p className="text-primary">{Translate("sell_request_state_reviewed")}</p>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepDeclined is_first={false} is_last={true}></TimelineStepDeclined>
                <div>
                    <p className="text-gray-400">{Translate("sell_request_state_expired")}</p>
                </div>
            </div>




        </> : null
        }
        {props.state == "waiting_for_submit" ? <>
            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepDone is_first={true}></TimelineStepDone>
                <div>
                    <p className="text-primary">{Translate("sell_request_state_reviewed")}</p>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepInprogress is_first={false}></TimelineStepInprogress>
                <div>
                    <p className="text-gray-400">{Translate("sell_request_state_waiting_for_submit")}</p>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-2 w-full">

                <TimelineStepNotYet is_first={false} is_last={true}></TimelineStepNotYet>
                <div>
                    <p className="text-gray-400">{Translate("sell_request_state_seller_review")}</p>
                </div>
            </div>



        </> : null
        }
        {props.state == "waiting_for_review_proposal" ? <>
            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepDone is_first={true}></TimelineStepDone>
                <div>
                    <p className="text-primary">{Translate("sell_request_state_reviewed")}</p>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepDone is_first={false}></TimelineStepDone>
                <div>
                    <p className="text-gray-400">{Translate("sell_request_state_submitted")}</p>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-2 w-full">

                <TimelineStepInprogress is_first={false} is_last={true}></TimelineStepInprogress>
                <div>
                    <p className="text-gray-400">{Translate("sell_request_state_waiting_for_seller_review")}</p>
                </div>
            </div>



        </> : null
        }

        {props.state == "waiting_for_seller_decision" ? <>
            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepDone is_first={true}></TimelineStepDone>
                <div>
                    <p className="text-primary">{Translate("sell_request_state_reviewed")}</p>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepDone is_first={false}></TimelineStepDone>
                <div>
                    <p className="text-gray-400">{Translate("sell_request_state_submitted")}</p>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-2 w-full">

                <TimelineStepInprogress is_first={false} is_last={true}></TimelineStepInprogress>
                <div>
                    <p className="text-gray-400">{Translate("sell_request_state_waiting_for_seller_decistion")}</p>
                </div>
            </div>



        </> : null
        }

        {props.state == "seller_accepted" ? <>
            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepDone is_first={true}></TimelineStepDone>
                <div>
                    <p className="text-primary">{Translate("sell_request_state_reviewed")}</p>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepDone is_first={false}></TimelineStepDone>
                <div>
                    <p className="text-gray-400">{Translate("sell_request_state_submitted")}</p>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepDone is_first={false} is_last={true}></TimelineStepDone>
                <div>
                    <p className="text-gray-400">{Translate("sell_request_state_accepted")}</p>
                </div>
            </div>
        </> : null
        }
        {props.state == "seller_accepted_another" ? <>
            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepDone is_first={true}></TimelineStepDone>
                <div>
                    <p className="text-primary">{Translate("sell_request_state_reviewed")}</p>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepDone is_first={false} last_color={"bg-red-400"}></TimelineStepDone>
                <div>
                    <p className="text-gray-400">{Translate("sell_request_state_submitted")}</p>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepDeclined is_first={false} is_last={true}></TimelineStepDeclined>
                <div>
                    <p className="text-red-400">{Translate("sell_request_state_accepted_another")}</p>
                </div>
            </div>
        </> : null
        }

        {props.state == "listed" ? <>
            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepDone is_first={true}></TimelineStepDone>
                <div>
                    <p className="text-primary">{Translate("sell_request_state_reviewed")}</p>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepDone is_first={false}></TimelineStepDone>
                <div>
                    <p className="text-gray-400">{Translate("sell_request_state_submitted")}</p>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepDone is_first={false}></TimelineStepDone>
                <div>
                    <p className="text-gray-400">{Translate("sell_request_state_accepted")}</p>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepInprogress is_first={false} is_last={true}></TimelineStepInprogress>
                <div>
                    <p className="text-gray-400">{Translate("sell_request_state_selling")}</p>
                </div>
            </div>
        </> : null
        }

        {props.state == "sold" ? <>
            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepDone is_first={true}></TimelineStepDone>
                <div>
                    <p className="text-primary">{Translate("sell_request_state_reviewed")}</p>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepDone is_first={false}></TimelineStepDone>
                <div>
                    <p className="text-gray-400">{Translate("sell_request_state_submitted")}</p>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepDone is_first={false}></TimelineStepDone>
                <div>
                    <p className="text-gray-400">{Translate("sell_request_state_accepted")}</p>
                </div>
            </div>

            <div className="flex flex-col items-center justify-center gap-2 w-full">
                <TimelineStepDone is_first={false} is_last={true}></TimelineStepDone>
                <div>
                    <p className="text-gray-400">{Translate("sell_request_state_sold")}</p>
                </div>
            </div>
        </> : null
        }
    </div>
}