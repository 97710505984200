import FeatureField from "./FeatureField"
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'

export default function FeatureList(props) {
    const {featureFields, featureList, useFormReturn, removePropertyFeature, replace} = props
    const {setValue, watch, getValues} = useFormReturn
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
          coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    
    function handleDragEnd(event) {
        const {active, over} = event;
        if (active.id !== over.id) {
            const features = getValues("features")
            const oldIndex = featureFields.findIndex((i) => i.id == active.id);
            const newIndex = featureFields.findIndex((i) => i.id == over.id);
            replace(arrayMove(features, oldIndex, newIndex))
        }
    }

    return (<DndContext 
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}>
        <SortableContext 
          items={featureFields}
          strategy={verticalListSortingStrategy}
        >
            {featureFields.map((field, index) => <FeatureField  {...props} field={watch(`features.${index}`)} key={field.id} id={field.id} index={index} featureList={featureList} useFormReturn={useFormReturn} removePropertyFeature={removePropertyFeature} getValues={getValues} />)}
        </SortableContext>
    </DndContext>)
}