import {_request} from '../http'

export async function signIn(auth) {
    return _request("POST", `/auth/signin`, auth)
}

export async function googleSignIn(auth) {
    return _request("POST", `/auth/signin/google`, auth)
}

export async function signUp(auth) {
    return _request("POST", `/auth/signup`, auth)
}

export async function resetPassword(email) {
    let data = {
        email : email
    }
    return _request("POST", `/auth/resetpassword`, data)
}

export async function newPassword(code, newPassword) {
    let data = {
        password : newPassword,
        code: code
    }
    return _request("POST", `/auth/newpassword`, data)
}



export async function acceptInvitation(data) {
    return _request("POST", `/auth/acceptinvitation`, data)
}

export async function signInByInvite(code) {
    return _request("POST", `/auth/invite/${code}`, null)
}