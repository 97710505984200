
import React from "react"
import { Translate } from "../../translate/Translate";

export function PropertyStatusBadge(props) {

    function renderBadge(status) {
        switch (status) {
            case 'inactive':
                return <span id="status-badge-inactive" className="px-2 inline-flex text-xs leading-5 font-medium rounded-full bg-gray-100 text-gray-600 uppercase">{Translate("Inactive")}</span>;
            case 'active':
                return <span id="status-badge-active" className="px-2 inline-flex text-xs leading-5 font-medium rounded-full bg-green-100 text-green-600 uppercase">{Translate("Active")}</span>;
            case 'business_negotiation':
                return <span id="status-badge-business_negotiation" className="px-2 inline-flex text-xs leading-5 font-medium rounded-full bg-yellow-100 text-yellow-500 uppercase">{Translate("Negotiation")}</span>;
            case 'sold':
                return <span id="status-badge-sold" className="px-2 inline-flex text-xs leading-5 font-medium rounded-full bg-red-100 text-red-600 uppercase">{Translate("Sold")}</span>;
            case 'draft':
                return <span id="status-badge-draft" className="px-2 inline-flex text-xs leading-5 font-medium rounded-full bg-gray-100 text-gray-600 uppercase">{Translate("Draft")}</span>
            case 'delete':
                return <span id="status-badge-delete" className="px-2 inline-flex text-xs leading-5 font-medium rounded-full bg-red-100 text-red-600 uppercase">{Translate("Delete")}</span>;
            default:
                return <span id="status-badge-default" className="px-2 inline-flex text-xs leading-5 font-medium rounded-full bg-blue-100 text-blue-600">{Translate(status)}</span>;
        }
    }

    return (
        renderBadge(props.status)
    )
}