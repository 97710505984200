import { Link } from 'react-router-dom';
import { Translate } from "../../translate/Translate";

export default function StepsWizard(props) {
    const { useStep, trigger, handleGoto, steps } = props
    const {
        step,
        farestStep,
    } = useStep

    return (
        <div className="bg-white flex-none p-6 border-b">
            <div className="flex items-center">
                <div className="flex-none">
                    <div className="flex flex-row mb-1">
                        <Link to="/v2/properties" type="button" className="text-xs uppercase font-medium text-gray-600 inline-flex items-center">
                            <svg className="fill-current text-gray-600 mr-2" width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.890625 5.76953C0.890625 5.91602 0.943359 6.04492 1.05469 6.15625L5.70117 10.6973C5.80078 10.8027 5.92969 10.8555 6.08203 10.8555C6.38672 10.8555 6.62109 10.627 6.62109 10.3223C6.62109 10.1699 6.55664 10.041 6.46289 9.94141L2.19727 5.76953L6.46289 1.59766C6.55664 1.49805 6.62109 1.36328 6.62109 1.2168C6.62109 0.912109 6.38672 0.683594 6.08203 0.683594C5.92969 0.683594 5.80078 0.736328 5.70117 0.835938L1.05469 5.38281C0.943359 5.48828 0.890625 5.62305 0.890625 5.76953Z" />
                            </svg>
                            <span>{Translate("Listing")}</span>
                        </Link>
                    </div>

                    <p className="sm:text-xs md:text-xs lg:text-2xl font-semibold">{Translate("Create new property")}</p>
                </div>
                <div className="ml-auto flex gap-4">
                    {steps.map((s, index) => {
                        if (index === step) {
                            return (<div key={s} className="cursor-pointer">
                                <div className="h-1 w-24 bg-primary mb-2"></div>
                                <p className='text-xs font-semibold text-primary'>STEP {index + 1}</p>
                                <p className="text-xs font-semibold">{s.name}</p>
                            </div>)
                        }

                        return (<div key={s.name} className={` ${index > farestStep ? "cursor-not-allowed" : "cursor-pointer"}`} onClick={() => {handleGoto(index)}}>
                            <div className={`h-1 w-24 mb-2 ${index < step ? "bg-primary" : index > farestStep ? "bg-gray-500 text-slate-400" : "bg-gray-300"}`}></div>
                            <p className={`text-xs font-semibold ${index < step ? "text-primary" : ""}`}>STEP {index + 1}</p>
                            <p className="text-xs font-semibold">{s.name}</p>
                        </div>)
                    })}
                </div>
            </div>
        </div>
    )
}