import { useState } from "react"
import { CopyToClipboard } from "react-copy-to-clipboard"
import { Popover, Transition } from '@headlessui/react'
import { usePopper } from 'react-popper'
import { ClipboardDocumentIcon } from "@heroicons/react/24/outline"


export default function CopyClipboard({text, Translate}) {
    let [referenceElement, setReferenceElement] = useState()
    let [popperElement, setPopperElement] = useState()
    let { styles, attributes } = usePopper(referenceElement, popperElement, { placement: 'bottom' })

    return (<Popover>
        <Popover.Button ref={setReferenceElement}>
            <div>
            <CopyToClipboard
                text={text}
                >
                <ClipboardDocumentIcon className="h-6 w-6"/>
            </CopyToClipboard>
            </div>
        </Popover.Button>
        <Popover.Panel
            className="bg-blue-600 rounded-lg shadow-lg ring-1 p-1 ring-slate-200 w-24 text-center"
            ref={setPopperElement}
            style={styles.popper}
            {...attributes.popper}>
            <p className="text-white font-semibold">{Translate("copied")}</p>
            <div className={`absolute left-10 -top-1 w-3 h-3 bg-blue-600 -z-10`} style={{"transform": "rotate(45deg)"}}></div>
        </Popover.Panel>
    </Popover>)
}