
import React, {useContext, useState, useEffect} from "react"
import SideNavBar from "../SideNavBar";


import AppContext from "../../../AppContext";
import * as api from '../../services/agent'
import * as Account from '../../../account';
import Alert from '../Shared/Alert'
import SettingsNavBar from "./SettingsNavBar";

export default function Settings(props) {
    const {Translate} = props
    const [account] = useState(Account.getUserAccount());

    const { language, setLanguage } = useContext(AppContext)
    const [offer, setOffer] = useState("")
    const [tours, setTours] = useState({})
    const [inPerson, setInPerson] = useState(false)
    const [video, setVideo] = useState(false)
    const [onlineConsultation, setOnlineConsultation] = useState(false)

    const [showAlert, setShowAlert] = useState(false);
    const [message, setMessage] = useState(Translate("Your setting has been updated"));

    const handleLanguageChange = (e) => {
        let lang = e.target.value;
        setLanguage(lang);
        updateLanguage(lang)
    }

    function loadProfile() {
        api.profile()
            .then(response => {
                setOffer(String(response.agent.can_be_offered))
                setTours(response.agent.available_tours)
                setInPerson(response.agent.available_tours["in-person"])
                setVideo(response.agent.available_tours["video"])
                setOnlineConsultation(response.agent.available_tours["online-consultation"])
            })
            .catch(error => {
            })
    }

    useEffect(() => {
        loadProfile()
    }, [])

    const handleOfferChange = (e) => {
        let o = e.target.value
        setOffer(o);
        updateOffer(o)
    }

    const updateOffer = (d) => {
        let data = {
            agent: {
                can_be_offered: d
            }
        }

        api.updateProfile(data)
            .then(response => {
                setShowAlert(true)
            })
            .catch(error => {

            })
    }

    const updateLanguage = (lang) => {
        //update locally in session storage
        account.user.settings.language = lang
        Account.updateAccount(account);
        //update it on the server
        let data = {
            settings: {
                language: lang
            }
        }
        api.updateProfile(data)
            .then(response => {
                setShowAlert(true)
            })
            .catch(error => {

            })
    }

    const updateTours = () => {
        let data = {
            agent: {
                available_tours: tours
            }
        }
        api.updateProfile(data)
            .then(response => {
                setShowAlert(true)
            })
            .catch(error => {

            })
    }
    return (

        <div className="w-full flex bg-slate-50">
            <SideNavBar {...props} isPresented={true} />
            <div className="flex flex-col w-full relative">

                <div className="bg-white">
                    <div className="w-full container mx-auto lg:px-8 px-4">

                        <div className="py-8">
                            <p className="text-3xl font-bold">{Translate("Settings")}</p>
                        </div>

                    </div>
                </div>
                <div className="lg:p-8 p-4 flex items-center container mx-auto">


                    <div className="w-full">
                        <div className="mb-4">
                            <p className="text-2xl font-semibold">{Translate("General")}</p>
                        </div>

                        <SettingsNavBar active="general"></SettingsNavBar>

                        <div className="w-full bg-white shadow rounded-lg">
                            <form className="w-full"  >
                                <div className="grid grid-cols-1 divide-y text-sm">
                                    <div className="col-span-1 p-4 lg:p-8">
                                        <p className="text-xl font-medium">{Translate("General Settings")}</p>
                                        {/* <p className="text-sm text-gray-500">{Translate("This information will be displayed publicly")}</p> */}
                                    </div>
                                    <div className="col-span-1 py-4 lg:py-4 px-6 ">
                                        <div className="grid grid-cols-3 items-center">
                                            <div className="col-span-3 lg:col-span-1">
                                                <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Language")}</p>
                                            </div>
                                            <div className="col-span-3 lg:col-span-2">

                                                <div className="flex flex-col lg:flex-row space-y-1 lg:space-y-0">
                                                    <label className="inline-flex items-center mr-6">
                                                        <input type="radio" name="transaction_type" value="ja" onChange={handleLanguageChange} checked={language === "ja"} />
                                                        <span className="ml-2">{Translate("Japanese")}</span>
                                                    </label>
                                                    <label className="inline-flex items-center mr-6">
                                                        <input type="radio" name="transaction_type" value="en" onChange={handleLanguageChange} checked={language === "en"} />
                                                        <span className="ml-2">{Translate("English")}</span>
                                                    </label>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-1 py-4 lg:py-4 px-6 ">
                                        <div className="grid grid-cols-3 items-center">
                                            <div className="col-span-3 lg:col-span-1">
                                                <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Accept Offer for properties")}</p>
                                            </div>
                                            <div className="col-span-3 lg:col-span-2">

                                                <div className="flex flex-col lg:flex-row space-y-1 lg:space-y-0">
                                                    <label className="inline-flex items-center mr-6">
                                                        <input type="radio" name="can_offered" value="true" onChange={handleOfferChange} checked={offer === "true"} />
                                                        <span className="ml-2">{Translate("Yes")}</span>
                                                    </label>
                                                    <label className="inline-flex items-center mr-6">
                                                        <input type="radio" name="can_offered" value="false" onChange={handleOfferChange} checked={offer === "false"} />
                                                        <span className="ml-2">{Translate("No")}</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-span-1 py-4 lg:py-4 px-6 ">
                                        <div className="grid grid-cols-3 items-center">
                                            <div className="col-span-3 lg:col-span-1">
                                                <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Requested tour")}</p>
                                            </div>
                                            <div className="col-span-3 lg:col-span-2">

                                                <div className="flex flex-col lg:flex-row space-y-1 lg:space-y-0">

                                                    <input name="in-person" type="checkbox" className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded" checked={inPerson}
                                                        onChange={(e) => {
                                                            tours["in-person"] = e.target.checked
                                                            setTours(tours)
                                                            setInPerson(e.target.checked)
                                                            updateTours()

                                                        }} />
                                                    <label htmlFor="in-person" className="ml-2 block text-sm text-gray-900">{Translate("in-person")}</label>
                                                    <input name="video" type="checkbox" className="ml-2 h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded" checked={video}
                                                        onChange={(e) => {
                                                            tours["video"] = e.target.checked
                                                            setTours(tours)
                                                            setVideo(e.target.checked)
                                                            updateTours()
                                                        }} />
                                                    <label htmlFor="video" className="ml-2 block text-sm text-gray-900">{Translate("video")}</label>

                                                    <input name="online-consultation" type="checkbox" className="ml-2 h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded" checked={onlineConsultation}
                                                        onChange={(e) => {
                                                            tours["online-consultation"] = e.target.checked
                                                            setTours(tours)
                                                            setOnlineConsultation(e.target.checked)
                                                            updateTours()
                                                        }} />
                                                    <label htmlFor="online-consultation" className="ml-2 block text-sm text-gray-900">{Translate("contact_agent_online_consultation_option")}</label>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <Alert {...props} isPresented={showAlert} message={message} dismiss={(e) => { setShowAlert(false) }}></Alert>
                        </div>
                    </div>



                </div>
            </div>
        </div>

    );
}