import { PaperClipIcon } from '@heroicons/react/24/solid'
import DateTime from '../../Shared/DateTime'

export default function Message({ data }) {
    return (<div className="flex items-start gap-4">
        <div>
            <div className="rounded-full w-10 h-10 bg-gray-300 overflow-hidden">
                <img src={data.user.image_url} alt="user_profile" />
            </div>
        </div>
        <div className="">
            <p className="font-medium">{data.user.last_name} {data.user.first_name}</p>
            <p className="py-1 whitespace-pre-wrap">{data.data.message}</p>

            {
                data.data.files ? data.data.files.map((file, index) => (
                    <div className="text-sm flex items-center flex-wrap gap-x-5 gap-y-2 mb-3 mt-2">
                        <div className="flex items-center gap-2 text-gray-500">
                            <PaperClipIcon className='w-5 h-5'/>
                            <a target="_blank" href={file.path}>{file.name }.{file.extension }</a><span></span>
                        </div>
                    </div>

                )) : null
            }

            <p className="text-gray-500 text-xs"><DateTime date={data.created_at} /></p>
        </div>
    </div>)
}