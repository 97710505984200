import React, {Fragment, useRef} from 'react'
import { Dialog, Transition } from '@headlessui/react'

export default function RequestProfessionalModal(props) {
  const {Translate, data, setData, isOpen, setIsOpen} = props
  // let [isOpen, setIsOpen] = useState(true)
  // const [data, setData] = React.useState("");
  let elementRef = useRef(null)

  const onConfirm = (e) => {
    props.confirm(data)
  }

  const onCancel = () => {
    setIsOpen(false)
  }

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment} >
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg p-6 font-medium leading-6 text-gray-900"
                  >
                    <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                            <svg className="mx-auto mb-2 fill-current text-gray-700" width="27" height="23" viewBox="0 0 27 23" xmlns="http://www.w3.org/2000/svg"><path d="M2.33789 16.3145C2.67969 18.2969 3.86133 19.1172 5.88281 18.7559L6.07812 18.7266V19.166C6.07812 21.1777 7.09375 22.1934 9.14453 22.1934H23.3828C25.4238 22.1934 26.4492 21.1777 26.4492 19.166V9.2832C26.4492 7.27148 25.4238 6.25586 23.3828 6.25586H21.2637L20.6875 3.04297C20.3359 1.07031 19.1543 0.230469 17.1426 0.591797L3.11914 3.07227C1.09766 3.42383 0.287109 4.58594 0.628906 6.57812L2.33789 16.3145ZM3.87109 15.9531L2.19141 6.38281C2.01562 5.39648 2.45508 4.78125 3.42188 4.60547L17.3965 2.14453C18.3438 1.97852 18.9785 2.39844 19.1543 3.39453L19.6621 6.25586H9.14453C7.09375 6.25586 6.07812 7.26172 6.07812 9.2832V17.125L5.63867 17.2031C4.68164 17.3789 4.05664 16.9492 3.87109 15.9531ZM7.65039 9.37109C7.65039 8.35547 8.1875 7.82812 9.16406 7.82812H23.3633C24.3301 7.82812 24.877 8.35547 24.877 9.37109V17.1348L21.3418 13.7949C20.9219 13.4043 20.4141 13.209 19.8965 13.209C19.3594 13.209 18.8906 13.3945 18.4414 13.7852L14.1152 17.6328L12.377 16.0703C11.9668 15.709 11.5273 15.5137 11.0684 15.5137C10.6484 15.5137 10.2383 15.6992 9.83789 16.0605L7.65039 17.9551V9.37109ZM12.5332 14.0391C13.7832 14.0391 14.7988 13.0137 14.7988 11.7539C14.7988 10.5137 13.7832 9.47852 12.5332 9.47852C11.2832 9.47852 10.2578 10.5137 10.2578 11.7539C10.2578 13.0137 11.2832 14.0391 12.5332 14.0391Z"></path></svg>
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                                {Translate("confirm_dialog_title")}
                            </h3>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    {Translate("confirm_dialog_detail")}
                                </p>
                            </div>
                        </div>
                    </div>
                  </Dialog.Title>
                  <div className="bg-white pt-5 pb-4 sm:pb-4">
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-center">
                        <label className="inline-flex items-center mr-6">
                            <input id="option-both-3d-photo" type="radio" name="request" onChange={(e)=>{
                              setData(e.target.value)
                            }} value="3D scan & Photos" checked={data === "3D scan & Photos"} />
                            <span className="ml-2">{Translate("3D scan & Photos")}</span>
                        </label>
                        <label className="inline-flex items-center mr-6">
                            <input id="option-photo" type="radio" name="request" onChange={(e)=>{
                              setData(e.target.value)
                            }} value="Photos" checked={data === "Photos"} />
                            <span className="ml-2">{Translate("Photos")}</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input id="option-3d" type="radio" name="request" onChange={(e)=>{
                              setData(e.target.value)
                            }} value="3D scan" checked={data === "3D scan"} />
                            <span className="ml-2">{Translate("3D scan")} </span>
                        </label>
                    </div>
                </div>

                    <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button onClick={onConfirm} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                            {Translate("Confirm")}
                        </button>
                        <button onClick={onCancel} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                            {Translate("Cancel")}
                        </button>
                    </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
