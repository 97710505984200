import React from "react"
import AppContext from "../../AppContext"
import {locales} from "../../locales/Locales"

import parse from "html-react-parser";


export function Translate(key) {    
    const {language} = React.useContext(AppContext)
    const value = locales[language][key];
    if (value === undefined) {
        return key;
    }
    return value.length === 0 ? key : value;
}

//Use this method when use Translate in a loop,
export function TranslateWithLang(lang,key) {  
    if (lang == undefined)
        return  
    if (key == undefined)
        return  
    const value = locales[lang][key];
    if (value === undefined) {
        return key;
    }
    return value.length === 0 ? key : value;
}

export function TranslateToHtml(key) {    
    const {language} = React.useContext(AppContext)   
    const value = locales[language][key];
    if (value === undefined) {
        return key;
    }
    
    return parse(value.length === 0 ? key : value)
}