import React, { useState, useEffect, useRef } from "react"
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import * as api from '../../services/agent'
import {parseContext} from '../../../mapbox/parseContext'
import { generatePath } from "react-router";
import * as Constants from '../../../constants'
import Alert from '../Shared/Alert'
import Error from '../Shared/Error'
import Confirm from '../Shared/Confirm'
import StatusInfo from '../Shared/StatusInfo'
import { Translate } from "../../translate/Translate";
import SideNavBar from "../SideNavBar";
import TopSearchbar from "../TopSearchbar";
import PropertyFeedRow from "../Components/PropertyFeedRow";
import * as Account from '../../../account';
import { EmbedProperty } from "../../embed/Embed";

export default function PropertyDetailSummary(props) {

    const [showAlert, setShowAlert] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showStatusInfo, setShowStatusInfo] = useState(false);
    const [statusWithInfo, setStatusWithInfo] = useState({});
    const [account] = React.useState(Account.getUserAccount());

    const [errorMessage, setErrorMessage] = useState("");
    const [message, setMessage] = useState("");

    let { id } = useParams();
    
    const [saving, setSaving] = useState(false);
    const [updated, setUpdated] = useState(false);

    const [reach, setReach] = useState(0)
    const [data, setData] = useState({
        area: {id:""},
        listed_by: {
            company: {}
        },
        images: [],
        image_count: 0
    });
    const [optionsVisible, setOptionsVisible] = useState(false);

    const linkRef = useRef(null)
    const embedRef = useRef(null)

    function loadPropertyDetail(id) {
        api.fetchPropertyDetail(id)
            .then(response => {
                setData(response);
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error.data.message)
            })
    }
    function loadReach(id) {
        api.getReach(id)
            .then(response => {
                setReach(response);
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error.data.message)
            })
    }

    function saveSurvey(want) {
        let survey = { property_id: data.property_id, data: { request: want } }
        api.createNewSurvey(survey)
            .then(response => {
                setShowPopup(false)
                setShowAlert(true)
                setMessage("We will contact you shortly.")
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error.data.message)
            })
    }

    // Similar to componentDidMount and componentDidUpdate:
    React.useEffect(() => {
        if (id !== undefined) {
            loadPropertyDetail(id);
            loadReach(id)
        }
    }, []);

    const markAs = (property) => {
        api.updateProperty(property)
            .then(response => {
                setData({ ...data, status: property.status })
                if (property.status == "sold")
                    props.history.replace(`/v2/properties/${response}`);
                else
                    props.history.replace({
                        pathname: generatePath(props.match.path, { id: response }),
                    });
                setShowStatusInfo(false)
                setShowAlert(true)
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error.data.message)
            })
    }

    function process(str) {

        var div = document.createElement('div');
        div.innerHTML = str.trim();
    
        return format(div, 0).innerHTML;
    }
    function format(node, level) {

        var indentBefore = new Array(level++ + 1).join('  '),
            indentAfter  = new Array(level - 1).join('  '),
            textNode;
    
        for (var i = 0; i < node.children.length; i++) {
    
            textNode = document.createTextNode('\n' + indentBefore);
            node.insertBefore(textNode, node.children[i]);
    
            format(node.children[i], level);
    
            if (node.lastElementChild == node.children[i]) {
                textNode = document.createTextNode('\n' + indentAfter);
                node.appendChild(textNode);
            }
        }
    
        return node;
    }

    const handleCopyClick = (ref) => {
        ref.current.select()
        // document.execCommand('copy')
          /* Copy the text inside the text field */
        navigator.clipboard.writeText(process(ref.current.value));
        ref.current.focus()

    }

    const sendRegistrationButton = () => {
        api.getPropertyRegistraionDetail(data.property_id)
            .then((res) => {
                setShowAlert(true)
                setMessage("We will send property registration to your email.")
            })
    }




    return (

        <div className="w-full flex bg-slate-50">
            <SideNavBar {...props} isPresented={true} />
            <main className="w-full h-full flex flex-col">
                <TopSearchbar />
                <div className="">
                    <div className="mt-16 mb-16 w-full container mx-auto py-8 px-4">

                        <div className="mb-8 flex justify-between items-center">
                            <div>
                                <div className="flex flex-row mb-1">
                                    <Link to="/v2/properties" className="text-xs uppercase font-medium text-gray-600 inline-flex items-center">
                                        <svg className="fill-current text-gray-600 mr-2" width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.890625 5.76953C0.890625 5.91602 0.943359 6.04492 1.05469 6.15625L5.70117 10.6973C5.80078 10.8027 5.92969 10.8555 6.08203 10.8555C6.38672 10.8555 6.62109 10.627 6.62109 10.3223C6.62109 10.1699 6.55664 10.041 6.46289 9.94141L2.19727 5.76953L6.46289 1.59766C6.55664 1.49805 6.62109 1.36328 6.62109 1.2168C6.62109 0.912109 6.38672 0.683594 6.08203 0.683594C5.92969 0.683594 5.80078 0.736328 5.70117 0.835938L1.05469 5.38281C0.943359 5.48828 0.890625 5.62305 0.890625 5.76953Z" />
                                        </svg>
                                        <span>{Translate("Back")}</span>
                                    </Link>
                                </div>

                                <p className="text-2xl font-semibold">{Translate("Property Summary")}</p>
                            </div>

                            <div className="py-2 flex justify-end gap-2">
                                <Link to={`/v2/properties/wizard/${data.property_id}/summary`} id="btn-edit" className="btn text-sm rounded bg-gray-400 text-white inline-flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                                    </svg>
                                    <span className="ml-2">{Translate("Edit")}</span>
                                </Link>
                                <a id="btn-view-on-villa" className="btn text-sm rounded bg-blue-600 text-white inline-flex items-center" target="_blank" href={`${Constants.BASE_URL}/${data.area.id}/${data.name}/${data.property_id}`}>
                                    <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                    </svg>
                                    <span className="ml-2">{Translate("View on Viila")}</span>
                                </a>
                            </div>
                        </div>
                        <div className="grid grid-cols-1 lg:grid-cols-3 mb-8">
                            {data.id ? <PropertyFeedRow property={data} />: null}
                            <div className="col-span-2">
                                <div className="lg:max-w-sm bg-white py-8 px-4 rounded-lg relative items-center my-auto mx-auto">
                                    <p className="text-xl font-semibold mb-2 text-center">{Translate('Share')}</p>
                                    <div className="flex flex-col overflow-hidden">
                                        <input id="short-url-input" ref={linkRef} className="rounded border focus:outline-none focus:ring-0 fo bg-transparent text-sm" type="text" value={`${Constants.BASE_URL}/p/${data.property_id}`} />
                                        <button id="copy-url-button" className="mt-2 rounded w-full px-2 text-sm font-semibold h-full text-center py-2  bg-blue-100 text-blue-600" onClick={e=>handleCopyClick(linkRef)}>{Translate("Copy")}</button>
                                    </div>
                                </div>
                                <div className="lg:max-w-sm bg-white py-8 px-4 rounded-lg relative items-center my-auto mx-auto">
                                    <p className="text-xl font-semibold mb-2 text-center">{Translate('Export as PDF')}</p>
                                    <div className="flex flex-col overflow-hidden">
                                        <a id="export-pdf" target="_blank" rel="noreferrer" href={`${Constants.BASE_URL}/p/${data.property_id}/pdf`} className="mt-2 rounded w-full px-2 text-sm font-semibold h-full text-center py-2  bg-blue-100 text-blue-600">{Translate("Export")}</a>
                                    </div>
                                </div>
                                <div className="lg:max-w-sm bg-white py-8 px-4 rounded-lg relative items-center my-auto mx-auto">
                                    <p className="text-xl font-semibold mb-2 text-center">{Translate('Embed property')}</p>
                                    <div className="flex flex-col overflow-hidden">
                                        <input rows={5} id="embed-area" ref={embedRef} className="rounded border focus:outline-none focus:ring-0 fo bg-transparent text-sm" type="text" value={EmbedProperty(Translate,data)} />
                                        <button id="copy-url-button" className="mt-2 rounded w-full px-2 text-sm font-semibold h-full text-center py-2  bg-blue-100 text-blue-600" onClick={e=>handleCopyClick(embedRef)}>{Translate("Copy")}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" py-4 border shadow-lg px-4 rounded hidden lg:block mb-6">
                            <p className="text-xl mt-8 mb-8 text-center">Your property is ready to reach user</p>
                            <p className="text-lg mb-2 text-center">Your property matches {reach} user’s preferences and saved search.</p>
                            {(new Date(account.user.subscription_end * 1000)) >= (new Date()) ? null: (<button className="mt-2 rounded w-full px-2 text-sm font-semibold h-full text-center py-2 bg-blue-700 text-white">Upgrade</button>)}
                        </div>
                        
                        {data.property_type !== 'apartment' ? (<div className=" py-4 border shadow-lg px-4 rounded hidden lg:block mb-6">
                            <h3 className="font-bold text-lg">{Translate("get_property_registraion_title")}</h3>
                            <p className="font-medium mt-2 text-sm text-gray-600">{Translate("get_property_registraion_detail")}</p>
                            <div className="mt-2 mb-4 text-xs">
                                <span className="text-gray-500">{Translate("registration_delivers_within_24_hrs")}</span>
                            </div>
                            <button className=" w-full rounded px-4 py-2 bg-green-500 text-white font-semibold inline-flex items-center justify-center" onClick={sendRegistrationButton}>
                                <span className="mr-2">{Translate("request_property_registration_button")}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                                </svg>
                            </button>
                        </div>): null}
                        
                    </div>
                    <Alert isPresented={showAlert} message={message} dismiss={(e) => { setShowAlert(false) }}></Alert>
                    <Confirm isPresented={showPopup} confirm={data => saveSurvey(data)} dismiss={(e) => { setShowPopup(false) }}></Confirm>
                    <StatusInfo property={data} isPresented={showStatusInfo} statusWithInfo={statusWithInfo} confirm={data => markAs(data)} dismiss={(e) => { setShowStatusInfo(false) }}></StatusInfo>
                    <Error isPresented={showError} errorMessage={errorMessage} dismiss={(e) => { setShowError(false) }}></Error>
                </div>
            </main>
        </div>
    )
}