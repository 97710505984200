
import { ArrowDownTrayIcon, ArrowRightIcon, ChatAltIcon, ChatIcon, ChevronRightIcon, CubeIcon, EllipsisHorizontalIcon, ArrowTopRightOnSquareIcon, EyeIcon, FlagIcon, HeartIcon, InformationCircleIcon, EnvelopeIcon, MapIcon, PencilIcon, PhotoIcon, VideoCameraIcon } from "@heroicons/react/24/solid"
import React, { Fragment, useState } from "react"
import { Link } from 'react-router-dom';
import { Popover, Transition, Menu } from '@headlessui/react'
import { usePopper } from 'react-popper'
import moment from "moment";
import PropertyNewStatusBadge from "./../Shared/PropertyNewStatusBadge"
import * as Constants from '../../../constants'
import { Translate } from "../../translate/Translate";
import PropertyTypeBadge from "../Components/PropertyTypeBadge";
import DateOnly from "../Shared/DateOnly"
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import { ChatBubbleLeftRightIcon, EyeSlashIcon, GlobeAltIcon, KeyIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

export default function PropertyCardInList(props) {
    const { property } = props

    let [referenceElement, setReferenceElement] = useState()
    let [popperElement, setPopperElement] = useState()
    let { styles, attributes } = usePopper(referenceElement, popperElement,
        {
            placement: 'bottom',
            modifiers: [{
                name: 'offset',
                options: {
                    offset: [0, 5],
                },
            }]
        })

    const icon = {
        inactive: <EyeSlashIcon className="flex-none w-4 h-4 mr-1 text-stone-500" />,
        active: <GlobeAltIcon className="flex-none w-4 h-4 mr-1 text-success" />,
        business_negotiation: <ChatBubbleLeftRightIcon className="flex-none w-4 h-4 mr-1 text-orange-500" />,
        sold: <KeyIcon className="flex-none w-4 h-4 mr-1 text-red-600" />,
        draft: <PencilIcon className="flex-none w-4 h-4 mr-1 text-yellow-400" />,
    }
    const labels = {
        "draft": "Mark as Draft",
        "sold": "Mark as Sold",
        "active": "Mark as Active",
        "business_negotiation": "Mark as Negotiation",
        "inactive": "Delist this property",
    }

    let actions = []

    switch (property.status) {
        case "draft":
        case "inactive":
            actions = [
                {
                    status: "active",
                    style: "bg-green-100 text-green-600",
                    label: Translate(labels["active"]),
                }
            ]
            break;
        case "active":
            actions = [
                {
                    status: "inactive",
                    style: "bg-gray-100 text-gray-600",
                    label: Translate(labels["inactive"]),
                },
                {
                    status: "sold",
                    style: "bg-red-100 text-red-600",
                    label: Translate(labels["sold"]),
                },
                {
                    status: "business_negotiation",
                    style: "bg-yellow-100 text-yellow-500",
                    label: Translate(labels["business_negotiation"]),
                },
            ]
            break;
        case "sold":
            actions = [
                {
                    status: "inactive",
                    style: "bg-gray-100 text-gray-600",
                    label: Translate(labels["inactive"]),
                },
                {
                    status: "business_negotiation",
                    style: "bg-yellow-100 text-yellow-500",
                    label: Translate(labels["business_negotiation"]),
                },
                {
                    status: "active",
                    style: "bg-green-100 text-green-600",
                    label: Translate(labels["active"]),
                }
            ]
            break;
        case "business_negotiation":
            actions = [
                {
                    status: "inactive",
                    style: "bg-gray-100 text-gray-600",
                    label: Translate(labels["inactive"]),
                },
                {
                    status: "sold",
                    style: "bg-red-100 text-red-600",
                    label: Translate(labels["sold"]),
                },
                {
                    status: "active",
                    style: "bg-green-100 text-green-600",
                    label: Translate(labels["active"]),
                }
            ]
            break;
        default:
    }

    return !property ? null : (
        <div className={clsx("flex items-start", props.className)}>
            {props.checked === undefined ? null : (<div className="pr-2">
                <input type="checkbox" checked={props.checked} onChange={e => props.onChange(property.id)} className="appearance-none h-6 w-6 text-primary rounded border border-gray-300 focus:ring-0 focus:outline-focus" />
            </div>)}
            <div className={`bg-white shadow rounded-lg w-full flex lg:flex-row flex-col mb-4 ${props.checked ? "ring-2 ring-primary" : ""}`} >
                <div className="p-4 w-full">
                    <div className="flex items-start gap-4">
                        <div className="flex-none w-20 h-20 lg:w-24 lg:h-24 bg-slate-100 rounded overflow-hidden relative">
                            {
                                property.images.length === 0 ?
                                    <div className="w-full h-full rounded object-cover border border-dashed border-slate-400 overflow-hidden"></div>
                                    :
                                    <img className="w-full h-full object-cover" src={property.images[0].url} />
                            }
                            <div className="absolute top-0 left-0 p-1 flex flex-col items-start gap-1">
                                <div className="flex items-center text-xs bg-white rounded px-1 py-0.5">
                                    <PhotoIcon className="w-3 h-3 mr-1" />
                                    <span className="font-medium">{property.images.length}</span>
                                </div>
                                {
                                    property.video.url ?
                                        <div className="flex items-center text-xs bg-red-500 rounded px-1 py-0.5">
                                            <VideoCameraIcon className="w-3 h-3 text-white" />
                                        </div>
                                        : null
                                }
                                {
                                    property.vr.url ?
                                        <div className="flex items-center text-xs bg-indigo-500 rounded px-1 py-0.5">
                                            <CubeIcon className="w-3 h-3 text-white" />
                                        </div>
                                        : null
                                }

                            </div>
                        </div>

                        <Link to={`/v2/properties/wizard/${property.property_id}/summary`} className="flex-1 w-full flex flex-col gap-0.5">
                            <div className="font-medium line-clamp-1">
                                {
                                    property.is_pinned ?
                                        <div className="mb-1">
                                            <svg className="w-4 h-4 fill-current text-primary" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.326172 8.58008C0.326172 9.07812 0.660156 9.40625 1.1875 9.40625H4.35742V12.1426C4.35742 13.0391 4.73242 13.7832 4.87891 13.7832C5.01953 13.7832 5.39453 13.0391 5.39453 12.1426V9.40625H8.56445C9.0918 9.40625 9.42578 9.07812 9.42578 8.58008C9.42578 7.34375 8.43555 6.03711 6.78906 5.43945L6.5957 2.75C7.45117 2.26367 8.1543 1.71289 8.45898 1.32031C8.61133 1.12109 8.6875 0.921875 8.6875 0.746094C8.6875 0.388672 8.41211 0.125 8.00195 0.125H1.75586C1.33984 0.125 1.07031 0.388672 1.07031 0.746094C1.07031 0.921875 1.14062 1.12109 1.29297 1.32031C1.59766 1.71289 2.30078 2.26367 3.15625 2.75L2.96289 5.43945C1.31641 6.03711 0.326172 7.34375 0.326172 8.58008Z" />
                                            </svg>
                                        </div>
                                        : null
                                }
                                {property.name}
                            </div>
                            <div className="flex items-center gap-4">
                                <span className="text-sm">{property.short_info}</span>
                            </div>
                            <div className="font-semibold">
                                {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: property.price.currency }).format(Number(property.price.amount))}
                            </div>
                        </Link>
                        <div className="ml-auto flex items-start flex-none">

                            <Popover>
                                <Popover.Button ref={setReferenceElement} className="focus:outline-none">
                                    <EllipsisHorizontalIcon className='w-6 h-6 text-slate-400' />
                                </Popover.Button>

                                <Transition
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                >
                                    <Popover.Panel
                                        ref={setPopperElement}
                                        style={styles.popper}
                                        {...attributes.popper}
                                    >
                                        <div className="bg-white rounded-lg shadow-lg w-56 ring-1 ring-slate-200 flex flex-col divide-y">
                                            {/* <Link to={`/v2/properties/${property.property_id}`} className="flex items-center gap-2 p-2 text-slate-500">
                                            <PencilIcon className="w-5 h-5" />
                                            <p className="font-medium">{Translate("Edit property")}</p>
                                            <ChevronRightIcon className="w-5 h-5 ml-auto" />
                                        </Link> */}
                                            <Link to={`/v2/properties/wizard/${property.property_id}/summary#settings`} className="flex items-center gap-2 p-2 text-slate-500">
                                                <PencilIcon className="w-5 h-5" />
                                                <p className="font-medium">{Translate("Edit property")}</p>
                                                <ChevronRightIcon className="w-5 h-5 ml-auto" />
                                            </Link>
                                            <Link to={`/v2/properties/wizard/${property.property_id}/summary#share`} className="flex items-center gap-2 p-2 text-slate-500">
                                                <FlagIcon className="w-5 h-5" />
                                                <p className="font-medium">{Translate("View summary")}</p>
                                                <ChevronRightIcon className="w-5 h-5 ml-auto" />
                                            </Link>
                                            <a href={`${Constants.BASE_URL}/p/${property.property_id}`} target="_blank" className="flex items-center gap-2 p-2 text-slate-500">
                                                <GlobeAltIcon className="w-5 h-5" />
                                                <p className="font-medium">{Translate("View on Viila")}</p>
                                                <ArrowTopRightOnSquareIcon className="w-5 h-5 ml-auto" />
                                            </a>
                                            {/* {
                                                property.is_private ? <a onClick={(e) => { props.setPublic(property.id); }} className="cursor-pointer flex items-center gap-2 p-2 text-slate-500">{Translate("Set to public")}</a>
                                                    : <a onClick={(e) => { props.setPrivate(property.id); }} className="cursor-pointer flex items-center gap-2 p-2 text-slate-500">{Translate("Set to private")}</a>
                                            } */}
                                            {
                                                property.is_pinned ?
                                                    <button onClick={(e) => { props.setUnpin(property.id); }} className="flex items-center gap-2 p-2 text-slate-500">
                                                        <svg className="text-red-500 fill-current w-4 h-4" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M4.01562 0.5C3.46094 0.5 3.10156 0.851562 3.10156 1.32812C3.10156 1.55469 3.1875 1.8125 3.39062 2.07812C3.78906 2.60938 4.73438 3.35156 5.88281 4L5.8125 5.02344L13.5859 12.7891C14 12.625 14.2422 12.2656 14.2422 11.7734C14.2422 10.125 12.9219 8.38281 10.7266 7.58594L10.4688 4C11.6094 3.35156 12.5469 2.625 12.9453 2.10156C13.1562 1.82812 13.2578 1.5625 13.2578 1.32812C13.2578 0.851562 12.8906 0.5 12.3438 0.5H4.01562ZM14.4922 16.7656C14.7344 17 15.1172 17 15.3438 16.7656C15.5781 16.5312 15.5859 16.1484 15.3438 15.9141L1.22656 1.80469C0.992188 1.57031 0.601562 1.5625 0.367188 1.80469C0.140625 2.03125 0.140625 2.42188 0.367188 2.65625L14.4922 16.7656ZM2.10938 11.7734C2.10938 12.4375 2.55469 12.875 3.25781 12.875H7.49219V16.5234C7.49219 17.7188 7.98438 18.7109 8.17969 18.7109C8.375 18.7109 8.86719 17.7188 8.86719 16.5234V12.9844H9.32031L4.50781 8.15625C3 9.03906 2.10938 10.4297 2.10938 11.7734Z" />
                                                        </svg>
                                                        <p className="font-medium">{Translate("unpin_button")}</p>
                                                    </button>
                                                    : <button onClick={(e) => { props.setPin(property.id); }} className="flex items-center gap-2 p-2 text-slate-500">
                                                        <svg className="w-4 h-4 fill-current text-slate-500" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M0.326172 8.58008C0.326172 9.07812 0.660156 9.40625 1.1875 9.40625H4.35742V12.1426C4.35742 13.0391 4.73242 13.7832 4.87891 13.7832C5.01953 13.7832 5.39453 13.0391 5.39453 12.1426V9.40625H8.56445C9.0918 9.40625 9.42578 9.07812 9.42578 8.58008C9.42578 7.34375 8.43555 6.03711 6.78906 5.43945L6.5957 2.75C7.45117 2.26367 8.1543 1.71289 8.45898 1.32031C8.61133 1.12109 8.6875 0.921875 8.6875 0.746094C8.6875 0.388672 8.41211 0.125 8.00195 0.125H1.75586C1.33984 0.125 1.07031 0.388672 1.07031 0.746094C1.07031 0.921875 1.14062 1.12109 1.29297 1.32031C1.59766 1.71289 2.30078 2.26367 3.15625 2.75L2.96289 5.43945C1.31641 6.03711 0.326172 7.34375 0.326172 8.58008Z" />
                                                        </svg>
                                                        <p className="font-medium">{Translate("pin_button")}</p>
                                                    </button>
                                            }
                                            <button onClick={(e) => { props.openModel(property) }} className="flex items-center gap-2 p-2 text-slate-500">
                                                <ArrowDownTrayIcon className="w-4 h-4 fill-current text-slate-500" />
                                                <p className="font-medium">{Translate("import_property_button_in_infowindow")}</p>
                                            </button>

                                        </div>

                                    </Popover.Panel>
                                </Transition>
                            </Popover>
                        </div>
                    </div>
                    <div className="mt-4 grid grid-cols-2 gap-2 lg:flex lg:gap-4 text-xs">
                        <div className="flex flex-col gap-0.5">
                            <p className="text-slate-400">{Translate("Property type")}</p>
                            <PropertyTypeBadge className="text-xs" type={property.property_type} />
                        </div>
                        <div className="flex flex-col gap-0.5">
                            <p className="text-slate-400">{Translate("Status")}</p>

                            {props.setStatus === undefined ? (<div className="col-span-3 lg:col-span-2">
                                <div>
                                    <div className={`inline-flex justify-center rounded-md bg-white text-gray-600 text-sm font-medium text-white  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}>
                                        <PropertyNewStatusBadge className="text-xs" status={property.status} />
                                    </div>
                                </div>
                            </div>) : (<Menu as="div" className="col-span-3 lg:col-span-2">
                                <div>
                                    <Menu.Button className={`inline-flex justify-center rounded-md bg-white text-gray-600 text-sm font-medium text-white  focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}>
                                        <PropertyNewStatusBadge className="text-xs" status={property.status} />
                                        <ChevronDownIcon
                                            className={`ml-2 h-5 w-5 text-gray-400`}
                                            aria-hidden="true"
                                        />
                                    </Menu.Button>
                                </div>
                                <Transition
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Menu.Items className="z-50 absolute w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                        <div className="px-1 py-1 ">
                                            {actions.map((action, index) => (<Menu.Item key={index} onClick={() => { props.setStatus(property.id, action.status) }}>
                                                {({ active }) => (
                                                    <button className={`${active ? action.style : 'text-gray-900'} group flex w-full items-center rounded-md px-2 py-2 text-sm`}>
                                                        {icon[action.status]}
                                                        {action.label}
                                                    </button>
                                                )}
                                            </Menu.Item>))}
                                        </div>
                                    </Menu.Items>
                                </Transition>
                            </Menu>)}

                        </div>
                        <div className="flex flex-col gap-0.5">
                            <p className="text-slate-400">{Translate("Property ID")}</p>
                            <p className="font-medium ">{property.property_id}</p>
                        </div>
                        {property.reference_id ?
                        <div className="flex flex-col gap-0.5">
                            <p className="text-slate-400">{Translate("Reference ID")}</p>
                            <p className="font-medium "> {property.reference_id}</p>
                        </div> : null}
                        {/* <div className="flex flex-col gap-0.5">
                            <p className="text-slate-400">{Translate("Published")}</p>
                            {
                                property.status === "draft" || property.status === "satei" ?
                                    <p className="font-medium ">-</p>
                                    :
                                    <p className="font-medium ">
                                        <DateOnly date={property.published_at} />
                                    </p>
                            }
                        </div>
                        <div className="flex flex-col gap-0.5">
                            <p className="text-slate-400">{Translate("Last updated")}</p>
                            <p className="font-medium ">{moment(property.updated_at).fromNow()}</p>
                        </div> */}
                        {property.status == "active" || property.status == "business_negotiation" ?
                        <div className="flex flex-col gap-0.5">
                            <p className="text-slate-400">{Translate("Next update")}</p>
                            <p className="font-medium ">{moment(property.next_update_at).format("YYYY-MM-DD")}</p>
                        </div> : null}
                        <div className="flex flex-col gap-0.5">
                            <p className="text-slate-400">{Translate("Time on Viila")}</p>
                            <p className="font-medium ">{moment(moment()).diff(property.created_at, 'days') + 1} {Translate("number_of_days")}</p>
                        </div>
                        <div className="flex flex-col gap-0.5">
                            <p className="text-slate-400">{Translate("Transaction type")}</p>
                            <p className="font-medium ">{Translate(property.transaction_type)}</p>
                        </div>

                    </div>
                </div>

                <div className="ml-auto flex-none w-full lg:w-80 bg-slate-100 p-4 flex flex-col gap-2 rounded-r-lg">
                    <div className="flex items-center text-slate-500">
                        <div className="flex gap-2 items-center">
                            <EyeIcon className="w-5 h-5" />
                            <p className="text-sm font-medium">{Translate("Page views")}</p>
                        </div>
                        <p className="ml-auto font-medium"> {props.hidelink ? new Intl.NumberFormat('en-US').format(Number(property.page_view)) : <Link className="border-b border-dashed border-b-primary text-primary flex items-center" to={`/v2/reports?property=${property.property_id}`} >{new Intl.NumberFormat('en-US').format(Number(property.page_view))}</Link>}</p>
                    </div>
                    <div className="flex items-center text-slate-500">
                        <div className="flex gap-2 items-center">
                            <MapIcon className="w-5 h-5" />
                            <p className="text-sm font-medium">{Translate("Map impression")}</p>
                        </div>
                        <p className="ml-auto font-medium">{props.hidelink ? new Intl.NumberFormat('en-US').format(Number(property.number_of_map_impression)) : <Link className="border-b border-dashed border-b-primary text-primary flex items-center" to={`/v2/reports?property=${property.property_id}`} >{new Intl.NumberFormat('en-US').format(Number(property.number_of_map_impression))}</Link>} </p>
                    </div>
                    <div className="flex items-center text-slate-500">
                        <div className="flex gap-2 items-center">
                            <HeartIcon className="w-5 h-5" />
                            <p className="text-sm font-medium">{Translate("Favorites")}</p>
                        </div>
                        <p className="ml-auto font-medium">{new Intl.NumberFormat('en-US').format(Number(property.number_of_favorites))}</p>
                    </div>
                    <div className="flex items-center text-slate-500">
                        <div className="flex gap-2 items-center">
                            <EnvelopeIcon className="w-5 h-5" />
                            <p className="text-sm font-medium">{Translate("Messages")}</p>
                        </div>
                        <p className="ml-auto font-medium">{new Intl.NumberFormat('en-US').format(Number(property.number_of_message))}</p>
                    </div>
                    <div className="flex items-center mt-auto">
                        <Link to={`/v2/properties/wizard/${property.property_id}/summary`} className="flex gap-2 items-center text-primary">
                            <FlagIcon className="w-5 h-5" />
                            <p className="text-sm font-medium">{Translate("View Summary")}</p>
                            <ArrowRightIcon className="w-5 h-5" />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}