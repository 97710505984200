import Typewriter from 'typewriter-effect';
import { Translate } from "../../translate/Translate";
import { DocumentTextIcon, TrashIcon } from "@heroicons/react/24/outline"
export default function AIGeneratedContentSection(props) {
    const {onSelected, onDeleted, data, generatedLang, typing, onTypingDone} = props
    const supportLanguages = [{ id: 'ja', title: 'Japanese' }, { id: 'en', title: 'English' }]
    return (
        <div key={data.id} className='relative w-full p-4'>

            <div className="flex flex-col">

                <div className="flex flex-1 flex-col gap-4">
                    <p className='font-bold'>{Translate("Name")}</p>
                    <div className="whitespace-pre-wrap  p-4  bg-gray-100">
                    {
                            typing ? 

                        <Typewriter
                            onInit={(typewriter) => {
                                typewriter
                                    .typeString(data.response[generatedLang].title)
                                    .callFunction((state) => {
                                        console.log('String typed out!');
                                        state.elements.cursor.style.animation = 'none'
                                        state.elements.cursor.style.display = 'none'
                                        if (onTypingDone) {
                                            onTypingDone()
                                        }
                                    })

                                    .start()
                            }}
                        />
                        :
                        <p>{data.response[generatedLang].title}</p>
}
                    </div>
                    <p className='font-bold'>{Translate("Overview")}</p>
                    <div className="type whitespace-pre-wrap  p-4  bg-gray-100">
                        {
                            typing ? 
                            <Typewriter
                            onInit={(typewriter) => {
                                typewriter
                                    .changeDelay(50)
                                    .typeString(data.response[generatedLang].description)
                                    .callFunction((state) => {
                                        console.log('String typed out!');
                                        state.elements.cursor.style.animation = 'none'
                                        state.elements.cursor.style.display = 'none'
                                    })

                                    .start()
                            }}
                        />
                        :
                        <p>{data.response[generatedLang].description}</p>
                        }
                        
                    </div>

                    <div className="flex flex-col gap-2">
                        <p className='font-bold'>{Translate("Unique selling point")}</p>
                        <p className="whitespace-pre-wrap  p-4  bg-gray-100">
                            {data.request?.brief}
                        </p>
                    </div>
                    <div className="flex flex-row">
                        <p className='font-bold'>{Translate("Language")}</p>
                        <div className='ml-auto'>
                            {Translate(supportLanguages.find((l) => l.id === generatedLang).title)}
                        </div>
                    </div>


                </div>
                <div className=" mt-6 flex items-center">
                    <button className='focus:outline-none flex select-none items-center rounded py-3 text-xs font-medium ring-offset-2 bg-white hover:bg-gray-50  text-blue-900 justify-center border text-opacity-100 px-5 gap-2'
                        onClick={() => onSelected(data)}>
                        <DocumentTextIcon className='w-5 h-5' />
                        {Translate("Use this description")}
                    </button>
                    <button className='ml-auto focus:outline-none flex select-none items-center rounded py-3 text-xs font-medium ring-offset-2 bg-white hover:bg-gray-50 text-blue-900 justify-center border text-opacity-100 px-5 gap-2'
                        onClick={() => onDeleted(data)}>
                        <TrashIcon className="w-5 h-5" />
                        {Translate("Remove")}
                    </button>
                </div>
            </div>

        </div>
    )
}