import React from "react"
import * as Constants from '../../../constants'
import { Link } from 'react-router-dom';
import { Translate } from "../../translate/Translate";
import moment from "moment";
export default function InfoWindow(props) {
    const property = props.property;
    const version = props.version;
    const formatCurrency = (amount, currency) => {
        return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: currency }).format(Number(amount))
    }

    const onAddedToCollection = () => {
        props.onAddedToCollection(property)
    }

    return (
        <div className="text-xs ring-1 ring-gray-100 h-96 w-72 transform bg-white rounded overflow-hidden z-50 flex flex-col">
            <div className="flex items-center px-2 py-2 border-b ">
                <div className="flex items-center truncate overflow-ellipsis">
                    <img src={property.listed_by.company.image_url} className="rounded-full w-6 h-6 bg-gray-100 border" />
                    <p className="ml-2 mr-2 truncate overflow-ellipsis">{property.listed_by.company.name}</p>
                </div>
                <div className="flex-none ml-auto flex items-center gap-2 ">
                    <a href={`${Constants.BASE_URL}/p/${property.property_id}/pdf`} target="_blank">

                        <svg className="fill-current text-blue-600 w-4 h-4" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.44531 15.6016H4.25V16.3438C4.25 17.4922 4.79688 18 5.90625 18H13.9375C15.0391 18 15.5938 17.4922 15.5938 16.3438V15.6016H16.3984C17.9922 15.6016 18.8516 14.7734 18.8516 13.1797V5.99219C18.8516 4.39844 17.9922 3.57031 16.3984 3.57031H15.6797V3.14844C15.6797 1.57031 14.8906 0.84375 13.3672 0.84375H6.47656C5.01562 0.84375 4.15625 1.57031 4.15625 3.14844V3.57031H3.44531C1.91406 3.57031 0.992188 4.39844 0.992188 5.99219V13.1797C0.992188 14.7734 1.85156 15.6016 3.44531 15.6016ZM5.58594 3.04688C5.58594 2.45312 5.89062 2.16406 6.46875 2.16406H13.3672C13.9531 2.16406 14.25 2.45312 14.25 3.04688V3.57031H5.58594V3.04688ZM13.9375 8.53125H5.90625C4.83594 8.53125 4.25 9.03906 4.25 10.1875V14.2188H3.4375C2.79688 14.2188 2.46094 13.8828 2.46094 13.2422V5.9375C2.46094 5.29688 2.79688 4.95312 3.4375 4.95312H16.3984C17.0391 4.95312 17.3828 5.29688 17.3828 5.9375V13.2422C17.3828 13.8828 17.0391 14.2188 16.3984 14.2188H15.5938V10.1875C15.5938 9.03906 15.0391 8.53125 13.9375 8.53125ZM13.6094 6.75781C13.6094 7.32031 14.0703 7.76562 14.625 7.76562C15.1719 7.75781 15.6328 7.32031 15.6328 6.75781C15.6328 6.21094 15.1719 5.75 14.625 5.75C14.0703 5.75 13.6094 6.21094 13.6094 6.75781ZM6.23438 16.6484C5.85156 16.6484 5.66406 16.4688 5.66406 16.0859V10.4375C5.66406 10.0625 5.85156 9.88281 6.23438 9.88281H13.6172C13.9922 9.88281 14.1719 10.0625 14.1719 10.4375V16.0859C14.1719 16.4688 13.9922 16.6484 13.6172 16.6484H6.23438ZM7.34375 12.4688H12.5234C12.8203 12.4688 13.0469 12.2344 13.0469 11.9297C13.0469 11.6406 12.8203 11.4141 12.5234 11.4141H7.34375C7.03125 11.4141 6.80469 11.6406 6.80469 11.9297C6.80469 12.2344 7.03125 12.4688 7.34375 12.4688ZM7.34375 15.125H12.5234C12.8203 15.125 13.0469 14.8984 13.0469 14.6094C13.0469 14.3047 12.8203 14.0703 12.5234 14.0703H7.34375C7.03125 14.0703 6.80469 14.3047 6.80469 14.6094C6.80469 14.8984 7.03125 15.125 7.34375 15.125Z" />
                        </svg>

                    </a>
                    <a href={`${Constants.BASE_URL}/p/${property.property_id}`} target="_blank" className=" text-blue-600 ">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                        </svg>
                    </a>
                </div>

            </div>
            <div className="relative">
                {
                    property.images.length > 0 ?
                        <div className="relative">
                            <img className="h-20 w-full object-cover" src={property.images[0].url} />
                            <div className="absolute top-0 left-0 m-2 px-2 py-1 bg-gray-700 bg-opacity-75 text-white rounded text-xs font-medium">
                                {property.images.length} {Translate("photos")}
                            </div>
                        </div>
                        :
                        <div className="bg-gray-200 h-24 w-full"></div>
                }

            </div>
            <div className="p-2 overflow-hidden overflow-y-scroll">

                <p className="mb-2 line-clamp-1 truncate text-ellipsis">{property.name}</p>
                <div className="flex flex-col items-center divide-y w-full gap-1 ">
                    {property.reference_id === "" ? null :
                        <div className="w-full flex items-center">
                            <div className="w-1/2 text-gray-500">{Translate("Ref ID")}</div>
                            <div className="w-1/2 font-medium">{Translate(property.reference_id)}</div>
                        </div>
                    }
                    <div className="w-full flex items-center">
                        <div className="w-1/2 text-gray-500">{Translate("Status")}</div>
                        <div className="w-1/2 font-medium">{Translate(property.status)}</div>
                    </div>
                    <div className="w-full flex items-center">
                        <div className="w-1/2 text-gray-500">{Translate("Transaction type")}</div>
                        <div className="w-1/2 font-medium">{Translate(property.transaction_type)}</div>
                    </div>
                    <div className="w-full flex items-center">
                        <div className="w-1/2 text-gray-500">{Translate("Property type")}</div>
                        <div className="w-1/2 font-medium">{Translate(property.property_type)}</div>
                    </div>
                    <div className="w-full flex items-center">
                        <div className="w-1/2 text-gray-500">{Translate("Price")}</div>
                        <div className="w-1/2 font-medium">{formatCurrency(property.price.amount, property.price.currency)}</div>
                    </div>
                    {property.property_type !== "lot" ?
                        <div className="w-full flex items-center">
                            <div className="w-1/2 text-gray-500">{Translate("Layout")}</div>
                            <div className="w-1/2 font-medium">{property.layout.name}</div>
                        </div>
                        :
                        null
                    }
                    {property.property_type !== "lot" ?
                        <div className="w-full flex items-center">
                            <div className="w-1/2 text-gray-500">{Translate("Building size")}</div>
                            <div className="w-1/2 font-medium">{property.sqm.toLocaleString()}m²</div>
                        </div>
                        : null
                    }
                    {property.property_type !== "apartment" ?
                        <div className="w-full flex items-center">
                            <div className="w-1/2 text-gray-500">{Translate("Land size")}</div>
                            <div className="w-1/2 font-medium">{property.land.sqm.toLocaleString()}m²</div>
                        </div>
                        : null
                    }
                    <div className="w-full flex items-center">
                        <div className="w-1/2 text-gray-500">{Translate("Page views")}</div>
                        <div className="w-1/2 font-medium">{new Intl.NumberFormat('en-US').format(Number(property.page_view))}</div>
                    </div>

                    <div className="w-full flex items-center">
                        <div className="w-1/2 text-gray-500">{Translate("Time on Viila")}</div>
                        <div className="w-1/2 font-medium">{moment(property.created_at).fromNow()}</div>
                    </div>



                </div>
            </div>

            <div className="mt-auto border-t px-2 py-3 w-full flex gap-2">


                {property.is_owner ?
                    <a href={version == 2 ? `/v2/properties/wizard/${property.property_id}/summary` : '/listing/' + property.property_id}>
                        <button className="rounded border border-blue-600 px-1 py-0.5 text-blue-600 font-medium">
                            {Translate("edit_property_button_in_infowindow")}
                        </button>
                    </a> : null

                }
                {
                    version == 2 ?
                        <button className="rounded border border-blue-600 px-1 py-0.5 text-blue-600 font-medium" onClick={(e) => { props.openModel(property) }}>
                            {Translate("import_property_button_in_infowindow")}
                        </button> :
                        <a href={`/listing/new?importing=${property.property_id}`}>
                            <button className="rounded border border-blue-600 px-1 py-0.5 text-blue-600 font-medium">
                                {Translate("import_property_button_in_infowindow")}
                            </button>
                        </a>
                }
                {props.onAddedToCollection ?
                    <button onClick={onAddedToCollection} className="rounded border border-blue-600 px-1 py-0.5  ml-auto font-medium text-blue-600">
                        {Translate("add_to_collection_button_in_infowindow")}
                    </button> : null
                }
            </div>
        </div>
    )
}