import React from "react"
import AppContext from "../../../AppContext"

export default function DateOnly(props) {

    const {language} = React.useContext(AppContext)   
    const {date} = props;
    return (
        <>
        {date ? new Intl.DateTimeFormat( language === "en" ? "en-US" : "ja-JP", { dateStyle: 'medium' }).format((new Date(date))) : null}
        </>
    )
}