import React, { Fragment } from "react"
import AppContext from "../../../AppContext"
import { MAPBOX_TOKEN } from '../../../constants'
import { Translate } from "../../translate/Translate";
import { Dialog, Transition } from '@headlessui/react'
import moment from "moment";
import { DocumentTextIcon, HomeIcon, MapPinIcon, UserIcon } from "@heroicons/react/24/outline";
import ReactMapGL, {
    Marker,
} from 'react-map-gl';
export default function SellRequest(props) {
    const { isOpen, setIsOpen, data, language } = props
    const mapRef = React.useRef()
    const [viewport, setViewport] = React.useState({
        latitude: data.latitude,
        longitude: data.longitude,
        zoom: 17

    })
    function closeModal() {
        setIsOpen(false)
    }
    const monthName = (month) => {
        const date = new Date();
        date.setMonth(month - 1);
        return date.toLocaleString(language === "en" ? "en-US" : "ja-JP", {
            month: 'short',
        });
    }


    return (
        <>
            <Transition show={isOpen} as={Fragment} onClose={closeModal}>
                <Dialog as="div" className="relative z-[50]">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300 "
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25"></div>
                    </Transition.Child>

                    <div className="fixed top-0 left-0 inset-0 w-full h-full outline-none">
                        <div className="flex items-center justify-end h-full">
                            <Transition.Child
                                as={Fragment}
                                enter="ease duration-300 transition transform"
                                enterFrom="opacity-0 translate-x-full"
                                enterTo="opacity-100 translate-x-0"
                                leave="ease duration-200 transition transform"
                                leaveFrom="opacity-100 translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="border-none shadow-lg relative flex flex-col lg:w-2/5 w-full h-full pointer-events-auto bg-white bg-clip-padding  outline-none text-current">
                                    <Dialog.Title as="div" className="flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                                        <div className="bg-white overflow-hidden">
                                                    <div className="flex flex-col">
                                                        <p className="text-xl font-bold">{Translate("Property Sell Request")}</p>
                                                        <p className="text-sm text-gray-500 font-medium">{Translate("Request No.")} {data.order_id} · {moment(data.canceled_at).format("LLL")}</p>
                                                    </div>
                                        </div>
                                        <button type="button" id="close" onClick={closeModal}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </Dialog.Title>
                                    <div className="relative flex-1  overflow-y-auto">

                                        <div className="container mx-auto flex lg:flex-row flex-col items-start ">
                                            <div className="w-full">
                                                <div className="bg-white overflow-hidden">
                                                    <div className="flex flex-col">
                                                        <div className="p-5 bg-white flex flex-col gap-4">
                                                            <div className="flex items-start flex-col gap-2">
                                                                <div className="w-full lg:w-1/3">
                                                                    <p className="font-semibold inline-flex items-center gap-2">
                                                                        <MapPinIcon className="w-6 h-6"/>
                                                                        <span>{Translate("Address")}</span>
                                                                    </p>
                                                                </div>
                                                                <div className="flex flex-col w-full">
                                                                    <div className="flex items-start w-full py-1">
                                                                        <p className="text-gray-500 flex-none">
                                                                            {Translate("Property address")}
                                                                        </p>
                                                                        <p className="ml-auto font-medium">{Translate(data.full_address)}</p>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="flex items-start flex-col gap-2">
                                                                <div className="w-full lg:w-1/3">
                                                                    <p className="font-semibold inline-flex items-center gap-2">
                                                                        <HomeIcon className="w-6 h-6"/>
                                                                        <span>{Translate("Property Info")}</span>
                                                                    </p>
                                                                </div>

                                                                <div className="flex flex-col w-full divide-y">
                                                                    <div className="flex items-start w-full py-1">
                                                                        <p className="text-gray-500 flex-none">{Translate("property_type")}</p>
                                                                        <p className="ml-auto font-medium">{Translate(data.property_type)}</p>
                                                                    </div>
                                                                    <div className="flex items-start w-full py-1">
                                                                        <p className="text-gray-500 flex-none">{Translate("floor_plan")}</p>
                                                                        <p className="ml-auto font-medium">{Translate(data.floor_plan)}</p>
                                                                    </div>
                                                                    <div className="flex items-start w-full py-1">
                                                                        <p className="text-gray-500 flex-none">{Translate("building_sqm")}</p>
                                                                        <p className="ml-auto font-medium">{data.room_sqm} {Translate("m²")}</p>
                                                                    </div>
                                                                    <div className="flex items-start w-full py-1">
                                                                        <p className="text-gray-500 flex-none">{Translate("lot_sqm")}</p>
                                                                        <p className="ml-auto font-medium">{data.lot_sqm} {Translate("m²")}</p>
                                                                    </div>
                                                                    <div className="flex items-start w-full py-1">
                                                                        <p className="text-gray-500 flex-none">{Translate("landshape")}</p>
                                                                        <p className="ml-auto font-medium">{Translate(data.landshape)}</p>
                                                                    </div>
                                                                    <div className="flex items-start w-full py-1">
                                                                        <p className="text-gray-500 flex-none">{Translate("Construction date")}</p>
                                                                        <p className="ml-auto font-medium"> <p className="text-xs">{data.construction_year !== "" ? data.construction_year : ""}</p></p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="flex flex-col gap-2">
                                                                <div className="w-full lg:w-1/3">
                                                                    <p className="font-semibold inline-flex items-center gap-2">
                                                                        <UserIcon className="w-6 h-6"/>
                                                                        <span>{Translate("Contact Info")}</span>
                                                                    </p>
                                                                </div>
                                                                <div className="flex flex-col w-full divide-y">
                                                                    <div className="flex items-start w-full py-1">
                                                                        <p className="text-gray-500 flex-none">{Translate("Name")}</p>
                                                                        <p className="ml-auto font-medium">{data.firstname} {data.lastname}</p>
                                                                    </div>
                                                                    <div className="flex items-start w-full py-1">
                                                                        <p className="text-gray-500 flex-none">{Translate("phone_number")}</p>
                                                                        <p className="ml-auto font-medium">{data.phone}</p>
                                                                    </div>
                                                                    <div className="flex items-start w-full py-1">
                                                                        <p className="text-gray-500 flex-none">{Translate("email")}</p>
                                                                        <p className="ml-auto font-medium">{data.email}</p>
                                                                    </div>
                                                                    <div className="flex items-start w-full py-1">
                                                                        <p className="text-gray-500 flex-none">{Translate("current_address")}</p>
                                                                        <p className="ml-auto font-medium">{data.contact_address1} {data.contact_address2} {data.contact_prefecture} {data.contact_zipcode}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="flex flex-col  gap-2">
                                                                <div className="w-full lg:w-1/3">
                                                                    <p className="font-semibold inline-flex items-center gap-2">
                                                                        <DocumentTextIcon className="w-6 h-6"/>
                                                                        <span>{Translate("Sell Info")}</span>
                                                                    </p>
                                                                </div>
                                                                <div className="flex flex-col w-full divide-y">
                                                                    <div className="flex items-start w-full py-1">
                                                                        <p className="text-gray-500 flex-none">{Translate("property_condition")}</p>
                                                                        <p className="ml-auto font-medium">{Translate(data.property_condition)}</p>
                                                                    </div>
                                                                    <div className="flex items-start w-full py-1">
                                                                        <p className="text-gray-500 flex-none">{Translate("reason_to_sell")}</p>
                                                                        <div className="ml-auto font-medium flex flex-col text-right">
                                                                            {data.reason_to_sell.map((item, index) => { return <p> {item == "others" ? Translate(item) + " (" + data["reason_to_sell-others"] + ") " : Translate(item)}</p> })}
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex items-start w-full py-1">
                                                                        <p className="text-gray-500 flex-none">{Translate("request_to_agents")}</p>
                                                                        <div className="ml-auto font-medium flex flex-col text-right">
                                                                            {data.request_to_agents && data.request_to_agents.map((item, index) => { return <p> {item == "others" ? Translate(item) + " (" + data["request_to_agents-others"] + ") " : Translate(item)}</p> })}
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex items-start w-full py-1">
                                                                        <p className="text-gray-500 flex-none">{Translate("property_owner")}</p>
                                                                        <p className="ml-auto font-medium">{Translate(data.property_owner)}</p>
                                                                    </div>
                                                                    <div className="flex items-start w-full py-1">
                                                                        <p className="text-gray-500 flex-none">{Translate("time_to_sell")}</p>
                                                                        <p className="ml-auto font-medium">{Translate(data.time_to_sell)}</p>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="w-full h-96">
                                            <ReactMapGL
                                                ref={mapRef}
                                                mapStyle="mapbox://styles/apisitviila/ckwqdi01c0pjz14pdgapgheaw"
                                                mapboxAccessToken={MAPBOX_TOKEN}
                                                {...viewport}
                                                style={{ width: "100%", height: "100%" }}>
                                                <Marker
                                                    color="#1454FF"
                                                    longitude={data.longitude}
                                                    latitude={data.latitude}
                                                ></Marker>
                                            </ReactMapGL>
                                        </div>
                                    </div>


                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>)
}