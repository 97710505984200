import React from "react"
import TopNavBar from '../Shared/TopNavBar'
import { Link } from 'react-router-dom';
import { PropertyRow } from "../Shared/PropertyRow";
import { Translate } from "../../translate/Translate";
import * as api from '../../services/agent'
import { PinnedProperty } from "../Shared/PinnedProperty";

export default function Listing(props) {

    const [data, setData] = React.useState({ properties: [] });
    const properties = props.properties.items;
    const pinnedProperties = props.pinnedProperties.items;
    const [checkAll, setCheckAll] = React.useState(false);
    const [selectedAction, setSelectedAction] = React.useState(null);
    
    React.useEffect(() => {
        props.search()
    }, [])
    React.useEffect(() => {
        if (props.filters === null) {
            return;
        }
        props.history.replace({
            pathname: window.location.pathname,
            search: `?query=${JSON.stringify(props.filters)}`
        })
    }, [props.history, props.filters]);

    const updatePropertyStatus = function (id, status, index) {

        props.properties.items[index].status = status
        let property = {
            id: id,
            status: status
        }

        api.updateProperty(property)
            .then(response => {

            })
            .catch(error => {

            })
    }

    const updatePropertyPrivate = function (id, is_private, index) {
        props.properties.items[index].is_private = is_private
        let d = { properties: [id], is_private: is_private }
        api.updatePropertyPrivate(d)
            .then(response => {
                props.search()
            })
            .catch(error => {

            })
    }

    const updatePropertyPin = function (id, is_pin, index) {
        props.properties.items[index].is_pin = is_pin
        let d = { properties: [id], is_pin: is_pin }
        api.updatePropertyPin(d)
            .then(response => {
                props.reloadPinnedProperties()
                props.search()
            })
            .catch(error => {

            })
    }

    const markAsPrivate = () => {
        if (data.properties.length === 0)
            return

        let d = data
        d.is_private = true
        api.updatePropertyPrivate(d)
            .then(response => {
                props.search()
            })
            .catch(error => {

            })
    }

    const markAsPublic = () => {
        if (data.properties.length === 0)
            return
        let d = data
        d.is_private = false
        api.updatePropertyPrivate(d)
            .then(response => {
                props.search()
            })
            .catch(error => {

            })
    }

    const markAsPin = () => {
        if (data.properties.length === 0)
            return

        let d = data
        d.is_pin = true
        api.updatePropertyPin(d)
            .then(response => {
                props.reloadPinnedProperties()
                props.search()
            })
            .catch(error => {

            })
    }

    const markAsUnpin = () => {
        if (data.properties.length === 0)
            return

        let d = data
        d.is_pin = false
        api.updatePropertyPin(d)
            .then(response => {
                props.reloadPinnedProperties()
                props.search()
            })
            .catch(error => {

            })
    }

    const unpin = (item) => {
        let items = data.properties
        items.push(item.id)
        setData({ properties: items })
        let d = data
        data.is_pin = false
        api.updatePropertyPin(d)
            .then(response => {
                props.reloadPinnedProperties()
                props.search()
            })
            .catch(error => {
                
            })
    }

    const handleAllChecked = (e) => {
        
        setCheckAll(e.target.checked)
        let items = []
        if (e.target.checked) {
            properties.forEach(k => items.push(k.id));
        } 
        setData({ properties: items })
    };

    const handleChecked = (id) => {
        let items = data.properties
        const index = data.properties.indexOf(id);
        if (index > -1) {
            items.splice(index, 1);
        } else {
            items.push(id)
        }
        setData({ properties: items })
    }

    const actionWithSelected = (e) => {
        let action = e.target.value;
        setSelectedAction(action)
        if (action === "pin") {
            markAsPin()
        } else if (action === "unpin") {
            markAsUnpin()
        } else if (action === "private") {
            markAsPrivate()
        } else if (action === "public") {
            markAsPublic()
        }
        //reset
        setData({ properties: [] })
        setCheckAll(false)
        setSelectedAction("")
    }

    const onSelectFilterMustbeUpdated = (e) => {
        if (e.target.checked)
            props.setFilters({ ...props.filters, must_be_updated: true,status:"active" })
        else
            props.setFilters({ ...props.filters, must_be_updated: false,status: "" })
    
    }

    return (
        <div className="">
            <TopNavBar active="listing"></TopNavBar>
            <div className="mt-16 w-full container mx-auto py-8 px-4">
                <div className="mb-8 flex justify-between items-center">
                    <div>
                        <p className="text-2xl font-semibold">{Translate("Listing")}</p>
                    </div>
                    <Link to="/listing/steps" id="add-property-button" className="btn btn-primary inline-flex items-center">
                        <svg className="w-4 h-4 mr-2" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7 13.8594C7.28906 13.8594 7.53125 13.625 7.53125 13.3359V7.90625H12.8047C13.0859 7.90625 13.3281 7.66406 13.3281 7.375C13.3281 7.08594 13.0859 6.84375 12.8047 6.84375H7.53125V1.40625C7.53125 1.11719 7.28906 0.882812 7 0.882812C6.71094 0.882812 6.46875 1.11719 6.46875 1.40625V6.84375H1.20312C0.914062 6.84375 0.671875 7.08594 0.671875 7.375C0.671875 7.66406 0.914062 7.90625 1.20312 7.90625H6.46875V13.3359C6.46875 13.625 6.71094 13.8594 7 13.8594Z" fill="white" />
                        </svg>
                        <span>{Translate("Add Property")}</span>
                    </Link>
                </div>
                <div className="mb-8 ">
                    <div className="mb-4">
                        <p className="text-lg font-semibold">{Translate("pinned_property_title")}</p>
                        <p className="text-xs text-gray-600">{Translate("pinned_property_description")}</p>
                    </div>
                    <div className="grid lg:grid-cols-3 gap-4">
                        {/* fetch pinned properties and show them here */}
                        {pinnedProperties === null ?
                            <div className="w-full col-span-3 border rounded border-dashed text-center py-4">
                                <p className="text-gray-600 text-xs">{Translate("empty_pinned_properties")}</p>
                            </div>
                            : pinnedProperties.map((property, index) => (
                                <div key={`p-${index}`} className="border rounded shadow-md flex items-center overflow-hidden">
                                    <div className="flex-1">
                                        <PinnedProperty index={index} key={property.id} data={property}></PinnedProperty>
                                    </div>
                                    <div className="ml-auto border-l">
                                        <button className="px-4 text-sm font-medium text-blue-600" onClick={(e)=> {unpin(property)}}>
                                        <svg className="text-red-600 fill-current w-5 h-5" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.01562 0.5C3.46094 0.5 3.10156 0.851562 3.10156 1.32812C3.10156 1.55469 3.1875 1.8125 3.39062 2.07812C3.78906 2.60938 4.73438 3.35156 5.88281 4L5.8125 5.02344L13.5859 12.7891C14 12.625 14.2422 12.2656 14.2422 11.7734C14.2422 10.125 12.9219 8.38281 10.7266 7.58594L10.4688 4C11.6094 3.35156 12.5469 2.625 12.9453 2.10156C13.1562 1.82812 13.2578 1.5625 13.2578 1.32812C13.2578 0.851562 12.8906 0.5 12.3438 0.5H4.01562ZM14.4922 16.7656C14.7344 17 15.1172 17 15.3438 16.7656C15.5781 16.5312 15.5859 16.1484 15.3438 15.9141L1.22656 1.80469C0.992188 1.57031 0.601562 1.5625 0.367188 1.80469C0.140625 2.03125 0.140625 2.42188 0.367188 2.65625L14.4922 16.7656ZM2.10938 11.7734C2.10938 12.4375 2.55469 12.875 3.25781 12.875H7.49219V16.5234C7.49219 17.7188 7.98438 18.7109 8.17969 18.7109C8.375 18.7109 8.86719 17.7188 8.86719 16.5234V12.9844H9.32031L4.50781 8.15625C3 9.03906 2.10938 10.4297 2.10938 11.7734Z"/>
                                        </svg>
                                        
                                        </button>
                                    </div>
                                </div>
                            ))}
                    </div>
                </div>

                <div id="filter-section" className="w-full mb-8 ">

                    <div className="grid grid-cols-12 gap-4">

                        {/* <div className="col-span-6 lg:col-span-2">
                            <label htmlFor="company_website" className="block text-sm font-medium leading-6 text-gray-600">{Translate("Area")}</label>
                            <select className="rounded w-full">
                                <option>{Translate("All")}</option>
                                <option>{Translate("Karuizawa")}</option>
                                <option>{Translate("Hakone")}</option>
                                <option>{Translate("Yuzawa")}</option>
                                <option>{Translate("Boso")}</option>
                                <option>{Translate("Zushi")}</option>
                                <option>{Translate("Yagawara")}</option>
                                <option>{Translate("Atami")}</option>
                                <option>{Translate("Ito")}</option>
                                <option>{Translate("Yamanakako")}</option>
                            </select>
                        </div> */}
                        <div className="col-span-6 lg:col-span-3">
                            <label htmlFor="company_website" className="block text-sm font-medium leading-6 text-gray-600">{Translate("Property type")}</label>
                            <select name="property_type" id="property_type" value={props.filters === null ? "" : props.filters.property_type} onChange={(e) => props.handleFilterChange(e)} className="rounded w-full">
                                <option value="">{Translate("All")}</option>
                                <option value="house">{Translate("Home")}</option>
                                <option value="lot">{Translate("Land")}</option>
                                <option value="apartment">{Translate("Apartment")}</option>
                            </select>
                        </div>
                        <div className="col-span-6 lg:col-span-3">
                            <label htmlFor="company_website" className="block text-sm font-medium leading-6 text-gray-600">{Translate("Status")}</label>
                            <select name="status" id="status" value={props.filters === null ? "" : props.filters.status} onChange={(e) => props.handleFilterChange(e)} className="rounded w-full">
                                <option value="">{Translate("All")}</option>
                                <option value="active">{Translate("Active")}</option>
                                <option value="business_negotiation">{Translate("Under negotiation")}</option>
                                <option value="sold">{Translate("Sold")}</option>
                                <option value="inactive">{Translate("Inactive")}</option>
                                <option value="draft">{Translate("Draft")}</option>
                            </select>
                        </div>
                        <div className="col-span-12 lg:col-span-6">
                            <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-600">{Translate("Search")}</label>
                            <div className="w-full inline-flex items-center">
                                <input id="search-keyword" name="search-keyword" value={props.keywords === null ? "" : props.keywords} onChange={props.handleFilterKeyword} type="text" placeholder={Translate("search_placeholder")} className="rounded w-full"></input>
                                <button id="search-button" onClick={(e) => { props.search() }} className="flex-none ml-2 px-4 bg-gray-600 font-medium rounded py-2 text-white focus:outline-none">{Translate("Search")}</button>
                            </div>
                        </div>
                    </div>
                </div>
                {props && props.statistics && props.statistics.must_be_updated_properties ?
                            <div className="flex flex-col mb-4">
                                <div className="flex items-center gap-x-4 gap-y-2 flex-wrap">
                                    <label className="flex items-center">
                                        <input onChange={(e) => onSelectFilterMustbeUpdated(e)} defaultChecked={props.filters?.must_be_updated} type="checkbox" name="must_be_updated" className="mr-2 rounded accent-primary focus:outline-none focus:ring-0 border-2 border-slate-400" />
                                        <span className="text-sm font-medium">{Translate("Required update")}<div className="ml-2 px-2 inline-flex text-md leading-5 font-medium rounded-full bg-blue-100 text-blue-600">{props && props.statistics && props.statistics.must_be_updated_properties}</div></span>
                                    </label>
                                </div>
                            </div> : null}
                <div id="pager" className="w-full">

                    <div className="mb-4 flex items-center justify-between">

                        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                            <div>
                            {props.properties.total_item === 0 ?
                                <p className="text-sm leading-5 text-gray-700">{Translate("No result")}</p>
                            :
                                <p className="text-sm leading-5 text-gray-700">
                                    {Translate("Showing")}<span className="font-medium px-1">{props.properties.from}-{props.properties.to} {Translate("Total")} {props.properties.total_item}</span>{Translate("results")}
                                </p>
                            }
                            </div>
                        </div>
                        {
                            props.properties.total_page === 1 ? null :
                                <div>
                                    <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
                                        {
                                            props.properties.current_page === 1 ? null :
                                                <a href="#" onClick={(e) => props.handleFilterChange({ target: { name: "page", value: props.properties.current_page - 1 } })} className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                                    <span className="sr-only"> {Translate("Back")}</span>
                                                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                        <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
                                                    </svg>
                                                </a>
                                        }

                                        {Array.from(Array(props.properties.total_page), (e, i) => {
                                            return <a key={`page-${i}`} href="#" onClick={(e) => props.handleFilterChange({ target: { name: "page", value: i + 1 } })} className={(props.properties.current_page === i + 1 ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600" : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50") + "relative inline-flex items-center px-4 py-2 border text-sm font-medium"}>{i + 1}</a>
                                        })}
                                        {
                                            (props.properties.current_page === props.properties.total_page || props.properties.total_page === 0) ? null :
                                                <a href="#" onClick={(e) => props.handleFilterChange({ target: { name: "page", value: props.properties.current_page + 1 } })} className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                                                    <span className="sr-only"> {Translate("Next")}</span>
                                                    <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                        <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
                                                    </svg>
                                                </a>
                                        }
                                    </nav>
                                </div>
                        }
                        <div className="ml-auto">
                            <select className="rounded text-xs" onChange={(e) => {actionWithSelected(e)}} value={selectedAction}>
                                <option value="">—</option>
                                <option value="pin">{Translate("multiple_select_action_pin_selected")}</option>
                                <option value="unpin">{Translate("multiple_select_action_unpin_selected")}</option>
                                <option value="private">{Translate("multiple_select_action_hide_from_viila_map_selected")}</option>
                                <option value="public">{Translate("multiple_select_action_show_on_viila_map_selected")}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="w-full">

                    <div className="flex flex-col">
                        <div className="">
                            <div className="shadow rounded-md overflow-x-auto lg:overflow-visible">
                                <div className="">
                                    <table className="w-full divide-y divide-gray-200">
                                        <thead className="bg-gray-100">
                                            <tr>
                                                <th className="px-2 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider  justify-center ">
                                                    <input checked={checkAll} type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" onChange={handleAllChecked}/>
                                                </th>
                                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider ">
                                                    <div className="flex items-center">
                                                    <a style={{ cursor: 'pointer' }} onClick={(e) => props.handleFilterChange({ target: { name: "order", value: props.filters.order == 'id_low_to_high' ? "id_high_to_low" : 'id_low_to_high' } })} >
                                                        {Translate("Name")}
                                                    </a>
                                                    {props.filters.order == 'id_low_to_high' ? 
                                                    <button onClick={(e) => props.handleFilterChange({ target: { name: "order", value: "id_high_to_low" } })} className="ml-4 z-10 focus:outline-none fill-current text-gray-500">
                                                        <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 0 1-1.576 0S4.924 9.581 4.516 9.163c-.409-.418-.436-1.17 0-1.615z"/></svg>
                                                    </button> : null }
                                                    {props.filters.order == 'id_high_to_low' ? 
                                                    <button onClick={(e) => props.handleFilterChange({ target: { name: "order", value: "id_low_to_high" } })} className="ml-4 z-10 focus:outline-none fill-current text-gray-500">
                                                        <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15.484 12.452c-.436.446-1.043.481-1.576 0L10 8.705l-3.908 3.747c-.533.481-1.141.446-1.574 0-.436-.445-.408-1.197 0-1.615.406-.418 4.695-4.502 4.695-4.502a1.095 1.095 0 0 1 1.576 0s4.287 4.084 4.695 4.502c.409.418.436 1.17 0 1.615z"/></svg>
                                                    </button>: null }
                                                    </div>
                                                </th>
                                                <th className="px-6 py-3 bg-gray-50 text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">
                                                <div className="flex items-center">
                                                    <a style={{ cursor: 'pointer' }} onClick={(e) => props.handleFilterChange({ target: { name: "order", value: props.filters.order == 'ref_id_low_to_high' ? "ref_id_high_to_low" : 'ref_id_low_to_high' } })} >
                                                        {Translate("REF ID")}
                                                    </a>
                                                    {props.filters.order == 'ref_id_low_to_high' ? 
                                                    <button onClick={(e) => props.handleFilterChange({ target: { name: "order", value: "ref_id_high_to_low" } })} className="ml-4 z-10 focus:outline-none fill-current text-gray-500">
                                                        <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 0 1-1.576 0S4.924 9.581 4.516 9.163c-.409-.418-.436-1.17 0-1.615z"/></svg>
                                                    </button>: null }
                                                     {props.filters.order == 'ref_id_high_to_low' ? 
                                                    <button onClick={(e) => props.handleFilterChange({ target: { name: "order", value: "ref_id_low_to_high" } })} className="ml-4 z-10 focus:outline-none fill-current text-gray-500">
                                                        <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15.484 12.452c-.436.446-1.043.481-1.576 0L10 8.705l-3.908 3.747c-.533.481-1.141.446-1.574 0-.436-.445-.408-1.197 0-1.615.406-.418 4.695-4.502 4.695-4.502a1.095 1.095 0 0 1 1.576 0s4.287 4.084 4.695 4.502c.409.418.436 1.17 0 1.615z"/></svg>
                                                    </button>: null }
                                                    </div>
                                                </th>
                                                <th className="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider ">
                                                    <div className="flex items-center justify-end">
                                                    <a style={{ cursor: 'pointer' }} onClick={(e) => props.handleFilterChange({ target: { name: "order", value: props.filters.order == 'price_low_to_high' ? "price_high_to_low" : 'price_low_to_high' } })} >
                                                        {Translate("Price")}
                                                    </a>
                                                    {props.filters.order == 'price_low_to_high' ? 
                                                    <button onClick={(e) => props.handleFilterChange({ target: { name: "order", value: "price_high_to_low" } })} className="ml-4 z-10 focus:outline-none fill-current text-gray-500">
                                                        <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 0 1-1.576 0S4.924 9.581 4.516 9.163c-.409-.418-.436-1.17 0-1.615z"/></svg>
                                                    </button>: null }
                                                    {props.filters.order == 'price_high_to_low' ? 
                                                    <button onClick={(e) => props.handleFilterChange({ target: { name: "order", value: "price_low_to_high" } })} className="ml-4 z-10 focus:outline-none fill-current text-gray-500">
                                                        <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15.484 12.452c-.436.446-1.043.481-1.576 0L10 8.705l-3.908 3.747c-.533.481-1.141.446-1.574 0-.436-.445-.408-1.197 0-1.615.406-.418 4.695-4.502 4.695-4.502a1.095 1.095 0 0 1 1.576 0s4.287 4.084 4.695 4.502c.409.418.436 1.17 0 1.615z"/></svg>
                                                    </button>: null }
                                                    </div>
                                                </th>
                                                <th className="px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider flex">
                                                <div className="flex items-center">
                                                    <a style={{ cursor: 'pointer' }} onClick={(e) => props.handleFilterChange({ target: { name: "order", value: props.filters.order == 'page_views_low_to_high' ? "page_views_high_to_low" : 'page_views_low_to_high' } })} >
                                                        {Translate("Views")}
                                                    </a>
                                                    {props.filters.order == 'page_views_low_to_high' ? 
                                                    <button onClick={(e) => props.handleFilterChange({ target: { name: "order", value: "page_views_high_to_low" } })} className="ml-4 z-10 focus:outline-none fill-current text-gray-500">
                                                        <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 0 1-1.576 0S4.924 9.581 4.516 9.163c-.409-.418-.436-1.17 0-1.615z"/></svg>
                                                    </button>: null }
                                                    {props.filters.order == 'page_views_high_to_low' ? 
                                                    <button onClick={(e) => props.handleFilterChange({ target: { name: "order", value: "page_views_low_to_high" } })} className="ml-4 z-10 focus:outline-none fill-current text-gray-500">
                                                        <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15.484 12.452c-.436.446-1.043.481-1.576 0L10 8.705l-3.908 3.747c-.533.481-1.141.446-1.574 0-.436-.445-.408-1.197 0-1.615.406-.418 4.695-4.502 4.695-4.502a1.095 1.095 0 0 1 1.576 0s4.287 4.084 4.695 4.502c.409.418.436 1.17 0 1.615z"/></svg>
                                                    </button>: null }
                                                    </div>
                                                </th>
                                                <th className="px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider ">{Translate("Status")}</th>
                                                <th className="px-6 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider ">
                                                <div className="flex items-center">
                                                    <a style={{ cursor: 'pointer' }} onClick={(e) => props.handleFilterChange({ target: { name: "order", value: props.filters.order == 'updated_low_to_high' ? "updated_high_to_low" : 'updated_low_to_high' } })} >
                                                        {Translate("Date")}
                                                    </a>
                                                    {props.filters.order == 'updated_low_to_high' ? 
                                                    <button onClick={(e) => props.handleFilterChange({ target: { name: "order", value: "updated_high_to_low" } })} className="ml-4 z-10 focus:outline-none fill-current text-gray-500">
                                                        <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M4.516 7.548c.436-.446 1.043-.481 1.576 0L10 11.295l3.908-3.747c.533-.481 1.141-.446 1.574 0 .436.445.408 1.197 0 1.615-.406.418-4.695 4.502-4.695 4.502a1.095 1.095 0 0 1-1.576 0S4.924 9.581 4.516 9.163c-.409-.418-.436-1.17 0-1.615z"/></svg>
                                                    </button>: null }
                                                    {props.filters.order == 'updated_high_to_low' ? 
                                                    <button onClick={(e) => props.handleFilterChange({ target: { name: "order", value: "updated_low_to_high" } })} className="ml-4 z-10 focus:outline-none fill-current text-gray-500">
                                                        <svg width="20px" height="20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M15.484 12.452c-.436.446-1.043.481-1.576 0L10 8.705l-3.908 3.747c-.533.481-1.141.446-1.574 0-.436-.445-.408-1.197 0-1.615.406-.418 4.695-4.502 4.695-4.502a1.095 1.095 0 0 1 1.576 0s4.287 4.084 4.695 4.502c.409.418.436 1.17 0 1.615z"/></svg>
                                                    </button>: null }
                                                    </div>
                                                </th>
                                                <th className="px-6 py-3 bg-gray-50 "></th>
                                                <th className="px-6 py-3 bg-gray-50 "></th>
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white divide-y divide-gray-200">
                                            {properties === null ? "" : properties.map((property, index) => (
                                                <PropertyRow {...props} checked={data.properties.findIndex((e) => e === property.id) != -1} onChange={handleChecked} onSetStatus={updatePropertyStatus} onSetPrivate={updatePropertyPrivate} onSetPin={updatePropertyPin} index={index} key={property.id} data={property} url={"/reports"}></PropertyRow>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}