import React from "react"

export function TimelineStepDone(props) {
    
    return <div className="flex flex-col items-center justify-center w-full relative">
        <div className="z-20 flex-none w-6 h-6 bg-primary rounded-full flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4 text-white">
                <path fillRule="evenodd" d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z" clipRule="evenodd" />
            </svg>
        </div>
        {!props.is_last ? <div className={`z-10 h-0.5 w-1/2 ${props.last_color ? props.last_color: "bg-primary"} absolute right-0`}></div> : null}
        {!props.is_first ? <div className={`z-10 h-0.5 w-1/2 bg-primary absolute left-0`}></div> : null}
    </div>
}