import React, { useEffect, useState } from "react"
import TopNavBar from '../Shared/TopNavBar'
import { Link } from 'react-router-dom';
import * as Constants from '../../../constants'

export default function Home(props) {
    const { Translate } = props
    function string_to_slug(str) {
        str = str.replace(/^\s+|\s+$/g, ""); // trim
        str = str.toLowerCase();
        return str;
    }


    return (
        <div className="">
            <TopNavBar active="dashboard"></TopNavBar>
            <div className="mt-16 mb-16 w-full container mx-auto py-8 px-4">
                <div className="mb-8 flex justify-between items-center">
                    <div>
                        <p className="text-2xl font-semibold">{Translate("Dashboard")}</p>
                    </div>

                </div>

                {props.account.user.is_temporary == true ?
                    <div className="bg-white border p-6 mb-12 rounded-lg shadow-lg">
                        <p className="text-lg font-semibold mb-2">{Translate("temp_agent_welcome_title")}</p>
                        <p>{Translate("temp_agent_welcome_description")}</p>
                        {/* <div className="mt-4">
                            <a href={`/listing?query={"status":"draft"}`}><button className="px-4 py-1 border rounded inline-flex items-center text-blue-600 border-blue-600">
                                {Translate("temp_agent_view_draft_listing_button")}
                                <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                                </svg>
                                </button>
                            </a>
                        </div> */}
                        <div className="mt-12 inline-flex items-center">
                            <a href="mailto:hello@olto.co?subject=Welcom to Viila"><button className="bg-blue-600 text-white px-4 py-2 rounded mr-2 inline-flex items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                </svg>
                                {Translate("temp_agent_call_to_action_button")}
                            </button>
                            </a>
                            <a href={`${Constants.BASE_URL}/agents/${props.agentProfile.agent.agent_id}/${string_to_slug(props.agentProfile.company.name)}`} target="_blank">
                                <button className="bg-blue-100 text-blue-600 px-4 py-2 rounded inline-flex items-center">
                                    {Translate("temp_agent_view_agent_profile_button")}
                                    <svg xmlns="http://www.w3.org/2000/svg" className="ml-2 h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                    </svg>
                                </button>
                            </a>
                        </div>
                    </div>
                    : null
                }

                <main className="w-full">
                    <div className="grid lg:grid-cols-4 gap-6 mb-12">

                        <div className="rounded-lg border shadow-lg flex flex-col bg-white overflow-hidden">
                            <div className="p-6 flex-auto">
                                <p className="text-sm font-medium text-gray-500">{Translate("number_of_new_messages_title")}</p>
                                <p className="text-3xl font-bold">{props.statistics.number_of_new_messages}<span className="text-sm text-gray-500 font-semibold ml-2">/{props.statistics.number_of_messages}</span></p>
                            </div>
                            <Link to="/inbox" className="h-12 bg-gray-50 flex items-center mt-auto px-6 leading-none text-sm text-blue-600 font-semibold">{Translate("View all")}</Link>
                        </div>
                        <div className="rounded-lg border shadow-lg flex flex-col bg-white overflow-hidden">
                            <div className="p-6 flex-auto">
                                <p className="text-sm font-medium text-gray-500">{Translate("number_of_properties_title")}</p>
                                <p className="text-3xl font-bold">{props.statistics.number_of_properties}</p>
                            </div>
                            <div className="h-12 flex items-center mt-auto bg-gray-50 px-6">
                                <Link to="/listing" className=" leading-none text-sm text-blue-600 font-semibold">{Translate("View all")}</Link>
                                <Link to="/listing/steps" className="ml-auto leading-none text-sm text-blue-600 font-semibold inline-flex items-center">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                                    </svg>
                                    <span>{Translate("Add Property")}</span>
                                </Link>
                            </div>
                        </div>
                        <div className="rounded-lg border shadow-lg flex flex-col bg-white overflow-hidden">
                            <div className="p-6 flex-auto">
                                <p className="text-sm font-medium text-gray-500">{Translate("number_of_views_title")}</p>
                                <p className="text-3xl font-bold">{Number(props.statistics.total_views).toLocaleString()}</p>
                            </div>
                            <Link to="/listing" className="h-12 bg-gray-50 flex items-center mt-auto px-6 leading-none text-sm text-blue-600 font-semibold">{Translate("View all")}</Link>
                        </div>
                        <div className="rounded-lg border shadow-lg flex flex-col bg-white overflow-hidden">
                            <div className="p-6 flex-auto">
                                <p className="text-sm font-medium text-gray-500">{Translate("number_of_offers_title")}</p>
                                <p className="text-3xl font-bold">{props.statistics.number_of_offers}</p>
                            </div>
                            <Link to="/offers" className="h-12 bg-gray-50 flex items-center mt-auto px-6 leading-none text-sm text-blue-600 font-semibold">{Translate("View all")}</Link>
                        </div>
                    </div>
                    <div className=" border rounded-lg shadow-sm overflow-hidden">
                        <table className="table-auto w-full">
                            <thead>
                                <tr className="border-b bg-gray-100">
                                    <th className="text-left px-6 py-4 w-1/6">{Translate("Property Type")}</th>
                                    <th className="text-right px-6 py-4 w-1/6">{Translate("Active")}</th>
                                    <th className="text-right px-6 py-4 w-1/6">{Translate("Under negotiation")}</th>
                                    <th className="text-right px-6 py-4 w-1/6">{Translate("Sold")}</th>
                                    <th className="text-right px-6 py-4 w-1/6">{Translate("Inactive")}</th>
                                    <th className="text-right px-6 py-4 w-1/6">{Translate("Draft")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    props.statistics.number_of_properties_by_status.map((item, index) => (
                                        <tr key={`row-${index}`} className="border-b">
                                            <td className="text-left px-6 py-4"><a className="text-blue-600 font-medium" href={`/listing?query={"property_type":"${item.type}"}`}>{Translate(item.type)}</a></td>
                                            <td className="text-right px-6 py-4"><a className="text-blue-600 font-medium" href={`/listing?query={"property_type":"${item.type}","status":"active"}`}>{item.active}</a></td>
                                            <td className="text-right px-6 py-4"><a className="text-blue-600 font-medium" href={`/listing?query={"property_type":"${item.type}","status":"business_negotiation"}`}>{item.business_negotiation}</a></td>
                                            <td className="text-right px-6 py-4"><a className="text-blue-600 font-medium" href={`/listing?query={"property_type":"${item.type}","status":"sold"}`}>{item.sold}</a></td>
                                            <td className="text-right px-6 py-4"><a className="text-blue-600 font-medium" href={`/listing?query={"property_type":"${item.type}","status":"inactive"}`}>{item.inactive}</a></td>
                                            <td className="text-right px-6 py-4"><a className="text-blue-600 font-medium" href={`/listing?query={"property_type":"${item.type}","status":"draft"}`}>{item.draft}</a></td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className="">
                        {/* {props.account.user.is_viila_partner ? "yes" : "no"} */}
                        {/* <div className="inline-flex items-center">
                            <span className="font-semibold">Viila Partner Agent </span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="ml-1 text-blue-600 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                            </svg>
                        </div> */}
                        {/* <Pricing></Pricing> */}
                        {/* <p className="mb-">Listing benefit of joining Viila Partner Agent Here</p>

                        <div className="mt-4">
                            <button className="px-2 py-2 bg-blue-600 text-white font-semibold rounded">Join Viila Partner Agent</button>
                        </div> */}
                    </div>
                </main>
            </div>
        </div>
    )
}