import {_authorizedRequest,_request} from '../http'

export async function fetchProperties(query) {
    var url = "/properties"
    if (query !== null) {
        url = `/properties?query=${JSON.stringify(query)}`
    }
    return _authorizedRequest("GET", url, null)
}

export async function fetchPropertyDetail(id) {
    return _authorizedRequest("GET", `/properties/${id}`, null)
}

export async function createNewProperty(data) {
    return _authorizedRequest("POST", `/properties/viila`, data)
}

export async function updateProperty(data) {
    if (data.id === undefined) {
        throw new Error("id is empty");
    }
    return _authorizedRequest("POST", `/properties/${data.id}/viila`, data)
}

export async function updatePropertyPrivate(data) {
    return _authorizedRequest("POST", `/properties/private`, data)
}

export async function updatePropertyPin(data) {
    return _authorizedRequest("POST", `/properties/pin`, data)
}


export async function uploadImage(base64Image, fileName) {
    let data = {
        fileBase64: base64Image,
        fileName: fileName
    }
    return _authorizedRequest("POST", `/file/upload`, data)
}

export async function features(lang) {
    if (lang === "") {
        lang = "en"
    }
    return _authorizedRequest("GET", `/${lang}/features`, null)
}
export async function defaultFeatures() {
    return _authorizedRequest("GET", `/features/default`, null)
}


export async function fetchThreads() {
    return _authorizedRequest("GET", `/threads`, null)
}

export async function replyToThread(threadID, data) {
    return _authorizedRequest("POST", `/threads/${threadID}/reply`, data)
}

export async function profile() {
    return _authorizedRequest("GET", `/profile`, null)
}

export async function updateProfile(data) {
    return _authorizedRequest("PUT", `/profile`, data)
}


export async function inviteTeamMember(email,platform) {
    let data = {
       email: email,
       platform:platform,
    }
    return _authorizedRequest("POST", `/invitation/invite`, data)
}


export async function team() {
    return _authorizedRequest("GET", `/team`, null)
}

export async function deleteTeam(code) {
    return _authorizedRequest("DELETE", `/team/${code}`, null)
}

export async function offers() {
    return _authorizedRequest("GET", `/offers`, null)
}

export async function acceptOffer(offerId, data) {
    return _authorizedRequest("POST", `/offers/${offerId}/accept`, data)
}

export async function rejectOffer(offerId, data) {
    return _authorizedRequest("POST", `/offers/${offerId}/reject`, data)
}



export async function commentOffer(offerId, data) {
    return _authorizedRequest("POST", `/offers/${offerId}/reply`, data)
}


export async function offerDetail(code) {
    return _authorizedRequest("GET", `/offers/${code}`, null)
}


export async function invitation(code) {
    return _request("GET", `/invitations/${code}`, null)
}

export async function areas(lang) {
    if (lang === "") {
        lang = "en"
    }
    return _authorizedRequest("GET", `/${lang}/areas`, null)
}

export async function createNewSurvey(data) {
    return _authorizedRequest("POST", `/survey`, data)
}

export async function regions(lang) {
    if (lang === "") {
        lang = "en"
    }
    return _authorizedRequest("GET", `/${lang}/m/regions`, null)
}

export async function prefectures(lang) {
    if (lang === "") {
        lang = "en"
    }
    return _authorizedRequest("GET", `/${lang}/m/prefectures`, null)
}

export async function cities(lang) {
    if (lang === "") {
        lang = "en"
    }
    return _authorizedRequest("GET", `/${lang}/m/cities`, null)
}

export async function statistics() {
    return _authorizedRequest("GET", `/statistics`, null)
}

export async function viewStatistics(from,to,properties) {
    let data = {
        from : from,
        to : to,
        properties : properties
    }
    return _authorizedRequest("POST", `/statistics/views`, data)
}

export async function addEmailEvent(user_id,event) {
    let data = {
        user_id: user_id,
        event: event,
    }
    return _authorizedRequest("POST", `/invitation/email`, data)
}

export async function removeEmailEvent(user_id,event) {
    let data = {
        user_id: user_id,
        event: event,
    }
    return _authorizedRequest("DELETE", `/invitation/email`, data)
}

export async function emailEvents() {
    return _authorizedRequest("GET", `/events`, null)
}

export async function apartments(id) {
    return _authorizedRequest("GET", `/apartments/agentdashboard/${id.length === 0 ? 'all' : id}`, null)
}

export async function apartmentsCompact(id) {
    return _authorizedRequest("GET", `/apartments/agentdashboard/${id.length === 0 ? 'all' : id}/compact`, null)
}

export async function fetchApartmentDetail(id) {
    return _authorizedRequest("GET", `/apartments/all/detail/${id}`, null)
}

