
import React from "react"

import { Translate } from "../../translate/Translate";
import { MapIcon, BuildingOfficeIcon, HomeIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

export default function PropertyTypeBadge(props) {
    const {type, className, onlyIcon} = props

    function renderBadge(status) {
        switch (status) {
            case 'house':
                return (
                    <div className={clsx("font-medium flex items-center gap-1 text-sm rounded", className)}>
                        <HomeIcon className="flex-none w-5 h-5 text-slate-500"/><span className={clsx(onlyIcon ? "hidden" : "")}>{Translate("House")}</span>
                    </div>
                );
            case 'lot':
                return (
                    <div className={clsx("font-medium flex items-center gap-1 text-sm rounded", className)}>
                        <MapIcon className="flex-none w-5 h-5 text-slate-500"/><span className={clsx(onlyIcon ? "hidden" : "")}>{Translate("Land")}</span>
                    </div>
                );
            case 'apartment':
                return (
                    <div className={clsx("font-medium flex items-center gap-1 text-sm rounded", className)}>
                        <BuildingOfficeIcon className="flex-none w-5 h-5 text-slate-500"/><span className={clsx(onlyIcon ? "hidden" : "")}>{Translate("Apartment")}</span>
                    </div>
                );
            default:
                return '';
        }
    }

    return (
        renderBadge(type)
    )
}