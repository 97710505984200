


import Modal from "./Modal";
import { Translate } from "../v2/translate/Translate"
import ReactMarkdown from 'react-markdown'
import * as api from '../v2/services/agent'
export default function WhatsNewModal(props) {
    const { isOpen, onClose } = props

    function tryPropertyEasy() {
        api.tryPropertyEasy()
          .then(url => {
            window.open(url, "_blank")
          })
          .catch(error => {
            console.log(error);
          })
      }

    return (
        <Modal hideBottomBar={true} isPresented={isOpen} onClose={onClose} widthClass="max-w-2xl w-full">

            <div className="p-4 sticky top-0 font-medium border-b flex items-center gap-2 w-full">
                {Translate("What's new?")}
                <div className="ml-auto">
                    <button onClick={(e) => { onClose() }} className="btn-action">{Translate("Dismiss")}</button>
                </div>
            </div>

            <div className="prose prose-sm prose-a:text-blue-500 mx-auto w-full py-4 px-2 lg:px-0">
                <ReactMarkdown
                    components={{
                        a: ({ node, ...props }) => (
                            <a {...props} target="_blank" rel="noopener noreferrer" />
                        ),
                    }}
                >{Translate("new_06092024")}</ReactMarkdown>
            </div>
            <div className="prose prose-sm prose-a:text-blue-500 mx-auto w-full py-4 px-2 lg:px-0">
                <button onClick={() => { tryPropertyEasy() }} className="w-full bg-green-600 text-white px-2 py-2 font-semibold text-xs flex items-center justify-center rounded gap-2">
                    {Translate("Try PropertEasy For Free")}
                </button>
            </div>
        </Modal>
    )
}