import { CubeIcon, PhotoIcon, VideoCameraIcon } from "@heroicons/react/24/solid";
import React from "react"
import { Link } from "react-router-dom"
import { Translate } from "../../translate/Translate";
import PropertyNewStatusBadge from "./PropertyNewStatusBadge";
export function PinnedProperty(props) {
    return (
        <Link to={`/v2/properties/wizard/${props.data.property_id}/summary`}>
            <div className="flex overflow-hidden items-center">
                <div className="bg-gray-600 h-24 w-24 flex-none relative">
                    {
                        props.data.images.length === 0 ?
                            null
                            :
                            <img src={props.data.images[0].url} className="object-cover w-full h-full rounded" />
                    }
                    <div className="absolute top-0 left-0 p-1 flex flex-col items-start gap-1">
                        <div className="flex items-center text-xs bg-white rounded px-1 py-0.5">
                            <PhotoIcon className="w-3 h-3 mr-1" />
                            <span className="font-medium">{props.data.images.length}</span>
                        </div>

                        {
                            props.data.video.url ?
                                <div className="flex items-center text-xs bg-red-500 rounded px-1 py-0.5">
                                    <VideoCameraIcon className="w-3 h-3 text-white" />
                                </div>
                                : null
                        }
                        {
                            props.data.vr.url ?
                                <div className="flex items-center text-xs bg-indigo-500 rounded px-1 py-0.5">
                                    <CubeIcon className="w-3 h-3 text-white" />
                                </div>
                                : null
                        }

                    </div>
                </div>
                <div className="px-4 flex-1 flex flex-col truncate text-ellipsis ">
                    <div className="text-sm truncate text-ellipsis ">
                        <p className="font-medium truncate text-ellipsis ">{props.data.name}</p>
                        <p className="">{props.data.short_info}</p>
                    </div>
                    <div className="mb-1">
                        <p className="text-sm text-gray-600 font-medium">{new Intl.NumberFormat('ja-JP', { style: 'currency', currency: props.data.price.currency }).format(Number(props.data.price.amount))}</p>
                    </div>
                    <PropertyNewStatusBadge className="text-xs" status={props.data.status} />
                </div>
            </div>
        </Link>
    )
}


