import clsx from 'clsx';
import { Controller } from "react-hook-form";

export default function CheckboxField (props) {
    const {id, name, className, control, children} = props

    return (<Controller 
        name={name} 
        control={control} 
        defaultValue={''}
        render={({ field: {value, onChange }, fieldState: { invalid, isTouched, isDirty, error } }) => 
            (<div className="flex">
                <input id={id} name={name} type="checkbox" 
                className={clsx("h-4 w-4 text-primary focus:outline-none focus:border-none border-gray-300 rounded cursor-pointer", className)} 
                checked={value} 
                onChange={(e) => {
                    onChange(e.target.checked)
                }} />
                <div className="ml-2 cursor-pointer" onClick={() => {
                    onChange(!value)
                }}>
                {children}
                </div>
            </div>)
        } 
    />)
}