
import React from "react"
import SideNavBar from "../SideNavBar";
import TopSearchbar from "../TopSearchbar";
import { Translate } from "../../translate/Translate";
import * as Account from '../../../account';
import * as api from '../../services/agent'

export default function Tools(props) {

    return (
        <div className="w-full flex bg-slate-50">
            <SideNavBar {...props} isPresented={true} />
            <div className="flex flex-col w-full relative">

                <div className="bg-white">
                    <div className="w-full container mx-auto lg:px-8 px-4">

                        <div className="py-8">
                            <p className="text-3xl font-bold">{Translate("Tools")}</p>
                        </div>

                    </div>
                </div>
                <div className="lg:p-8 p-4 flex items-center container mx-auto">
                    {/* content starts here */}
                    {/* content ends here */}
                </div>
            </div>
        </div>
    );
}