import React, { useState, useEffect } from "react"
import * as Yup from "yup";

import { useHistory, useLocation } from 'react-router-dom';

import * as api from '../../services/agent'
import SideNavBar from "../SideNavBar";
import StepPrefectureArea from "./StepPrefectureArea";
import StepEssential from "./StepEssential";
import StepAddress from "./StepAddress";
import StepPropertyDetail from "./StepPropertyDetail";
import StepPropertyStory from "./StepPropertyStory";
import StepPhotos from "./StepPhotos";
import StepSummary from "./StepSummary";
import StepsWizard from "./StepsWizard";
import { Translate } from "../../translate/Translate";
import { stepPrefectureArea, stepEssential, stepAddress, stepPropertyStory, stepPropertyDetail, stepSummary } from "../../validators/property"
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';
import WizardFooter from "./WizardFooter"
import ScreenLoading from "../Components/ScreenLoading";
import AIGenerativeModal from "../Components/AIGenerativeModal";
import Error from '../Shared/Error'

const extractHash = (hash) => {
    const [hashOnly, query] = hash.split("?")
    
    return Number(hashOnly.replace("#", ""))
}

export default function NewPropertyStepsWizard(props) {

    let history = useHistory();
    const [dataLoaded, setDataLoaded] = useState(false)
    const [isLoading, setLoading] = useState(true)
    const [showError, setShowError] = useState(false);
    // const [errors, setErrors] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [areas, setArea] = useState({})
    const [subareas, setSubareas] = useState({

    })
    const [step, setCurrentStep] = useState(history.location.hash ? extractHash(history.location.hash) : 0);
    const [farestStep, setFarestStep] = useState(history.location.hash ? extractHash(history.location.hash) : 0);

    const search = useLocation().search;
    const [dataFromQueryString, setDataFromQueryString] = useState(JSON.parse(new URLSearchParams(search).get('data')) ?? null);

    const totalStep = 6

    const stepTitles = {
        0 : Translate("Area"),
        1 : Translate("Property Info"),
        2 : Translate("Address"),
        3 : Translate("Property Details"),
        4 : Translate("Overview"),
        5 : Translate("Photos"),
        6 : Translate("Summary"),

    }
    const next = () => {
        if (farestStep < step + 1) {
            setFarestStep(step + 1)
        }
        setCurrentStep(step + 1)
    }
    const goto = (step) => {
        if (farestStep < step) {
            setFarestStep(step)
        }
        setCurrentStep(step)
    }
    const back = () => {
        setCurrentStep(step - 1)
    }
    const useStep = {
        step, totalStep, next, back, goto, farestStep
    }

    Yup.setLocale({
        mixed: {
            default: 'is invalid',
            required: 'is a required field',
            defined: 'must be defined',
            notNull: 'cannot be null',
            oneOf: 'must be one of the following values: ${values}',
            notOneOf: 'must not be one of the following values: ${values}'
        },
        string: {
            length: 'must be exactly ${length} characters',
            min: 'must be at least ${min} characters',
            max: 'must be at most ${max} characters',
            matches: 'must match the following: "${regex}"',
            email: 'must be a valid email',
            url: 'must be a valid URL',
            uuid: 'must be a valid UUID',
            trim: 'must be a trimmed string',
            lowercase: 'must be a lowercase string',
            uppercase: 'must be a upper case string',
        },
        number: {
            min: 'must be greater than or equal to ${min}',
            max: 'must be less than or equal to ${max}',
            integer: 'should have digits only',
        },
    })

    useEffect(() => {
        history.replace("#" + step)
        document.title = stepTitles[step] + " | Viila Agent Dashboard"
    }, [step, history]);

    const getDefaultFeatures = (type) => {
        const findFeature = (key, index) => {
            var data = props.featureList.find(f => f.key === key)
            if (data) return { data, key: data.key, type: data.type, title: data.title, index: index, value: data.values && data.values[0] ? data.values[0].value : "" };
            return { key: key, type: "text", title: key, index: index, value: "" };
        }

        var features = []

        if (props.defaultFeatures[type] !== undefined) {
            features.push(...props.defaultFeatures[type].map((e, index) => { return findFeature(e, index) }))
        }

        return features
    }

    const emptyData = {
        status: "active",
        transaction_type: "brokerage_general",
        property_type: "house",
        is_private: false,
        privacy_mode: false,
        city: {
            id: "karuizawa",
        },
        area: {
            id: "karuizawa",
            level: 1,
        },
        address: {},
        prefecture: {
            id: "nagano"
        },
        price: {
            amount: "",
            currency: "JPY"
        },
        land: {},
        layout: {},
        other_costs: [],
        tax_histories: [],
        tax_histories_delete: [],
        features: getDefaultFeatures("house"),
        viewport: {
            latitude: 36.3418518,
            longitude: 138.6179432,
            zoom: 12,
        },
        latlng: {
            latitude: 36.3418518,
            longitude: 138.6179432,
        },
        built: {
            year: `${new Date().getFullYear()}`,
            month: ''
        },
        document: {
            files: [],
            data: {}
        },
        images: [],
        archived_images: [],
        lotnumber: {
            type: "FeatureCollection",
            features: []
        },
    }

    const [data, setData] = useState({ ...emptyData });
    const [nearby, setNearby] = useState({
        fetched: false,
        walking: [],
        driving: [],
    })
    const [aiDialogIsOpen,setAIDialogIsOpen] = useState(false)

    const createNewProperty = (property) => {
        setLoading(true)
        if (property.sqm)
            property.sqm = parseFloat(property.sqm)
        else
            property.sqm = 0

        if (property.property_type == "lot") {
            property.layout = {}
        }

        if (property.land && property.land.sqm)
            property.land.sqm = parseFloat(property.land.sqm)
        else
            property.land = { sqm: 0 }

        if (property.latlng && property.latlng.latitude)
            property.latlng.latitude = parseFloat(property.latlng.latitude)

        if (property.latlng && property.latlng.longitude)
            property.latlng.longitude = parseFloat(property.latlng.longitude)

        if (property.built) {
            if (property.built.year === "") {
                delete property.built.year
            } else {
                property.built.year = +property.built.year
            }
            if (property.built.month === "") {
                delete property.built.month
            } else {
                property.built.month = +property.built.month
            }
        }
        if (property.price && Number.isFinite(property.price.amount))
            property.price.amount = `${property.price.amount}`

        property.other_costs = property.other_costs.map((c) => {
            c.price.amount = `${c.price.amount}`
            return c
        })
        
        if (property.property_type === "apartment") {
            property.features = [...(property.apartments || []), ...property.features]
        }

        if (property.layout.bedrooms) {
            try {
                property.layout.bedrooms = parseInt(property.layout.bedrooms)
            } catch (error) {
                console.error("errror bathrooms", error)
            }
        }
        if (property.layout.bathrooms) {
            try {
                property.layout.bathrooms = parseInt(property.layout.bathrooms)
            } catch (error) {
                console.error("errror bathrooms", error)
            }
        }

        api.createNewProperty(property)
            .then(response => {
                setTimeout(() => {
                    localStorage.removeItem("new_property")
                    history.push(`/v2/properties/wizard/${response}/summary`);
                }, 250)
            })
            .catch(error => {
                setLoading(false)
                setShowError(true)
                setErrorMessage(error.data.message)
            })
    }

    const publishProperty = (data) => {
        createNewProperty({ ...data, status: "active" })
    }

    const saveAsDraft = (data) => {
        createNewProperty({ ...data, status: "draft" })
    }

    const resetData = () => {
        if (window.confirm("Would you like to reset the whole property data?") == true) {
            localStorage.removeItem("new_property")
            setData({ ...emptyData })
            setFarestStep(0)
            goto(0)
            setTimeout(() => {
                window.location.reload()
            }, 250)
        }
    }


    const [apartments, setApartments] = React.useState({});
    const loadApartments = (area) => {
        return api.apartments(area, props.language)
            .then(response => {
                setApartments((apartments) => ({...apartments,[area]:response}))
            })
            .catch(error => {
                setShowError(true)
                setErrorMessage(error.data.message)
            })
    }



    useEffect(() => {
        const handleTabClose = event => {
            event.preventDefault();
            console.log('beforeunload event triggered');

            // const { getValues } = steps[step].useFormReturn
            // setData((d) => ({ ...d, ...getValues() }))

            return (event.returnValue = 'Are you sure you want to exit?');
        };

        window.addEventListener('beforeunload', handleTabClose);

        return () => {
            window.removeEventListener('beforeunload', handleTabClose);
        };
    }, [])

    useEffect(() => {
        let cache = null

        if (localStorage.getItem("new_property")) {
            cache = JSON.parse(localStorage.getItem("new_property"))
        }

        if (dataFromQueryString !== null)  {
            cache = { ...emptyData, ...dataFromQueryString }
            localStorage.removeItem("new_property")
        }

        if (cache != null) {
            setData(cache)
            if (cache.area.id !== "" && cache.property_type === "apartment") {
                return loadApartments(cache.area.id)
                    .then(() => {
                        setDataLoaded(true)
                        setLoading(false)
                    })
            } 
            setDataLoaded(true)
            setLoading(false)
        }
         else {
            setData({...emptyData})
            setDataLoaded(true)
            setLoading(false)
        }
        
    }, [])

    useEffect(() => {
        if (dataLoaded && data) {
            localStorage.setItem("new_property", JSON.stringify(data))
        }
    }, [dataLoaded, data])

    useEffect(() => {
        if (step == 1 && data.area.id !== "" && !apartments[data.area.id]) {
            loadApartments(data.area.id)
        }
    }, [data.area, step])

    const handleGoto = (index) => {
        if (index > farestStep) {
            return
        }

        if (index > step) {
            if (!steps[step])
                return

            if (!steps[step].useFormReturn) {
                goto(index)
                return
            }
            
            const { handleSubmit } = steps[step].useFormReturn
            handleSubmit((d) => {
                setData({ ...data, ...d })
                goto(index)
            })()
        } else {
            if (!steps[step])
                return

            if (!steps[step].useFormReturn) {
                goto(index)
                return
            }
            const { getValues } = steps[step].useFormReturn
            setData((d) => ({ ...d, ...getValues() }))
            goto(index)
        }
    }

    const handleBack = () => {
        if (!steps[step])
            return

        if (!steps[step].useFormReturn) {

            if (steps[step-1].useFormReturn) {
                const { reset } = steps[step-1].useFormReturn
                reset(data)
            }
            back()

            return
        }
    
        const { getValues } = steps[step].useFormReturn
        setData((d) => ({ ...d, ...getValues() }))
        if (steps[step-1].useFormReturn) {
            const { reset } = steps[step-1].useFormReturn
            reset({ ...data, ...getValues() })
        }
        
        back()
    }
    const handleNext = () => {
        if (!steps[step])
            return

        if (!steps[step].useFormReturn) {
            next()
            return
        }
        
        const { handleSubmit } = steps[step].useFormReturn
        handleSubmit((d) => {
            setData({ ...data, ...d })
            next()
        }, (err) => {
            console.log('error', err)
        })()
    }
    const handleDraft = () => {
        if (!steps[step])
            return

        if (!steps[step].useFormReturn) {
            saveAsDraft(data)
            return
        }

        const { handleSubmit } = steps[step].useFormReturn
        handleSubmit((d) => {
            saveAsDraft({ ...data, ...d })
        })()
    }

    const handlePublish = () => {
        const { handleSubmit } = steps[step].useFormReturn
        handleSubmit((d) => {
            publishProperty({ ...data, ...d })
        })()

    }

    const validates = [
        useForm({
            resolver: yupResolver(stepPrefectureArea),
            // mode: "onBlur",
            // reValidateMode: 'onBlur',
            defaultValues: data
        }),
        useForm({
            resolver: yupResolver(stepEssential),
            // mode: "onBlur",
            // reValidateMode: 'onBlur',
            defaultValues: data
        }),
        useForm({
            resolver: yupResolver(stepAddress),
            mode: "onBlur",
            reValidateMode: 'onBlur',
            defaultValues: data
        }),
        useForm({
            resolver: yupResolver(stepPropertyStory),
            mode: "onBlur",
            reValidateMode: 'onBlur',
            defaultValues: data
        }),
        useForm({
            resolver: yupResolver(stepPropertyDetail),
            mode: "onBlur",
            reValidateMode: 'onBlur',
            defaultValues: data
        }),
        undefined,
        useForm({
            resolver: yupResolver(stepSummary),
            mode: "onBlur",
            reValidateMode: 'onBlur',
            defaultValues: data
        })

    ]

    const steps = [
        {
            name: Translate("Area"),
            view: (<StepPrefectureArea {...props} {...{ useStep, handleBack, handleNext, handleDraft, handlePublish, data, setData, areas, setArea, subareas, setSubareas }} useFormReturn={validates[0]} />),
            useFormReturn: validates[0]
        },
        {
            name: Translate("Property Info"),
            view: (<StepEssential {...props} {...{ useStep, handleBack, handleNext, handleDraft, handlePublish, data, setData, apartments }} useFormReturn={validates[1]} />),
            useFormReturn: validates[1]
        },
        {
            name: Translate("Address"),
            view: (<StepAddress {...props} {...{ useStep, handleBack, handleNext, handleDraft, handlePublish, data, setData, apartments, setNearby }} useFormReturn={validates[2]} />),
            useFormReturn: validates[2]
        },
        {
            name: Translate("Property Details"),
            view: (<StepPropertyDetail {...props} {...{ useStep, handleBack, handleNext, handleDraft, handlePublish, data, setData, apartments }} useFormReturn={validates[4]} />),
            useFormReturn: validates[4]
        },
        {
            name: Translate("Overview"),
            view: (<StepPropertyStory {...props} {...{ useStep, handleBack, handleNext, handleDraft, handlePublish, data, setData, apartments, setAIDialogIsOpen: () => {
                if (nearby.fetched) {
                    setAIDialogIsOpen(true)
                } else {
                api.fetchPlaceNearby({ lat: data.latlng.latitude, lng: data.latlng.longitude, area_id: data.area.id })
                    .then(resp => {
                        setNearby({...resp, fetched: true})
                        setAIDialogIsOpen(true)

                    })
                }
            } }} useFormReturn={validates[3]} />),
            useFormReturn: validates[3]
        },
        {
            name: Translate("Photos"),
            view: (<StepPhotos {...props} {...{ useStep, handleBack, handleNext, handleDraft, handlePublish, data, setData }} useFormReturn={validates[5]} />),
            useFormReturn: validates[5]
        },
        {
            name: Translate("Summary"),
            view: (<StepSummary {...props} {...{ useStep, handleBack, handleNext, handleDraft, handlePublish, data, setData, apartments }} useFormReturn={validates[6]} />),
            useFormReturn: validates[6]
        }
    ]

    return (
        <div className="absolute inset-0 w-full h-full flex bg-slate-50">
            <SideNavBar {...props} isPresented={true} />
            <div className="w-full h-full flex flex-col overflow-hidden" onClick={e => {
                if (!steps[step])
                    return

                if (steps[step].useFormReturn) {
                    const { clearErrors } = steps[step].useFormReturn
                    clearErrors()
                }
            }}>
                <StepsWizard {...props} {...{ useStep, steps, handleGoto }} />
                {dataLoaded && steps[step] ? steps[step].view : (
                    <div className="inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-lg text-white bg-indigo-500 hover:bg-indigo-400 transition ease-in-out duration-150 cursor-not-allowed" disabled>
                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        Loading...
                    </div>
                )}
                <WizardFooter {...props} {...{ steps, useStep, handleBack, handleNext, handleDraft, handlePublish, resetData }} />
            </div>
            {isLoading && (<ScreenLoading {...props} />)}
            {aiDialogIsOpen ? (<AIGenerativeModal {...props} data={data} nearby={nearby} isOpen={aiDialogIsOpen} setIsOpen={setAIDialogIsOpen} updateProperty={({name, overview}) => {
                const {setValue} = validates[3]
                setValue("name", name)
                setValue("overview", overview)
                setAIDialogIsOpen(false)
            }} />): null}
            <Error isPresented={showError} errorMessage={errorMessage} dismiss={(e) => { setShowError(false) }}></Error>
        </div>
    )
}