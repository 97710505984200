import { useEffect, useState } from "react"
import moment from "moment"
import { Translate } from "../../translate/Translate";
import { customerPreferenceDetail } from "../../services/agent";
import PreferenceItem from "./PreferenceItem";
import PropertyInsight from "./PropertyInsight";
import { ChevronLeftIcon, ChevronRightIcon, EyeSlashIcon } from "@heroicons/react/24/solid"
import CustomerInsightRow from "../Components/CustomerInsightRow";

export default function PreferenceRow(props) {

    const { preference, customer, stats, index, max } = props
    const { handleSelectedPreference } = props

    // const [matchProperties, setMatchProperties] = useState({ items: [], total_item: 0 })
    // const [sentProperties, setSentProperties] = useState({ items: [] })

    // useEffect(() => {
    //     async function fetchData() {
    //         if (customer && preference?.code != "" && customer.customer_no != "") {
    //             customerPreferenceDetail(customer.customer_no, preference?.code).then((response) => {
    //                 setMatchProperties(response)
    //             })

    //         }
    //     }
    //     fetchData()
    // }, [preference, customer])


    return (<div className="m-4">
        <div className="flex mb-2">
            <p>
                <span className="font-semibold">{Translate("Preference")}</span>
            </p>

        </div>
        <p className="text-sm font-medium text-gray-500">{Translate("Area")} <ul className="ml-4 mb-2 list-disc">{preference?.areas?.length == 0 || !preference || !preference.areas ? Translate("not specificed")
            : preference?.areas?.map((area) => { return <li className="ml-4">{Translate(area)}</li> })}</ul>
        </p>
        <p className="text-sm font-medium text-gray-500">{Translate("Budget")} <ul className="ml-4 mb-2 list-disc"> <li className="ml-4">{preference && preference?.budget > 0 ? new Intl.NumberFormat('ja-JP', { style: 'currency', currency: "JPY" }).format(Number(preference?.budget)) : Translate("not specificed")}</li></ul></p>
        <p className="text-sm font-medium text-gray-500">{Translate("I'm looking for")}   <ul className="ml-4 mb-2 list-disc">{preference?.looking_for_property?.length == 0 || !preference || !preference.looking_for_property ? Translate("not specificed")
            : preference?.looking_for_property?.map((l) => { return <li className="ml-4">{Translate(l)}</li> })}</ul>
        </p>
        <p className="text-sm font-medium text-gray-500">{Translate("Purposes")} <ul className="ml-4 mb-2 list-disc">{preference?.purposes?.length == 0 || !preference || !preference.purposes ? Translate("not specificed")
            : preference?.purposes.map((l) => { return <li className="ml-4">{Translate(l)}</li> })}</ul>
        </p>
        <p className="text-sm font-medium text-gray-500">{Translate("Stage")}<ul className="ml-4 mb-2 list-disc"><li className="ml-4">{Translate(preference?.stage)}</li></ul></p>

        {/* <div className="flex mb-2 mt-4 border-t-2">
            <p className="font-semibold mt-4">{Translate("Sent properties")} ({matchProperties?.items?.length})</p>
        </div>

        <div>

            {
                matchProperties?.items?.map((property) => {
                    return <CustomerInsightRow key={property.id} value={{ property: property }} />
                })
            }
        </div> */}

        <div className="mt-8 inline-flex text-gray-500 font-sm gap-4 justify-center items-center flex flex-inline w-full">
            {index != 0 ? (<button className="bg-blue-500 text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white" onClick={(e) => { handleSelectedPreference(index - 1) }}>
                <ChevronLeftIcon className='w-4 h-4'></ChevronLeftIcon>
            </button>) : null}
            <p className="text-xs">{moment(preference?.updated_at).format("LL")}</p>
            {/* {preference?.deleted_at ? <div id="status-badge-sold" className="font-medium flex items-center text-sm rounded whitespace-nowrap text-red-600">
                    <EyeSlashIcon className="flex-none w-4 h-4 mr-1" /><span className="hidden lg:block">{Translate("Inactive")}</span>
                </div> : null} */}
            {index != max - 1 ? (<button className="bg-blue-500 text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white" onClick={(e) => { handleSelectedPreference(index + 1) }}>
                <ChevronRightIcon className='w-4 h-4'></ChevronRightIcon>
            </button>) : null}
        </div>

    </div>)
}