
import React, { useEffect,useState } from "react"
import * as api from '../../services/agent'
import SideNavBar from "../SideNavBar";
import { CurrencyYenIcon, RectangleStackIcon, ClockIcon,HomeIcon,BuildingOfficeIcon, MapIcon } from "@heroicons/react/24/outline";

export default function RevenueReports(props) {

    const [revenue, setRevenue] = useState({
        number_of_sold_properties: 0,
        properties: [],
        sold_reports_by_type: {
            apartment: {
                number_of_sold_properties: 0,
                percentage_of_sold_properties: 0,
                sold_days_on_viila: 0,
                sum_of_sold_properties_price: 0,
            },
            lot: {
                number_of_sold_properties: 0,
                percentage_of_sold_properties: 0,
                sold_days_on_viila: 0,
                sum_of_sold_properties_price: 0,
            },
            house: {
                number_of_sold_properties: 0,
                percentage_of_sold_properties: 0,
                sold_days_on_viila: 0,
                sum_of_sold_properties_price: 0,
            },
        },
        sum_of_sold_properties_price: 0,
    })

    useEffect(() => {
        api.soldStatistics()
            .then((response) => {
                setRevenue(response)
            })
    },[])

    const {Translate} = props
    return (
        <div className="w-full flex bg-slate-50">
            <SideNavBar {...props} isPresented={true} />
            <div className="flex flex-col w-full relative">
                <div className="bg-white">
                    <div className="w-full container mx-auto lg:px-8 px-4">
                        <div className="py-8">
                            <p className="text-3xl font-bold">{Translate("Revenue")}</p>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full max-w-screen-2xl mx-auto p-4 space-y-2">
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                        <div className="rounded-lg shadow bg-white p-4">
                            <div className="flex items-center mb-4">
                                <p className="text-sm font-semibold text-slate-500">{Translate("Property Value")}</p>
                                <div className="ml-auto">

                                </div>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="rounded-full bg-green-100 p-2">
                                    <CurrencyYenIcon className="w-6 h-6 text-green-600" />
                                </div>
                                <div className="flex items-center gap-4">
                                    <p className="text-2xl font-bold ">{new Intl.NumberFormat('ja-JP',{ style: 'currency', currency: "JPY" }).format(Number(revenue.sum_of_sold_properties_price/10000))} 万円</p>
                                </div>
                            </div>
                        </div>

                        <div className="rounded-lg shadow bg-white p-4">
                            <div className="flex items-center mb-4">
                                <p className="text-sm font-semibold text-slate-500">{Translate("Sold property")}</p>
                                <div className="ml-auto">

                                </div>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="rounded-full bg-sky-100 p-2">
                                    <RectangleStackIcon className="w-6 h-6 text-sky-600" />
                                </div>
                                <div className="flex items-center gap-4">
                                    <p className="text-2xl font-bold ">
                                        {new Intl.NumberFormat('en-US').format(Number(revenue.number_of_sold_properties))}
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="rounded-lg shadow bg-white p-4">
                            <div className="flex items-center mb-4">
                                <p className="text-sm font-semibold text-slate-500">{Translate("avg_time_on_viila")}</p>
                                <div className="ml-auto">

                                </div>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="rounded-full bg-yellow-100 p-2">
                                    <ClockIcon className="w-6 h-6 text-yellow-600" />
                                </div>
                                <div className="flex items-center gap-4">
                                    <p className="text-2xl font-bold ">
                                        3 weeks
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
                        <div className="rounded-lg shadow bg-white p-4">
                            <div className="flex items-center mb-4">
                                <p className="text-sm font-semibold text-slate-500">{Translate("House")}</p>
                                <div className="ml-auto">
                                </div>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="rounded-full bg-blue-100 p-2">
                                    <HomeIcon className="w-6 h-6 text-blue-600" />
                                </div>
                                <div>
                                    <p className="text-2xl font-bold">{revenue.sold_reports_by_type.house.percentage_of_sold_properties} % ({revenue.sold_reports_by_type.house.number_of_sold_properties})</p>
                                </div>
                            </div>
                        </div>

                        <div className="rounded-lg shadow bg-white p-4">
                            <div className="flex items-center mb-4">
                                <p className="text-sm font-semibold text-slate-500">{Translate("Apartment")}</p>
                                <div className="ml-auto">
                                </div>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="rounded-full bg-violet-100 p-2">
                                    <BuildingOfficeIcon className="w-6 h-6 text-violet-600" />
                                </div>
                                <div>
                                    <p className="text-2xl font-bold">{revenue.sold_reports_by_type.apartment.percentage_of_sold_properties} % ({revenue.sold_reports_by_type.apartment.number_of_sold_properties})</p>
                                </div>
                            </div>
                        </div>

                        <div className="rounded-lg shadow bg-white p-4">
                            <div className="flex items-center mb-4">
                                <p className="text-sm font-semibold text-slate-500">{Translate("Land")}</p>
                                <div className="ml-auto">
                                </div>
                            </div>
                            <div className="flex items-center gap-2">
                                <div className="rounded-full bg-lime-100 p-2">
                                    <MapIcon className="w-6 h-6 text-lime-600" />
                                </div>
                                <div>
                                    <p className="text-2xl font-bold">{revenue.sold_reports_by_type.lot.percentage_of_sold_properties} % ({revenue.sold_reports_by_type.lot.number_of_sold_properties})</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}