
import React from "react"
import { Translate } from "../../translate/Translate";

export function SellRequestStatusBadge(props) {

    function renderBadge(status) {
        switch (status) {
            case 'pending':
                return <p className="p-2 text-md font-bold text-yellow-500">{Translate("Pending")}</p>;
            case 'proposal_accepted':
                return <p className="p-2 text-md font-bold text-green-500">{Translate("Accepted") + (props.accepted ? "" : " · " + Translate("another agent"))}</p>;
            default:
                return <p className="p-2 text-md font-bold text-gray-500">{Translate(status)}</p>;
        }
    }

    return (
        renderBadge(props.status)
    )
}