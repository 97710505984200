import { EyeIcon, XCircleIcon, CheckCircleIcon } from "@heroicons/react/24/solid";
import moment from "moment"
import { useEffect, useState } from "react"
import * as api from '../../../services/agent'
import AppContext from "../../../../AppContext";
export default function ProposalBox(props) {
    const { Translate, language, proposal, activities } = props

    const getActivity = (status) => {
        return activities.find((a) => a.activity === status)
    }
    const [servicesProvide, setServicesProvide] = useState({})

    const declinedActivity = getActivity("proposal_declined")
    const acceptedActivity = getActivity("proposal_accepted")
    function getServicesProvided() {
        api.servicesProvide(language)
            .then((res) => {
                setServicesProvide(res.data)
            })
            .catch((err) => {
                alert(err.data.message)
            })
    }


    useEffect(() => {
        getServicesProvided()
    }, [])

    return (<>

        <div className="px-5 py-5 border-b w-full flex items-center">
            <div>
                <p className="text-lg font-medium">{Translate("proposal_box_proposal")}</p>
                <p className="text-xs text-gray-500">{Translate("proposal_box_your_proposal_has_been_sent")}</p>
            </div>

            <div className="ml-auto">
                {proposal.status == "accepted" && (<div className="flex flex-col ">
                    <div className="flex justify-start">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-6 h-6 text-green-500 mr-1">
                            <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
                        </svg>
                        <div className="ml-2">
                            <p className="text-green-500  font-semibold">{Translate("proposal_box_accepted")}</p>
                            <p className="text-xs text-gray-500">{moment(proposal.updated_at).format('LLL')}</p>
                        </div>
                    </div>
                </div>)}
                {proposal.status == "declined" && (<div className="flex flex-col ">
                    <div className="flex justify-start">
                        <XCircleIcon className="w-6 h-6 text-red-500" />
                        <div className="ml-2">
                            <p className="text-red-500  font-semibold">{Translate("proposal_box_declined")}</p>
                            <p className="text-xs text-gray-500">{moment(proposal.updated_at).format('LLL')}</p>
                        </div>
                    </div>
                </div>)}
            </div>
        </div>

        <div className="grid grid-cols-2 p-5 gap-5">

            <div className="w-full flex flex-col items-start">
                <div>
                    <p className="text-sm font-medium text-gray-500">{Translate("proposal_box_your_estimated")}</p>
                </div>
                <div className=" text-primary">
                    <p className="font-bold ">
                        {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: "JPY" }).format(Number(proposal.min_price.amount))}
                        <span className="px-1">~</span>
                        {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: "JPY" }).format(Number(proposal.max_price.amount))}
                    </p>
                </div>
            </div>
            <div className="w-full flex flex-col items-start">
                <div>
                    <p className="text-sm font-medium text-gray-500">{Translate("proposal_box_commision_fee")}</p>
                </div>
                <div className="">
                    <p className="font-medium">{proposal.commission}%</p>
                </div>
            </div>

            <div className="w-full flex flex-col items-start">
                <div>
                    <p className="text-sm font-medium text-gray-500">{Translate("proposal_box_submitted_date")}</p>
                </div>
                <div className="">
                    <p className="font-medium">{moment(proposal.proposed_at).format("LLL")}</p>
                </div>
            </div>
            <div className="w-full flex flex-col items-start">
                <div>
                    <p className="text-sm font-medium text-gray-500">{Translate("proposal_box_by")}</p>
                </div>
                <div className="">
                    <p className="font-medium">{proposal.user.last_name} {proposal.user.first_name}</p>
                </div>
            </div>

            <div className="w-full flex flex-col items-start ">
                <div>
                    <p className="text-sm font-medium text-gray-500">{Translate("proposal_box_remarks")}</p>
                </div>
                <div className="py-1 flex overflow-y-auto max-h-96">
                    <p className="whitespace-pre-wrap">
                        {proposal.remarks}
                    </p>
                </div>
            </div>
            <div className="w-full flex flex-col items-start ">
                <div>
                    <p className="text-sm font-medium text-gray-500">{Translate("proposal_box_services_offered")}</p>
                </div>
                <div className="">
                    {servicesProvide && proposal.services_offered ? proposal.services_offered.map((c, index) => (
                        <div key={`${c}-title`} className="flex items-center mt-2">
                            <span dangerouslySetInnerHTML={{ __html: servicesProvide[c] && servicesProvide[c].icon }}></span>
                            <p className="leading-none">{servicesProvide[c] && servicesProvide[c].title}</p>
                        </div>
                    )): "-"}

                </div>
            </div>
        </div>
        {proposal.status === "declined" && declinedActivity && <div className="p-5 border-t bg-gray-50">

            <div className="flex items-center gap-2">
                <XCircleIcon className="w-6 h-6 text-red-500" />
                <p className="font-medium">{Translate("seller_declined")}</p>

            </div>
            <p className="mt-3">
                {declinedActivity?.data?.remarks.length > 0 && `"${declinedActivity?.data?.remarks}"`}
            </p>
            <p className="text-xs text-gray-500 mt-3">{moment(declinedActivity.created_at).format('LLL')}</p>
        </div>}
        {proposal.status === "accepted" && <div className="p-5 border-t bg-gray-50">

            <div className="flex items-center gap-2">
                <CheckCircleIcon className="w-6 h-6 text-green-500" />
                <p className="font-medium">{Translate("seller_accepted")}</p>

            </div>
            <p className="mt-3">
                {proposal?.data?.remarks.length > 0 && `"${proposal?.data?.remarks}"`}
            </p>
            <p className="text-xs text-gray-500 mt-3">{moment(acceptedActivity.created_at).format('LLL')}</p>
        </div>}
        <div className="p-5 border-t bg-gray-50">
            <div className="flex items-center gap-2 text-sm">
                <EyeIcon className="w-4 h-4 text-gray-500" />
                <p className="text-xs  text-gray-500">{Translate("proposal_box_your_proposal_has_been_viewed")} {proposal.number_of_view} {Translate("proposal_box_times")}</p>
            </div>
        </div>
    </>)
}