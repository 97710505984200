import * as Constants from '../../constants'
import * as Account from '../../account';
import * as api from '../services/agent'
export async function _get(url) {
    var options = {
        method: "GET",
        headers: {
            'Content-Type': 'application/json',
        },
    }
    const response = await fetch(url, options);

    let status = response.status;

    if (status !== 200) {
        let text = await response.text()
        throw new Error(text);
    }

    let data = await response.json()
    return data;
}

export async function _authorizedRequest(method, resourceUrl, postBody) {
    return makerequest(method, resourceUrl, postBody, true)
}

export async function _authorizedDownloadRequest(method, resourceUrl, postBody) {
    var acc = Account.getUserAccount();
    let headers = {
        'Content-Type': 'application/json',
        'Accept': 'text/csv',
    }

    if (acc === null) {
        throw new Error("account is empty")
    }
    headers["Authorization"] = `Bearer ${acc.access_token}`;

    var options = {
        method: method,
        headers: headers,
        body: postBody !== null && postBody !== undefined ? JSON.stringify(postBody) : null
    }

    return fetch(Constants.API_BASE_URL + resourceUrl, options).then((res) => res.blob()).then((data) => {
        var a = document.createElement("a");
        a.href = window.URL.createObjectURL(data);
        a.download = "export.csv";
        a.click();
    })
}

export async function _request(method, resourceUrl, postBody) {
    return makerequest(method, resourceUrl, postBody, false)
}

async function makerequest(method, resourceUrl, postBody, authorizedRequest) {
    var acc = Account.getUserAccount();
    let headers = {
        'Content-Type': 'application/json'
    }

    if (authorizedRequest === true) {
        if (acc === null) {
            throw new Error("account is empty")
        }
        headers["Authorization"] = `Bearer ${acc.access_token}`;
    }

    var options = {
        method: method,
        headers: headers,
        body: postBody !== null && postBody !== undefined ? JSON.stringify(postBody) : null
    }

    const response = await fetch(Constants.API_BASE_URL + resourceUrl, options);
    let status = response.status;
    if (status === 401) {
        //unautorized
        //clear localStorage
        localStorage.removeItem(Account.accountSessionKey)
        window.location.href = "/"
        return null;
    }

    if (status === 505) {
        //HTTP Version Not Supported
        //clear localStorage
        localStorage.removeItem(Account.accountSessionKey)
        window.location.href = "/login"
        return null;
    }

    if (status === 402) {
        //Payment Required
        // if (window.confirm("25% of our clients use paid feature,Would you like to subscribe?") == true) {
        //     api.startSubscription()
        //         .then(checkout_url => {
        //             window.open(checkout_url, "_self")
        //         })
        //         .catch(error => {
        //             console.log(error);
        //         })
        // }
        window.open("/", "_self")
        return null;
    }


    if (status !== 200) {
        let error = new Error();
        let isParseError = false
        try {
            let e = await response.json()
            error = { ...error, statusCode: status, data: e };
        } catch (e) {
            isParseError = true
        }

        if (isParseError) {
            error = { ...error, statusCode: status, data: { message: response.statusText } };
            throw error;
        }

        throw error;

    }

    let data = await response.json()
    return data;
}
