import { ArrowDownCircleIcon, ArrowUpCircleIcon } from "@heroicons/react/24/solid";

export default function DateRange({ value }) {
    
    if (Number(value) == 0 || value === undefined) {
        return <p className="text-xs">
            <span className=" text-slate-400 font-bold">{new Intl.NumberFormat('en-US').format(Number(0))}%</span>
        </p>
    }

    return (
        <div className="flex items-center gap-1 text-xs">
            { }
            <span className="">
                {value > 0 ? <ArrowUpCircleIcon className="w-4 h-4 text-teal-500" /> : null}
                {value < 0 ? <ArrowDownCircleIcon className="w-4 h-4 text-red-500" /> : null}
            </span>
            <p>
                <span className={` ${value > 0 ? "text-teal-500" : "text-red-500"} font-bold`}>{new Intl.NumberFormat('en-US').format(Number(value).toPrecision(2))}%</span>
            </p>
        </div>
    )
}