import React from "react"
import { PinnedProperty } from "./PinnedProperty";
import moment from "moment";
import { Translate } from "../../translate/Translate";
import { useDropzone } from 'react-dropzone'
import * as api from '../../services/agent'
import * as apiv2 from '../../../v2/services/agent'
export function MessageRow(props) {

    const message = props.data;
    const [showReplyContainer, setShowReplyContainer] = React.useState(false);
    const account = props.account;


    const [replyingMessage, setReplyingMessage] = React.useState({
        data: {
            message: "",
            attachments: []
        }
    })

    const handleMessageChange = (e) => {
        replyingMessage.data.message = e.target.value
        setReplyingMessage({ ...replyingMessage })
    }

    const filenameFromURL = (url) => {
        return url.substring(url.lastIndexOf('/') + 1)
    }

    function subscription() {
        apiv2.startSubscription()
            .then(checkout_url => {
                window.open(checkout_url, "_self")
            })
            .catch(error => {
                console.log(error);
            })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        replyingMessage.user = {
            id: account.user.id,
            first_name: account.user.first_name,
            last_name: account.user.last_name
        }
        props.onReply(props.data, replyingMessage)
        setReplyingMessage({
            data: {
                message: "",
                attachments: []
            }
        })
    }

    const markAsReplied = (e) => {
        e.preventDefault();
        let markasReplied = {
            data: {
                mark_as_replied: true
            },
            user: {
                id: account.user.id,
                first_name: account.user.first_name,
                last_name: account.user.last_name
            }
        };

        props.onReply(props.data, markasReplied)
    }

    function hashCode(str) { // java String#hashCode
        if (str === undefined) {
            return 0;
        }
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    }

    function intToRGB(i) {
        var c = (i & 0x00FFFFFF)
            .toString(16)
            .toUpperCase();

        return "00000".substring(0, 6 - c.length) + c;
    }

    const PropertyInMessage = (props) => {
        if (props.data.data.type === "tour") {
            return null;
        }
        if (props.data.property.property_id === "") {
            return null
        }
        return (
            <div className="mt-4 inline-block bg-white rounded-lg border shadow-sm p-4">
                <p className="font-medium text-blue-600 mb-2 text-xs ">{Translate("Property")}</p>
                <PinnedProperty data={props.data.property}></PinnedProperty>
            </div>
        )
    }

    const onDrop = React.useCallback((acceptedFiles, fileRejections) => {
        acceptedFiles.map((file) => {
            //add fill temp
            let image = {
                url: URL.createObjectURL(file),
            }

            var reader = new FileReader();
            reader.onload = function (event) {
                var imageData = event.target.result;
                var base64Data = imageData.split("base64,")[1];
                var fileName = file.name.replace(/\.[^/.]+$/, "")
                api.uploadImage(base64Data, fileName)
                    .then(response => {
                        let imageUrl = response.data;
                        replyingMessage.data.attachments.push(imageUrl)
                        setReplyingMessage({ ...replyingMessage })
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
            reader.readAsDataURL(file);
        });

        fileRejections.forEach((file) => {
            file.errors.forEach((err) => {
                if (err.code === "file-too-large") {
                    alert("file size is exceed limit (10MB)")
                }
            })
        })
    }, [replyingMessage])

    const dropMessageAttachments = useDropzone({
        onDrop: onDrop,
        accept: 'image/jpeg, image/png, application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        multiple: false,
        maxSize: 10 * 1024 * 1024, // 10MB
    });

    const onRemoveAttachmentAtIndex = (index) => {
        replyingMessage.data.attachments.splice(index, 1)
        setReplyingMessage({ ...replyingMessage })
    }


    const RepliedMessages = (props) => {

        const responderName = (reply) => {
            return reply.user.last_name + " " + reply.user.first_name;
        }

        if (props.message.replies.length === 0) {
            return null
        }

        return (
            <div className="mt-4">
                <div className="flex items-center">
                    <svg className="fill-current text-gray-400 h-2 mr-2" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.99512 13.333C7.45996 13.333 7.79492 12.9912 7.79492 12.5332V9.92188H7.99316C10.6455 9.92188 12.3135 10.5918 13.5098 12.8271C13.749 13.2646 14.0635 13.333 14.3506 13.333C14.7129 13.333 15.0547 13.0049 15.0547 12.417C15.0547 7.36523 12.915 4.21387 7.99316 4.21387H7.79492V1.62988C7.79492 1.17188 7.45996 0.795898 6.98145 0.795898C6.64648 0.795898 6.4209 0.939453 6.05859 1.28125L0.665039 6.32617C0.398438 6.5791 0.316406 6.83203 0.316406 7.06445C0.316406 7.29004 0.405273 7.5498 0.665039 7.7959L6.05859 12.8887C6.38672 13.1963 6.66016 13.333 6.99512 13.333Z" />
                    </svg>
                    <p className="flex-grow whitespace-nowrap text-xs text-gray-400 font-medium mr-2"></p>
                    <div className="h-px w-full bg-gray-200"></div>
                </div>

                {props.message.replies.map((reply, index) => (

                    reply.data.mark_as_replied ?
                        <p className="py-2 text-gray-600 text-sm">{responderName(reply)} {Translate("marked_as_replied")} · {moment(reply.created_at).fromNow()}</p>
                        :
                        <div key={`reply-${reply.id}`} className="py-2">
                            <p className="font-medium text-blue-600 mb-2">{responderName(reply)} <span className="text-xs text-gray-600 font-medium">{moment(reply.created_at).fromNow()}</span></p>
                            <p className="whitespace-pre-wrap">{reply.data.message}</p>

                            <div className="flex gap-6 mt-4">
                                {
                                    reply.data.attachments && reply.data.attachments.map((fileURL, index) => (
                                        <div key={`file-${index}`} className="flex">
                                            <a href={fileURL} className="flex" target="_blank">
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                                                </svg>
                                                <span className="text-xs">{filenameFromURL(fileURL)}</span>
                                            </a>
                                        </div>
                                    ))
                                }
                            </div>

                        </div>

                ))}
            </div>
        )
    }


    const TourDetail = (props) => {
        if (props.message.data.type !== "tour") {
            return null;
        }
        return (
            <div className="mt-4 bg-white rounded-lg border shadow-sm inline-block p-4">
                <p className="font-medium text-blue-600 mb-2">{props.message.data.tour.type == "online_consultation" ? Translate("online_consultation") : Translate("Requested tour")}</p>
                <div className="flex items-center space-x-8">
                    {props.message.data.tour.type == "online_consultation" ? null : <div>
                        <p className="text-xs text-gray-600 uppercase">{Translate("Type")}</p>
                        <p className=" font-medium">{Translate(props.message.data.tour.type)}</p>
                    </div>}
                    <div>
                        <p className="text-xs text-gray-600 uppercase">{Translate("Date")}</p>
                        <p className="font-medium">{moment(props.message.data.tour.date).format("LL")}</p>
                    </div>

                    <div>
                        <p className="text-xs text-gray-600 uppercase">{Translate("Preferred time")}</p>
                        <p className=" font-medium">{Translate(props.message.data.tour.preferred_time)}&nbsp;</p>
                    </div>
                </div>
                <div>
                    <p className="text-xs text-gray-600 uppercase mt-4 mb-2">{Translate("Property")}</p>
                    <PinnedProperty data={props.message.property}></PinnedProperty>
                </div>
            </div>
        );
    }

    return (
        <div key={message.id} className="flex space-x-4 border-b py-4">
            <div className="flex-none">
                <a name={message.id}></a>
                <div className="w-12 h-12 rounded-full flex items-center justify-center text-white text-xs" style={{ backgroundColor: '#' + intToRGB(hashCode(message.sender.email)) }}>

                    {
                        message.sender.id != "" ?
                            <img className="rounded-full w-full h-full object-cover" src={message.sender.image_url} />
                            :
                            <span className="uppercase overflow-ellipsis overflow-hidden p-1 font-medium text-base">{message.sender.first_name.charAt(0)}{message.sender.last_name.charAt(0)}</span>
                    }
                </div>
            </div>
            <div className="flex-grow">
                <div className="flex flex-wrap items-baseline">
                    <div className="font-semibold mr-2">
                        <div className="mb-0">
                            {message.sender.first_name} {message.sender.last_name}
                        </div>

                        <div className="inline-flex items-center text-sm mt-2 gap-4 mb-4">
                            {message.sender.id.length === 0 ? null
                                :
                                <div className="inline-flex items-center text-sm text-blue-600">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                                        <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z" clipRule="evenodd" />
                                    </svg>
                                    <span>Viila Registered User</span>
                                </div>
                            }
                            {
                                message.data.type != "sell_request" || (account && account.user && (new Date(account.user.subscription_end * 1000)) >= (new Date())) ?
                                    <>
                                        <div className="inline-flex items-center text-sm">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                                            </svg>
                                            <span>{message.sender.email}</span>
                                        </div>
                                        <div className="inline-flex items-center text-sm">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                                            </svg>
                                            <span>{message.sender.phone !== "" ? message.sender.phone : "—"}</span>
                                        </div>
                                    </> : null}
                        </div>
                    </div>
                    <p className="ml-auto text-xs text-gray-600 font-medium">{moment(message.created_at).fromNow()}</p>
                </div>
                <div className="">
                    {
                        message.data.type == "sell_request" ?
                            <>
                                {account && account.user && (new Date(account.user.subscription_end * 1000)) >= (new Date()) ?
                                    <a className="focus:outline-none px-2 py-1 border border-gray-600 rounded inline-flex items-center" href="/v2/customers">{Translate("sell_request")}</a> :
                                    <>
                                        <p className="whitespace-pre-wrap text-gray-400 mb-4">{Translate("sell_request_limit_message")}</p>
                                        <button className="focus:outline-none px-2 py-1 border border-gray-600 rounded inline-flex items-center" onClick={e => subscription()}>
                                            <span className="ml-2 text-gray-600 font-medium text-sm">{Translate("subscription")}</span>
                                        </button>
                                    </>
                                }
                            </>
                            :
                            <p className="whitespace-pre-wrap">{message.data.message}</p>
                    }

                    {message.data.type == "sell_request" ? null :
                        <>
                            <div><TourDetail message={message}></TourDetail></div>

                            <div><PropertyInMessage data={message}></PropertyInMessage></div>

                            <RepliedMessages message={message}></RepliedMessages>
                            <div className="mt-4">
                                <div className="inline-flex items-center gap-4">
                                    <button type="button" onClick={(e) => { setShowReplyContainer(!showReplyContainer) }} className="focus:outline-none px-2 py-1 border border-gray-600 rounded inline-flex items-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                        </svg>
                                        <span className="ml-2 text-gray-600 font-medium text-sm">{Translate("Reply")}</span>
                                    </button>

                                    {message.replies.length === 0 ?
                                        <button type="button" onClick={(e) => { markAsReplied(e) }} className="focus:outline-none px-2 py-1 border border-gray-600 rounded inline-flex items-center">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 19v-8.93a2 2 0 01.89-1.664l7-4.666a2 2 0 012.22 0l7 4.666A2 2 0 0121 10.07V19M3 19a2 2 0 002 2h14a2 2 0 002-2M3 19l6.75-4.5M21 19l-6.75-4.5M3 10l6.75 4.5M21 10l-6.75 4.5m0 0l-1.14.76a2 2 0 01-2.22 0l-1.14-.76" />
                                            </svg>
                                            <span className="ml-2 text-gray-600 font-medium text-sm">{Translate("mark_as_replied_button")}</span>
                                        </button>
                                        : null
                                    }
                                </div>

                                {
                                    showReplyContainer == false ? null :

                                        <div className="pt-4">
                                            <form onSubmit={onSubmit}>

                                                <textarea value={replyingMessage.data.message} onChange={handleMessageChange} required autoFocus className="w-full rounded mb-2 border focus:ring-2 bg-white" rows="3" placeholder={Translate("Type reply message here")}></textarea>
                                                <div className="flex space-x-2">
                                                    <button type="submit" className="px-4 rounded font-medium bg-blue-500 text-white py-1 focus:outline-none disabled:opacity-50">{Translate("Send")}</button>
                                                    <button type="button" onClick={(e) => { setShowReplyContainer(!showReplyContainer) }} className="px-4 rounded font-medium bg-gray-400 text-white py-1 focus:outline-none">{Translate("Cancel")}</button>
                                                </div>
                                                <div className="flex gap-6 mt-4">
                                                    {
                                                        replyingMessage.data.attachments.map((fileURL, index) => (
                                                            <div key={`file-${index}`} className="flex">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13" />
                                                                </svg>
                                                                <span className="text-xs">{filenameFromURL(fileURL)}</span>
                                                                <button type="button" onClick={(e) => onRemoveAttachmentAtIndex(index)} className="ml-2 text-red-500">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                                <div {...dropMessageAttachments.getRootProps()} className="mt-4 w-full flex items-center text-center mx-auto h-24 border-dashed border-2 rounded border-gray-400 bg-white">
                                                    <div className="mx-auto text-xs">

                                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto mb-2 stroke-current text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                                        </svg>
                                                        <input className="w-full h-full" {...dropMessageAttachments.getInputProps()} />
                                                        <p><button type="button" className="focus:outline-none text-indigo-600 font-medium">{Translate("Upload files")}</button> {Translate("or drag and drop")}</p>
                                                        <p className="text-xs">{Translate("JPG, PNG, PDF, XLS, DOC, DOCX")}</p>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                }
                            </div>
                        </>}
                </div>
            </div>
        </div>
    )
}