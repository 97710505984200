import React, { useCallback } from "react"
import { Link, useHistory } from 'react-router-dom';
import * as api from '../../services/agent'
import { Translate } from "../../translate/Translate";
import StepPropertyType from "./StepPropertyType"
import StepArea from "./StepArea"
import StepApartmentSelector from "./StepApartmentSelector"
import SideNavBar from "../SideNavBar";
import TopSearchbar from "../TopSearchbar";

export default function NewPropertySteps(props) {

    const [property, setProperty] = React.useState({
        "area": {
            "id": "karuizawa"
        },
        "property_type": "house",
        "apartment": ""
    });
    const onSelected = (name, value) => {
        setProperty({ ...property, [name]: value })
    }

    const onSelectedArea = (name, value) => {
        setProperty({ ...property, area: { id: value } })
    }

    const onSelectedApartmentBuilding = (selected) => {
        setProperty({ ...property, apartment: selected.id, amount_of_floors: selected.amount_of_floors })
    }

    let history = useHistory();
    const [step, setCurrentStep] = React.useState(history.location.hash ? Number(history.location.hash.replace("#", "")) : 0);
    const totalStep = 2 // area > type
    const next = () => {
        setCurrentStep(step + 1);
    }

    const back = () => {
        setCurrentStep(step - 1);
    }

    function removeEmpty(obj) {
        return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
    }

    React.useEffect(() => {
        history.replace("#" + step)

        if (step === totalStep) {
            window.location.href = "/v2/properties/new?data=" + JSON.stringify(property)
        }

    }, [step]);

    const [apartments, setApartments] = React.useState([]);
    const loadApartments = (area) => {
        api.apartments(area)
            .then(response => {
                setApartments(response)
            })
            .catch(error => {
                // setShowError(true)
                // setErrorMessage(error.data.message)
            })
    }

    React.useEffect(() => {
        if (property.property_type === "apartment") {
            loadApartments(property.area.id)
        }
    }, [property.area.id, property.property_type]);

    return (
        <div className="w-full flex bg-slate-50">
            <SideNavBar {...props} isPresented={true} />
            <main className="w-full h-full flex flex-col">
                <TopSearchbar {...props} />
                <div className="flex-1 flex relative w-full overflow-hidden">
                    <div className="flex-none w-full overflow-hidden overflow-y-auto h-auto relative mt-16 container mx-auto py-8 px-4">
                        <div className="mb-8 flex justify-between items-center">
                            <div>
                                <div className="flex flex-row mb-1">
                                    <button type="button" onClick={(e) => { history.goBack() }} className="text-xs uppercase font-medium text-gray-600 inline-flex items-center">
                                        <svg className="fill-current text-gray-600 mr-2" width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.890625 5.76953C0.890625 5.91602 0.943359 6.04492 1.05469 6.15625L5.70117 10.6973C5.80078 10.8027 5.92969 10.8555 6.08203 10.8555C6.38672 10.8555 6.62109 10.627 6.62109 10.3223C6.62109 10.1699 6.55664 10.041 6.46289 9.94141L2.19727 5.76953L6.46289 1.59766C6.55664 1.49805 6.62109 1.36328 6.62109 1.2168C6.62109 0.912109 6.38672 0.683594 6.08203 0.683594C5.92969 0.683594 5.80078 0.736328 5.70117 0.835938L1.05469 5.38281C0.943359 5.48828 0.890625 5.62305 0.890625 5.76953Z" />
                                        </svg>
                                        <span>{Translate("Listing")}</span>
                                    </button>
                                </div>

                                <p className="sm:text-xs md:text-xs lg:text-2xl font-semibold">{Translate("Create new property")}</p>
                            </div>
                        </div>
                        {
                            step === 0 ?
                                <StepArea options={props.areas} onSelected={onSelectedArea} data={property} area={property.area}></StepArea>
                                : null
                        }
                        {
                            step === 1 ?
                                <StepPropertyType name="property_type" onSelected={onSelected} data={property} property_type={property.property_type}></StepPropertyType>
                                : null
                        }

                        {
                            property.property_type === "apartment" && apartments.length > 0 && step === 1 ?
                                <div className="mt-4">
                                    <StepApartmentSelector options={apartments} onSelected={onSelectedApartmentBuilding} data={property}></StepApartmentSelector>
                                </div>
                                :
                                null
                        }

                        <div className="w-full mt-8 flex gap-4">
                            {
                                step > 0 ?
                                    <button type="button" onClick={(e) => back()} className="py-3 font-semibold bg-gray-300 text-gray-600 rounded w-full">{Translate("Back")}</button>
                                    : null
                            }

                            <button type="button" onClick={(e) => next()} className="py-3 font-semibold bg-blue-600 text-white rounded w-full">{Translate("Next")}</button>
                        </div>

                    </div>
                </div>
            </main>
        </div>
    )
}