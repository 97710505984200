import React, { useCallback } from "react"
import moment from "moment";
import { Translate } from "../../translate/Translate";
import * as api from '../../services/agent'
import { useDropzone } from 'react-dropzone'
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';

import Resizer from "react-image-file-resizer";


export function PhotoManager(props) {
    const onDismiss = (e) => {
        props.dismiss()
    }

    const [multipleSelections, setMultipleSelections] = React.useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = React.useState([0]);
    const [overlay, setOverlay] = React.useState({
        backgroundcolor: '',
        opacity: 0
    })



    // React.useEffect(() => {
    //     if (props.images.length > 0) {
    //         var list = [...props.data.images];
    //         setSelectedImageIndex([0])
    //     }
    // }, [props.images]);

    React.useEffect(() => {
        const body = document.querySelector('body');
        body.style.overflow = props.isPresented ? 'hidden' : 'auto'
    }, [props.isPresented]);


    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                2000,
                2000,
                "JPEG",
                90,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });





    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        // Do something with the files
        setOverlay({
            backgroundcolor: "",
            opacity: 0,
        })
        var list = [...props.data.images];
        acceptedFiles.map((file) => {
            //add fill temp
            let image = {
                url: URL.createObjectURL(file),
                alt: "",
                uploaded: false
            }


            list.push(image)

            let index = (list.length - 1)
            props.setData({ ...props.data, images: list })

            var reader = new FileReader();
            reader.onload = function (event) {
                try {
                    Resizer.imageFileResizer(
                        file,
                        2000,
                        2000,
                        "JPEG",
                        90,
                        0,
                        (uri) => {
                            console.log(uri);
                            var imageData = uri;
                            var base64Data = imageData.split("base64,")[1];
                            var fileName = "" //for new filename -  file.name.replace(/\.[^/.]+$/, "")
                            api.uploadImage(base64Data,fileName)
                                .then(response => {
                                    list[index].uploaded = true;
                                    list[index].url = response.data;
                                    props.setData({ ...props.data, images: list })
                                })
                                .catch(error => {
                                    console.log(error)
                                })
                        },
                        "base64",
                        200,
                        200
                    );
                } catch (err) {
                    console.log(err);
                }

            }
            reader.readAsDataURL(file);
        });

        fileRejections.forEach((file) => {
            file.errors.forEach((err) => {
                if (err.code === "file-too-large") {
                    alert("file size is exceed limit (10MB)")
                }
            })
        })
    }, [props.data])

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: onDrop,
        accept: 'image/jpeg, image/png',
        maxSize: 10 * 1024 * 1024, // 10MB
    });

    const handleImageAltChange = (e) => {
        var value = e.target.value;
        var name = "alt"

        for (var i in selectedImageIndex) {
            props.data.images[selectedImageIndex[i]] = { ...props.data.images[selectedImageIndex[i]], [name]: value }
        }

        props.setData({ ...props.data, images: props.data.images })
    }

    const onSortEnd = ({ oldIndex, newIndex }) => {
        props.setData({ ...props.data, images: arrayMove(props.data.images, oldIndex, newIndex) });
        setSelectedImageIndex([0])
    }

    const onRemove = (e) => {
        var list = [...props.data.images];
        var archived = [...props.data.archived_images];

        for (var i in selectedImageIndex) {
            let removed = list.splice(selectedImageIndex[i] - i, 1)
            archived.push(...removed)
        }

        props.setData({ ...props.data, images: list, archived_images: archived })
        
        if (list.length === 0) {
            setSelectedImageIndex([])
        } else {
            if (selectedImageIndex[0] >= list.length) {
                setSelectedImageIndex([0])
            }
        }

    }

    const removeImageAtIndex = (index) => {
        var list = props.data.images;
        var archived = props.data.archived_images;

         
        let removed = list.splice(index, 1)
        archived.push(...removed)
        

        props.setData({ ...props.data, images: list, archived_images: archived })
        
    }

    const onSelect = (index) => {
        var list = [...props.data.images];
        if (index >= list.length) {
            index = 0
        }
        if (index < 0) {
            index = list.length - 1
        }

        if (multipleSelections) {
            const i = selectedImageIndex.indexOf(index);
            if (i > -1) {
                selectedImageIndex.splice(i, 1);
            } else {
                selectedImageIndex.push(index)
            }
            setSelectedImageIndex(selectedImageIndex)
        } else {
            setSelectedImageIndex([index])
        }

        //trigger loop images
        props.setData({ ...props.data, images: props.data.images });
    }

    if (props.isPresented === false) {
        return null;
    }

    const SortableItem = SortableElement(({ image, imageIndex, onSelect }) => {
        return (
            <div index={imageIndex} key={`sortable-image-${imageIndex}`} className="flex flex-col w-28 flex-none items-center z-50 relative" onClick={e => { onSelect(imageIndex) }} style={{ cursor: 'pointer' }}>
                <img className={`object-cover h-28 w-28 rounded ${(selectedImageIndex.indexOf(imageIndex) > -1 ? "ring-4 ring-blue-600" : "ring-1 ring-blue-600")}`} src={image.url}></img>
                <button className={`mt-4 w-8 h-8 text-sm font-semibold text-white rounded-full ${(selectedImageIndex.indexOf(imageIndex) > -1 ? "bg-blue-600" : "bg-gray-600")}`}>{imageIndex + 1}</button>
                {
                    image.uploaded == true ? 
                    <div className="absolute top-0 left-0 p-1 bg-green-500 text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                    </div>
                    : 
                    <div className="absolute top-0 left-0 p-1 bg-yellow-500 text-white">
                    <svg className="animate-spin  h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    </div>
                }
                <div onClick={()=> removeImageAtIndex(imageIndex)} className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full -mt-3 -mr-3">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                </div>
                
            </div>
        )
    });

    const SortableList = SortableContainer(({ images, onSelect }) => {
        return (
            <div className="flex flex-row space-x-4 h-full"  >
                {images.map((image, index) => (
                    <SortableItem key={index} index={index} image={image} imageIndex={index} onSelect={onSelect} style={{ zIndex: 99999999 }} />
                ))}
            </div>
        );
    });

    const nextImage = function (e) {
        onSelect(selectedImageIndex[0] + 1)
    }

    const previosImage = function (e) {
        onSelect(selectedImageIndex[0] - 1)
    }


    const dropHandler = function (ev) {
        ev.preventDefault();
        setOverlay({
            backgroundcolor: "",
            opacity: 0,
        })
    }

    // only react to actual files being dragged
    const dragEnterHandler = function (e) {
        e.preventDefault();
        setOverlay({
            backgroundcolor: "rgba(255, 255, 255, 0.7)",
            opacity: 1,
        })
    }

    const dragLeaveHandler = function (e) {
        e.preventDefault();
        setOverlay({
            backgroundcolor: "",
            opacity: 0,
        })
    }

    const dragOverHandler = function (e) {
        e.preventDefault();
        setOverlay({
            backgroundcolor: "rgba(255, 255, 255, 0.7)",
            opacity: 1,

        })
    }

    const toggleMultiple = function (e) {
        if (!e.target.checked)
            setSelectedImageIndex([0])

        setMultipleSelections(e.target.checked)
    }

    if (props.images.length === 0) {
        return (
            <div className="sticky top-0 left-0 h-screen p-4 bg-black bg-opacity-60 z-20">
                <div className="bg-white shadow-md rounded-md flex flex-col h-full">
                    <div className="p-4 flex items-center border-b ">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        <p className="text-lg font-semibold">{Translate("Manage Photos")}</p>
                        <div className="ml-auto">
                            <button className="p-2 bg-gray-600 text-white rounded-full" onClick={onDismiss}>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    </div>
                    <div {...getRootProps()} className="flex items-center text-center mx-4 my-4 h-full border-dashed border-2 rounded border-gray-400 bg-white">
                        <div className="mx-auto text-xs">

                            <svg className="mx-auto mb-2 fill-current text-gray-700" width="27" height="23" viewBox="0 0 27 23" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.33789 16.3145C2.67969 18.2969 3.86133 19.1172 5.88281 18.7559L6.07812 18.7266V19.166C6.07812 21.1777 7.09375 22.1934 9.14453 22.1934H23.3828C25.4238 22.1934 26.4492 21.1777 26.4492 19.166V9.2832C26.4492 7.27148 25.4238 6.25586 23.3828 6.25586H21.2637L20.6875 3.04297C20.3359 1.07031 19.1543 0.230469 17.1426 0.591797L3.11914 3.07227C1.09766 3.42383 0.287109 4.58594 0.628906 6.57812L2.33789 16.3145ZM3.87109 15.9531L2.19141 6.38281C2.01562 5.39648 2.45508 4.78125 3.42188 4.60547L17.3965 2.14453C18.3438 1.97852 18.9785 2.39844 19.1543 3.39453L19.6621 6.25586H9.14453C7.09375 6.25586 6.07812 7.26172 6.07812 9.2832V17.125L5.63867 17.2031C4.68164 17.3789 4.05664 16.9492 3.87109 15.9531ZM7.65039 9.37109C7.65039 8.35547 8.1875 7.82812 9.16406 7.82812H23.3633C24.3301 7.82812 24.877 8.35547 24.877 9.37109V17.1348L21.3418 13.7949C20.9219 13.4043 20.4141 13.209 19.8965 13.209C19.3594 13.209 18.8906 13.3945 18.4414 13.7852L14.1152 17.6328L12.377 16.0703C11.9668 15.709 11.5273 15.5137 11.0684 15.5137C10.6484 15.5137 10.2383 15.6992 9.83789 16.0605L7.65039 17.9551V9.37109ZM12.5332 14.0391C13.7832 14.0391 14.7988 13.0137 14.7988 11.7539C14.7988 10.5137 13.7832 9.47852 12.5332 9.47852C11.2832 9.47852 10.2578 10.5137 10.2578 11.7539C10.2578 13.0137 11.2832 14.0391 12.5332 14.0391Z" />
                            </svg>
                            <input className="w-full h-full" {...getInputProps()} />
                            <p><button type="button" className="focus:outline-none text-indigo-600 font-medium">{Translate("Upload files")}</button> {Translate("or drag and drop")}</p>
                            <p className="text-xs">{Translate("JPG or PNG up to 10MB")}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


    return (
        <div className="sticky top-0 left-0 h-screen p-4 bg-black bg-opacity-60 z-20">
            <div style={{ backgroundColor: overlay.backgroundcolor }} className="w-full h-full absolute top-0 left-0 pointer-events-none z-10 flex flex-col items-center justify-center rounded-md">
                <i style={{ opacity: overlay.opacity }}>
                    <svg className="fill-current w-12 h-12 mb-3 text-gray-500" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <path d="M19.479 10.092c-.212-3.951-3.473-7.092-7.479-7.092-4.005 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408zm-7.479-1.092l4 4h-3v4h-2v-4h-3l4-4z" />
                    </svg>
                </i>
                <p className="text-lg text-gray-500" style={{ opacity: overlay.opacity }}>{Translate("Drop files to upload")}</p>
            </div>
            <div className="bg-white shadow-md rounded-md flex flex-col h-full">
                <div className="p-4 flex items-center border-b ">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                    <p className="text-lg font-semibold">{Translate("Manage Photos")}</p>
                    <div className="ml-auto">
                        <button className="p-2 bg-gray-600 text-white rounded-full" onClick={onDismiss}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>

                <div className="p-4 border-b flex flex-nowrap items-top space-x-4 overflow-x-scroll scrollable-x">
                    <SortableList distance={2} onSortEnd={onSortEnd} onRemove={onRemove} onSelect={onSelect} axis="x" lockAxis="x" images={props.data.images} ></SortableList>
                    <div {...getRootProps()} className="w-28 h-28 flex-none border rounded border-blue-600 text-blue-600 inline-flex items-center" style={{ cursor: 'pointer' }} onDragOver={e => dragOverHandler(e)} onDragLeave={e => dragLeaveHandler(e)} onDragEnter={e => dragEnterHandler(e)}>
                        <div className="mx-auto text-xs">
                            <input className="w-full h-full" {...getInputProps()} />
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4v16m8-8H4" />
                            </svg>
                        </div>
                    </div>
                    <div {...getRootProps()} className="flex w-full h-full" onDragOver={e => dragOverHandler(e)} onDragLeave={e => dragLeaveHandler(e)} onDragEnter={e => dragEnterHandler(e)}></div>
                </div>

                <div className="flex-1 h-96">
                    <div className="flex h-full">
                        <div {...getRootProps()} className="p-4 h-full w-full" onDragOver={e => dragOverHandler(e)} onDragLeave={e => dragLeaveHandler(e)} onDragEnter={e => dragEnterHandler(e)}>
                        {selectedImageIndex.length > 0 ? (
                            <img className="mx-auto h-full w-auto object-scale-down" src={props.data.images[selectedImageIndex.at(-1)].url} />):null}
                        </div>
                        <div className="flex-none ml-auto w-80 border-l p-4">
                            <div className="ml-auto mb-4">
                                <label htmlFor="toggle-multiple" className="flex items-center cursor-pointer">
                                    <div className="relative">
                                        <input type="checkbox" id="toggle-multiple" className="sr-only" onChange={toggleMultiple} />
                                        <div className="block bg-gray-600 w-14 h-8 rounded-full"></div>
                                        <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                                    </div>
                                    <div className="ml-3 text-gray-700 font-medium">
                                        {Translate("Enable multiple selections")}
                                    </div>
                                </label>
                            </div>
                            {
                                multipleSelections ?
                                    <div className="flex w-full">
                                        <div className="flex-auto w-full mx-auto text-lg font-semibold text-center">
                                            {[...selectedImageIndex].sort().map(e => e + 1 + " ")}
                                        </div>
                                    </div>
                                    :
                                    <div className="flex w-full">
                                        <div className=" mb-4">
                                            <button onClick={(e) => previosImage(e)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16l-4-4m0 0l4-4m-4 4h18" />
                                                </svg>
                                            </button>
                                        </div>
                                        {selectedImageIndex ? <div className="flex-auto w-full mx-auto text-lg font-semibold text-center">{selectedImageIndex[0] + 1}/{props.images.length}</div> : null}
                                        <div className="ml-auto">
                                            <button onClick={(e) => nextImage(e)}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                            }
                            <p className="text-sm font-medium text-gray-600 mb-2">{Translate("Photo description")}</p>
                            <textarea className="rounded w-full" rows="6" onChange={(e) => handleImageAltChange(e)} value={selectedImageIndex.length > 0 ? props.data.images[selectedImageIndex[0]].alt : ""}></textarea>
                            <div className="mt-4 pt-4 border-t">
                                <button className="bg-red-600 w-full py-2 rounded text-white" onClick={(e) => onRemove(e)}>{Translate("Remove photo")}</button>
                            </div>
                            <div {...getRootProps()} className="flex w-full h-full" onDragOver={e => dragOverHandler(e)} onDragLeave={e => dragLeaveHandler(e)} onDragEnter={e => dragEnterHandler(e)}></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}