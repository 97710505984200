import React from "react"
import { Link } from 'react-router-dom';
import { Translate } from "../../translate/Translate";
import * as Account from '../../../account'

export default function SettingsNavBar(props) {
  return (
    <div className="w-full mt-8 border-b space-x-4 h-10">
      <Link to="/v2/settings" className={"focus:outline-none text-sm font-medium pb-4 px-2 text-gray-500 " + (props.active === 'general' ? 'border-b-2 border-primary text-primary' : '')}>{Translate("General")}</Link>
      <Link to="/v2/settings/profile" className={"focus:outline-none text-sm font-medium pb-4 px-2 text-gray-500 " + (props.active === 'profile' ? 'border-b-2 border-primary text-primary' : '')}>{Translate("Profile")}</Link>
      <Link to="/v2/settings/password" className={"focus:outline-none text-sm font-medium pb-4 px-2 text-gray-500 " + (props.active === 'password' ? 'border-b-2 border-primary text-primary' : '')}>{Translate("Password")}</Link>
      {
        Account.getUserAccount() && Account.getUserAccount().user && (Account.getUserAccount().user.role == 'owner' ||  Account.getUserAccount().user.role == 'admin') ? <Link to="/v2/settings/team" className={"focus:outline-none text-sm font-medium pb-4 px-2 text-gray-500 " + (props.active === 'team' ? 'border-b-2 border-primary text-primary' : '')}>{Translate("Team members")}</Link> : null
      }
       {/* <Link to="/v2/settings/billing" className={"focus:outline-none text-sm font-medium pb-4 px-2 text-gray-500 " + (props.active === 'billing' ? 'border-b-2 border-primary text-primary' : '')}>{Translate("Billing")}</Link> */}
    </div>
  )
}
