import React from "react"
import { Link } from 'react-router-dom';
import { Translate } from "../../translate/Translate";
import { PropertyStatusBadge } from "../Shared/PropertyStatusBadge"
import moment from "moment";

export default function OffersByProperty(props) {

    return (

        <div className="grid grid-cols-1 lg:grid-cols-4 lg:space-x-6">
            <div className="col-span-1">
                <div className="bg-white rounded-lg shadow overflow-hidden">

                    {
                        props.property.images.length > 0 ?
                            <img className="object-cover h-36 w-full" src={props.property.images[0].url} />
                            :
                            <div className="w-full h-36 bg-gray-300"></div>
                    }

                    <div className="p-4">
                        <p className="text-xs">{Translate(props.property.property_type)} · {props.property.property_id}</p>
                        <p className="text-lg font-medium">{props.property.name}</p>
                        <p className="text-lg font-medium">
                            {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: props.property.price.currency }).format(Number(props.property.price.amount))}
                        </p>
                        <PropertyStatusBadge status={props.property.status}></PropertyStatusBadge>
                    </div>
                </div>
            </div>
            <div className="col-span-3">
                <p className="text-lg font-semibold mb-4">{Translate("Offers")}</p>
                <div className="rounded-lg shadow  overflow-hidden overflow-x-auto">
                    <table className="w-full divide-y divide-gray-200 table-auto">
                        <thead className="bg-gray-100">
                            <tr>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">{Translate("ID")}</th>
                                <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">{Translate("Name")}</th>
                                <th className="px-6 py-3 bg-gray-50 text-right text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">{Translate("Offer")}</th>
                                <th className="px-0 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">{Translate("Payment")}</th>
                                <th className="px-0 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">{Translate("Date")}</th>
                                <th className="px-0 py-3 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">{Translate("Status")}</th>
                                <th className="pr-6 py-3 bg-gray-50">&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 text-xs">
                            {
                                props.property.offers.map((offer, index) => (
                                    <tr>
                                        <td className="px-6 py-4 font-medium">
                                            {offer.code}
                                        </td>
                                        <td className="px-6 py-4 whitespace-no-wrap inline-flex items-center">
                                            <img className="w-8 h-8 rounded-full mr-2 object-cover" src={offer.contact.image_url} />
                                            <span>{offer.contact.first_name} {offer.contact.last_name}</span>
                                        </td>
                                        <td className="px-6 py-4 whitespace-no-wrap text-right font-medium">
                                            {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: props.property.price.currency }).format(Number(offer.offer.amount))}
                                        </td>
                                        <td className="px-0 py-4 whitespace-no-wrap text-center">{Translate(offer.payment.method)}</td>
                                        <td className="px-0 py-4 whitespace-no-wrap text-center">{moment(offer.created_at).fromNow()}</td>
                                        <td className="px-0 py-4 whitespace-no-wrap text-center">
                                            {offer.accepted_at && !offer.canceled_at ? <span className="bg-green-400 text-white font-medium text-xs px-2 py-1 rounded">{Translate("Accepted")}</span> : null}
                                            {offer.rejected_at && !offer.canceled_at ? <span className="bg-red-400 text-white font-medium text-xs px-2 py-1 rounded">{Translate("Rejected")}</span> : null}
                                            {offer.canceled_at ? <span className="bg-red-400 text-white font-medium text-xs px-2 py-1 rounded">{Translate("Canceled")}</span> : null}
                                            {!offer.accepted_at && !offer.rejected_at && !offer.canceled_at ? <span className="bg-yellow-400 text-white font-medium text-xs px-2 py-1 rounded">{Translate("Waiting for result")}</span> : null}
                                        </td>
                                        <td className="pr-6 py-4 whitespace-no-wrap text-right">
                                            <a href={'/v2/offers/' + offer.code} className="bg-gray-100 py-1 border rounded px-2 text-xs font-medium inline-flex items-center"><span>
                                                {Translate("View detail")}</span>
                                            </a>
                                        </td>
                                    </tr>
                                ))
                            }
                            {/* <tr>
                                <td className="px-6 py-4 whitespace-no-wrap inline-flex items-center">
                                    <img className="w-8 h-8 rounded-full mr-2" src="https://lh4.googleusercontent.com/-IXxRYMosFTU/AAAAAAAAAAI/AAAAAAAAAAA/AMZuuckSD6QZn3Q5RlzclWNDIMZZ4VZBaQ/s96-c/photo.jpg" />
                                    <span>Bill Gates</span>
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap text-right font-medium">¥355,000,000</td>
                                <td className="px-0 py-4 whitespace-no-wrap text-center">Cash</td>
                                <td className="px-0 py-4 whitespace-no-wrap text-center">8 hours ago</td>
                                <td className="px-0 py-4 whitespace-no-wrap text-center">
                                    <span className="bg-green-400 text-white font-medium text-xs px-2 py-1 rounded">Accepted</span>
                                </td>
                                <td className="pr-6 py-4 whitespace-no-wrap text-right">
                                    <button className="bg-gray-100 py-1 border rounded px-2 text-xs font-medium inline-flex items-center"><span>
                                        View offer detail</span>
                                        <svg className="ml-2 w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4 whitespace-no-wrap inline-flex items-center">
                                    <img className="w-8 h-8 rounded-full mr-2" src="https://lh4.googleusercontent.com/-IXxRYMosFTU/AAAAAAAAAAI/AAAAAAAAAAA/AMZuuckSD6QZn3Q5RlzclWNDIMZZ4VZBaQ/s96-c/photo.jpg" />
                                    <span>Apisit Toompakdee</span>
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap text-right font-medium">¥305,000,000</td>
                                <td className="px-0 py-4 whitespace-no-wrap text-center">Loan</td>
                                <td className="px-0 py-4 whitespace-no-wrap text-center">2 days ago</td>
                                <td className="px-0 py-4 whitespace-no-wrap text-center">
                                    <span className="bg-red-400 text-white font-medium text-xs px-2 py-1 rounded">Rejected</span>
                                </td>
                                <td className="pr-6 py-4 whitespace-no-wrap text-right">
                                    <button className="bg-gray-100 py-1 border rounded px-2 text-xs font-medium inline-flex items-center"><span>
                                        View offer detail</span>
                                        <svg className="ml-2 w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                        </svg>
                                    </button>
                                </td>
                            </tr>
                            <tr>
                                <td className="px-6 py-4 whitespace-no-wrap inline-flex items-center">
                                    <img className="w-8 h-8 rounded-full mr-2" src="https://lh4.googleusercontent.com/-IXxRYMosFTU/AAAAAAAAAAI/AAAAAAAAAAA/AMZuuckSD6QZn3Q5RlzclWNDIMZZ4VZBaQ/s96-c/photo.jpg" />
                                    <span>Arco Oliemans</span>
                                </td>
                                <td className="px-6 py-4 whitespace-no-wrap text-right font-medium">¥310,000,000</td>
                                <td className="px-0 py-4 whitespace-no-wrap text-center">Loan</td>
                                <td className="px-0 py-4 whitespace-no-wrap text-center">3 days ago</td>
                                <td className="px-0 py-4 whitespace-no-wrap text-center">
                                    <span className="bg-blue-400 text-white font-medium text-xs px-2 py-1 rounded">Waiting</span>
                                </td>
                                <td className="pr-6 py-4 whitespace-no-wrap text-right">
                                    <button className="bg-gray-100 py-1 border rounded px-2 text-xs font-medium inline-flex items-center"><span>
                                        View offer detail</span>
                                        <svg className="ml-2 w-3 h-3" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                        </svg>
                                    </button>
                                </td>
                            </tr> */}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}