import FeatureItem from './FeatureItem';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from '@dnd-kit/core'
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable'


export default function FeatureList(props) {
    const {features, setFeatures} = props

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
          coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    function handleDragEnd(event) {
        const {active, over} = event;
        console.log("handleDragEnd", active, over, features)
        
        if (active.id !== over.id) {
            const oldIndex = features.findIndex((i) => i.id === active.id);
            const newIndex = features.findIndex((i) => i.id === over.id);
            console.log("features",features)
            console.log("oldIndex",features[oldIndex],"newIndex", features[newIndex])
            // console.log("arrayMove", arrayMove(features, oldIndex, newIndex))
            setFeatures(arrayMove(features, oldIndex, newIndex))
        }
    }

    return (<DndContext 
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}>
        <SortableContext 
          items={features}
          strategy={verticalListSortingStrategy}
        >
        {features.map((feature, index) => (<FeatureItem {...props} feature={feature} key={`${feature.key}-${index}-${feature.id}`} index={index} id={feature.id} />))}
        </SortableContext>
    </DndContext>)
}