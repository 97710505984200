import React from "react"

export default function ViilaLogo(props) {
    const {className} = props
    return (
        <>
            <svg className={className} viewBox="0 0 54 51" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 26.4301C0 27.5607 0.883074 28.5392 2.29599 28.5392C2.98038 28.5392 3.59853 28.1695 4.15045 27.7347L6.66721 25.6473V45.8686C6.66721 49.0866 8.63205 51 12.0098 51H41.8798C45.2355 51 47.2224 49.0866 47.2224 45.8686V25.5386L49.8716 27.7347C50.4015 28.1695 51.0196 28.5392 51.704 28.5392C53.0065 28.5392 54 27.7347 54 26.4736C54 25.7343 53.713 25.1472 53.139 24.6689L47.2224 19.7549V10.4922C47.2224 9.51378 46.5822 8.90497 45.5887 8.90497H42.5421C41.5707 8.90497 40.9084 9.51378 40.9084 10.4922V14.5365L30.157 5.64347C28.2363 4.05621 25.8078 4.05621 23.8872 5.64347L0.883074 24.6689C0.286999 25.1472 0 25.7995 0 26.4301Z" />
                <path d="M47.4741 3.33977C47.4741 5.18428 45.9559 6.67954 44.0831 6.67954C42.2103 6.67954 40.6921 5.18428 40.6921 3.33977C40.6921 1.49527 42.2103 0 44.0831 0C45.9559 0 47.4741 1.49527 47.4741 3.33977Z" />
            </svg>
        </>
    )
}