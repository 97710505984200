export const geolocateStyle = {
    bottom: 125,
    right: 0,
    padding: '10px'
};

export const navStyle = {
    bottom: 30,
    right: 0,
    padding: '10px'
};

export const scaleControlStyle = {
    bottom: 24,
    left: 0,
    padding: '10px'
};

export const circleStyle = {
    id: 'radius',
    interactive: false,
    type: 'fill',
    paint: {
      'fill-color': '#45b6fe',
      'fill-opacity': 0.1
    }
}

export const layerStyle = {
    id: 'point',
    interactive: true,
    type: 'circle',
    paint: {
        'circle-radius': {
            'base': 4,
            'stops': [
                [12, 4],
                [22, 180]
            ]
        },
        "circle-opacity": 0.8,
        'circle-color': [
            'match',
            ['get', 'property_type'],
            'house',
            '#2563EB',
            'lot',
            '#34D399',
            'apartment',
            '#7C3AED',
        /* other */ '#ccc'
        ]
    }
};

export const layerText = {
    id: "property-price",
    interactive: true,
    type: "symbol",
    layout: {
        "text-field": [
            'format',
            ['upcase', ['get', 'formatted_price']],
            { 'font-scale': 1 },
            // '\n',
            // {},
            // ['downcase', ['get', 'localized_status']],
            // { 'font-scale': 0.8 }

        ],
        "text-size": 12,
        "text-offset": [0, -0.5],
        'text-anchor': 'bottom',
        'text-font': ['Inter Medium', 'Arial Unicode MS Bold']
    },
    paint: {
        "text-color": [
            'match',
            ['get', 'status'],
            'draft',
            '#4B5563',
            'active',
            '#2563EB',
            'sold',
            '#DC2626',
            'business_negotiation',
            '#FBBF24',
            'inactive',
            '#374151',
        /* other */ '#ccc'
        ],
        "text-halo-color": "#fff",
        "text-halo-width": 1,
    },
}
export const walkingPathStyle = {
    id:"walking-path", 
    type:"fill",
    paint: {
        'fill-color': ['get', 'fill'],
        // 'fill-outline-color': ['get', 'fill'],
        'fill-opacity': 0.25,
    }
}

export const intersectionStyle = {
    id:"intersection", 
    type:"fill",
    paint: {
        'fill-color': "#2563EB",
        'fill-opacity': 0.25,
    }
}


export const geolocateStyleNoPadding = {
    bottom: 125,
    right: 0,
    padding: '0px'
};

export const fullscreenControlStyleNoPadding = {
    bottom: 160,
    right: 0,
    padding: '0px'
};

export const navStyleNoPadding = {
    bottom: 30,
    right: 0,
    padding: '0px'
};

export const scaleControlStyleNoPadding = {
    bottom: 24,
    left: 0,
    padding: '0px'
};

export function getCursor({ isHovering, isDragging }) {
    return isDragging ? 'grabbing' : isHovering ? 'pointer' : 'default';
}

export const boundaryLine = {
    "type": 'line',
    "minzoom": 14,
    "paint": {
        "line-color": "#1454ff",
        "line-opacity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            12,
            0.1,
            22,
            1
        ]
    }
}

export const boundaryfill = {
    'type': 'fill',
    'layout': {},
    'paint': {
        'fill-color': '#1454FF',
        'fill-opacity': 0
    }
}

export const highlightBoundaryfill = {
    'type': 'fill',
    'layout': {},
    'paint': {
        'fill-color': '#1454FF',
        'fill-opacity': 0.5
    }
}