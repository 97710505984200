import React, { Fragment } from "react"
import { Dialog, Transition } from '@headlessui/react'
import { Translate } from "../../translate/Translate";
import * as turf from "@turf/turf";
import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from 'mapbox-gl';
import _ from "lodash";

export default function Lotnumber(props) {
    const [map, setMap] = React.useState(null);
    //prepare buttons to toggle dialog visible/hidden
    document.querySelectorAll("[data-toggle-dialog]").forEach((e) => {
        let dialog = e.dataset.toggleDialog;
        e.addEventListener("click", function () {
            let div = document.querySelector("#" + dialog)

            let isHidden = div.classList.contains("hidden")
            if (isHidden) { //show
                div.classList.remove("fade-out")
                div.classList.add("fade-in")
                div.classList.remove("hidden")
            } else {
                div.classList.add("fade-out")
                div.classList.remove("fade-in")
                setTimeout(() => {
                    div.classList.add("hidden")
                }, 500);
            }
        })
    })


    const otherArea = (prefecture, city) => {
        fetch(`http://localhost:8888/api/v2/landboundary/interest/${prefecture}-${city}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then((res) => {

            })
    }


    const fetchPrefectures = () => {
        const url = "http://localhost:8888/api/v2/prefectures"
        fetch(url)
            .then(res => res.json())
            .then((res) => {
                var prefectureSelector = document.querySelector("#prefecture-selector");
                res.data.forEach((data) => {
                    var optionElement = document.createElement("option");
                    if (data["id"] === '{{.SelectedPrefecture.ID}}') {
                        optionElement.selected = true
                    }
                    optionElement.value = data["id"];
                    optionElement.dataset.lat = data["latlng"]["latitude"];
                    optionElement.dataset.lng = data["latlng"]["longitude"];
                    optionElement.text = data["name"];
                    optionElement.dataset.official = data["official_id"]

                    prefectureSelector.appendChild(optionElement);
                })
            })
    }


    const fetchCities = (prefectureID) => {
        const url = "http://localhost:8888/api/v2/prefectures/" + prefectureID + "/cities"
        return fetch(url)
            .then(res => res.json())
            .then((res) => {
                var citySelector = document.querySelector("#city-selector");

                //selected first city
                if (res.data && res.data.length > 0 && res.data[0].map_id && selectedMapID == "") {
                    selectedMapID = res.data[0].id
                }

                res.data.forEach((data) => {
                    var optionElement = document.createElement("option");
                    if (data["id"] === selectedMapID) {
                        optionElement.selected = true
                    }
                    optionElement.value = data["id"];
                    optionElement.dataset.lat = data["lat"];
                    optionElement.dataset.lng = data["lng"];
                    optionElement.dataset.slug = data["slug"];
                    optionElement.text = data["name"];

                    citySelector.appendChild(optionElement);
                })
            })

    }


    const prefectureselector = document.getElementById("prefecture-selector");
    const citySelector = document.getElementById("city-selector");

    if (prefectureselector)
        prefectureselector.addEventListener("change", async function () {
            selectedPrefectureID = prefectureselector.value
            // Get the selected option
            const selectedOption = prefectureselector.options[prefectureselector.selectedIndex];
            // Get the data attributes
            const lat = selectedOption.dataset.lat;
            const lng = selectedOption.dataset.lng;
            mapFlyTo(lat, lng)

            citySelector.innerHTML = ""

            selectedMapID = ""

            fetchCities(selectedOption.dataset.official)
                .then(() => {
                    citySelector.selectedIndex = 0;
                    citySelector.dispatchEvent(new Event('change'));
                })
        });

    if (citySelector)
        citySelector.addEventListener("change", function (e) {
            let selectedOption = e.target.options[e.target.selectedIndex]
            let slug = selectedOption.dataset.slug;
            selectedMapID = e.target.value;
            if (selectedMapID) {
                let newsourceId = tilesetIdPrefix + prefectures[selectedMapID.padStart(5, "0").slice(0, 2)] + "-" + selectedMapID.padStart(5, "0")
                console.log("city changed", newsourceId)
                setupSource(newsourceId)
                let lat = selectedOption.dataset.lat;
                let lng = selectedOption.dataset.lng;
                mapFlyTo(lat, lng);
            } else {
                // alert("We're making improvements - thanks for your patience!")
                document.querySelector('#under-development-dialog').classList.remove('hidden')
                otherArea(selectedPrefectureID, slug)
            }

        })

    const sharingData = {}
    const localStorageKey = "land_boundary_listing"
    let defaultLat = 36.3387281
    let defaultLng = 138.6034158
    let defaultZoom = 15
    if (sharingData.hash) {
        let hashs = sharingData.hash.split("/")
        if (hashs.length == 3) {
            defaultLat = hashs[1]
            defaultLng = hashs[2]
            defaultZoom = hashs[0].replace("#", "")
        }

    }
    let lotNumberLayer = "viila-lotnumbers"

    let selectedPrefectureID = "nagano"
    let selectedCity = { id: "20321" }
    let selectedMapID = selectedCity && selectedCity.id ? selectedCity.id : ""
    console.log("selectedMapID", selectedMapID)
    const prefectures = {
        "01": "hokkaido",
        "02": "aomori",
        "03": "iwate",
        "04": "miyagi",
        "05": "akita",
        "06": "yamagata",
        "07": "fukushima",
        "08": "ibaraki",
        "09": "tochigi",
        "10": "gunma",
        "11": "saitama",
        "12": "chiba",
        "13": "tokyo",
        "14": "kanagawa",
        "15": "niigata",
        "19": "yamanashi",
        "20": "nagano",
        "15": "niigata",
        "16": "toyama",
        "17": "ishikawa",
        "18": "fukui",
        "21": "gifu",
        "22": "shizuoka",
        "23": "aichi",
        "24": "mie",
        "25": "shiga",
        "26": "kyoto",
        "27": "osaka",
        "28": "hyogo",
        "29": "nara",
        "30": "wakayama",
        "31": "tottori",
        "32": "shimane",
        "33": "okayama",
        "34": "hiroshima",
        "35": "yamaguchi",
        "36": "tokushima",
        "37": "kagawa",
        "38": "ehime",
        "39": "kochi",
        "40": "fukuoka",
        "41": "saga",
        "42": "nagasaki",
        "43": "kumamoto",
        "44": "oita",
        "45": "miyazaki",
        "46": "kagoshima",
        "47": "okinawa",
    }
    let tilesetIdPrefix = `apisitviila.lotnumbers-`
    let sourceID = `${tilesetIdPrefix}${prefectures[selectedMapID.padStart(5, "0").slice(0, 2)]}-${selectedMapID.padStart(5, "0")}`
    let boudaryFillsLayer = "viila-boundary-fills"
    let lotNumberKey = "地番"
    let lotNumberAreaKey = "市区町村名"
    let areaKey = "地図名"
    let mapNameKey = "地図名"
    var hoveredStateIdList = []
    var selectedFeatures = sharingData && sharingData.selectedFeatures ? sharingData.selectedFeatures : []
    var loaded = false
    var firstStyleLoad = true

    function setupSource(newSourceID) {
        sourceID = newSourceID
        //if source were added previously then we just update the layers
        if (map.getSource(newSourceID)) {
            setupDataLayers(newSourceID)
            return;
        }
        map.addSource(newSourceID, {
            // type: 'geojson',
            // data: {type:'FeatureCollection',features:[]},
            type: 'vector',
            url: 'mapbox://' + newSourceID,
            promoteId: "id", //telling map box which field inside "properties" to use as Id
            tolerance: 0,
        });
        map.addSource(newSourceID + '-point', {
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: [],
            },
            promoteId: "id" //telling map box which field inside "properties" to use as Id
        });
        setupDataLayers(newSourceID)

    }




    var roadMapStyle = 'mapbox://styles/apisitviila/ckwk55so8393b14mdforyhaxf'


    mapboxgl.accessToken = 'pk.eyJ1IjoiYXBpc2l0dmlpbGEiLCJhIjoiY2t3NjdlMW83MngxMjJvcGFhN2d2cmNkNyJ9.K2ko2jB7iJ8o-_6C6WeNyw';

    // map.addControl(new mapboxgl.NavigationControl(), 'bottom-right');
    // map.addControl(new mapboxgl.GeolocateControl({
    //    positionOptions: {
    //       enableHighAccuracy: true
    //    },
    //    trackUserLocation: true,
    //    showUserHeading: true
    // }), 'bottom-right');

    const removeDataLayers = () => {
        let layers = ["viila-boundary", "viila-lotnumbers", "viila-boundary-fills"]
        layers.forEach((l) => {
            if (map.getLayer(l)) {
                map.removeLayer(l);
            }
        })
    }

    const setupLayers = () => {
        //add empty geojson source to display length
        map.addSource('_measurements', {
            tolerance: 0,
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            }
        });
        //add empty geojson source to display combine length
        map.addSource('_measurements_combine', {
            tolerance: 0,
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            }
        });

        //add empty geojson source to fill boundary in different color
        map.addSource('_selectedLots', {
            tolerance: 0,
            type: 'geojson',
            data: {
                type: 'FeatureCollection',
                features: []
            }
        });

        //add empty source for lot number label to solve the issue of mapbox cannot find center of boundary in some of the lots
        map.addSource('_titleSource', {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': []
            }
        });



        //a layer to fill boundary with different color
        map.addLayer({
            'id': "viila-unique-fills",
            'type': 'fill',
            'source': '_selectedLots',
            'layout': {},
            'paint': {
                'fill-color': ['get', 'color'],
                'fill-opacity': 0.1,
            },
        });

        //add layer to show line of selected boundary
        map.addLayer({
            'id': "viila-selected-line",
            'type': 'line',
            'source': '_selectedLots',
            'layout': {},
            'paint': {
                'line-color': ['get', 'color'],
                'line-width': 2
            },
        });

        //add layer to show circle of each point of the  boundary
        map.addLayer({
            id: "viila-selected-circle",
            type: 'circle',
            'source': '_selectedLots',
            paint: {
                'circle-color': ['get', 'color'],
                'circle-radius': 3,
                'circle-opacity': 1
            },
        });

        //add layer to show measured distant in m between two coordinates
        map.addLayer({
            id: "viila-boundary-measurement",
            type: 'symbol',
            source: '_measurements',
            layout: {
                'text-field': ['get', 'length'],
                'text-size': 14,
                'text-offset': [0, 0.8],
                'text-anchor': 'center',
                'symbol-placement': 'line-center',
                'symbol-spacing': 10,
                'text-font': ['Noto Sans CJK JP Bold', 'Arial Unicode MS Bold'],
            },
            paint: {
                'text-color': '#232d85',
                'text-halo-color': 'white',
                'text-halo-width': 1
            }
        });
        map.addLayer({
            id: 'boundary-length-combine',
            type: 'symbol',
            source: '_measurements_combine',
            layout: {
                'text-field': ['get', 'length'],
                'text-size': 14,
                'text-offset': [0, 0.8],
                'text-anchor': 'center',
                'symbol-placement': 'line-center',
                'symbol-spacing': 10,
                'text-font': ['Noto Sans CJK JP Bold', 'Arial Unicode MS Bold'],
            },
            paint: {
                'text-color': '#232d85',
                'text-halo-color': 'white',
                'text-halo-width': 1
            }
        });


        map.addLayer({
            "id": "viila-selected-lotnumbers",
            "type": "symbol",
            'source': "_titleSource",
            "minzoom": 17,
            "layout": {
                "text-field": ["format",
                    ["get", "name"], {},
                    "\n", {},
                    ["get", "totalSQM"], {},
                    " (", {},
                    ["get", "totalTsubo"], {},
                    ")", {}
                ],
                "text-font": [
                    "Noto Sans CJK JP Bold",
                    "Arial Unicode MS Regular"
                ],
                "text-size": 14
            },
            "paint": {
                "text-halo-color": "#ffffff",
                "text-halo-width": 1,
                "text-color": "black"
            }
        })

        if (map.getLayer("viila-boundary-measurement")) {
            map.moveLayer("viila-boundary-measurement");
        }



        //move lot number layer to the top
        if (map.getLayer(lotNumberLayer)) {
            map.moveLayer(lotNumberLayer);
        }

        if (map.getLayer("viila-selected-lotnumbers")) {
            map.moveLayer("viila-selected-lotnumbers");
        }
    }


    const setupDataLayers = (source) => {
        removeDataLayers()

        map.addLayer({
            "id": "viila-boundary",
            "type": "line",
            "source": source,
            "source-layer": source,
            "minzoom": 14,
            "paint": {
                "line-color": "#1454ff",
                "line-opacity": [
                    "interpolate",
                    ["linear"],
                    ["zoom"],
                    12,
                    0.1,
                    22,
                    1
                ]
            },
        })

        map.addLayer({
            'id': "viila-boundary-fills",
            'type': 'fill',
            'source': source,
            'source-layer': source,
            'layout': {},
            'paint': {
                'fill-color': '#1454FF',
                'fill-opacity': [
                    'case',
                    ['boolean', ['feature-state', 'hover'], false],
                    0.5,
                    0
                ]
            }
        });


        map.addLayer({
            "id": "viila-lotnumbers",
            "type": "symbol",
            'source': source + "-point",
            // 'source-layer': source + "-point",
            "minzoom": 17,
            "layout": {
                "text-field": ["to-string", ["get", lotNumberKey]],
                "text-font": [
                    "Noto Sans CJK JP Bold",
                    "Arial Unicode MS Regular"
                ],
                "text-size": 14
            },
            "paint": {
                "text-halo-color": "#ffffff",
                "text-halo-width": 1,
                "text-color": "#1454ff"
            }
        })


        if (map.getLayer("viila-boundary-measurement")) {
            map.moveLayer("viila-boundary-measurement");
        }

        //move lot number layer to the top
        if (map.getLayer(lotNumberLayer)) {
            map.moveLayer(lotNumberLayer);
            console.log("move lotnumber top")
        }

        if (map.getLayer("viila-selected-lotnumbers")) {
            map.moveLayer("viila-selected-lotnumbers")
        }

    }



    var tsubo =  Translate("tsubo")
    function displayArea() {

        var labelFeatures = [];
        var boundaryFeatures = [];
        var lotNumberFeatures = [];
   

        selectedFeatures.forEach(function (feature) {

            var lotNumber = feature.properties[lotNumberKey];
            var mapName = feature.properties[mapNameKey];

            let uniqueId = lotNumber + mapName;

            var boundaryData = JSON.parse(JSON.stringify(feature))
            boundaryData.properties["color"] = "#" + stringToHexColor(uniqueId)
            boundaryFeatures.push(boundaryData)

            let areSQM = turf.area(feature)
            var fomattedSQM = `${new Intl.NumberFormat('ja-JP', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(areSQM)}m²`

            let areaTsubo = toTsubo(areSQM)
            var formattedTsubo = `${new Intl.NumberFormat('ja-JP', { minimumFractionDigits: 2, maximumFractionDigits: 2, }).format(areaTsubo)}${tsubo}`


            lotNumberFeatures.push({
                'type': 'Feature',
                'geometry': {
                    'type': 'Point',
                    'coordinates': [feature.properties["代表点経度"], feature.properties["代表点緯度"]]
                }
                , "properties": {
                    "name": feature.properties["地番"],
                    "totalSQM": fomattedSQM,
                    "totalTsubo": formattedTsubo,
                    "color": "#" + stringToHexColor(uniqueId)
                }
            })

            var coords = feature.geometry.coordinates;
            feature.geometry.coordinates.forEach((c) => {
                var boundaries = c

                for (var i = 0; i < boundaries.length - 1; i++) {

                    //PRO VERSION
                    var point1 = turf.point(boundaries[i]);
                    var point2 = turf.point(boundaries[i + 1]);

                    var distance = turf.distance(point1, point2, { units: 'meters' });
                    var label = distance.toFixed(2) + "m"

                    var data = {
                        'type': 'Feature',
                        'properties': {
                            "length": label,
                        },
                        'geometry': {
                            'type': 'LineString',
                            'coordinates': [
                                point1.geometry.coordinates,
                                point2.geometry.coordinates,
                            ]
                        }
                    }

                    labelFeatures.push(data)
                }
            })
        });

        map.getSource('_selectedLots').setData({
            type: 'FeatureCollection',
            features: boundaryFeatures
        });

        map.getSource('_measurements').setData({
            type: 'FeatureCollection',
            features: labelFeatures
        });

        // console.log("_titleSource", lotNumberFeatures)
        map.getSource('_titleSource').setData({
            type: 'FeatureCollection',
            features: lotNumberFeatures
        });
    }

    const addFooter = async (image) => {
        let newCanvas = document.createElement('canvas');

        newCanvas.width = image.width;
        newCanvas.height = image.height + 100;

        let context = newCanvas.getContext('2d');

        context.fillStyle = '#fff';
        context.fillRect(0, 0, newCanvas.width, newCanvas.height);
        context.drawImage(image, 0, 0);
        let svg = document.createElement('img');
        svg.src = '/static/brand/viila-logo-light.svg';
        await svg.decode();
        context.drawImage(svg, image.width - 150, image.height + 35);

        context.font = 'bold 24px Helvetica';
        context.fillStyle = '#000';
        context.imageSmoothingEnabled = false;
        context.fillText(Translate("lotnumbers_meta_title") + ' • viila.co/lotnumbers', 30, image.height + 60);

        return newCanvas
    }


    const addWatermark = async (image, padding = 200, spacing = 200, degrees = -25) => {
        // Create a new canvas with the same size as the input image
        const canvas = document.createElement('canvas');
        canvas.width = image.width;
        canvas.height = image.height;
        const context = canvas.getContext('2d');

        // Load the watermark image in SVG format
        const watermarkImg = new Image();
        watermarkImg.src = '/static/brand/viila-logo-light.svg';
        await watermarkImg.decode();

        // Draw the input image onto the canvas
        context.drawImage(image, 0, 0);

        // Set the opacity of the watermark image
        context.globalAlpha = 0.05;

        const radians = degrees * (Math.PI / 180);

        // Determine the number of times to repeat the watermark image horizontally and vertically
        const repeatX = Math.ceil(canvas.width / (watermarkImg.width + spacing));
        const repeatY = Math.ceil(canvas.height / (watermarkImg.height + spacing));

        // Draw the rotated watermark image onto the canvas with padding and spacing
        for (let x = 0; x < repeatX; x++) {
            for (let y = 0; y < repeatY; y++) {
                const offsetX = padding + (watermarkImg.width + spacing) * x;
                const offsetY = padding + (watermarkImg.height + spacing) * y;
                context.translate(offsetX + watermarkImg.width / 2, offsetY + watermarkImg.height / 2);
                context.rotate(radians); // Rotate by 45 degrees
                context.drawImage(watermarkImg, -watermarkImg.width / 2, -watermarkImg.height / 2);
                context.rotate(-radians); // Rotate back to the original orientation
                context.translate(-offsetX - watermarkImg.width / 2, -offsetY - watermarkImg.height / 2);
            }
        }

        // Convert the canvas to a data URL
        const dataURL = canvas.toDataURL();

        // Create a new image element with the watermarked image as the source
        const watermarkedImage = new Image();
        watermarkedImage.src = dataURL;

        // Return the watermarked canvas
        return canvas;
    }





    var exportMapOptions = {
        showBaseLayer: true,
        showMeasurement: true,
        showBoundary: true,
        showLotNumbers: true,
        showHazardMap: false,
        targetId: "map-export-image"
    }




    const previewExportMapImage = async (options) => {
        let allLayers = map.getStyle().layers;
        allLayers.forEach((l) => {
            if (options.showBaseLayer) {
                map.setLayoutProperty(l.id, 'visibility', 'visible');
            } else {
                if (l.id.startsWith("viila-")) {
                    map.setLayoutProperty(l.id, 'visibility', 'visible');
                } else {
                    map.setLayoutProperty(l.id, 'visibility', 'none');
                }
            }
        })

        map.setLayoutProperty('viila-boundary', 'visibility', options.showBoundary ? 'visible' : 'none');
        map.setLayoutProperty('viila-boundary-measurement', 'visibility', options.showMeasurement ? 'visible' : 'none');
        map.setLayoutProperty('viila-lotnumbers', 'visibility', options.showLotNumbers ? 'visible' : 'none');


    }



    const ACCESS_TOKEN = 'pk.eyJ1IjoiYXBpc2l0dmlpbGEiLCJhIjoiY2t3NjdmY2NzMjRqYjJvcm92ZG9ybWk0NCJ9.8cVGNnPUBF-WgN7PD2IaEQ';
    const mapFlyTo = (lat, lng, zoom) => {
        var desiredLngLat = [lng, lat];
        map.fire('flystart')
        map.flyTo({
            center: desiredLngLat,
            essential: true,
            zoom: zoom ? zoom : 17
        });
    }

    function stringToHexColor(str) {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var hex = '';
        for (var i = 0; i < 3; i++) {
            var value = (hash >> (i * 8)) & 0xff;
            hex += value.toString(16).padStart(2, '0');
        }
        return hex;
    }

    function clickOnSuggestion(item) {
        fetch(`https:\/\/api.mapbox.com/search/v1/retrieve?session_token=&access_token=${ACCESS_TOKEN}`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(item.action.body),
        })
            .then((response) => response.json())
            .then(async ({ features }) => {
                if (features.length === 0) {
                    console.log("no result")
                    return
                }
                const selected = features[0]
                map.fire('flystart')
                map.flyTo({
                    center: selected.geometry.coordinates,
                    zoom: 17,
                })
            })
    }



    function selectBoundary(features) {
        var list = features
        var foundFeature = features[0]
        if (features.length > 1) {
            console.log("union", features)
            //union all found features
            var union = features.reduce((a, b) => turf.union(a, b), features[0])
            //assign properties back
            union.properties = foundFeature.properties
            list = []
            list.push(union)
        }
        return list
    }

    const addSuggestionLandBoundary = (item) => {
        var temp = document.querySelector("#suggested-land");
        var clone = temp.content.cloneNode(true);
        clone.querySelector(".lot-number").textContent = "地番: " + item.lot_number + ""
        clone.querySelector(".lot-number-area").textContent = "市区町村名: " + item.lot_number_area
        //when click full address 
        clone.querySelector(".lot-action").addEventListener("click", function () {

            document.querySelector("#address-input").value = ""
            document.querySelector("#lot-number-search-result-container").innerHTML = ""
            document.querySelector("#lot-number-search-result-container").classList.add("hidden")

            mapFlyTo(item.location.latlng.latitude, item.location.latlng.longitude)
            map.once('moveend', () => {
                var filter =
                    [
                        'all',
                        ['==', lotNumberKey, item.lot_number],
                        ['==', mapNameKey, item.map_name]
                    ]

                const foundFeatures = map.queryRenderedFeatures({
                    source: sourceID,
                    sourceLayer: sourceID,
                    filter: filter,
                    layers: ['viila-boundary']
                })

                let list = selectBoundary(foundFeatures)
                // var list = foundFeatures
                // var foundFeature = foundFeatures[0]
                // if (foundFeatures.length > 1) {
                //    console.log("union", foundFeatures)
                //    //union all found features
                //    var union = foundFeatures.reduce((a, b) => turf.union(a, b), foundFeatures[0])
                //    //assign properties back
                //    union.properties = foundFeature.properties
                //    list = []
                //    list.push(union)
                // }

                list.forEach((feat) => {
                    var foundIndex = selectedFeatures.findIndex((f) => {
                        return (f.properties[lotNumberKey] == feat.properties[lotNumberKey] && f.properties[mapNameKey] == feat.properties[mapNameKey]);
                    })
                    //not found in list
                    if (foundIndex == -1) {
                        selectedFeatures.push(feat)
                    }
                })
                displayArea()
            })

        })
        document.querySelector("#lot-number-search-result-container").prepend(clone);
    }

    const addSuggestion = (item) => {
        var temp = document.querySelector("#suggested-address");
        var clone = temp.content.cloneNode(true);
        console.log(clone)
        clone.querySelector(".full-address").textContent = item.feature_name
        //when click full address 
        clone.querySelector(".full-address").addEventListener("click", function () {
            clickOnSuggestion(item)
            document.querySelector("#address-input").value = ""
            document.querySelector("#suggested-addresses-container").innerHTML = ""
            document.querySelector("#suggested-addresses-container").classList.add("hidden")
        })
        document.querySelector("#suggested-addresses-container").appendChild(clone);
    }



    const toTsubo = (sqm) => {
        let tsuboUnit = 0.3025
        return sqm * tsuboUnit
    }

    

    const fetchSuggestion = _.debounce(async (text) => {

        document.querySelector("#search-result-loading-indicator").classList.remove("hidden")
        document.querySelector("#lot-number-search-result-container").innerHTML = ""
        document.querySelector("#lot-number-search-result-container").classList.add("hidden")

        document.querySelector("#suggested-addresses-container").innerHTML = ""
        document.querySelector("#suggested-addresses-container").classList.add("hidden")

        fetch(`http://localhost:8888/api/v2/landboundary/${selectedMapID}/lotnumbers/${text}`)
            .then(res => res.json())
            .then(res => {
                document.querySelector("#search-result-loading-indicator").classList.add("hidden")
                if (res.length > 0) {
                    document.querySelector("#lot-number-search-result-container").classList.remove("hidden")
                }
                for (const item of res.reverse()) {
                    addSuggestionLandBoundary(item)
                }
            })


        fetch(`http://localhost:8888/api/landprice/suggest/${text}`)
            .then(res => res.json())
            .then(res => {
                if (res.data.suggestions.length > 0) {
                    document.querySelector("#suggested-addresses-container").classList.remove("hidden")
                }
                for (const item of res.data.suggestions) {
                    addSuggestion(item)
                }
            })
    }, 1000)



    React.useEffect(() => {
        if (map == null || map == undefined) return


        map.on('style.load', function () {
            console.log("style loaded")
            if (firstStyleLoad) {
                firstStyleLoad = false
                return
            }
            setupSource(sourceID);
            setupLayers()
            displayArea()
        })

        map.on('load', function () {
            // Change the cursor to a pointer when the mouse is over the places layer.
            map.on('mouseenter', boudaryFillsLayer, function () {
                map.getCanvas().style.cursor = 'pointer';
            });

            // Change it back to a pointer when it leaves.
            map.on('mouseleave', boudaryFillsLayer, function () {
                map.getCanvas().style.cursor = '';
            });

            if (!selectedMapID) {
                document.querySelector('#under-development-dialog').classList.remove('hidden')
                otherArea(selectedPrefectureID, selectedCity.slug)
            } else {
                console.log("map on load", sourceID)
                setupSource(sourceID);
            }


            setupLayers()

            map.on('moveend', function (event) {
                if (event.target.getZoom() < 17) {
                    console.log("not show lot numbers")
                    return
                }
                const foundFeatures = map.queryRenderedFeatures({
                    source: sourceID,
                    sourceLayer: sourceID,
                    layers: ['viila-boundary']
                })
                if (foundFeatures.length > 0) {
                    let lotnumbers = foundFeatures.map((f) => {
                        f.geometry.type = 'Point'
                        f.geometry.coordinates = [f.properties['代表点経度'], f.properties['代表点緯度']]
                        return f
                    });
                    map.getSource(sourceID + '-point').setData({
                        type: 'FeatureCollection',
                        features: lotnumbers,
                    })
                }
            })

            map.on('click', "viila-boundary-fills", function (event) {
                if (event.features.length == 0) {
                    return;
                }
                let feature = event.features[0]
                //query by id
                let found = map.querySourceFeatures(sourceID,
                    {
                        sourceLayer: sourceID,
                        filter: ["==", "id", feature.id]
                    })

                let list = selectBoundary(found)
                list.forEach((f) => {
                    let selectedFeature = JSON.parse(JSON.stringify(f))
                    var foundIndex = selectedFeatures.findIndex((f) => {
                        return f.properties.id === selectedFeature.properties.id
                    })
                    if (foundIndex != -1) {
                        selectedFeatures.splice(foundIndex, 1)
                    } else {
                        selectedFeatures.push(selectedFeature)
                    }
                })

                displayArea()
             
            });

            // Add the mousemove event listener to the map
            map.on('mousemove', "viila-boundary-fills", function (event) {

                if (event.features.length == 0) {
                    return;
                }

                if (hoveredStateIdList.length > 0) {
                    hoveredStateIdList.map((id) => {
                        map.setFeatureState({
                            source: sourceID,
                            sourceLayer: sourceID,
                            id: id
                        }, {
                            hover: false
                        });
                    })
                }

                hoveredStateIdList = []
                event.features.map((f) => {
                    hoveredStateIdList.push(f.id)
                })

                hoveredStateIdList.map((id) => {
                    map.setFeatureState({
                        source: sourceID,
                        sourceLayer: sourceID,
                        id: id
                    }, {
                        hover: true
                    });
                })
            });

            map.on('mouseleave', "viila-boundary-fills", function (event) {
                if (hoveredStateIdList !== null && hoveredStateIdList.length > 0) {
                    hoveredStateIdList.map((id) => {
                        map.setFeatureState({
                            source: sourceID,
                            sourceLayer: sourceID,
                            id: id
                        }, {
                            hover: false
                        });
                    })
                }
                hoveredStateIdList = []
            });

            if (selectedFeatures.length > 0 && !loaded) {
                displayArea()
                loaded = true
                setTimeout(() => {
                    if (sharingData && sharingData.hash) {
                        const [zoom, lat, lng] = sharingData.hash.substring(1).split('/');
                        mapFlyTo(lat, lng, zoom)
                    } else {
                        var gotoFeature = selectedFeatures[0]
                        if (!gotoFeature) return
                        const mapname = gotoFeature.properties["ファイル名"].split('-')
                        const officialOptions = document.querySelectorAll('option[data-official="' + mapname[0].padStart(5, "0").slice(0, 2) + '"]');
                        var prefecture = ""
                        officialOptions.forEach(function (option) {
                            prefecture = option.value;
                        });
                        var newSourceID = tilesetIdPrefix + prefectures[mapname[0].padStart(5, "0").slice(0, 2)] + "-" + mapname[0].padStart(5, "0")
                        console.log("newSourceID", newSourceID)
                        if (newSourceID != sourceID) {
                            sourceID = newSourceID
                            setupSource(sourceID)
                        }
                        mapFlyTo(gotoFeature.properties["代表点緯度"], gotoFeature.properties["代表点経度"])
                    }

                }, 1000);
            } else {
                if (selectedCity && selectedCity.lat && selectedCity.lng)
                    mapFlyTo(selectedCity.lat, selectedCity.lng)

            }
        });//map on load ends

    }, [map]);

    React.useEffect(() => {
        fetchPrefectures()
        fetchCities('20')

        setMap(new mapboxgl.Map({
            hash: false,
            container: 'map', // container ID
            // style: "mapbox://styles/apisitviila/clfj0nw3d002j01mrx6ch29xd", style with boundary
            style: roadMapStyle,
            center: [defaultLng, defaultLat],
            zoom: defaultZoom,
            preserveDrawingBuffer: true, //for getCanvas
            attributionControl: false,
            minZoom: 4,
            maxZoom: 22,
        }));



        document.querySelector("#address-input").addEventListener("focus", function () {
            let value = this.value.trim()
            if (value.length > 0) {
                document.querySelector("#search-result-container").classList.remove("hidden")
            }
        })

        document.querySelector("#address-input").addEventListener("blur", function () {
            // if a user tries to click one of the example cities it will not work because the container hides faster than click
            //so we delay hiding container a little bit to compensate for the click
            setTimeout(() => {
                document.querySelector("#search-result-container").classList.add("hidden")
            }, 200)
        })

        document.querySelector("#address-input").addEventListener("input", function (event) {

            let value = this.value.trim()
            //clear sugggested addresses when input is empty
            if (value.length == 0) {
                document.querySelector("#search-result-container").classList.add("hidden")
                document.querySelector("#lot-number-search-result-container").innerHTML = ""
                // document.querySelector("#lot-number-search-result-container").innerHTML = ""
            } else {
                document.querySelector("#search-result-container").classList.remove("hidden")
                if (!value) { return false; }
                fetchSuggestion(value)
            }
        })

        document.querySelector("#address-input").addEventListener("keyup", function (event) {
            if (event.keyCode === 13) {
                // Cancel the default action, if needed
                event.preventDefault();
                // Trigger the button element with a click
                // document.getElementById("search-via-address").click();
            }
        })



    }, []);


    return (

        <div className="w-full h-full flex flex-col">
            <div className="w-full flex items-center z-50 bg-white border-b">
                <div className="flex flex-col w-full relative">
                    <div className="w-full bg-white border rounded overflow-hidden flex items-center relative">
                        <div className="flex flex-row w-full">
                            <div className="w-64 pl-3 h-full py-2 font-semibold border-white flex items-center">
                                <select
                                    className="text-sm py-0 px-0 pr-6 overflow-ellipsis no-select appearance-none w-full border-0 ring-0 focus:outline-none focus:ring-0"
                                    id="prefecture-selector">
                                </select>
                            </div>
                            <div className="w-64 pl-3 h-full py-2 font-semibold flex items-center">
                                <select
                                    className="text-sm py-0 px-0 pr-6 overflow-ellipsis no-select appearance-none w-full border-0 ring-0 focus:outline-none focus:ring-0"
                                    id="city-selector">
                                </select>
                            </div>
                            <input placeholder={Translate("lotnumbers_input_placeholder")} id="address-input"
                                className="focus:outline-none focus:ring-0 border-0 border-none w-full border-0 appearance-none px-3 py-2"
                                type="text" name="address" />
                        </div>
                    </div>
                    <div id="search-result-container"
                        className="hidden z-50 mt-1 w-full absolute top-10 border rounded overflow-hidden">

                        <div id="search-result-loading-indicator" className="bg-white w-full p-3 flex items-center">
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none"
                                viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                                </path>
                            </svg>
                        </div>
                        <div id="lot-number-search-result-container" className="hidden bg-white w-full flex flex-col">
                        </div>
                        <div id="suggested-addresses-container" className="hidden bg-white w-full flex flex-col">
                        </div>


                        <template id="suggested-land">
                            <div className="px-3 py-2 flex items-center border-b cursor-pointer lot-action gap-3 hover:bg-gray-100">
                                <div className="flex-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                        className="w-4 h-4 text-gray-500">
                                        <path fillRule="evenodd"
                                            d="M8.157 2.175a1.5 1.5 0 00-1.147 0l-4.084 1.69A1.5 1.5 0 002 5.251v10.877a1.5 1.5 0 002.074 1.386l3.51-1.453 4.26 1.763a1.5 1.5 0 001.146 0l4.083-1.69A1.5 1.5 0 0018 14.748V3.873a1.5 1.5 0 00-2.073-1.386l-3.51 1.452-4.26-1.763zM7.58 5a.75.75 0 01.75.75v6.5a.75.75 0 01-1.5 0v-6.5A.75.75 0 017.58 5zm5.59 2.75a.75.75 0 00-1.5 0v6.5a.75.75 0 001.5 0v-6.5z"
                                            clipRule="evenodd" />
                                    </svg>
                                </div>
                                <div className="flex flex-col">
                                    <span className="text-sm w-full lot-number font-semibold">番: 111-111</span>
                                    <span className="text-sm w-full lot-number-area">市区町村名: 市区町村名</span>
                                </div>
                            </div>
                        </template>
                        <template id="suggested-address">
                            <div className="px-3 py-2  flex items-center border-b cursor-pointer gap-3 hover:bg-gray-100">
                                <div className="flex-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
                                        className="w-4 h-4 text-gray-500">
                                        <path fillRule="evenodd"
                                            d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
                                            clipRule="evenodd" />
                                    </svg>
                                </div>
                                <p className="text-sm full-address w-full">Suggested address</p>
                            </div>
                        </template>

                        <template id="city-item">
                            <div className="w-full py-2 cursor-pointer border-b text-sm">Karuizawa</div>
                        </template>
                    </div>
                </div>
            </div>
            <div className="w-full h-full mt-auto flex items-start">
                <div id="map-container" className="w-full h-96">
                    <div id="map" className="w-full h-full"></div>
                </div>
            </div>





        </div >


    )
}