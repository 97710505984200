import React, { useEffect } from "react"
import clsx from "clsx";
import moment from "moment";
import NumberInputField from "./NumberInputField"
import MoneyInputField from "./MoneyInputField"
import PropertyTypeField from "./PropertyTypeField"
import RadioField from "./RadioField"
import SelectControl from "./SelectControl"
import InputField from "./InputField";
import { defaultFeatures } from "../../../services/agent";

export default function EssentialForm(props) {
    const { setData, data, useFormReturn, apartments, language, loadApartments, className, Translate } = props
    const { register, formState: { errors }, control, setValue, getValues, watch, clearErrors } = useFormReturn
    const _data = watch()

    const yearOptions = (currentYear) => {
        let options = [
            { value: '', label: '-' }
        ]
        let startYear = currentYear - 100

        for (let y = currentYear + 5; y > startYear; y--) {
            options.push({ value: `${y}`, label: `${y}` })
        }

        return options
    }

    const monthOptions = () => {
        let options = [
            { value: '', label: '-' },
        ]
        
        const months = Array.apply(0, Array(12)).map(function(_,i){
            const thisMonth = moment().month(i).startOf("month")
            return {
                value: (i+1).toString(),
                label: thisMonth.toDate().toLocaleString(language === "en" ? "en-US" : "ja-JP", {
                    month: 'long',
                })
            }
        })

        return [...options, ...months]
    }

    const floorOptions = (floor) => {
        if (!floor) {
            return []
        }
        let options = Array.from(Array(+floor), (x, i) => i + 1)
        return options.map((opt) => ({ label: `${opt}`, value: `${opt}` }))
    }

    const transactionTypes = [
        "seller",
        "brokerage_general",
        "brokerage_exclusive",
        "brokerage_privileged_exclusive",
        "proxy",
    ]



    const handlePropertyType = (oldType, newType) => {
        console.log("handlePropertyType", oldType, newType)
        if (newType !== "apartment") {
            setValue('apartment', "", {
                shouldValidate: false,
            })
            setValue("apartment_detail", null)
            setValue("apartments", [])
            setValue("floor", "")
            setValue("room_number", "")
        } else {
            if (!apartments[_data.area?.id]) {
                loadApartments(_data.area.id, {apartment: ""})
            }
        }

        const findFeature = (key, index) => {
            var data = props.featureList.find(f => f.key === key)
            if (data) return { data, key: data.key, type: data.type, title: data.key, index: index, value: data.values && data.values[0] ? data.values[0].value : "" };
            return { key: key, type: "text", title: key, index: index, value: "" };
        }

        var features = []
        if (data && data.features)
            features.push(...data.features.filter((f) => f.addbyuser))

        if (props.defaultFeatures[newType] !== undefined) {
            features.push(...props.defaultFeatures[newType].map((e, index) => { return findFeature(e, index) }))
        }

        setValue("features", features)
        setData({ ...data, features: features })
    }

    const filterByReference = (arr1, arr2) => {
        let res = [];
        res = arr1.filter(el => {
            return !arr2.find(element => {
                return element && el ? element.key === el.key : false;
            });
        });
        return res;
    }

    const years = yearOptions(new Date().getFullYear())
    const months = monthOptions()

    return (
        <div className={clsx("grid grid-cols-1 text-sm rounded-t-md bg-white", className)}>
            <div className="py-6  px-6">
                <div className="flex items-center">
                    <div>
                        <p className="text-xl font-medium">{Translate("Property info")}</p>
                    </div>
                </div>
            </div>

            <div className="col-span-1 py-4 lg:py-4 px-6">
                <div className="col-span-1 py-4 lg:py-4 px-6">
                    <div className="grid grid-cols-3 mt-4">
                        <div className="col-span-3 lg:col-span-1">
                            <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Reference ID")}</p>
                        </div>
                        <div className="col-span-3 lg:col-span-2">
                            <InputField
                                control={control}
                                errors={errors} clearErrors={clearErrors}
                                name="reference_id"
                                id="reference_id"
                                placeholder={Translate("Property ID in your system")}
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-3 items-start mt-4">
                        <div className="col-span-3 lg:col-span-1">
                            <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Transaction type")}</p>
                        </div>
                        <div className="col-span-3 lg:col-span-2">
                            <div className="grid grid-cols-2 lg:grid-cols-3 gap-y-2">
                                {transactionTypes.map((tType, index) => (<RadioField key={index} name="transaction_type" data={tType} {...{ register }} />))}
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-3 mt-4">
                        <div className="col-span-3 lg:col-span-1">
                            <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Type")}</p>
                        </div>
                        <div className="col-span-3 lg:col-span-2">
                            <PropertyTypeField name="property_type"
                                {...{ setValue, getValues, clearErrors }}
                                className="flex items-center gap-2"
                                onChangeCallback={handlePropertyType}
                            />
                        </div>
                    </div>
                    {_data.property_type === "apartment" ?
                        (<div className="grid grid-cols-3 mt-4" >
                            <div className="col-span-3 lg:col-span-1">
                                <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Apartment")}</p>
                            </div>
                            <div className="col-span-3 lg:col-span-2">
                                <SelectControl name="apartment" id="apartment"
                                    control={control} errors={errors} clearErrors={clearErrors}
                                    options={apartments[data.area?.id] || []}
                                    getOptionLabel={(option => option.name)}
                                    getOptionValue={(option => option.id)}
                                    onChangeCallback={(selected) => {
                                        setValue("amount_of_floors", selected.amount_of_floors)
                                        setValue("floor", "1")
                                        setValue("latlng", selected.latlng)
                                        setValue("viewport", selected.latlng)

                                        let apartmentFeatures = selected.features.map((e) => ({ ...e, key: e.key ? e.key : e.title, value: e.value ? e.value : "-", disable: true }))
                                        let features = filterByReference(data.features, apartmentFeatures)
                                        setValue("apartments", apartmentFeatures)
                                        setValue("features", features)
                                        setValue("apartment_detail", selected)
                                        setValue("address", selected.address)
                                        setData({ ...data, address: selected.address })
                                    }}
                                />
                            </div>
                        </div>) : null}

                    {_data.property_type === "apartment" && _data.apartment !== undefined && _data.apartment !== "" ?
                        (
                            <div className="grid grid-cols-3 mt-4">
                                <div className="col-span-3 lg:col-span-1">
                                    <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Floor")}</p>
                                </div>
                                <div className="col-span-3 lg:col-span-2">
                                    <SelectControl name="floor" id="floor"
                                        control={control} errors={errors} clearErrors={clearErrors}
                                        options={floorOptions(_data.amount_of_floors)}
                                    />
                                </div>
                            </div>
                        ): null
                    }
                    {_data.property_type === "apartment" ?
                        (<div className="grid grid-cols-3 mt-4">
                            <div className="col-span-3 lg:col-span-1">
                                <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Room number")}</p>
                            </div>
                            <div className="col-span-3 lg:col-span-2">
                                <InputField
                                    control={control}
                                    errors={errors} clearErrors={clearErrors}
                                    name="room_number"
                                    id="room_number"
                                    placeholder={Translate("Room number")}
                                />
                            </div>
                        </div>) : null}
                    <div className="grid grid-cols-3 mt-4">
                        <div className="col-span-3 lg:col-span-1">
                            <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Price")}</p>
                        </div>
                        <div className="col-span-3 lg:col-span-2">
                            <MoneyInputField
                                control={control}
                                errors={errors} clearErrors={clearErrors}
                                required={true}
                                placeholder={"50,000,000"}
                                name="price.amount"
                                id="price-amount"
                                className=""
                            />
                        </div>
                    </div>

                    <div className="grid grid-cols-3 items-top mt-4">
                        <div className="col-span-3 lg:col-span-1">
                            <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Size")}</p>
                        </div>
                        <div className="col-span-3 lg:col-span-2">
                            <div className="grid grid-cols-1 gap-4 mb-2">
                                {
                                    _data.property_type === "house" || _data.property_type === "lot" ?
                                        <div className="col-span-1">
                                            <label htmlFor="land.sqm" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Lot size")}</label>
                                            <NumberInputField control={control}
                                                errors={errors} clearErrors={clearErrors}
                                                type="text"
                                                data-type="float"
                                                name="land.sqm"
                                                id="land-sqm"
                                                placeholder="94.3"
                                                required={_data.property_type === "house" || _data.property_type === "lot"} />
                                        </div> : null
                                }
                                {
                                    _data.property_type === "house" || _data.property_type === "apartment" ?

                                        <div className="col-span-1">
                                            <label htmlFor="sqm" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Floor area")}</label>
                                            <NumberInputField control={control}
                                                errors={errors} clearErrors={clearErrors}
                                                type="text"
                                                data-type="float"
                                                name="sqm"
                                                id="sqm"
                                                placeholder="94.3"
                                                required={_data.property_type === "house" || _data.property_type === "apartment"}
                                            />
                                        </div>
                                        : null
                                }
                            </div>
                        </div>
                    </div>

                    {_data.property_type === "house" ?
                        <div className="grid grid-cols-3" >
                            <div className="col-span-3 lg:col-span-1" >
                                <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0"> {Translate("Built")} </p>
                                <p className="text-sm font-sm text-gray-700 mb-2 lg:mb-0">({Translate("optional")})</p>
                            </div>
                            <div className="col-span-3 lg:col-span-2">
                                <div className="grid grid-cols-2 gap-4 mb-2" >
                                    <div className="col-span-1" >
                                        <label htmlFor="built.year" className="block text-sm font-medium leading-6 text-gray-700" > {Translate("Year")} </label>
                                        <SelectControl name="built.year" id="built-year" control={control} errors={errors} clearErrors={clearErrors}
                                            options={years}
                                            onChangeCallback={(data) => {
                                                // if (_data.value === "") {
                                                //     setValue("built.month", "")
                                                // }
                                            }} />
                                    </div>
                                    <div className="col-span-1">
                                        <label htmlFor="built.month" className="block text-sm font-medium leading-6 text-gray-700" > {Translate("Month")} </label>
                                        <SelectControl name="built.month" id="built-month" control={control} errors={errors} clearErrors={clearErrors}
                                            options={months}
                                            onChangeCallback={(data) => {
                                                // if (_data.value !== "" && getValues('built.year') === "") {
                                                //     setValue("built.year", years[1].value)
                                                // }
                                            }} />
                                    </div >
                                </div>
                            </div>
                        </div> : null}
                </div>
            </div>
        </div>)
}