import React from "react"
import { Translate } from "../../translate/Translate";
import * as api from '../../services/agent'
import { useParams } from "react-router-dom"; 
import moment from "moment";
import { PropertyStatusBadge } from "../Shared/PropertyStatusBadge"

export function OfferRow(props) {
    const { offer,customerId } = props

    function OfferStatus(props) {
        const { data } = props
        if (data.canceled_at) {
            return (
                <div>
                    <p className="bg-red-400 text-center text-xs font-semibold px-2 py-1 rounded text-white">{Translate("Canceled")}</p>
                    <p className="text-sm">{moment(data.canceled_at).format("LLL")}</p>
                </div>
            )
        }

        if (data.accepted_at) {
            return (
                <div>
                    <p className="bg-green-400 text-center text-xs font-semibold px-2 py-1 rounded text-white">{Translate("Accepted")}</p>
                    <p className="text-sm">{moment(data.accepted_at).format("LLL")}</p>
                </div>
            )
        }

        if (data.rejected_at) {
            return (
                <div>
                    <p className="bg-red-400 text-center text-xs font-semibold px-2 py-1 rounded text-white">{Translate("Rejected")}</p>
                    <p className="text-sm">{moment(data.rejected_at).format("LLL")}</p>
                </div>
            )
        }

        return (
            <div>
                <p className="bg-yellow-400 text-center text-xs font-semibold px-2 py-1 rounded text-white">{Translate("Waiting for result")}</p>
                <p className="text-sm"></p>
            </div>
        );

    }
    return (
        <div>
            <div className="w-full border shadow-sm rounded-md overflow-hidden bg-white">
                <div className="flex items-center py-3 px-4 border-b">
                    <p className="font-semibold">{offer.code}</p>
                    <div className="ml-auto">
                    <OfferStatus data={offer}></OfferStatus>
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row p-4">
                    <div className="w-full lg:w-96">
                    <div className="col-span-1">
                            <div className="bg-white rounded border overflow-hidden mb-4">
                                {
                                    offer.property.images.length > 0 ?
                                        <img className="object-cover h-36 w-full" src={offer.property.images[0].url} />
                                    :
                                    <div className="w-36 h-36 bg-gray-300"></div>
                                }
                                
                                <div className="p-4">
                                    <p className="text-xs">{offer.property.property_id}</p>
                                    <p className="text-lg font-medium">{offer.property.name}</p>
                                    <p className="text-lg font-medium">
                                        {new Intl.NumberFormat('ja-JP',{ style: 'currency', currency: offer.property.price.currency ?? "JPY" }).format(Number(offer.property.price.amount)) }
                                        </p>
                                    <PropertyStatusBadge status={offer.property.status}></PropertyStatusBadge>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col w-full ml-4">
                        <div className="flex w-full justify-between border-b  py-2">
                            <p className="text-gray-500">Application Date</p>
                            <p>{moment(offer.created_at).fromNow()}</p>
                        </div>
                        <div className="flex w-full justify-between border-b  py-2">
                            <p className="text-gray-500">Offer</p>
                            <p> {new Intl.NumberFormat('ja-JP',{ style: 'currency', currency: offer.offer.currency ?? "JPY" }).format(Number(offer.offer.amount)) }</p>
                        </div>
                        <div className="flex w-full justify-between border-b  py-2">
                            <p className="text-gray-500">Payment type</p>
                            <p>{Translate(offer.payment.method)}</p>
                        </div>

                    </div>
                </div>
            </div>
            <div className="mt-4 mb-6">
                <a href={'/v2/customers/' + customerId + '/offers/' + offer.code} className="bg-gray-100 py-1 border rounded px-2 text-xs font-medium inline-flex items-center"><span>
                    {Translate("View offer detail")}</span>
                 </a>
            </div>
        </div>
    )
}

export default function CustomerDetailOffers(props) {

    let { customerId } = useParams();
    const [offers, setOffers] = React.useState(null)


    React.useEffect(() => {
        loadOffers(customerId)
    }, [customerId])

    const loadOffers = (id) =>{
        api.customerOffer(id).then(data => {
            setOffers(data.items)
        })
        .catch(error => {
            console.log(error);
        })
    }

    return (
        <div className="flex flex-col relative h-full overflow-hidden overflow-y-auto w-full">
            <div className="container mx-auto  p-6">
                <p className="font-semibold text-xl mb-6">{Translate("Offers")}</p>
                {offers ? offers.map((offer, i) => <OfferRow key={i} offer={offer} customerId={customerId}></OfferRow>) : null}
            </div>
        </div>
    )
}