import { RadioGroup } from '@headlessui/react'
import { Controller } from "react-hook-form";
import clsx from "clsx";
import { Translate } from "../../../translate/Translate";


function CheckIcon(props) {
    return (
      <svg viewBox="0 0 24 24" fill="none" {...props}>
        <circle cx={12} cy={12} r={12} fill="#fff" opacity="0.2" />
        <path
          d="M7 13l3 3 7-7"
          stroke="#fff"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    )
}

function Option({option, area, className, onClick}){
    return (<RadioGroup.Option 
        as="div"
        value={option.id}
        className={({ checked }) =>
            `${checked || (area?.parent == option.id && area?.level > 1) ? 'bg-primary text-white' : 'bg-white'}
            cursor-pointer relative flex p-4 border rounded focus:outline-none 
            ${className}`
        }
        onClick={() => {
            onClick(option)
        }}
        >
        {({ checked }) => (
            <>
                <div className="flex w-full items-center justify-between" id={`area-${option.id}`}>
                <div className="flex items-center">
                    <div className="text-sm">
                    <RadioGroup.Label
                        as="p"
                        className={` flex items-center font-medium  ${checked || (area?.parent == option.id && area?.level > 1) ? 'text-white' : 'text-gray-600'}`}>
                        {option.name}

                    </RadioGroup.Label>
                    <RadioGroup.Description
                        as="span"
                        className={`inline ${
                        checked || (area?.parent == option.id && area?.level > 1) ? 'text-white' : 'text-gray-500'
                        }`}
                    >
                        <span>
                        
                        </span>
                    </RadioGroup.Description>
                    </div>
                </div>
                {
                    (checked || (area?.parent == option.id && area?.level > 1)) &&  (
                    <div className=" text-white">
                        <CheckIcon className="h-5 w-5" />
                    </div>
                    )
                }
                
                
                </div>
            </>
        )}
    </RadioGroup.Option>)
}
  

export default function AreaField(props) {
    const {name, control, options, parentOptions, area, onChangeCallback, grid, deselectable} = props

    return (
    <div className="w-full">
        <Controller name={name} control={control} render={({ field: { value, onChange } }) => {
            return (<RadioGroup value={value} onChange={(data) => {
                onChange(data)
                onChangeCallback && onChangeCallback(options.find((o) => o.id === data))
            }}>
                <RadioGroup.Label className={"sr-only"}>{Translate("Area")}</RadioGroup.Label>
                <div className={grid ? "grid grid-cols-3 gap-2":"space-y-2"}>
                    {options.map((option, index) => (<Option option={option} key={index} area={area} onClick={(opt) => {
                        if (deselectable) {
                            if (opt.id == area.id) {
                                onChangeCallback && onChangeCallback(parentOptions.find((o) => o.id === opt.parent))
                            }
                        }
                    }}  />))}
                </div>
            </RadioGroup>)
        }} />
    </div>)
}