
import { BASE_URL } from "../../../constants";
import { Translate } from "../../translate/Translate";

export default function PropertyInsight({data, isFavorite, views}) {
    return (<div className='grid grid-cols-1 lg:grid-cols-3'>
        <div className={`mt-4 border rounded overflow-hidden w-full ${data.images.length != 0 ? "": "bg-gray-100"}`}>
        {data.images.length != 0 ?<img src={data.images[0].url} className="h-32 w-full bg-gray-100 object-cover" alt={data.images[0].alt} />: null}
        </div>
        <div className="w-full p-4 lg:col-span-2">
            <a href={`${BASE_URL}/p/${data.property_id}`} rel="noreferrer" target="_blank">
                <div className=" "> 
                    <p className="text-xl lg:truncate">{data.name} </p>
                    <div className="flex items-baseline">
                        <p className="font-bold text-3xl leading-8">{new Intl.NumberFormat('ja-JP',{ style: 'currency', currency: data.price.currency ?? "JPY" }).format(Number(data.price.amount)) }</p>
                    </div>
                    <div className="flex mt-1">
                        <div className="flex space-x-6 lg:space-x-4 truncate overflow-ellipsis">
                            <div>
                                <p className="lg:text-xl text-gray-600">{Translate("Layout")}</p>
                                <p className="text-3xl font-medium truncate overflow-ellipsis">{data.layout.name}</p>
                            </div>
                            <div className="tooltip">
                                <p className="lg:text-xl text-gray-600">{Translate("Floor area")}</p>
                                <p className="text-3xl font-medium ">{(data.sqm || 0).toLocaleString()}m²</p>
                                
                            </div>
                            <div>
                                <p className="lg:text-xl text-gray-600">{Translate("Lot size")}</p>
                                <p className="text-3xl font-medium">{(data.land.sqm || 0).toLocaleString()}m²</p>
                            </div>
                        </div>
                    </div>
                    <div className="flex items-center ">
                        {isFavorite ?<span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-red-500 uppercase last:mr-0 mr-1">
                            {Translate("favorite")}
                        </span>: null}
                        {views ?<span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded text-white bg-green-500 uppercase last:mr-0 mr-1">
                            {Translate("number_of_views_title")}: {views}
                        </span>: null}
                        
                    </div>
                </div>
            </a>
        </div>
    </div>)
}