import React, { useCallback } from "react"
import { Translate } from "../../translate/Translate";

export function PricingTable(props) {
    return (
        <div className="w-full">
            <table className="table-auto w-full">
                <thead>
                    <tr className="border-b">
                        <th className="text-left px-6 py-3 text-gray-600 font-medium w-1/3">{Translate("Plans")}</th>
                        <th className="text-left px-6 py-3 text-lg font-semibold w-1/3">{Translate("Basic")}</th>
                        <th className="text-left px-6 py-3 text-lg  font-semibold w-1/3">{Translate("Partner Agent")}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="border-b">
                        <td className=" px-6 py-3 flex items-top text-gray-600 font-medium">{Translate("Pricing")}</td>
                        <td className="px-6 py-3">
                            <div>
                                <p className="text-3xl font-bold uppercase">{Translate("Free")}</p>
                                <p className="text-gray-600 mt-2">{Translate("Free forver with limited support")}</p>
                                <button className="mt-6 w-full py-2 bg-gray-600 text-white font-semibold rounded-md">{Translate("Join Viila")}</button>
                            </div>
                        </td>
                        <td className="px-6 py-3">
                        <div>
                                <p className="text-3xl font-bold">{Translate("¥50,000")}<span className="ml-1 text-sm text-gray-600">{Translate("per month")}</span></p>
                                <p className="text-blue-600 font-medium mt-2"><a href="https://buy.stripe.com/14k16B0ZK6SXcFibIJ">{Translate("Get 2 months free when paid annually")} ({Translate("¥500,000")}{Translate("per month")})</a></p>
                                <a href="https://buy.stripe.com/8wMcPj4bWcdh34I8wy"><button className="mt-6 w-full py-2 bg-gray-600 text-white font-semibold rounded-md">{Translate("Partner with Viila")}</button></a>
                            </div>
                        </td>
                    </tr>
                    <tr className="border-b bg-gray-100">
                        <td className="  px-6 py-3 flex items-top text-gray-600 font-medium">{Translate("Features")}</td>
                        <td className="px-6 py-3"></td>
                        <td className="px-6 py-3"></td>
                    </tr>

                    <tr className="border-b text-sm">
                        <td className="  px-6 py-3 flex items-top text-gray-600 font-normal">{Translate("Unlimited listing")}</td>
                        <td className="px-6 py-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-green-500 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </td>
                        <td className="px-6 py-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-green-500 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </td>
                    </tr>

                    <tr className="border-b text-sm">
                        <td className="  px-6 py-3 flex items-top text-gray-600 font-normal">{Translate("Manage offers")}</td>
                        <td className="px-6 py-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-green-500 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </td>
                        <td className="px-6 py-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-green-500 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </td>
                    </tr>

                    <tr className="border-b text-sm">
                        <td className="  px-6 py-3 flex items-top text-gray-600 font-normal">{Translate("Manage inquiries and scheduled viewing")}</td>
                        <td className="px-6 py-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-green-500 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </td>
                        <td className="px-6 py-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-green-500 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </td>
                    </tr>
                    <tr className="border-b text-sm">
                        <td className=" px-6 py-3 flex items-top text-gray-600 font-normal">{Translate("Unlimited property pictures")}</td>
                        <td className="px-6 py-3">
                        <svg xmlns="http://www.w3.org/2000/svg" className="text-green-500 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                        </td>
                        <td className="px-6 py-3">
                        <svg xmlns="http://www.w3.org/2000/svg" className="text-green-500 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                        </td>
                    </tr>
                    <tr className="border-b text-sm">
                        <td className="  px-6 py-3 flex items-top text-gray-600 font-normal">{Translate("Team")}</td>
                        <td className="px-6 py-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4" />
                            </svg>
                        </td>
                        <td className="px-6 py-3">
                        <svg xmlns="http://www.w3.org/2000/svg" className="text-green-500 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                        </td>
                    </tr>
                    <tr className="border-b text-sm">
                        <td className="  px-6 py-3 flex items-top text-gray-600 font-normal">{Translate("Unbranded Agent Page")}</td>
                        <td className="px-6 py-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4" />
                            </svg>
                        </td>
                        <td className="px-6 py-3">
                        <svg xmlns="http://www.w3.org/2000/svg" className="text-green-500 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                        </td>
                    </tr>
                    <tr className="border-b text-sm">
                        <td className=" px-6 py-3 items-top text-gray-600 font-normal inline-flex items-baseline">{Translate("3D Scan Service")} <a className="ml-2 text-xs text-blue-600" href="https://my.matterport.com/show/?m=nL7SUDJPC6p" target="_blank">{Translate("See example")}</a></td>
                        <td className="px-6 py-3 items-top">
                            <p className="">{Translate("¥30,000")} {Translate("per property")}</p>
                        </td>
                        <td className="px-6 py-3">
                            <p className="font-semibold text-blue-600">{Translate("¥10,000")} {Translate("per property")}</p>
                        </td>
                    </tr>
                    <tr className="border-b text-sm">
                        <td className=" px-6 py-3 items-top text-gray-600 font-normal inline-flex items-baseline">{Translate("High resolution Photo Service")} <a className="ml-2 text-xs text-blue-600" href="https://drive.google.com/drive/u/1/folders/1vfYLkZAm-GlsXbL48Xxpx4bnzDQt7nUl" target="_blank">{Translate("See example")}</a></td>
                        <td className="px-6 py-3 items-top">
                            <p className="">{Translate("¥30,000")} {Translate("per property")}</p>
                        </td>
                        <td className="px-6 py-3">
                            <p className="font-semibold text-blue-600">{Translate("¥10,000")} {Translate("per property")}</p>
                        </td>
                    </tr>
                    <tr className="border-b text-sm">
                        <td className="  px-6 py-3 flex items-top text-gray-600 font-normal">{Translate("Pin recommended properties")}</td>
                        <td className="px-6 py-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4" />
                            </svg>
                        </td>
                        <td className="px-6 py-3">
                        <svg xmlns="http://www.w3.org/2000/svg" className="text-green-500 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                        </td>
                    </tr>

                    <tr className="border-b text-sm">
                        <td className="  px-6 py-3 flex items-top text-gray-600 font-normal">{Translate("Private properties")}</td>
                        <td className="px-6 py-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4" />
                            </svg>
                        </td>
                        <td className="px-6 py-3">
                        <svg xmlns="http://www.w3.org/2000/svg" className="text-green-500 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                        </td>
                    </tr>
                    <tr className="border-b text-sm">
                        <td className="  px-6 py-3 inline-flex items-center items-top text-gray-600 font-normal">{Translate("Viila Partner Agent Logo")}
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-blue-600 ml-2" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M6.267 3.455a3.066 3.066 0 001.745-.723 3.066 3.066 0 013.976 0 3.066 3.066 0 001.745.723 3.066 3.066 0 012.812 2.812c.051.643.304 1.254.723 1.745a3.066 3.066 0 010 3.976 3.066 3.066 0 00-.723 1.745 3.066 3.066 0 01-2.812 2.812 3.066 3.066 0 00-1.745.723 3.066 3.066 0 01-3.976 0 3.066 3.066 0 00-1.745-.723 3.066 3.066 0 01-2.812-2.812 3.066 3.066 0 00-.723-1.745 3.066 3.066 0 010-3.976 3.066 3.066 0 00.723-1.745 3.066 3.066 0 012.812-2.812zm7.44 5.252a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                        </svg>
                        </td>
                        <td className="px-6 py-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4" />
                            </svg>
                        </td>
                        <td className="px-6 py-3">
                        <svg xmlns="http://www.w3.org/2000/svg" className="text-green-500 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                        </svg>
                        </td>
                    </tr>
                    <tr className="border-b bg-gray-100">
                        <td className="  px-6 py-3 flex items-top text-gray-600 font-medium">{Translate("Support")}</td>
                        <td className="px-6 py-3"></td>
                        <td className="px-6 py-3"></td>
                    </tr>
                    <tr className="border-b text-sm">
                        <td className="  px-6 py-3 flex items-top text-gray-600 font-normal">{Translate("Email")}</td>
                        <td className="px-6 py-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-green-500 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </td>
                        <td className="px-6 py-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-green-500 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </td>
                    </tr>
                    <tr className="border-b text-sm">
                        <td className="  px-6 py-3 flex items-top text-gray-600 font-normal">{Translate("Phone")}</td>
                        <td className="px-6 py-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-gray-400 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 12H4" />
                            </svg>
                        </td>
                        <td className="px-6 py-3">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-green-500 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                            </svg>
                        </td>
                    </tr>
                    <tr className="border-b">
                        <td className="px-6 py-3"></td>
                        <td className="px-6 py-3"></td>
                        <td className="pt-3 pb-12">
                            <a href="https://buy.stripe.com/8wMcPj4bWcdh34I8wy" target="_blank"><button className="w-full py-2 bg-blue-600 text-white font-semibold rounded-md">{Translate("Join Viila Partner Agent")}</button></a>
                        </td>
                        
                    </tr>
                    
                </tbody>
            </table>
        </div>
    )
}