import React from "react"
import AppContext from "../../../AppContext"

export default function DateTime(props) {

    const {language} = React.useContext(AppContext)   
    const {date} = props;
    return (
        <>
        {new Intl.DateTimeFormat( language === "en" ? "en-US" : "ja-JP", { dateStyle: 'full', timeStyle: 'short' }).format(new Date(date))}
        </>
    )
}