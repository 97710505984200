import clsx from "clsx";
import { useDropzone } from 'react-dropzone'
import * as api from '../../services/agent'
import { Translate } from "../../translate/Translate";
import TextAreaField from "./StepWizard/TextAreaField";
import React, { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'


export default function SellRequestReplyDialog(props) {
    const { isOpen, setIsOpen } = props
    const [data, setData] = useState({
        archived_files: [],
        attachments: [],
        subject: "",
        body: ""
    })

    const onDrop = React.useCallback((acceptedFiles, fileRejections) => {
        acceptedFiles.forEach((file) => {
            var reader = new FileReader();
            reader.onload = function (event) {
                var imageData = event.target.result;
                var base64Data = imageData.split("base64,")[1];
                var fileName = file.name.replace(/\.[^/.]+$/, "")
                api.uploadAndGetFullContent(base64Data, fileName)
                    .then(response => {
                        var attachments = [...data.attachments]
                        var list = attachments ? [...attachments] : [];
                        list.push(response.data)
                        attachments = list
                        setData({ ...data, attachments: attachments })
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
            reader.readAsDataURL(file);
        });

        fileRejections.forEach((file) => {
            file.errors.forEach((err) => {
                if (err.code === "file-too-large") {
                    alert("file size is exceed limit (10MB)")
                }
            })
        })
    }, [data])

    const dropMessageAttachments = useDropzone({
        onDrop: onDrop,
        accept: 'image/jpeg, image/png, application/pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
        multiple: false,
        maxSize: 10 * 1024 * 1024, // 10MB
    });

    const removeFileAt = (index) => {
        var attachments = [...data.attachments]
        var list = attachments ? [...attachments] : [];
        var archived = data.archived_files ? [...data.archived_files] : [];
        let removed = list.splice(index, 1)
        archived.push(...removed)
        data.archived_files = archived
        attachments = list
        setData({ ...data, attachments: attachments })
    }


    function closeModal() {
        setIsOpen(false)
    }


    function updateReply() {
        api.updateSellRequestReply(data)
            .then(response => {
                alert("done")
            })
            .catch(error => {
                console.log(error);
            })
    }

    function getReply() {
        api.getSellRequestReply()
            .then(response => {
                if (!response.data.attachments) {
                    response.data.attachments = []
                }
                if (!response.data.archived_files) {
                    response.data.archived_files = []
                }
                setData(response.data)
            })
            .catch(error => {
                console.log(error);
            })
    }


    React.useEffect(() => {
        getReply()
    }, []);

    return (
        <>
            <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-[50]" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300 "
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-100"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25"></div>
                    </Transition.Child>

                    <div className="fixed top-0 left-0 inset-0 w-full h-full outline-none">
                        <div className="flex items-center justify-end h-full">
                            <Transition.Child
                                as={Fragment}
                                enter="ease duration-300 transition transform"
                                enterFrom="opacity-0 translate-x-full"
                                enterTo="opacity-100 translate-x-0"
                                leave="ease duration-200 transition transform"
                                leaveFrom="opacity-100 translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <Dialog.Panel className="border-none shadow-lg relative flex flex-col lg:w-4/5 w-full h-full pointer-events-auto bg-white bg-clip-padding  outline-none text-current">
                                    <Dialog.Title
                                        as="div"
                                        className="flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md"
                                    >
                                        <h3 className='text-lg font-medium leading-6 text-gray-900'>
                                            {Translate("auto_reply_title")}
                                        </h3>
                                        <button type="button" id="close" onClick={closeModal}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    </Dialog.Title>
                                    <div className="relative flex-1  overflow-hidden">
                                        <div className={"grid grid-cols-1 text-sm bg-white"}>
                                            <div className="py-6  px-6">
                                                <div className="flex items-center">
                                                    <div>
                                                        {/* <p className="text-lg font-medium">{Translate("Property Materials")}</p> */}
                                                        <p className="text-sm text-gray-700">{Translate("auto_reply_description")}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="py-4 lg:py-4 px-6">
                                                <div className="col-span-1 py-4 lg:py-4 px-6">
                                                    <div className="grid grid-cols-3">
                                                        <div className="col-span-3 lg:col-span-1 pr-4">
                                                            <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("auto_reply_subject")}</p>
                                                        </div>
                                                        <div className="col-span-3 lg:col-span-2">
                                                            <input
                                                                value={data.subject}
                                                                onChange={e => { setData({ ...data, subject: e.target.value }) }}
                                                                id="data-subject" name="data.subject"
                                                                placeholder={Translate("Subject")}
                                                                className="flex-1 border border-gray-500 p-4 w-full h-8"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-3">
                                                        <div className="col-span-3 lg:col-span-1 pr-4">
                                                            <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("auto_reply_body")}</p>
                                                            {/* <p className="text-xs text-gray-700 mb-2 lg:mb-0">{Translate("When a Viila user requests property materials, this message will be appeared in the automated email we send to the user.")}</p> */}
                                                        </div>
                                                        <div className="col-span-3 lg:col-span-2">
                                                            <textarea
                                                                value={data.body}
                                                                onChange={e => { setData({ ...data, body: e.target.value }) }}
                                                                required rows="6"
                                                                id="data-body" name="data.body"
                                                                placeholder={Translate("Body")}
                                                                className="mt-4 w-full"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-3">
                                                        <div className="col-span-3 lg:col-span-1">
                                                            <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("auto_reply_attachments")}</p>
                                                        </div>
                                                        <div className="col-span-3 lg:col-span-2">
                                                            {data.attachments === null ? null : data.attachments.map((file, index) => (
                                                                <div className="flex mb-2" key={`att-${index}`}>
                                                                    {file.path ? <>
                                                                    <a href={file.path} id={`file-${index}`} className="w-full" target="_blank" > <p>{file.path.substring(file.path.lastIndexOf('/') + 1)}</p></a>
                                                                    <button type="button" id={`remove-file-${index}`} onClick={(e) => removeFileAt(index)} className="ml-2 focus:outline-none text-xs font-medium text-red-500">
                                                                        <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                                                        </svg>
                                                                    </button>
                                                                    </> : null }
                                                                </div>

                                                            ))}

                                                            <div {...dropMessageAttachments.getRootProps()} className="mt-4 w-full flex items-center text-center mx-auto h-24 border-dashed border-2 rounded border-gray-400 bg-white">
                                                                <div className="mx-auto text-xs">

                                                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mx-auto mb-2 stroke-current text-gray-700" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                                                                    </svg>
                                                                    <input className="w-full h-full" {...dropMessageAttachments.getInputProps()} />
                                                                    <p><button type="button" className="focus:outline-none text-indigo-600 font-medium">{Translate("Upload files")}</button> {Translate("or drag and drop")}</p>
                                                                    <p className="text-xs">{Translate("JPG, PNG, PDF, XLS, DOC, DOCX")}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-auto flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                                        <div className="ml-auto">
                                            <button id="update" type="button" className={data.status == "delete" ? "btn bg-red-600 text-white" : "btn btn-primary"} onClick={e => updateReply()}>{Translate("Update")}</button>
                                        </div>
                                    </div>

                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>)
}
