import React from "react"
import { Link } from 'react-router-dom';

export default function Policy(props) {
    const {Translate} = props

    return (
        <div>

            <nav className="w-full  border-b bg-white sticky top-0 z-50">

                <div className="flex container mx-auto py-2  px-5">
                    <Link to="/" className="flex items-center text-gray-800">
                        <svg className="h-8 w-8" width="86" height="81" viewBox="0 0 86 81" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 41.9772C0 43.7729 1.40638 45.3269 3.65658 45.3269C4.74652 45.3269 5.73099 44.7398 6.60998 44.0492L10.6182 40.734V72.8501C10.6182 77.9611 13.7473 81 19.1267 81H66.6975C72.0417 81 75.206 77.9611 75.206 72.8501V40.5613L79.4252 44.0492C80.269 44.7398 81.2535 45.3269 82.3434 45.3269C84.4178 45.3269 86 44.0492 86 42.0462C86 40.8721 85.5429 39.9397 84.6288 39.18L75.206 31.3754V16.6641C75.206 15.1101 74.1864 14.1432 72.6042 14.1432H67.7522C66.2052 14.1432 65.1505 15.1101 65.1505 16.6641V23.0874L48.0278 8.96316C44.9689 6.44222 41.1014 6.44222 38.0425 8.96316L1.40638 39.18C0.457073 39.9397 0 40.9757 0 41.9772Z" fill="black" />
                            <path d="M75.6069 5.30434C75.6069 8.23385 73.189 10.6087 70.2064 10.6087C67.2238 10.6087 64.8059 8.23385 64.8059 5.30434C64.8059 2.37483 67.2238 0 70.2064 0C73.189 0 75.6069 2.37483 75.6069 5.30434Z" fill="black" />
                        </svg>
                        <span className="text-lg font-bold ml-2">Agent Dashboard</span>
                    </Link>
                    <div className="ml-auto">
                        <Link to="/login" className="flex items-center btn btn-primary">
                            <span className="mr-1">{Translate("Sign in")}</span>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                            </svg>
                        </Link> 
                    </div>
                </div>
            </nav>


            <div className="w-full relative overflow-hidden bg-slate-50">

                <div className="container mx-auto px-8 my-12">
                    <h1><strong>プライバシーポリシー</strong></h1>
                    <p><span>Viila Technologies株式会社（以下，「当社」といいます。）は，本ウェブサイト上で提供するサービス（以下,「本サービス」といいます。）における，ユーザーの個人情報の取扱いについて，以下のとおりプライバシーポリシー（以下，「本ポリシー」といいます。）を定めます。</span></p>
                    <h2 className="text-xl mb-4 mt-8"><strong>第1条（個人情報）</strong></h2>
                    <p><span>「個人情報」とは，個人情報保護法にいう「個人情報」を指すものとし，生存する個人に関する情報であって，当該情報に含まれる氏名，生年月日，住所，電話番号，連絡先その他の記述等により特定の個人を識別できる情報及び容貌，指紋，声紋にかかるデータ，及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。</span></p>
                    <h2 className="text-xl mb-4 mt-8"><strong>第2条（個人情報の収集方法）</strong></h2>
                    <p><span>当社は，ユーザーが利用登録をする際に氏名，生年月日，住所，電話番号，メールアドレス，銀行口座番号，クレジットカード番号，運転免許証番号などの個人情報をお尋ねすることがあります。また，ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を,当社の提携先（情報提供元，広告主，広告配信先などを含みます。以下，｢提携先｣といいます。）などから収集することがあります。</span></p>
                    <h2 className="text-xl mb-4 mt-8"><strong>第3条（個人情報を収集・利用する目的）</strong></h2>
                    <p><span>当社が個人情報を収集・利用する目的は，以下のとおりです。</span></p>
                    <ol>
                    <li><span>当社サービスの提供・運営のため</span></li>
                    <li><span>ユーザーからのお問い合わせに回答するため（本人確認を行うことを含む）</span></li>
                    <li><span>ユーザーが利用中のサービスの新機能，更新情報，キャンペーン等及び当社が提供する他のサービスの案内のメールを送付するため</span></li>
                    <li><span>メンテナンス，重要なお知らせなど必要に応じたご連絡のため</span></li>
                    <li><span>利用規約に違反したユーザーや，不正・不当な目的でサービスを利用しようとするユーザーの特定をし，ご利用をお断りするため</span></li>
                    <li><span>ユーザーにご自身の登録情報の閲覧や変更，削除，ご利用状況の閲覧を行っていただくため</span></li>
                    <li><span>上記の利用目的に付随する目的</span></li>
                    </ol>
                    <h2 className="text-xl mb-4 mt-8"><strong>第4条（利用目的の変更）</strong></h2>
                    <ol>
                    <li><span>当社は，利用目的が変更前と関連性を有すると合理的に認められる場合に限り，個人情報の利用目的を変更するものとします。</span></li>
                    <li><span>利用目的の変更を行った場合には，変更後の目的について，当社所定の方法により，ユーザーに通知し，または本ウェブサイト上に公表するものとします。</span></li>
                    </ol>
                    <h2 className="text-xl mb-4 mt-8"><strong>第5条（個人情報の第三者提供）</strong></h2>
                    <ol>
                    <li><span>当社は，次に掲げる場合を除いて，あらかじめユーザーの同意を得ることなく，第三者に個人情報を提供することはありません。ただし，個人情報保護法その他の法令で認められる場合を除きます。</span></li>
                    <ol>
                    <li><span>人の生命，身体または財産の保護のために必要がある場合であって，本人の同意を得ることが困難であるとき</span></li>
                    <li><span>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって，本人の同意を得ることが困難であるとき</span></li>
                    <li><span>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって，本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</span></li>
                    <li><span>予め次の事項を告知あるいは公表し，かつ当社が個人情報保護委員会に届出をしたとき</span></li>
                    <ol>
                    <li><span>利用目的に第三者への提供を含むこと</span></li>
                    <li><span>第三者に提供されるデータの項目</span></li>
                    <li><span>第三者への提供の手段または方法</span></li>
                    <li><span>本人の求めに応じて個人情報の第三者への提供を停止すること</span></li>
                    <li><span>本人の求めを受け付ける方法</span></li>
                    </ol>
                    </ol>
                    <li><span>前項の定めにかかわらず，次に掲げる場合には，当該情報の提供先は第三者に該当しないものとします。</span></li>
                    <ol>
                    <li><span>当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</span></li>
                    <li><span>合併その他の事由による事業の承継に伴って個人情報が提供される場合</span></li>
                    <li><span>個人情報を特定の者との間で共同して利用する場合であって，その旨並びに共同して利用される個人情報の項目，共同して利用する者の範囲，利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について，あらかじめ本人に通知し，または本人が容易に知り得る状態に置いた場合</span></li>
                    </ol>
                    </ol>
                    <h2 className="text-xl mb-4 mt-8"><strong>第6条（個人情報の開示）</strong></h2>
                    <ol>
                    <li><span>当社は，本人から個人情報の開示を求められたときは，本人に対し，遅滞なくこれを開示します。ただし，開示することにより次のいずれかに該当する場合は，その全部または一部を開示しないこともあり，開示しない決定をした場合には，その旨を遅滞なく通知します。なお，個人情報の開示に際しては，1件あたり1，000円の手数料を申し受けます。</span></li>
                    <ol>
                    <li><span>本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合</span></li>
                    <li><span>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</span></li>
                    <li><span>その他法令に違反することとなる場合</span></li>
                    </ol>
                    <li><span>前項の定めにかかわらず，履歴情報および特性情報などの個人情報以外の情報については，原則として開示いたしません。</span></li>
                    </ol>
                    <h2 className="text-xl mb-4 mt-8"><strong>第7条（個人情報の訂正および削除）</strong></h2>
                    <ol>
                    <li><span>ユーザーは，当社の保有する自己の個人情報が誤った情報である場合には，当社が定める手続きにより，当社に対して個人情報の訂正，追加または削除（以下，「訂正等」といいます。）を請求することができます。</span></li>
                    <li><span>当社は，ユーザーから前項の請求を受けてその請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の訂正等を行うものとします。</span></li>
                    <li><span>当社は，前項の規定に基づき訂正等を行った場合，または訂正等を行わない旨の決定をしたときは遅滞なく，これをユーザーに通知します。</span></li>
                    </ol>
                    <h2 className="text-xl mb-4 mt-8"><strong>第8条（個人情報の利用停止等）</strong></h2>
                    <ol>
                    <li><span>当社は，本人から，個人情報が，利用目的の範囲を超えて取り扱われているという理由，または不正の手段により取得されたものであるという理由により，その利用の停止または消去（以下，「利用停止等」といいます。）を求められた場合には，遅滞なく必要な調査を行います。</span></li>
                    <li><span>前項の調査結果に基づき，その請求に応じる必要があると判断した場合には，遅滞なく，当該個人情報の利用停止等を行います。</span></li>
                    <li><span>当社は，前項の規定に基づき利用停止等を行った場合，または利用停止等を行わない旨の決定をしたときは，遅滞なく，これをユーザーに通知します。</span></li>
                    <li><span>前2項にかかわらず，利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって，ユーザーの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は，この代替策を講じるものとします。</span></li>
                    </ol>
                    <h2 className="text-xl mb-4 mt-8"><strong>第9条（プライバシーポリシーの変更）</strong></h2>
                    <ol>
                    <li><span>本ポリシーの内容は，法令その他本ポリシーに別段の定めのある事項を除いて，ユーザーに通知することなく，変更することができるものとします。</span></li>
                    <li><span>当社が別途定める場合を除いて，変更後のプライバシーポリシーは，本ウェブサイトに掲載したときから効力を生じるものとします。</span></li>
                    </ol>
                    <h2 className="text-xl mb-4 mt-8"><strong>第10条（お問い合わせ窓口）</strong></h2>
                    <p><span>本ポリシーに関するお問い合わせは，下記の窓口までお願いいたします。</span></p>
                    <p><span>住所：5F Daiwa Azabu Terrace, 3-20-1 Minamiazabu Minato-ku, Tokyo, 106-0047, 日本</span></p>
                    <p><span>社名：Viila Technologies株式会社</span></p>
                    <p><span>Eメールアドレス：hello@viila.co</span></p>
                    <p><br /><br /></p>
                </div>
            </div>
            <div id="footer" className="py-10 bg-gray-900">
                <div className="container mx-auto p-4">
                    <p className="text-sm text-gray-500">©Viila Technologies株式会社. All rights reserved.</p>
                </div>
            </div>

        </div>
    )
}