import { useEffect } from "react"

const DocumentTitle = ({title}) => {
    useEffect(() => {
        document.title = title + " | Viila Agent Dashboard"  || "Agent Dashboard"
    }, [title]) 

    return (<></>)
}

export default DocumentTitle