import clsx from 'clsx';
import { useState } from "react"
import { usePopper } from 'react-popper'
import { Controller } from "react-hook-form";
import { Translate } from "../../../translate/Translate"

export default function MoneyInputField(props) {
    const { id, name, placeholder, required = false, className, control, clearErrors, defaultValue, onChangeCallback, prefix = "¥", suffix } = props
    const [referenceRef, setReferenceRef] = useState(null);
    const [popperRef, setPopperRef] = useState(null);

    const { styles, attributes } = usePopper(referenceRef, popperRef, {
        placement: "bottom-start",
        modifiers: [
            {
                name: "offset",
                enabled: true,
                options: {
                    offset: [0, 10]
                }
            },
        ]
    });

    const borderStyle = (error, touched) => {
        if (error) {
            return "error border-red-600 border-1 focus:border-red-600 focus:ring-red-600 focus:focus:ring-1"
        }
        // if (touched) {
        //     return "border-green-600 border-2"
        // }
        return ""
    }

    return (<Controller
        name={name}
        control={control}
        defaultValue={defaultValue ?? ''}
        render={({ field: { value, onChange, onBlur }, fieldState: { invalid, isTouched, error } }) => {
            return (<>
                <div className="relative flex items-center w-full" ref={setReferenceRef}>

                    <div className="rounded-l px-3 h-10 flex items-center justify-center ring-1 ring-gray-500 bg-gray-100 ">
                        <span className="text-gray-500 whitespace-nowrap">{prefix}</span>
                    </div>
                    <input
                        className={clsx("z-100 w-full placeholder-gray-200", className, borderStyle(error, isTouched), prefix && !suffix ? "rounded-r" : "", !prefix && suffix ? "rounded-l" : "" )}
                        required={required}
                        type="text"
                        name={name}
                        id={id || name}
                        placeholder={placeholder?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        onChange={(e) => {
                            let price = parseFloat(e.target.value.trim().replaceAll(",", "").normalize('NFKC').replace(/[^0-9]/g, ""))
                            if (!isNaN(price)) {
                                price = price.toString()
                            } else {
                                price = ""
                            }
                            onChange(price)
                            onChangeCallback && onChangeCallback(price)
                        }}
                        onFocus={() => {
                            clearErrors(name)
                            // if (value)
                            //     onChange(`${value}`.replaceAll(",", ""))
                        }}
                        onBlur={(e) => {
                            if (value == "") {
                                onBlur()
                                return;
                            }

                            let price = parseFloat(value.toString().trim().replaceAll(",", "").normalize('NFKC').replace(/[^0-9]/g, ""))
                            if (!isNaN(price)) {
                                price = price.toString()
                            } else {
                                price = ""
                            }
                            onChange(price)
                        }}
                        defaultValue={defaultValue?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        value={value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} />
                    {
                        suffix ?
                            <div className="rounded-r px-3 h-10 flex items-center justify-center ring-1 ring-gray-500 bg-gray-100 z-10 ">
                                <span className="text-gray-500 whitespace-nowrap">{suffix}</span>
                            </div>
                            : null
                    }

                </div>


                <div ref={setPopperRef} className={`bg-red-600 text-white rounded-lg p-1 z-10 ${error ? "block" : "hidden"}`} style={styles.popper} {...attributes.popper}>
                    <p className="text-white p-1 font-semibold">{Translate(error?.message)}</p>
                    <div className={`absolute left-12 -top-1 w-3 h-3 bg-red-600 -z-10 ${error ? "block" : "hidden"}`} style={{ "transform": "rotate(45deg)" }}></div>
                </div>
            </>)
        }}
    />)
}