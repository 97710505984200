
import React from "react"

import { Translate } from "../../translate/Translate";

export default function PropertyRequestBuilder(props) {

    const {agents, propertyRequest} = props

    const onSelectStatuses = (e) => {
        let status = e.target.value
        if (props.propertyRequest.list_status === undefined) {
            props.propertyRequest.list_status = []
        }
        const index = props.propertyRequest.list_status.indexOf(status);
        if (index > -1) {
            props.propertyRequest.list_status.splice(index, 1);
        } else {
            props.propertyRequest.list_status.push(status)
        }
        props.onPropertyRequestChange("list_status", props.propertyRequest.list_status)
    }

    const isStatusSelected = (status) => {
        return props.propertyRequest.list_status.includes(status)
    }

    const onSelectPropertyTypes = (e) => {
        let status = e.target.value
        if (props.propertyRequest.property_types === undefined) {
            props.propertyRequest.property_types = []
        }
        const index = props.propertyRequest.property_types.indexOf(status);
        if (index > -1) {
            props.propertyRequest.property_types.splice(index, 1);
        } else {
            props.propertyRequest.property_types.push(status)
        }
        props.onPropertyRequestChange("property_types", props.propertyRequest.property_types)
    }

    const isPropertyTypeSelected = (type) => {
        return props.propertyRequest.property_types.includes(type)
    }


    const onSelectAgents = (e) => {
        let value = e.target.value
        if (props.propertyRequest.agents === undefined) {
            props.propertyRequest.agents = []
        }
        const index = props.propertyRequest.agents.indexOf(value);
        if (index > -1) {
            props.propertyRequest.agents.splice(index, 1);
        } else {
            props.propertyRequest.agents.push(value)
        }
        props.onPropertyRequestChange("agents", props.propertyRequest.agents)
    }

    const isAgentSelected = (type) => {
        return props.propertyRequest.agents.includes(type)
    }

    const onChangePrice = (e) => {
        let value = e.target.value
        let field = e.target.dataset.field
        props.propertyRequest.price[field] = value
        props.onPropertyRequestChange("price", props.propertyRequest.price)
    }

    const onChangeBuildingSize = (e) => {
        let value = e.target.value
        let field = e.target.dataset.field
        props.propertyRequest.sqm[field] = value
        props.onPropertyRequestChange("sqm", props.propertyRequest.sqm)
    }

    const onChangeLandSize = (e) => {
        let value = e.target.value
        let field = e.target.dataset.field
        props.propertyRequest.lot[field] = value
        props.onPropertyRequestChange("lot", props.propertyRequest.lot)
    }

    const onChangeTransactionType = (e) => {
        let value = e.target.value
        props.onPropertyRequestChange("transaction_type", value)
    }

    const onToggleSelectAllAgents = (e) => {
        let checked = e.target.checked
        if (checked) {
            props.propertyRequest.agents = []
            agents.map((a)=>{
                props.propertyRequest.agents.push(a.agent_id)
            })
        }else {
            props.propertyRequest.agents  = []
        }
        props.onPropertyRequestChange("agents", props.propertyRequest.agents)
    }

    return (
        <div className="flex flex-col pb-6 relative">

            <div className="flex-none flex items-center px-4 py-2 w-full border-b bg-white top-0 sticky z-40">
                <p className="font-medium text-lg">{Translate("filters_title")} <span className="">({props.properties.features.length})</span></p>
                {/* <button className="ml-auto">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button> */}
            </div>


            <div className="px-4 py-2 flex items-center relative ">

                <div className="flex flex-col gap-4">

                    <div className="text-sm">
                        <p className="text-sm text-gray-500 font-medium mb-2">{Translate("Transaction type")}</p>
                        <div className="grid grid-cols-2 gap-2">

                        <div className="inline-flex items-center">
                                <input id="transaction-type-seller" checked={propertyRequest.transaction_type === ""}
                                 onChange={onChangeTransactionType} name="transaction_type" value="" type="radio" className=" h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 "
                                />
                                <label htmlFor="transaction-type-seller" className="ml-2 block text-sm">{Translate("All")}</label>
                            </div>

                            <div className="inline-flex items-center">
                                <input id="transaction-type-seller" checked={propertyRequest.transaction_type === "seller"}
                                 onChange={onChangeTransactionType} name="transaction_type" value="seller" type="radio" className=" h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 "
                                />
                                <label htmlFor="transaction-type-seller" className="ml-2 block text-sm">{Translate("seller")}</label>
                            </div>

                            <div className="inline-flex items-center">
                                <input id="transaction-type-brokerage_general" checked={propertyRequest.transaction_type === "brokerage_general"} 
                                onChange={onChangeTransactionType} name="transaction_type" value="brokerage_general" type="radio" className=" h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 "
                                />
                                <label htmlFor="transaction-type-brokerage_general" className="ml-2 block text-sm">{Translate("brokerage_general")}</label>
                            </div>
                            <div className="inline-flex items-center">
                                <input id="transaction-type-brokerage_exclusive" checked={propertyRequest.transaction_type === "brokerage_exclusive"}
                                 onChange={onChangeTransactionType} name="transaction_type" value="brokerage_exclusive" type="radio" className=" h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 "
                                />
                                <label htmlFor="transaction-type-brokerage_exclusive" className="ml-2 block text-sm">{Translate("brokerage_exclusive")}</label>
                            </div>
                            <div className="inline-flex items-center">
                                <input id="transaction-type-brokerage_privileged_exclusive" checked={propertyRequest.transaction_type === "brokerage_privileged_exclusive"}
                                 onChange={onChangeTransactionType} name="transaction_type" value="brokerage_privileged_exclusive" type="radio" className=" h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 "

                                />
                                <label htmlFor="transaction-type-brokerage_privileged_exclusive" className="ml-2 block text-sm">{Translate("brokerage_privileged_exclusive")}</label>
                            </div>

                            <div className="inline-flex items-center">
                                <input id="transaction-type-proxy" checked={propertyRequest.transaction_type === "proxy"}
                                 onChange={onChangeTransactionType} name="transaction_type" value="proxy" type="radio" className=" h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 "

                                />
                                <label htmlFor="transaction-type-proxy" className="ml-2 block text-sm">{Translate("proxy")}</label>
                            </div>

                        </div>
                    </div>


                    <div className="text-sm">
                        <p className="text-sm text-gray-500 font-medium mb-2">{Translate("Property type")}</p>
                        <div className="grid grid-cols-2 gap-2">
                            <div className="inline-flex items-center">
                                <input id="property-type-house" name="property-type" type="checkbox" value="house" className=" h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 rounded"
                                    checked={isPropertyTypeSelected("house")}
                                    onChange={onSelectPropertyTypes}
                                />
                                <label htmlFor="property-type-house" className="ml-2 block text-sm">{Translate("House")}</label>
                            </div>

                            <div className="inline-flex items-center">
                                <input id="property-type-land" name="property-type" type="checkbox" value="lot" className=" h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 rounded"
                                    checked={isPropertyTypeSelected("lot")}
                                    onChange={onSelectPropertyTypes}
                                />
                                <label htmlFor="property-type-land" className="ml-2 block text-sm">{Translate("Land")}</label>
                            </div>

                            <div className="inline-flex items-center">
                                <input id="property-type-apartment" name="property-type" type="checkbox" value="apartment" className=" h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 rounded"
                                    checked={isPropertyTypeSelected("apartment")}
                                    onChange={onSelectPropertyTypes}
                                />
                                <label htmlFor="property-type-apartment" className="ml-2 block text-sm">{Translate("Apartment")}</label>
                            </div>
                        </div>
                    </div>

                    <div className="text-sm">
                        <p className="text-sm text-gray-500 font-medium mb-2">{Translate("Property status")}</p>
                        <div className="grid grid-cols-2 gap-2">
                            <div className="inline-flex items-center">
                                <input id="property-status-active" name="list_status" type="checkbox" className=" h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 rounded" value="active" checked={isStatusSelected("active")} onChange={onSelectStatuses} />
                                <label htmlFor="property-status-active" className="ml-2 block">{Translate("Active")}</label>
                            </div>
                            <div className="inline-flex items-center">
                                <input id="property-status-business-negotiation" name="list_status" type="checkbox" value="business_negotiation" className=" h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 rounded" checked={isStatusSelected("business_negotiation")} onChange={onSelectStatuses} />
                                <label htmlFor="property-status-business-negotiation" className="ml-2 block">{Translate("business_negotiation")}</label>
                            </div>
                            <div className="inline-flex items-center">
                                <input id="property-status-sold" name="list_status" type="checkbox" value="sold" className=" h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 rounded" checked={isStatusSelected("sold")} onChange={onSelectStatuses} />
                                <label htmlFor="property-status-sold" className="ml-2 block">{Translate("Sold")}</label>
                            </div>
                            <div className="inline-flex items-center">
                                <input id="property-status-inactive" name="list_status" type="checkbox" value="inactive" className=" h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 rounded" checked={isStatusSelected("inactive")} onChange={onSelectStatuses} />
                                <label htmlFor="property-status-inactive" className="ml-2 block">{Translate("Inactive")}</label>
                            </div>
                            <div className="inline-flex items-center">
                                <input id="property-status-draft" name="list_status" type="checkbox" value="draft" className=" h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 rounded" checked={isStatusSelected("draft")} onChange={onSelectStatuses} />
                                <label htmlFor="property-status-draft" className="ml-2 block">{Translate("Draft")}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="ml-auto flex gap-4">
                    {/* <button className="px-2 py-1 rounded border border-blue-600 text-blue-600 text-sm font-medium" onClick={(e) => { setMoreFiltersPresented(!moreFiltersPresented) }}>
                        More Filters
                    </button> */}
                </div>

            </div>


            <div className="flex flex-col items-center gap-x-6 gap-y-3 flex-wrap px-4 mt-2">

                <div className="w-full">
                    <p className="text-sm text-gray-500 mb-2">{Translate("Land size")}</p>
                    <div className="flex items-center justify-between gap-4">
                        <select onChange={onChangeLandSize} value={propertyRequest.lot.min} data-field="min" className="border-gray-200 rounded text-sm w-full">
                            <option value="" selected="">-</option>
                            <option value="200">200 m²</option>
                            <option value="300">300 m²</option>
                            <option value="400">400 m²</option>
                            <option value="500">500 m²</option>
                            <option value="600">600 m²</option>
                            <option value="700">700 m²</option>
                            <option value="800">800 m²</option>
                            <option value="900">900 m²</option>
                            <option value="1000">1,000 m²</option>
                            <option value="1200">1,200 m²</option>
                            <option value="2000">2,000 m²</option>
                        </select>

                        <select onChange={onChangeLandSize} value={propertyRequest.lot.max} data-field="max" className="border-gray-200 rounded text-sm w-full">
                            <option value="" selected="">-</option>
                            <option value="2000">2,000 m²</option>
                            <option value="1200">1,200 m²</option>
                            <option value="1000">1,000 m²</option>
                            <option value="900">900 m²</option>
                            <option value="800">800 m²</option>
                            <option value="700">700 m²</option>
                            <option value="600">600 m²</option>
                            <option value="500">500 m²</option>
                            <option value="400">400 m²</option>
                            <option value="300">300 m²</option>
                            <option value="200">200 m²</option>
                        </select>
                    </div>
                </div>

                <div className="w-full">
                    <p className="text-sm text-gray-500 mb-2">{Translate("Building size")}</p>
                    <div className="flex items-center justify-between gap-4">
                        <select onChange={onChangeBuildingSize} value={propertyRequest.sqm.min} data-field="min" className="border-gray-200 rounded text-sm w-full">
                            <option value="" selected="">-</option>
                            <option value="30">30 m²</option>
                            <option value="40">40 m²</option>
                            <option value="50">50 m²</option>
                            <option value="60">60 m²</option>
                            <option value="70">70 m²</option>
                            <option value="80">80 m²</option>
                            <option value="90">90 m²</option>
                            <option value="100">100 m²</option>
                            <option value="110">110 m²</option>
                            <option value="120">120 m²</option>
                            <option value="130">130 m²</option>
                            <option value="140">140 m²</option>
                            <option value="150">150 m²</option>
                            <option value="200">200 m²</option>
                        </select>


                        <select onChange={onChangeBuildingSize} value={propertyRequest.sqm.min} data-field="max" className="border-gray-200 rounded text-sm w-full">
                            <option value="" selected="">-</option>
                            <option value="200">200 m²</option>
                            <option value="150">150 m²</option>
                            <option value="140">140 m²</option>
                            <option value="130">130 m²</option>
                            <option value="120">120 m²</option>
                            <option value="110">110 m²</option>
                            <option value="100">100 m²</option>
                            <option value="90">90 m²</option>
                            <option value="80">80 m²</option>
                            <option value="70">70 m²</option>
                            <option value="60">60 m²</option>
                            <option value="50">50 m²</option>
                            <option value="40">40 m²</option>
                            <option value="30">30 m²</option>
                        </select>

                    </div>
                </div>

                <div className="w-full">
                    <p className="text-sm text-gray-500 mb-2">{Translate("Price")}</p>
                    <div className="flex items-center justify-between gap-4">
                        <select onChange={onChangePrice} value={propertyRequest.price.min} data-field="min" className="border-gray-200 rounded text-sm w-full">
                            <option value="">-</option>
                            <option value="1000000">¥1,000,000</option>
                            <option value="5000000">¥5,000,000</option>
                            <option value="10000000">¥10,000,000</option>
                            <option value="15000000">¥15,000,000</option>
                            <option value="20000000">¥20,000,000</option>
                            <option value="25000000">¥25,000,000</option>
                            <option value="30000000">¥30,000,000</option>
                            <option value="35000000">¥35,000,000</option>
                            <option value="40000000">¥40,000,000</option>
                            <option value="45000000">¥45,000,000</option>
                            <option value="50000000">¥50,000,000</option>
                            <option value="100000000">¥100,000,000</option>
                        </select>



                        <select onChange={onChangePrice} value={propertyRequest.price.max} data-field="max" className="border-gray-200 rounded text-sm w-full">
                            <option value="">-</option>
                            <option value="5000000">¥5,000,000</option>
                            <option value="10000000">¥10,000,000</option>
                            <option value="15000000">¥15,000,000</option>
                            <option value="20000000">¥20,000,000</option>
                            <option value="25000000">¥25,000,000</option>
                            <option value="30000000">¥30,000,000</option>
                            <option value="35000000">¥35,000,000</option>
                            <option value="40000000">¥40,000,000</option>
                            <option value="45000000">¥45,000,000</option>
                            <option value="50000000">¥50,000,000</option>
                            <option value="100000000">¥100,000,000</option>
                        </select>
                    </div>
                </div>
            </div>

            <div className="flex flex-col px-4 mt-4">
                <div className="flex items-center mb-2">
                <p className="text-sm text-gray-500 ">{Translate("agents_list_title")}</p>
                <div className="ml-auto">
                    <label className="text-sm  text-gray-500">
                        <input type="checkbox"
                        checked={agents.length == propertyRequest.agents.length ? true : false}
                        onChange={onToggleSelectAllAgents}
                         className=" h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 rounded mr-2"/>{Translate("select_all_checkbox_label")}
                    </label>
                </div>
                </div>
                
                <div className="flex flex-col gap-2">
                    {
                        props.agents.map((agent, index) => (
                            <div key={agent.agent_id} className="flex items-center">
                                <input id={`agent-${agent.agent_id}`} name="agents" type="checkbox" value={agent.agent_id} className=" h-4 w-4 text-blue-600 focus:ring-0 border-gray-300 rounded"
                                    checked={isAgentSelected(agent.agent_id)}
                                    onChange={onSelectAgents}
                                />
                                <label htmlFor={`agent-${agent.agent_id}`} className="ml-2 flex items-center">
                                    <img className="w-6 h-6 object-cover rounded-full bg-gray-200" src={agent.company.image_url}></img>
                                    <p className="ml-2 text-xs">{agent.company.name} ({agent.property_amount})</p>
                                </label>
                            </div>
                        ))
                    }

                </div>
            </div>

        </div>
    );
}
