import { useEffect, useRef, useState, Fragment, useMemo } from "react";
import SideNavBar from "../SideNavBar";
import * as turf from '@turf/turf';
import * as api from '../../services/agent'
import ReactMapGL, {
    Marker,
    // FlyToInterpolator,
    Popup,
    NavigationControl,
    FullscreenControl,
    ScaleControl,
    GeolocateControl,
    Source,
    Layer,
} from 'react-map-gl';
import * as mapbox from '../../../mapbox/api'
import { getCursor, scaleControlStyleNoPadding, walkingPathStyle, fullscreenControlStyleNoPadding, navStyleNoPadding, geolocateStyleNoPadding, intersectionStyle } from '../../../mapbox/style'
import Select from 'react-select'
import { MAPBOX_TOKEN } from '../../../constants'
import TrainSearcher from "../Components/TrainSearcher";
import { MapPinIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { Listbox, Transition, Tab, Disclosure } from '@headlessui/react'
import TrainIcon from "../../../Icons/TrainIcon";
import PlaceSearchInput from "../Components/PlaceSearchInput";
import { debounce } from "lodash";

export default function Reverse(props) {
    const { Translate } = props
    const mapRef = useRef()

    const query = new URLSearchParams(window.location.search)

    const [search, setSearch] = useState(query.get("query") ? { places:[],stations: [], ...JSON.parse(query.get("query"))} : ({
        places:[],
        stations: [],
    }))

    const [popup, setPopup] = useState({
        show: false,
        latitude: 0,
        longitude: 0,
    })
    const [markers, setMarkers] = useState([])
    const [places, setPlaces] = useState([])

    const walkingDistance = useMemo(() => {
        const a1 = markers.reduce((all, item) => {
            if (item.walkingPath) {
                all.features.push(...item.walkingPath.features)
            }
            return all
        }, { type: "FeatureCollection", features: [] })

        const a2 =  places.reduce((all, item) => {
            if (item.walkingPath) {
                all.features.push(...item.walkingPath.features)
            }
            return all
        }, { type: "FeatureCollection", features: [] })
        return {type: "FeatureCollection", features: a1.features.concat(a2.features)}
    }, [markers, places])

    const intersection = useMemo(() => {
        if (walkingDistance.features.length < 2) {
            return null
        }
        return walkingDistance.features.reduce((x, y) => {
            if (turf.intersect(x, y) == null) {
                return turf.union(x, y)
            }
            return turf.intersect(x, y)
        })
    }, [walkingDistance])

    const selectStyles = {
        control: base => ({
            ...base,
            border: 0,
            // This line disable the blue border
            boxShadow: 'none'
        })
    };

    const onTrainStationResult = (result) => {
        console.log("onTrainStationResult", result)
        if (!result) return

        api.trainRoutes(result.station_group_code)
            .then((res) => {
                setMarkers((d) => [...d, { ...result, routes: res.data, distance: { max: 5 } }])
                setSearch((d) => ({...d, stations: [...d.stations, { station_name: result.station_name_jp, station_group_code:result.station_group_code, distance: { max: 5 } }] }))
            })
    }

    const fetchStationDetails = async (stations) => {
        const stationDetails = []
        for (const station of stations) {
            const result = await api.trainStations(station.station_name, station.station_group_code)
            let [matchStation] = result.data
            const routeResult = await api.trainRoutes(matchStation.station_group_code)
            let lineDetail = {}
            if (station.line_name) {
                const line = routeResult.data.find(({line_name}) => line_name === station.line_name)
                lineDetail = {
                    line_color_hex: line.line_color_hex,
                    line_code: line.line_code,
                    line_name: line.line_name,
                }
                matchStation.latlng.latitude = line.latlng.latitude
                matchStation.latlng.longitude = line.latlng.longitude
            }
            const walkingPath = await mapbox.isochrone({
                lat: matchStation.latlng.latitude,
                lng: matchStation.latlng.longitude,
                distance: station.distance,
                color: lineDetail.line_color_hex,
            })
            stationDetails.push({
                ...matchStation, ...lineDetail, routes: routeResult.data, distance: { max: station.distance.max }, walkingPath
            })
        }
        setMarkers(stationDetails)
    }
    
    const fetchPlaceDetails = async (places) => {
        const placeDetails = []
        for (const place of places) {
            const response = await api.fetchPlaceDetail(place.place_id)
            const matchPlace = response.data.result
            const name = matchPlace.name
            const address = matchPlace.formatted_address
            const location = matchPlace.geometry.location
            const color = stringToColour(matchPlace.place_id)
            const walkingPath = await mapbox.isochroneWithMeters({
                lat: location.lat,
                lng: location.lng,
                meters: place.distance.meters,
                color: stringToColour(matchPlace.place_id),
            })
            placeDetails.push({
                ...matchPlace,
                name: name,
                address: address,
                type: "place",
                color: color,
                distance: { meters: place.distance.meters },
                latlng: {
                    latitude: location.lat,
                    longitude: location.lng,
                },
                walkingPath
            })
        }
        setPlaces(placeDetails)
    }

    useEffect(() => {
        if (props.history) {
            props.history.replace({
                pathname: window.location.pathname,
                search: `?query=${JSON.stringify(search)}`
            })
            // console.log("trigger", search, markers, places)
            if (search.stations.length > 0 && markers.length === 0) {
                fetchStationDetails(search.stations)
            } 
            if (search.places.length > 0  && places.length === 0) {
                fetchPlaceDetails(search.places)
            }
        }
        
    }, [search])

    useEffect(() => {
        if ((markers.length > 0 || places.length > 0)) {
            if (walkingDistance.features.length > 0) {
                const features = turf.featureCollection(walkingDistance.features)
                const bbox = turf.bbox(features)
                mapRef.current?.fitBounds(bbox, { padding: 40, duration: 1000 })
            }
        }

    }, [markers,places, walkingDistance])

    var stringToColour = function (str) {
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        var colour = '';
        for (var i = 0; i < 3; i++) {
            var value = (hash >> (i * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
    }


    const onPlaceSearchInputSelected = (result) => {
        console.log(result)

        const defaultMeters = 100

        const name = result.name
        const address = result.formatted_address
        const location = result.geometry.location
        const place_id = result.place_id
        const color = stringToColour(result.place_id)
        console.log(name, color);

        mapbox.isochroneWithMeters({
            lat: location.lat,
            lng: location.lng,
            meters: defaultMeters,
            color: stringToColour(result.place_id),
        }).then((res) => {

            setPlaces((d) => [...d, {
                ...result,
                name: name,
                address: address,
                type: "place",
                color: color,
                distance: { meters: defaultMeters },
                latlng: {
                    latitude: location.lat,
                    longitude: location.lng,
                },
                walkingPath: res
            }])
            setSearch((d) => ({...d, places: [...d.places, { place_id: place_id, distance:{meters: 100}}]}))

        })

    }

    const debouncePlaceDistance = useMemo(() => {
        return debounce((e, i) => {
            const text = e.target.value.trim()
            if (text.length === 0) {
                setPlaces((places) => {
                    places[i].distance.meters = ""
                    return [...places]
                })
                setSearch((s) => {
                    s.places[i].distance.meters = "";
                    return {...s, places: [...s.places]}
                })
                return
            }
            try {
                let distance = Number.parseInt(text, 10)
                if (distance > 2000) {
                    distance = 2000
                    e.target.value = distance
                }
                setPlaces((places) => {
                    places[i].distance.meters = distance
                    return [...places]
                })
                setSearch((s) => {
                    s.places[i].distance.meters = distance;
                    return {...s, places: [...s.places]}
                })
                console.log("debounce Place distance, index", distance, i, places[i])
                mapbox.isochroneWithMeters({
                    lat: places[i].latlng.latitude,
                    lng: places[i].latlng.longitude,
                    meters: distance,
                    color: places[i].color,
                }).then((res) => {
                    console.log("res", res)
                    if (res.features.length === 0) {
                        return
                    }
        
                    var first = res.features[0]
                    if (first.geometry.coordinates.length === 0){
                        return
                    }
                    setPlaces((places) => {
                        places[i].distance.meters = distance
                        places[i].walkingPath = res
                        return [...places]
                    })
        
                    setSearch((d) => {
                        d.places[i].distance.meters = distance
                        return ({...d, places: [...d.places]})
                    })
                })
            } catch (error) {
                console.log("error", error)
            }
        }, 750)
    }, [places]) 

    const debounceStationDistance = useMemo(() => {
        return debounce((e, i) => {
            const value = e.target.value
            if (value.length === 0) {
                setMarkers((markers) => {
                    markers[i].distance.max = ""
                    return [...markers]
                })
                setSearch((s) => {
                    s.stations[i].distance.max = "";
                    return {...s, stations: [...s.stations]}
                })
                return
            }
            try {
                const minutes = Number.parseInt(value, 10)
                if (minutes > 60) {
                    minutes = 60
                    e.target.value = minutes
                }
                setMarkers((markers) => {
                    markers[i].distance.max = minutes
                    return [...markers]
                })
                setSearch((s) => {
                    console.log("s.stations", s.stations, i)
                    s.stations[i].distance.max = minutes;
                    return {...s, stations: [...s.stations]}
                })
                console.log("debounce Station distance, index", minutes, i)
                mapbox.isochrone({
                    lat: markers[i].latlng.latitude,
                    lng: markers[i].latlng.longitude,
                    distance: { max: minutes },
                    color: markers[i].line_color_hex,
                }).then((res) => {
                    setMarkers((markers) => {
                        markers[i].distance.max = minutes
                        markers[i].walkingPath = res
                        return [...markers]
                    })
                    setSearch((s) => {
                        s.stations[i].distance.max = minutes;
                        return {...s, stations: [...s.stations]}
                    })
                })
            } catch (error) {
                console.log("error", error)
            }
        }, 750)
    }, [markers])

    return (
        <div className="absolute inset-0 w-full flex h-full">
            <SideNavBar {...props} isPresented={true} />

            <div className="w-full h-full flex flex-col">


                <div className="flex-none flex items-center w-full border-b ">

                    <div className="w-full container mx-auto lg:px-8 px-4">

                        <div className="pt-8 pb-4 flex gap-2">
                            <p className="text-3xl font-bold">Location</p>
                        </div>

                        <Tab.Group >
                            <Tab.List className="flex items-center w-full border-b">
                                <Tab className="focus:outline-none" onClick={() => {
                                }}>
                                    {({ selected }) => (
                                        <div className={`flex items-center gap-2 px-3 py-3 font-medium border-b-2 ${selected ? " border-primary text-primary" : "border-transparent text-gray-400"}`}>
                                            <MapPinIcon className="w-5 h-5" />
                                            <span>Search place</span>
                                        </div>
                                    )}
                                </Tab>
                                <Tab className="focus:outline-none" onClick={() => {

                                }}>
                                    {({ selected }) => (
                                        <div className={`flex items-center gap-2 px-3 py-3 font-medium border-b-2  ${selected ? "border-primary text-primary" : "border-transparent text-gray-400"}`}>
                                            <TrainIcon className="w-5 h-5" />
                                            <span>Search Train station</span>
                                        </div>
                                    )}
                                </Tab>
                            </Tab.List>

                            <Tab.Panels as="div" className="bg-white py-4 w-full">
                                <Tab.Panel>
                                    <PlaceSearchInput onSelectedResult={onPlaceSearchInputSelected} clearAfterSelected={true} />
                                </Tab.Panel>
                                <Tab.Panel >
                                    <TrainSearcher onResult={onTrainStationResult} clearAfterResult={true} />
                                </Tab.Panel>
                            </Tab.Panels>
                        </Tab.Group>
                    </div>
                </div>


                <div className="flex-1 flex relative w-full overflow-hidden">
                    <div className="w-96 h-full border-r overflow-y-auto">


                        <div className="flex flex-col divide-y">

                            {
                                places.map((place, i) => (
                                    <div key={`place-${i}`} className="p-4 flex flex-col text-sm">
                                        <div className="flex items-start gap-2 ">
                                            <div className="w-2 h-14 bg-gray-200" style={{backgroundColor:"#" + stringToColour(place.place_id)}}>
                                                &nbsp;
                                            </div>
                                            <div className="">
                                                <p className="font-medium mb-1">{place.name}</p>
                                                <p className="text-xs text-gray-500">{place.address}</p>
                                            </div>
                                            <button className="ml-auto" onClick={() => {
                                                setPlaces((places) => {
                                                    places.splice(i, 1);
                                                    return [...places]
                                                })
                                                setSearch((d) => {
                                                    d.places.splice(i, 1)
                                                    return ({...d, places: [...d.places]})
                                                })

                                            }}>
                                                <XMarkIcon className="w-4 h-4" />
                                            </button>
                                        </div>
                                        <div className="text-sm font-medium mt-2">
                                            <p className="mb-2">Distance (meters)</p>
                                            <input className="rounded border-gray-300 w-full text-sm" type="number" defaultValue={place.distance.meters} min={20} max={2000} step={1}
                                                onChange={(e) => {debouncePlaceDistance(e, i)}} />
                                        </div>
                                    </div>


                                ))
                            }

                            {
                                markers.map((marker, i) => (

                                    <div key={`station-${i}`} className="p-4 flex flex-col text-sm">

                                        <div className="flex items-center gap-2 h-full">

                                            {
                                                marker.line_color_hex &&
                                                <div className={`flex-none w-2 h-20 flex items-center justify-center bg-gray-100`} style={{ backgroundColor: `#${marker.line_color_hex}` }}>
                                                </div>
                                            }

                                            <div className="flex flex-col w-full">

                                                <div className="flex items-center mb-2">
                                                    <p className="font-medium">
                                                        {marker.station_name_jp}
                                                    </p>
                                                    <div className="ml-auto">

                                                        <XMarkIcon className="w-4 h-4 cursor-pointer" onClick={() => {
                                                            setMarkers((markers) => {
                                                                markers.splice(i, 1);
                                                                return [...markers]
                                                            })
                                                            setSearch((s) => {
                                                                s.stations.splice(i, 1);
                                                                return {...s, stations: [...s.stations]}
                                                            })
                                                        }} />
                                                    </div>
                                                </div>

                                                <div className="text-sm font-medium w-full">
                                                    <Select
                                                        styles={selectStyles}
                                                        className="border border-gray-300 rounded w-full"
                                                        options={marker.routes}
                                                        getOptionLabel={({ line_name }) => line_name}
                                                        getOptionValue={({ line_code }) => line_code}
                                                        onChange={opt => {
                                                            if (Number.isInteger(marker.distance.max)) {
                                                                mapbox.isochrone({
                                                                    lat: opt.latlng.latitude,
                                                                    lng: opt.latlng.longitude,
                                                                    distance: marker.distance,
                                                                    color: opt.line_color_hex,
                                                                }).then((res) => {
                                                                    setMarkers((markers) => {
                                                                        markers[i].line_color_hex = opt.line_color_hex
                                                                        markers[i].line_code = opt.line_code
                                                                        markers[i].line_name = opt.line_name
                                                                        markers[i].latlng.latitude = opt.latlng.latitude
                                                                        markers[i].latlng.longitude = opt.latlng.longitude
                                                                        markers[i].walkingPath = res
                                                                        return [...markers]
                                                                    })
                                                                    setSearch((s) => {
                                                                        s.stations[i].line_name = opt.line_name
                                                                        return {...s}
                                                                    })
                                                                })
                                                            }
                                                        }}
                                                        value={marker.routes.filter((dr) => dr.line_code === marker.line_code)}
                                                    />
                                                </div>

                                            </div>

                                        </div>

                                        <div className="text-sm font-medium mt-2">
                                            <p className="mb-2">Walking Distance (minutes)</p>

                                            <input className="rounded border-gray-300 w-full text-sm" type="number" name={`marker-distance-${i}`} defaultValue={marker.distance.max} min={1} max={60} onChange={(e) => {
                                                
                                                    debounceStationDistance(e, i)
                                                
                                            }} />
                                        </div>

                                    </div>
                                ))}
                        </div>

                    </div>

                    <div className="h-full w-full">
                        <div className="h-full w-full relative">
                            <ReactMapGL
                                ref={mapRef}
                                attributionControl={false}
                                cursor={getCursor}
                                mapStyle="mapbox://styles/apisitviila/cl1vo7gbg006p14miqclslj60"
                                mapboxAccessToken={MAPBOX_TOKEN}
                                initialViewState={{
                                    latitude: 36.3418518,
                                    longitude: 138.6179432,
                                    zoom: 9
                                }}
                                style={{ width: "100%", height: "100%" }}
                                onContextMenu={(e) => {
                                    setPopup({
                                        show: true,
                                        latitude: e.lngLat.lat,
                                        longitude: e.lngLat.lng,
                                    })
                                }}
                            >
                                {markers.map((marker, i) => (
                                    <Fragment key={`marker-${i}-${marker.station_name_jp}`}>
                                        <Marker
                                            latitude={marker.latlng.latitude}
                                            longitude={marker.latlng.longitude}
                                        >
                                            <svg width="20" height="48" viewBox="0 0 20 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.3" d="M10 32C14.9706 32 19 29.7614 19 27C19 24.2386 14.9706 22 10 22C5.02944 22 1 24.2386 1 27C1 29.7614 5.02944 32 10 32Z" fill="#C4C4C4" />
                                                <g opacity="0.3">
                                                    <g opacity="0.3">
                                                        <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M10 32C15 32 19 29.8 19 27C19 24.2 15 22 10 22C5 22 1 24.2 1 27C1 29.8 5 32 10 32Z" fill="#BFBFBF" />
                                                    </g>
                                                </g>
                                                <path fill={marker.line_color_hex ? `#${marker.line_color_hex}` : `#4264FB`} stroke="#314CCD" d="M19.2499 10.4C19.1337 12.2242 18.6361 14.0037 17.7892 15.6235C16.8522 17.554 15.767 19.409 14.5433 21.1718C13.3604 22.9087 12.1771 24.4502 11.2892 25.5577C10.8454 26.1113 10.4757 26.5561 10.2171 26.8623C10.1327 26.9623 10.0601 27.0475 10.0007 27.1168C9.94069 27.0468 9.86819 26.9604 9.78339 26.859C9.52469 26.5502 9.15499 26.1019 8.71109 25.5443C7.82319 24.4289 6.63969 22.8779 5.45679 21.1343C4.23519 19.3683 3.14995 17.5117 2.21049 15.5808C1.3669 13.9749 0.869336 12.21 0.749887 10.4C0.700513 7.89475 1.64648 5.47212 3.38042 3.6632C5.11436 1.85428 7.49479 0.806665 9.99989 0.75C12.505 0.806665 14.8854 1.85428 16.6194 3.6632C18.3533 5.47212 19.2993 7.89475 19.2499 10.4V10.4Z" strokeWidth="0.5" />
                                                <path d="M13.5502 10.0002C13.5502 10.7023 13.342 11.3887 12.9519 11.9725C12.5618 12.5563 12.0074 13.0113 11.3587 13.28C10.71 13.5487 9.99626 13.619 9.30763 13.482C8.61899 13.345 7.98644 13.0069 7.48997 12.5104C6.99349 12.0139 6.65539 11.3814 6.51841 10.6928C6.38143 10.0041 6.45173 9.29035 6.72042 8.64167C6.98912 7.99299 7.44413 7.43856 8.02792 7.04848C8.61172 6.6584 9.29807 6.4502 10.0002 6.4502C10.4664 6.44999 10.9282 6.54167 11.359 6.72C11.7898 6.89833 12.1812 7.15981 12.5109 7.4895C12.8406 7.81919 13.1021 8.21062 13.2804 8.64142C13.4587 9.07222 13.5504 9.53394 13.5502 10.0002Z" fill="white" stroke="#314CCD" strokeWidth="0.5" />
                                            </svg>
                                        </Marker>
                                    </Fragment>
                                ))}

                                {places.map((place, i) => (
                                    <Fragment key={`place-${i}`}>
                                        <Marker
                                            latitude={place.latlng.latitude}
                                            longitude={place.latlng.longitude}
                                        >
                                            <svg width="20" height="48" viewBox="0 0 20 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.3" d="M10 32C14.9706 32 19 29.7614 19 27C19 24.2386 14.9706 22 10 22C5.02944 22 1 24.2386 1 27C1 29.7614 5.02944 32 10 32Z" fill="#C4C4C4" />
                                                <g opacity="0.3">
                                                    <g opacity="0.3">
                                                        <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M10 32C15 32 19 29.8 19 27C19 24.2 15 22 10 22C5 22 1 24.2 1 27C1 29.8 5 32 10 32Z" fill="#BFBFBF" />
                                                    </g>
                                                </g>
                                                <path fill={place.color ? `#${place.color}` : `#4264FB`} stroke="#314CCD" d="M19.2499 10.4C19.1337 12.2242 18.6361 14.0037 17.7892 15.6235C16.8522 17.554 15.767 19.409 14.5433 21.1718C13.3604 22.9087 12.1771 24.4502 11.2892 25.5577C10.8454 26.1113 10.4757 26.5561 10.2171 26.8623C10.1327 26.9623 10.0601 27.0475 10.0007 27.1168C9.94069 27.0468 9.86819 26.9604 9.78339 26.859C9.52469 26.5502 9.15499 26.1019 8.71109 25.5443C7.82319 24.4289 6.63969 22.8779 5.45679 21.1343C4.23519 19.3683 3.14995 17.5117 2.21049 15.5808C1.3669 13.9749 0.869336 12.21 0.749887 10.4C0.700513 7.89475 1.64648 5.47212 3.38042 3.6632C5.11436 1.85428 7.49479 0.806665 9.99989 0.75C12.505 0.806665 14.8854 1.85428 16.6194 3.6632C18.3533 5.47212 19.2993 7.89475 19.2499 10.4V10.4Z" strokeWidth="0.5" />
                                                <path d="M13.5502 10.0002C13.5502 10.7023 13.342 11.3887 12.9519 11.9725C12.5618 12.5563 12.0074 13.0113 11.3587 13.28C10.71 13.5487 9.99626 13.619 9.30763 13.482C8.61899 13.345 7.98644 13.0069 7.48997 12.5104C6.99349 12.0139 6.65539 11.3814 6.51841 10.6928C6.38143 10.0041 6.45173 9.29035 6.72042 8.64167C6.98912 7.99299 7.44413 7.43856 8.02792 7.04848C8.61172 6.6584 9.29807 6.4502 10.0002 6.4502C10.4664 6.44999 10.9282 6.54167 11.359 6.72C11.7898 6.89833 12.1812 7.15981 12.5109 7.4895C12.8406 7.81919 13.1021 8.21062 13.2804 8.64142C13.4587 9.07222 13.5504 9.53394 13.5502 10.0002Z" fill="white" stroke="#314CCD" strokeWidth="0.5" />
                                            </svg>
                                        </Marker>
                                    </Fragment>
                                ))}

                                {walkingDistance && (<Source id={`source-walkpath`} type="geojson" data={walkingDistance}>
                                    <Layer id={`layer-walkpath`}  {...walkingPathStyle} />
                                </Source>)}
                                {intersection && (<Source id={`source-intersection`} type="geojson" data={intersection}>
                                    <Layer id={`layer-intersection`}  {...intersectionStyle} />
                                </Source>)}

                                {popup.show && (<Popup
                                    className="p-0"
                                    anchor="top"
                                    offsetLeft={10}
                                    offsetTop={-10}
                                    latitude={popup.latitude}
                                    longitude={popup.longitude}
                                    closeOnClick={true}
                                    closeButton={false}
                                    onClose={(e) => {
                                        setPopup({
                                            show: false,
                                        })
                                    }}
                                >
                                    <div className="text-xs">
                                        <a href={`https://maps.google.com/maps?q=${popup.latitude},${popup.longitude}`} target="_blank" rel="noreferer">
                                            <p className="p-2">{popup.latitude.toPrecision(5)},{popup.longitude.toPrecision(5)}</p>
                                        </a>
                                        <div className="border-t px-2 py-2">
                                            <a href={`https://www.google.com/maps?q&layer=c&cbll=${popup.latitude},${popup.longitude}&cbp`} target="_blank" rel="noreferer" className="text-blue-600 font-semibold  flex items-center">
                                                <span>{Translate("google stree view")}</span>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                                                </svg>
                                            </a>

                                        </div>
                                    </div>
                                </Popup>)}

                                <ScaleControl position="bottom-right" style={scaleControlStyleNoPadding} />
                                <FullscreenControl position="bottom-right" style={fullscreenControlStyleNoPadding} />
                                <NavigationControl position="bottom-right" style={navStyleNoPadding} />
                                <GeolocateControl position="bottom-right" style={geolocateStyleNoPadding} />

                            </ReactMapGL>

                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}