import moment from "moment"
import "react-range-slider-input/dist/style.css";
import { useEffect, useState, useContext } from "react"
import { useForm } from "react-hook-form"
import RangeSlider from "react-range-slider-input";
import { yupResolver } from '@hookform/resolvers/yup';
import { submitProposal } from "../../../validators/proposal"
import TextAreaField from "../../Components/StepWizard/TextAreaField";
import MoneyInputField from "../../Components/StepWizard/MoneyInputField";
import { ChevronUpIcon, ChevronDownIcon, ArrowDownIcon, PlusIcon, MinusIcon, ArrowLongDownIcon, CameraIcon, PencilIcon, VideoCameraIcon, MagnifyingGlassIcon, RectangleGroupIcon } from "@heroicons/react/24/outline";
import SqmTsuboSwitch from "./SqmTsuboSwitch";
import ConfirmSubmitProposalModal from "./ConfirmSubmitProposalModal";
import * as api from '../../../services/agent'
import AppContext from "../../../../AppContext";

const isHouseorLot = (sellRequest) => {
    return sellRequest.property_type === "house" || sellRequest.property_type === "lot"
}

const landPrice = (price, { data }) => {
    return Math.round(price * (+data.lot_sqm))
}

const roomPrice = (price, { data }) => {
    return Math.round(price * (+data.room_sqm))
}

const totalPrice = (price, sellRequest) => {
    if (isHouseorLot(sellRequest)) {
        return landPrice(price, sellRequest)
    }
    return roomPrice(price, sellRequest)
}

const toPriceSqm = (price, sellRequest) => {
    const { data } = sellRequest
    if (isHouseorLot(sellRequest)) {
        return Math.round(price / (+data.lot_sqm))
    }
    return Math.round(price / (+data.room_sqm))
}
const toPriceTsubo = (price, sellRequest) => {
    return toPriceSqm(price, sellRequest) * 3.306
}

const brokerageFee = (price, commission, sellRequest) => {
    const consumptionTax = 1.10

    const firstPart = 2000000
    const secondPart = 2000000

    const firstPartFee = 0.05
    const secondPartFee = 0.04
    const remainingPartFee = 0.03

    let totalFee = 0
    // //price under or equal to 2,000,000 yen
    if (price <= firstPart) {
        totalFee = totalFee + Math.round(price * firstPartFee)
        return Math.round(totalFee * consumptionTax)
    }
    //price under or equal to 4,000,000 yen
    if (price <= (firstPart + secondPart)) {
        totalFee = totalFee + Math.round(firstPart * firstPartFee)
        totalFee = totalFee + Math.round(price - firstPart * secondPartFee)
        return Math.round(totalFee * consumptionTax)
    }

    //price more than 4,000,000 yen
    totalFee = totalFee + Math.round(firstPart * firstPartFee)
    // console.log("fee1", Math.round(firstPart * firstPartFee))
    totalFee = totalFee + Math.round(secondPart * secondPartFee)
    // console.log("fee2", Math.round(secondPart * secondPartFee))
    totalFee = totalFee + Math.round((price - (firstPart + secondPart)) * remainingPartFee)
    // console.log("fee3", Math.round((price - (firstPart + secondPart)) * remainingPartFee))
    return Math.round(totalFee * consumptionTax)
}

export default function SubmitProposalBox(props) {
    const { Translate, sellRequest, avgPricePerSqm } = props
    const [dialogIsOpen, setDialogIsOpen] = useState(false)
    const [servicesProvide, setServicesProvide] = useState({})
    const { language } = useContext(AppContext)
    console.log("SubmitProposalBox", avgPricePerSqm)
    const validators = useForm({
        resolver: yupResolver(submitProposal),
        defaultValues: {
            commission: 3,
            estimate_price_sqm: { amount: avgPricePerSqm },
            estimate_price_tsubo: { amount: avgPricePerSqm * 3.306 },
            estimate_price: {
                amount: isHouseorLot(sellRequest) ? landPrice(avgPricePerSqm, sellRequest) : roomPrice(avgPricePerSqm, sellRequest)
            },
            min_price_sqm: {
                amount: Math.round(avgPricePerSqm * 0.8)
            },
            max_price_sqm: {
                amount: Math.round(avgPricePerSqm * 1.2)
            },
            min_price: {
                amount: (isHouseorLot(sellRequest) ? landPrice(avgPricePerSqm, sellRequest) : roomPrice(avgPricePerSqm, sellRequest)) * 0.8
            },
            max_price: {
                amount: (isHouseorLot(sellRequest) ? landPrice(avgPricePerSqm, sellRequest) : roomPrice(avgPricePerSqm, sellRequest)) * 1.2
            },
            services_offered : {},

        }
    })

    const { control, formState: { errors }, watch, setValue, trigger, clearErrors, handleSubmit } = validators
    useEffect(() => {
        if (avgPricePerSqm !== 0) {
            setValue("estimate_price_sqm.amount", avgPricePerSqm)
            setValue("estimate_price_tsubo.amount", avgPricePerSqm * 3.306)
            setValue("estimate_price.amount", isHouseorLot(sellRequest) ? landPrice(avgPricePerSqm, sellRequest) : roomPrice(avgPricePerSqm, sellRequest))
            setValue("min_price_sqm.amount", Math.round(avgPricePerSqm * 0.8))
            setValue("max_price_sqm.amount", Math.round(avgPricePerSqm * 1.2))
            setValue("min_price.amount", (isHouseorLot(sellRequest) ? landPrice(avgPricePerSqm, sellRequest) : roomPrice(avgPricePerSqm, sellRequest)) * 0.8)
            setValue("max_price.amount", (isHouseorLot(sellRequest) ? landPrice(avgPricePerSqm, sellRequest) : roomPrice(avgPricePerSqm, sellRequest)) * 1.2)
        }
    }, [avgPricePerSqm])
    const proposal = watch()
    const [isInputPricePerTsuboEnabled, setIsInputPricePerTsuboEnabled] = useState(false)

    const size = (sqm, isTsubo) => {
        if (isTsubo) {
            return `${Math.round(sqm * 3.306)} ${Translate("tsubo")}`
        }
        return `${sqm} ${Translate("m2")}`
    }

    const toPrice = (price, sellRequest, isTsubo) => {
        if (isTsubo) {
            return toPriceTsubo(price, sellRequest)
        }
        return toPriceSqm(price, sellRequest)
    }

    function handleCheckSubmitProposal() {
        trigger().then((valid) => {
            if (valid) {
                setDialogIsOpen(true)
            }
        })
    }
    function handleSubmitProposal() {
        handleSubmit((d) => {
            api.submitProposal(sellRequest.id, d)
                .then((res) => {
                    window.location.href = `/v2/sellers/request/${sellRequest.sell_request_no}`
                })
                .catch((err) => {
                    alert(err.data.message)
                })
        }, (err) => {
            console.log("handleSubmit error", err)
        })()
    }

    function getServicesProvided() {
        api.servicesProvide(language)
            .then((res) => {
                setServicesProvide(res.data)
            })
            .catch((err) => {
                alert(err.data.message)
            })
    }


    useEffect(() => {
        getServicesProvided() 
    }, [])

    return (

        <div className="bg-white rounded border overflow-hidden">
            <div className="px-5 py-5 border-b w-full flex items-center">
                <p className="text-xl font-bold">{Translate("submit_proposal_box_submit_proposal")}</p>
                <div className="ml-auto">
                    <SqmTsuboSwitch {...props} enabled={isInputPricePerTsuboEnabled} setEnabled={setIsInputPricePerTsuboEnabled} />
                </div>
            </div>
            <div className="flex flex-col px-5 divide-y">

                <div className="grid grid-cols-3 items-start py-4">
                    <div className="col-span-3 lg:col-span-1">
                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("submit_proposal_box_estimated_price")}</p>
                    </div>
                    <div className="col-span-3 lg:col-span-2">

                        {
                            isInputPricePerTsuboEnabled &&
                                <MoneyInputField
                                    control={control}
                                    errors={errors} clearErrors={clearErrors}
                                    required={true}
                                    placeholder={"50,000,000"}
                                    name="estimate_price_tsubo.amount"
                                    id="estimate_price_tsubo-appraisal-value"
                                    suffix={isInputPricePerTsuboEnabled ? "/" + Translate("sqm_to_tsubo_tsubo") : "/m²"}
                                    onChangeCallback={(price) => {
                                        // console.log("onchange estimate_price_tsubo callback", price)
                                        const priceAmount = +price
                                        // console.log("priceAmount / 3.306", priceAmount / 3.306)
                                        setValue("estimate_price_sqm.amount", priceAmount / 3.306)
                                        if (isHouseorLot(sellRequest)) {
                                            const totalPrice = landPrice(priceAmount / 3.306, sellRequest)
                                            setValue("estimate_price.amount", landPrice(priceAmount, sellRequest))
                                            setValue("min_price.amount", Math.round(totalPrice * 0.8))
                                            setValue("max_price.amount", Math.round(totalPrice * 1.2))
                                        } else {
                                            const totalPrice = roomPrice(priceAmount / 3.306, sellRequest)
                                            setValue("estimate_price.amount", roomPrice(priceAmount, sellRequest))
                                            setValue("min_price.amount", Math.round(totalPrice * 0.8))
                                            setValue("max_price.amount", Math.round(totalPrice * 1.2))
                                        }
                                    }}
                                />}
                                
                         {!isInputPricePerTsuboEnabled &&<MoneyInputField
                                    control={control}
                                    errors={errors} clearErrors={clearErrors}
                                    required={true}
                                    placeholder={"50,000,000"}
                                    name="estimate_price_sqm.amount"
                                    id="estimate_price_sqm-appraisal-value"
                                    suffix={isInputPricePerTsuboEnabled ? "/" + Translate("sqm_to_tsubo_tsubo") : "/m²"}
                                    onChangeCallback={(price) => {
                                        // console.log("onchange estimate_price_sqm callback", price, typeof price)
                                        const priceAmount = +price
                                        setValue("estimate_price_tsubo.amount", priceAmount * 3.306)
                                        if (isHouseorLot(sellRequest)) {
                                            const totalPrice = landPrice(priceAmount, sellRequest)
                                            setValue("estimate_price.amount", totalPrice)
                                            setValue("min_price.amount", Math.round(totalPrice * 0.8))
                                            setValue("max_price.amount", Math.round(totalPrice * 1.2))
                                        } else {
                                            const totalPrice = roomPrice(priceAmount, sellRequest)
                                            setValue("estimate_price.amount", totalPrice)
                                            setValue("min_price.amount", Math.round(totalPrice * 0.8))
                                            setValue("max_price.amount", Math.round(totalPrice * 1.2))
                                        }
                                    }}
                                />
                        }



                    </div>
                </div>

                <div className="grid grid-cols-3 items-start py-4">
                    <div className="col-span-3 lg:col-span-1">
                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("submit_proposal_box_estimated_sale")}</p>
                    </div>
                    <div className="col-span-3 lg:col-span-2 flex items-center gap-2 w-full justify-between">

                    <MoneyInputField
                                    className="w-full flex-1"
                                    control={control}
                                    errors={errors} clearErrors={clearErrors}
                                    required={true}
                                    
                                    placeholder={"40,000,000"}
                                    name="min_price.amount"
                                    id="estimate_price_sqm_min"
                                    onChangeCallback={(price) => {
                                        console.log(price)
                                    }}
                                />
                                <span className="flex-none px-2">to</span>
                                <MoneyInputField
                                className="w-full flex-1"
                                    control={control}
                                    errors={errors} clearErrors={clearErrors}
                                    required={true}
                                    placeholder={"50,000,000"}
                                    name="max_price.amount"
                                    id="estimate_price_sqm_max"
                                    onChangeCallback={(price) => {
                                        console.log(price)
                                    }}
                                />
                    </div>
                </div>

                <div className="grid grid-cols-3 items-start py-4">
                    <div className="col-span-3 lg:col-span-1">
                        <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("submit_proposal_box_your_commission")}</p>
                    </div>
                    <div className="col-span-3 lg:col-span-2 ">
                        <div className="flex items-center gap-2">
                        <button className={`cursor-pointer ${proposal.commission <= 0 ? "text-primary/30" : "text-primary"}`} type="button" onClick={() => {
                            if (proposal.commission <= 0) {
                                return
                            }
                            setValue("commission", (proposal.commission * 100 - 25) / 100)
                        }}>
                            <MinusIcon className="w-6 h-6" />
                        </button>
                        <p className="font-medium w-[55px] text-right">
                            {proposal.commission.toFixed(2)} %
                        </p>
                        <button className={`cursor-pointer ${proposal.commission >= 3 ? "text-primary/30" : "text-primary"}`} type="button" onClick={() => {
                            if (proposal.commission >= 3) {
                                return
                            }
                            setValue("commission", (proposal.commission * 100 + 25) / 100)
                        }}>
                            <PlusIcon className="w-6 h-6" />
                        </button>
                        </div>
                        <div className="flex items-center text-xs mt-2">
                            <p>{Translate("submit_proposal_box_estimated_commision")}&nbsp;</p>
                            <p>{Translate("submit_proposal_box_between")} {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: "JPY" }).format(Number(brokerageFee(proposal.min_price.amount, proposal.commission, sellRequest)))} to {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: "JPY" }).format(Number(brokerageFee(proposal.max_price.amount, proposal.commission, sellRequest)))}</p>
                        </div>

                    </div>
                </div>



                {/* <div className="w-full flex flex-col items-center py-2 gap-2">
                    <div className="mx-auto grid grid-cols-2">
                        <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">Size</label>
                        <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{isHouseorLot(sellRequest) ? size(sellRequest.data.lot_sqm, isInputPricePerTsuboEnabled) : size(sellRequest.data.room_sqm, isInputPricePerTsuboEnabled)}</label>
                    </div>
                </div> */}
                {/* <div className="flex w-full m-auto items-center justify-center p-8">
                    <RangeSlider
                        min={proposal?.estimate_price_sqm?.amount * 0.8}
                        max={proposal?.estimate_price_sqm?.amount * 1.2}
                        step={1000}
                        value={[
                            proposal?.min_price_sqm?.amount,
                            proposal?.max_price_sqm?.amount,
                        ]}
                        onInput={([min, max]) => {
                            console.log("updated min ", min, " max ", max)
                            setValue("min_price_sqm.amount", min)
                            setValue("max_price_sqm.amount", max)

                            setValue("min_price.amount", totalPrice(min, sellRequest))
                            setValue("max_price.amount", totalPrice(max, sellRequest))
                        }} />
                </div>
                <div className="w-full">
                    <div className="grid grid-cols-3">
                        <div className="w-full">
                            <div className="text-center">
                                <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Min")}</label>
                                <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{new Intl.NumberFormat('ja-JP', { style: 'currency', currency: "JPY" }).format(Number(isInputPricePerTsuboEnabled ? Math.round(proposal.min_price_sqm.amount * 3.306) : proposal.min_price_sqm.amount))}</label>
                            </div>
                        </div>
                        <div className="w-full">
                        </div>
                        <div className="w-full">
                            <div className="text-center">
                                <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Max")}</label>
                                <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{new Intl.NumberFormat('ja-JP', { style: 'currency', currency: "JPY" }).format(Number(isInputPricePerTsuboEnabled ? Math.round(proposal.max_price_sqm.amount * 3.306) : proposal.max_price_sqm.amount))}</label>
                            </div>
                        </div>
                    </div>
                    <div className="grid grid-cols-3">
                        <div className="w-full">
                            <ArrowLongDownIcon className="w-10 h-10 mx-auto" />
                        </div>
                        <div className="w-full">
                        </div>
                        <div className="w-full">
                            <ArrowLongDownIcon className="w-10 h-10 mx-auto" />
                        </div>
                    </div>
                    <div className="grid grid-cols-3">
                        <div className="w-full">
                            <div className="text-center">
                                <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Min")}</label>
                                <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{new Intl.NumberFormat('ja-JP', { style: 'currency', currency: "JPY" }).format(Number(proposal.min_price.amount))}</label>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="text-center">
                                <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Median")}</label>
                                <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{new Intl.NumberFormat('ja-JP', { style: 'currency', currency: "JPY" }).format(Number(proposal.min_price.amount))}</label>
                            </div>
                        </div>
                        <div className="w-full">
                            <div className="text-center">
                                <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Max")}</label>
                                <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{new Intl.NumberFormat('ja-JP', { style: 'currency', currency: "JPY" }).format(Number(proposal.max_price.amount))}</label>
                            </div>
                        </div>
                    </div>
                </div> */}

               
                <div className="grid grid-cols-3 items-start py-4">
                    <div className="col-span-3 lg:col-span-1">
                        <p className="text-sm font-medium text-gray-700 mb-1">{Translate("submit_proposal_box_your_proposal")}</p>
                        <p className="text-xs">{Translate("submit_proposal_box_explain_why")}</p>
                    </div>
                    <div className="col-span-3 lg:col-span-2 flex flex-col gap-4">

                        <div className="flex flex-col gap-2">
                            <p className="font-medium">{Translate("submit_proposal_box_services_offered")}</p>
                            <div className="flex flex-col gap-3">
                                { Object.keys(servicesProvide).map((key, index) => (<>
                                    <label key={`${key}-label`} className="flex items-center gap-2">
                                    <input key={`${key}-input`} className=" focus:outline-none rounded" type="checkbox" onChange={e=>{
                                          setValue(`services_offered.${key}`, e.target.checked)
                                    }}/>
                                    <div key={`${key}-title`} className="flex items-center">
                                        <span dangerouslySetInnerHTML={{__html:servicesProvide[key].icon}}></span>
                                        <p className="leading-none">{servicesProvide[key].title}</p>
                                    </div>
                                </label>
                                </>))}
                            </div>
                        </div>

                        <div className="flex flex-col gap-2">
                        <p className="font-medium">{Translate("Detail")}</p>
                        <TextAreaField
                            control={control} errors={errors} clearErrors={clearErrors}
                            required rows="6"
                            id="remarks" name="remarks"
                            placeholder={Translate("Describe about this sell request")}
                        />
                        </div>
                    </div>
                </div>

            </div>
            {moment(sellRequest.approved_at).add(30, 'd') < (new Date()).getTime() ? null :
                <div className="mt-6 rounded bg-white overflow-hidden ring-1 ring-gray-200 shadow">
                    <div className="flex flex-col px-2">
                        <div className="p-2 flex items-center gap-2">
                            <div className="ml-auto">
                                <button type="button" className="btn bg-blue-600 text-white text-sm w-36" onClick={handleCheckSubmitProposal}>
                                    {Translate("submit_proposal_box_submit_proposal")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>}
            <ConfirmSubmitProposalModal {...props} confirm={handleSubmitProposal} isOpen={dialogIsOpen} proposal={proposal} setIsOpen={setDialogIsOpen} />
        </div>)
}