
import React, { useRef, useState, Fragment, useCallback, useEffect } from "react"
import { debounce } from "lodash";
import SideNavBar from "../SideNavBar";
import * as api from '../../services/agent'
import * as mapbox from '../../../mapbox/api'
import WalkIcon from '../../../Icons/WalkIcon'
import TrainIcon from '../../../Icons/TrainIcon'
import { CheckCircleIcon, ChevronRightIcon, DocumentArrowDownIcon, ChevronUpDownIcon, ChevronUpIcon, ChevronDownIcon, InformationCircleIcon, FunnelIcon, HomeIcon, XMarkIcon, ChevronLeftIcon, DocumentMagnifyingGlassIcon, ChartBarIcon, MagnifyingGlassIcon, InboxIcon } from "@heroicons/react/24/solid";
import { MAPBOX_TOKEN } from '../../../constants'
import { getCursor, scaleControlStyleNoPadding, walkingPathStyle, fullscreenControlStyleNoPadding, navStyleNoPadding, geolocateStyleNoPadding } from '../../../mapbox/style'
import ScreenLoading from "../Components/ScreenLoading";
import ReactMapGL, {
    Marker,
    // FlyToInterpolator,
    NavigationControl,
    FullscreenControl,
    ScaleControl,
    GeolocateControl,
    Source,
    Layer,
} from 'react-map-gl';
import { Transition, Tab } from '@headlessui/react'
import Geocoder from "../Components/Geocoder"
import TradeFilter from "../Components/TradeExplore/TradeFilter"
import TrainSearcher from "../Components/TrainSearcher";
import TransactionSummary from "../Components/TradeExplore/TransactionSummary";
import Select from 'react-select'
import TransactionDetail from "./TransactionDetail";
import TrainStationDetail from "./TrainStationDetail";
import filterOptions from "../../../transactions/options"

export default function Transactions(props) {
    const {Translate} = props
    const mapRef = useRef()
    const initialFilterRequest = {
        type: "address",
        postal_code: "",
        land_type: [],
        zone_area: [],
        city_plan_zone: [],
        transaction_period_year: [],
        nearest_station: [],
        timeperiod: [],
        is_renovated: [],
        landshape: [],
        layout: [],
        building_structure: [],
        frontage_direction: [],
        usage: [],
        // build_year: {min:1946, max:2023},
        // distance: {max:15},
        // area: {min:1, max:5000},
        // price_per_sqm: {min:1, max:300000},
        // price: {min:1000, max:30000000000},
    }


    const selectStyles = {
        control: base => ({
            ...base,
            border: 0,
            // This line disable the blue border
            boxShadow: 'none'
        })
    };

    const [isLoading, setLoading] = React.useState(false)
    const [delaySec, setDelaySec] = React.useState(450)
    const [tab, setTab] = React.useState(null)
    const [address, setAddress] = React.useState(null)
    const [selectedTrainStationResult, setSelectedTrainStationResult] = React.useState(null)
    const [trainStationDetail, setTrainStationDetail] = useState({
        station_code: 0,
        station_group_code: 0,
        station_name_jp: "",
        station_name_kana: "",
        station_name_en: "",
        latlng: {},
        prefecture_official_id: 0,
        prefecture: "",
        city: "",
        neighborhood: "",
        line_code: 0,
        postal_code: "",
        address: "",
        status: "",
        routes: [],
    })
    const [selectedGeocoderResult, setSelectedGeocoderResult] = React.useState(null)

    const [isDetailPresented, setIsDetailPresented] = React.useState(false)
    const [selectedDetail, setSelectedDetail] = React.useState(null)
    const onGeocoderResult = (result) => {
        console.log("onGeocoderResult", result)
        setSelectedGeocoderResult(result)
    }
    const onTrainStationResult = (result) => {
        console.log("onTrainStationResult", result)
        setSelectedTrainStationResult(result)
    }
    const [summary, setSummary] = useState({ trade_summary: [] })
    const [transactions, setTransactions] = useState({
        items: [],
        total_item: 0,
        total_page: 0,
        current_page: 0,
        page_size: 0,
        from: 0,
        to: 0,
    })
    const [addressWalkingPath, setAddressWalkingPath] = useState(null)
    const [trainWalkingPath, setTrainWalkingPath] = useState(null)
    const [stats, setStats] = useState({
        trade_area_stats: [],
        trade_nearby_station_sqm_distance_stats: [],
        trade_price_sqm_per_land_type: [],
        trade_nearby_station_stats: [],
        trade_price_sqm_stats: [],
        trade_stats: []
    })
    const [filters, setFilters] = useState({
        land_type: [],
        zone_area: [],
        city_plan_zone: [],
        landshape: [],
        is_renovated: [],
        layout: [],
        usage: [],
        building_structure: [],
        frontage_direction: [],
        frontage_road_classification: [],
    })
    const query = new URLSearchParams(window.location.search);
    const [filterRequest, setFilterRequest] = React.useState(query.get("query") ? {...initialFilterRequest,...JSON.parse(query.get("query"))} : initialFilterRequest); //default filter here
    const [isMoreFiltersPresented, setIsMoreFiltersPresented] = useState(false)
    const [isDataFilterPresented, setIsDataFilterPresented] = useState(false)

    useEffect(() => {
        setFilterRequest(filterRequest)
        if (props.history) {
            props.history.replace({
                pathname: window.location.pathname,
                search: `?query=${JSON.stringify(filterRequest)}`
            })
        }
    }, [filterRequest]);

    const searchFilter = (requestType) => {
        // const {postal_code, timeperiod} = filterRequest
        // setFilterRequest(filterRequest)
        if (requestType === "address") {
            setFilterRequest({ ...filterRequest, nearest_station: [], type: requestType })
            fetchData({ ...filterRequest, nearest_station: [], type: requestType })
        }
        if (requestType === "train") {
            setFilterRequest({ ...filterRequest, address: '', type: requestType })
            fetchData({ ...filterRequest, address: '', type: requestType })
        }
    }
    useEffect(() => {
        if (selectedGeocoderResult && selectedGeocoderResult?.features?.length > 0) {
            const [feature] = selectedGeocoderResult.features
            const postcodeCtx = feature.properties.context.find((c) => c.layer === "postcode")
            setAddress(feature.properties.feature_name)
            if (postcodeCtx) {
                const zipcode = postcodeCtx.name.replace("-", "")
                setFilterRequest((data) => ({
                    ...data,
                    address: feature.properties.feature_name,
                    postal_code: zipcode,
                    latitude: selectedGeocoderResult?.features[0].geometry.coordinates[1],
                    longitude: selectedGeocoderResult?.features[0].geometry.coordinates[0],
                }))

                mapbox.isochrone({
                    lat: feature.geometry.coordinates[1],
                    lng: feature.geometry.coordinates[0]
                }).then((res) => {
                    setAddressWalkingPath(res)
                })
            }
            mapRef.current?.flyTo({ center: feature.geometry.coordinates, duration: 250, zoom: 13 });

        }
    }, [selectedGeocoderResult])

    useEffect(() => {
        if (selectedTrainStationResult) {
            const { latitude, longitude } = selectedTrainStationResult.latlng
            setTrainStationDetail((d) => ({ ...d, ...selectedTrainStationResult }))
            const zipcode = selectedTrainStationResult.postal_code.replace("-", "")
            setFilterRequest((data) => ({
                ...data,
                postal_code: zipcode, nearest_station: [selectedTrainStationResult.station_name_jp], latitude, longitude, distance: { ...filterRequest.distance }
            }))
            setTab(selectedTrainStationResult.station_name_jp)
            mapbox.isochrone({
                lat: latitude,
                lng: longitude,
                distance: filterRequest.distance,
            }).then((res) => {
                setTrainWalkingPath(res)
            })
            mapRef.current?.flyTo({ center: [longitude, latitude], duration: 250, zoom: 13 });

            api.trainRoutes(selectedTrainStationResult.station_group_code)
                .then((res) => {
                    console.log("train routes", res.data)
                    setTrainStationDetail((d) => ({ ...d, ...selectedTrainStationResult, routes: res.data }))
                })
        }
    }, [selectedTrainStationResult])

    const fetchData = useCallback(
        debounce((filterRequest) => {
            filterRequest.request_datas = ["stats", "transaction", "summary", "filter_option"]
            setLoading(true)
            api.fetchTradeTransaction(filterRequest)
                .then((res) => {
                    console.log("fetchTradeTransaction", res)
                    setTransactions(res.data.transaction)
                    setStats(res.data.stats)
                    setFilters((f) => ({ ...f, ...res.data.filter_option }))
                    setSummary((f) => ({ ...f, ...res.data.summary }))
                    setLoading(false)
                })
                .catch((err) => {
                    console.error("error is ", err)
                    setLoading(false)
                });

        }, delaySec)
        , [])
    const fetchTableData = useCallback(
        debounce((filterRequest) => {
            filterRequest.request_datas = ["transaction", "summary"]
            setLoading(true)
            api.fetchTradeTransaction(filterRequest)
                .then((res) => {
                    console.log("fetchTradeTransaction", res)
                    setTransactions(res.data.transaction)
                    setSummary((f) => ({ ...f, ...res.data.summary }))
                    setLoading(false)
                })
                .catch((err) => {
                    console.error("error is ", err)
                    setLoading(false)
                });

            // api.fetchTradeTransaction(filterRequest)
            //     .then((res) => {
            //         console.log("fetchTradeTransaction", res)
            //         setTransactions(res.data)
            //     });

            // api.fetchTradeSummary(filterRequest)
            //     .then((res) => {
            //         console.log("fetchTradeTransaction", res)
            //         setSummary((f) => ({ ...f, ...res.data }))
            //     });
        }, delaySec)
        , [])
    const fetchFilterStationData = useCallback(
        debounce((filterRequest) => {
            filterRequest.request_datas = ["transaction", "summary", "filter_option"]
            setLoading(true)
            api.fetchTradeTransaction(filterRequest)
                .then((res) => {
                    console.log("fetchTradeTransaction", res)
                    setTransactions(res.data.transaction)
                    setFilters((f) => ({ ...f, ...res.data.filter_option }))
                    setSummary((f) => ({ ...f, ...res.data.summary }))
                    setLoading(false)
                })
                .catch((err) => {
                    console.error("error is ", err)
                    setLoading(false)
                });
            // api.fetchTradeTransaction(filterRequest)
            //     .then((res) => {
            //         console.log("fetchTradeTransaction", res)
            //         setTransactions(res.data)
            //     });
            // api.fetchTradeFilterOptions(filterRequest)
            //     .then((res) => {
            //         console.log("fetchTradeTransaction", res)
            //         setFilters((f) => ({ ...f, ...res.data }))
            //     });
            // api.fetchTradeSummary(filterRequest)
            //     .then((res) => {
            //         console.log("fetchTradeTransaction", res)
            //         setSummary((f) => ({ ...f, ...res.data }))
            //     });
        }, delaySec)
        , [])

    const setFilterRequestCallback = () => {
        console.log("filter changed!!")
        fetchTableData(filterRequest, {
            latitude: selectedGeocoderResult?.features[0].geometry.coordinates[1],
            longitude: selectedGeocoderResult?.features[0].geometry.coordinates[0],
        })
    }
    const setFilterStationRequestCallback = (nearest_station) => {
        console.log("filter changed!!")
        setFilterRequest((d) => {
            return { ...d, "nearest_station": [nearest_station] }
        })
        setTab(nearest_station)

        fetchFilterStationData({ ...filterRequest, nearest_station: [nearest_station] }, {
            latitude: selectedGeocoderResult?.features[0].geometry.coordinates[1],
            longitude: selectedGeocoderResult?.features[0].geometry.coordinates[0],
        })
    }
    const handleBack = () => {
        setFilterRequest((d) => ({ ...d, page: transactions.current_page - 1 }))
        fetchTableData({ ...filterRequest, page: transactions.current_page - 1 }, {
            latitude: selectedGeocoderResult?.features[0].geometry.coordinates[1],
            longitude: selectedGeocoderResult?.features[0].geometry.coordinates[0],
        })
    }
    const handlePage = (pageNo) => {
        if (pageNo === transactions.current_page) {
            return
        }
        setFilterRequest((d) => ({ ...d, page: pageNo }))
        fetchTableData({ ...filterRequest, page: pageNo }, {
            latitude: selectedGeocoderResult?.features[0].geometry.coordinates[1],
            longitude: selectedGeocoderResult?.features[0].geometry.coordinates[0],
        })
    }
    const handleNext = () => {
        setFilterRequest((d) => ({ ...d, page: transactions.current_page + 1 }))
        fetchTableData({ ...filterRequest, page: transactions.current_page + 1 }, {
            latitude: selectedGeocoderResult?.features[0].geometry.coordinates[1],
            longitude: selectedGeocoderResult?.features[0].geometry.coordinates[0],
        })
    }
    const handleOrder = (order) => {
        if (order === filterRequest.order) {
            return
        }
        setFilterRequest((d) => ({ ...d, order }))
        fetchTableData({ ...filterRequest, order }, {
            latitude: selectedGeocoderResult?.features[0].geometry.coordinates[1],
            longitude: selectedGeocoderResult?.features[0].geometry.coordinates[0],
        })
    }

    const markerStyle = (hex) => {
        return {
            backgroundColor: `#${hex}`
        }
    }

    return (
        <div className="absolute inset-0 w-full flex h-full">
            <SideNavBar {...props} isPresented={true} />
            <main className="w-full h-full flex flex-col">

                <div className="flex-none flex items-center w-full border-b ">

                    <Tab.Group selectedIndex={filterRequest.type === "address" ? 0 : 1}>
                        <div className="w-full container mx-auto  px-4">

                            <div className="pt-4 flex items-center gap-2">
                                <MagnifyingGlassIcon className="w-6 h-6"/>
                                <p className="text-xl font-bold">{Translate("Real Estate Transactions")}</p>
                            </div>

                            <div className="flex items-center w-full border-b">

                                <Tab.List className="">

                                    <div className="flex items-center w-full">
                                        <Tab className="focus:outline-none" onClick={() => {
                                            setFilterRequest((d) => ({ ...d, type: "address", }))
                                        }}>
                                            {({ selected }) => (
                                                <div className={`flex items-center gap-2 px-3 py-3 font-medium border-b-2 ${selected ? " border-primary text-primary" : "border-transparent text-gray-400"}`}>
                                                    <HomeIcon className="w-5 h-5" />
                                                    <span>{Translate("Search by Address")}</span>
                                                </div>
                                            )}
                                        </Tab>
                                        <Tab className="focus:outline-none" onClick={() => {
                                            setFilterRequest((d) => ({ ...d, type: "train", distance: { ...filterOptions.distanceRange[0] } }))
                                        }}>
                                            {({ selected }) => (
                                                <div className={`flex items-center gap-2 px-3 py-3 font-medium border-b-2  ${selected ? "border-primary text-primary" : "border-transparent text-gray-400"}`}>
                                                    <TrainIcon className="w-5 h-5" />
                                                    <span>{Translate("Search by Train station")}</span>
                                                </div>
                                            )}
                                        </Tab>
                                    </div>
                                </Tab.List>

                                <button onClick={(e) => { setIsMoreFiltersPresented(!isMoreFiltersPresented) }} className="ml-auto flex items-center font-medium gap-2 text-gray-500">
                                    <FunnelIcon className="w-5 h-5" />
                                    <span className="hidden lg:flex">{Translate("Advanced filters")}</span>
                                    <ChevronDownIcon className="w-5 h-5" />
                                </button>

                            </div>
                            <Tab.Panels as="div" className="bg-white py-4 w-full">
                                <Tab.Panel >
                                    <div className="w-full h-full flex flex-col gap-4 ">
                                        <div className="flex items-end gap-4">
                                            <div className="flex flex-col gap-2 w-full">
                                                <Geocoder {...props}  address={filterRequest.address} onResult={onGeocoderResult} resetSearchCallback={() => {
                                                    setFilterRequest((d) => ({ ...d, address: "" }))
                                                }}></Geocoder>
                                            </div>
                                            <button className="ml-auto h-10  bg-primary text-white font-medium px-2 py-2 whitespace-nowrap rounded flex-nowrap disabled:bg-primary/30"
                                                onClick={() => {
                                                    setIsMoreFiltersPresented(false)
                                                    searchFilter("address")
                                                }}
                                                disabled={!selectedGeocoderResult}>{Translate("Search transactions")}</button>
                                        </div>
                                        {
                                            isMoreFiltersPresented ?
                                                <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
                                                    <TradeFilter {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} onChange={setFilterRequestCallback} name="land_type" options={filterOptions} />
                                                    <TradeFilter {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} onChange={setFilterRequestCallback} name="zone_area" options={filterOptions} />
                                                    <TradeFilter {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} onChange={setFilterRequestCallback} name="city_plan_zone" options={filterOptions} />
                                                    <TradeFilter {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} onChange={setFilterRequestCallback} name="landshape" options={filterOptions} />
                                                    <TradeFilter {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} onChange={setFilterRequestCallback} name="layout" options={filterOptions} />
                                                    <TradeFilter {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} onChange={setFilterRequestCallback} name="usage" options={filterOptions} />
                                                    <TradeFilter {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} onChange={setFilterRequestCallback} name="building_structure" options={filterOptions} />
                                                    <TradeFilter {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} onChange={setFilterRequestCallback} name="frontage_direction" options={filterOptions} />
                                                </div>
                                                : null
                                        }


                                    </div>
                                </Tab.Panel>
                                <Tab.Panel>
                                    <div className="w-full h-full flex flex-col gap-4 ">
                                        <div className="grid lg:grid-cols-4 gap-4">
                                            <div className="lg:col-span-2 flex items-end gap-4">
                                                <div className="flex flex-col gap-2 w-full">
                                                    <TrainSearcher station={filterRequest.nearest_station} onResult={onTrainStationResult} resetSearchCallback={() => {
                                                        setFilterRequest((d) => ({ ...d, nearest_station: [] }))
                                                    }} />
                                                </div>
                                            </div>
                                            <div className="flex items-end gap-4">
                                                <div className="flex flex-col gap-2 w-full">
                                                    <Select
                                                        required
                                                        styles={selectStyles}
                                                        className="text-base border border-gray-300 rounded "
                                                        name="distance"
                                                        id="distance"
                                                        options={filterOptions.distanceRange}
                                                        getOptionLabel={({ label }) => { return `${label} ${Translate("minutes")}` }}
                                                        getOptionValue={({ id }) => id}
                                                        onChange={opt => {
                                                            setFilterRequest((f) => ({ ...f, distance: { ...opt } }))
                                                            if (onTrainStationResult) {
                                                                mapbox.isochrone({
                                                                    lat: selectedTrainStationResult.latlng.latitude,
                                                                    lng: selectedTrainStationResult.latlng.longitude,
                                                                    distance: opt,
                                                                }).then((res) => {
                                                                    setTrainWalkingPath(res)
                                                                })
                                                            }
                                                        }}
                                                        value={filterOptions.distanceRange.filter((dr) => dr.min === filterRequest.distance?.min)}
                                                    />
                                                </div>
                                            </div>
                                            <div>
                                                <button className="w-full bg-primary text-white font-medium px-2 h-10 rounded flex-nowrap disabled:bg-primary/20"
                                                    onClick={() => {
                                                        setIsMoreFiltersPresented(false)
                                                        searchFilter("train")
                                                    }} disabled={!selectedTrainStationResult}>{Translate("Search transactions")}</button>
                                            </div>
                                        </div>
                                        {
                                            isMoreFiltersPresented ?
                                                <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
                                                    <TradeFilter {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} onChange={setFilterRequestCallback} name="land_type" options={filterOptions} />
                                                    <TradeFilter {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} onChange={setFilterRequestCallback} name="zone_area" options={filterOptions} />
                                                    <TradeFilter {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} onChange={setFilterRequestCallback} name="city_plan_zone" options={filterOptions} />
                                                    <TradeFilter {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} onChange={setFilterRequestCallback} name="landshape" options={filterOptions} />
                                                    <TradeFilter {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} onChange={setFilterRequestCallback} name="layout" options={filterOptions} />
                                                    <TradeFilter {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} onChange={setFilterRequestCallback} name="usage" options={filterOptions} />
                                                    <TradeFilter {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} onChange={setFilterRequestCallback} name="building_structure" options={filterOptions} />
                                                    <TradeFilter {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} onChange={setFilterRequestCallback} name="frontage_direction" options={filterOptions} />
                                                </div>
                                                : null
                                        }
                                    </div>
                                </Tab.Panel>
                            </Tab.Panels>
                        </div>
                    </Tab.Group>
                </div>

                <div className="flex-1 flex relative w-full overflow-hidden">
                    {/* left panel */}
                    <div className="flex-none w-80  overflow-hidden h-auto flex flex-col">

                        <div className="bg-gray-100 w-full h-80 border-b border-gray-300 overflow-hidden">
                            <ReactMapGL
                                ref={mapRef}
                                attributionControl={false}
                                cursor={getCursor}
                                mapStyle="mapbox://styles/apisitviila/ckwqdi01c0pjz14pdgapgheaw"
                                mapboxAccessToken={MAPBOX_TOKEN}
                                initialViewState={{
                                    latitude: 36.3418518,
                                    longitude: 138.6179432,
                                    zoom: 9
                                }}
                                style={{ width: "100%", height: "100%" }}
                            >
                                {filterRequest.type === "address" && selectedGeocoderResult && selectedGeocoderResult?.features.map((feature) => (
                                    <Fragment key={feature.properties.id}>
                                        <Marker
                                            latitude={feature.geometry.coordinates[1]}
                                            longitude={feature.geometry.coordinates[0]}
                                        >

                                            <svg className="animate-bounce" width="20" height="48" viewBox="0 0 20 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.3" d="M10 32C14.9706 32 19 29.7614 19 27C19 24.2386 14.9706 22 10 22C5.02944 22 1 24.2386 1 27C1 29.7614 5.02944 32 10 32Z" fill="#C4C4C4" />
                                                <g opacity="0.3">
                                                    <g opacity="0.3">
                                                        <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M10 32C15 32 19 29.8 19 27C19 24.2 15 22 10 22C5 22 1 24.2 1 27C1 29.8 5 32 10 32Z" fill="#BFBFBF" />
                                                    </g>
                                                </g>
                                                <path d="M19.2499 10.4C19.1337 12.2242 18.6361 14.0037 17.7892 15.6235C16.8522 17.554 15.767 19.409 14.5433 21.1718C13.3604 22.9087 12.1771 24.4502 11.2892 25.5577C10.8454 26.1113 10.4757 26.5561 10.2171 26.8623C10.1327 26.9623 10.0601 27.0475 10.0007 27.1168C9.94069 27.0468 9.86819 26.9604 9.78339 26.859C9.52469 26.5502 9.15499 26.1019 8.71109 25.5443C7.82319 24.4289 6.63969 22.8779 5.45679 21.1343C4.23519 19.3683 3.14995 17.5117 2.21049 15.5808C1.3669 13.9749 0.869336 12.21 0.749887 10.4C0.700513 7.89475 1.64648 5.47212 3.38042 3.6632C5.11436 1.85428 7.49479 0.806665 9.99989 0.75C12.505 0.806665 14.8854 1.85428 16.6194 3.6632C18.3533 5.47212 19.2993 7.89475 19.2499 10.4V10.4Z" fill="#4264FB" stroke="#314CCD" strokeWidth="0.5" />
                                                <path d="M13.5502 10.0002C13.5502 10.7023 13.342 11.3887 12.9519 11.9725C12.5618 12.5563 12.0074 13.0113 11.3587 13.28C10.71 13.5487 9.99626 13.619 9.30763 13.482C8.61899 13.345 7.98644 13.0069 7.48997 12.5104C6.99349 12.0139 6.65539 11.3814 6.51841 10.6928C6.38143 10.0041 6.45173 9.29035 6.72042 8.64167C6.98912 7.99299 7.44413 7.43856 8.02792 7.04848C8.61172 6.6584 9.29807 6.4502 10.0002 6.4502C10.4664 6.44999 10.9282 6.54167 11.359 6.72C11.7898 6.89833 12.1812 7.15981 12.5109 7.4895C12.8406 7.81919 13.1021 8.21062 13.2804 8.64142C13.4587 9.07222 13.5504 9.53394 13.5502 10.0002Z" fill="white" stroke="#314CCD" strokeWidth="0.5" />
                                            </svg>
                                        </Marker>
                                    </Fragment>
                                ))}
                                {filterRequest.type === "train" && selectedTrainStationResult && (
                                    <Fragment >
                                        <Marker
                                            latitude={selectedTrainStationResult.latlng.latitude}
                                            longitude={selectedTrainStationResult.latlng.longitude}
                                        >
                                            <div className="w-6 h-6 rounded-full flex items-center justify-center drop-shadow-lg" style={markerStyle("145AFF")}>
                                                <TrainIcon className="w-4 h-4 text-white" />
                                            </div>
                                        </Marker>
                                    </Fragment>
                                )}
                                {filterRequest.type === "address" && stats.trade_nearby_station_stats.filter((station) => station.latlng.latitude && station.latlng.longitude && station.latlng.latitude !== 0 && station.latlng.longitude !== 0).map((station) => (<Fragment key={station.nearest_station}>
                                    <Marker
                                        latitude={station.latlng.latitude}
                                        longitude={station.latlng.longitude}
                                    ></Marker>
                                </Fragment>))}

                                {filterRequest.type === "address" && addressWalkingPath ? <Source id="address-walking-path" type="geojson" data={addressWalkingPath}>
                                    <Layer {...walkingPathStyle} />
                                </Source> : null}
                                {filterRequest.type === "train" && trainWalkingPath ? <Source id="station-walking-path" type="geojson" data={trainWalkingPath}>
                                    <Layer {...walkingPathStyle} />
                                </Source> : null}
                                <ScaleControl position="bottom-right" style={scaleControlStyleNoPadding} />
                                <FullscreenControl position="bottom-right" style={fullscreenControlStyleNoPadding} />
                                <NavigationControl position="bottom-right" style={navStyleNoPadding} />
                                <GeolocateControl position="bottom-right" style={geolocateStyleNoPadding} />
                            </ReactMapGL>
                        </div>

                        <div className="mt-auto h-full bg-white flex flex-col divide-y overflow-hidden overflow-y-auto">

                            {filterRequest.type === "address" && (<div className="p-4 flex flex-col">
                                <p className="font-medium">{Translate("Address")}</p>
                                <p className="text-sm">{address}</p>
                            </div>)}

                            {filterRequest.type === "train" && (<TrainStationDetail {...props} detail={trainStationDetail} />)}

                            {/* {filterRequest.type === "address" && stats.trade_nearby_station_stats.sort((x, y) => {
                                if (!x.google_map_distance?.map_data?.rows) {
                                    return 1
                                }
                                if (!y.google_map_distance?.map_data?.rows) {
                                    return -1
                                }
                                return x.google_map_distance?.map_data?.rows[0].elements[0].distance.value - y.google_map_distance?.map_data?.rows[0].elements[0].distance.value
                            }).map((st) => ( */}
                            {filterRequest.type === "address" && stats.trade_nearby_station_stats.sort((x,y) => x.distance_from_marker - y.distance_from_marker).map((st) => ( 
                                <div key={st.nearest_station} className="flex items-start group cursor-pointer text-sm" onClick={(e) => {
                                    console.log("near by station", st.nearest_station)
                                    if (filterRequest.nearest_station.filter((selected) => selected === st.nearest_station).length === 0) {
                                        setFilterStationRequestCallback(st.nearest_station)
                                    }
                                }} >
                                    <div className="pl-4 py-4">
                                        <WalkIcon className="w-5 h-5" />
                                    </div>
                                    <div className="p-4 ">
                                        <p className="font-medium">{st.nearest_station}</p>
                                        <p className="text-xs text-gray-500">({st.nearby_properties} {Translate("Properties")})</p>
                                    </div>
                                    <div className="ml-auto text-right flex-none p-4">
                                        {/* {st.google_map_distance?.map_data?.rows.filter(r => r.elements.length > 0).map((row, i) => (<Fragment key={i}>
                                            <p className="font-medium">{row.elements[0].duration.text}</p>
                                            <p className="text-xs text-gray-500">{row.elements[0].distance.text}</p>
                                        </Fragment>))} */}
                                        {st.distance_from_marker && <p className="text-xs text-gray-500">{st.distance_from_marker / 1000} km</p>}
                                    </div>
                                    <div className="py-4 pr-4 bg-white h-full flex-none flex items-center justify-center">
                                        <CheckCircleIcon className={`w-8 h-8  group-hover:text-primary ${filterRequest.nearest_station.includes(st.nearest_station) ? "text-primary" : "text-gray-200"}`} />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* end left panel */}

                    {/* center panel */}
                    <div className="w-full h-full flex flex-col border-l border-r overflow-hidden">

                        <div className="px-4 py-2 flex items-center">
                            <div className="">
                                <p className=" font-medium">{Translate("Transactions")} ({transactions.total_item})</p>
                                <p className="text-xs text-gray-400">{Translate("List of transactions based on search conditions")}</p>
                            </div>
                            <div className="ml-auto flex items-center gap-4">
                                <button className="rounded text-sm py-1 text-gray-500 hover:text-gray-600 flex items-center" onClick={() => {
                                    api.exportTradeTransaction(filterRequest)
                                }}>
                                    <DocumentArrowDownIcon className="w-4 h-4 mr-1" />
                                    <span className="text-xs font-medium">CSV</span>
                                </button>
                                {/* <button onClick={(e) => { setIsDetailPresented(true) }} className="rounded text-sm  py-1 text-gray-500 hover:text-gray-600 flex items-center">
                                    <PrinterIcon className="w-4 h-4 mr-1" />
                                    <span className="text-xs font-medium">Print</span>
                                </button> */}
                                <button onClick={(e) => { setIsDataFilterPresented(!isDataFilterPresented) }} className="rounded ring-1 ring-primary text-primary text-sm px-2 py-1 hover:bg-primary hover:text-white flex items-center">
                                    <FunnelIcon className="w-4 h-4 mr-1" />
                                    <span className="text-xs font-medium">{Translate("More Filters")}</span>
                                </button>

                            </div>
                        </div>

                        <div className="w-full h-full overflow-y-auto relative">
                            {transactions.items.length === 0 ? (<div className="flex flex-grow flex-col h-full bg-white">
                                <div className="flex h-full items-center justify-center flex-col  text-center  shadow-md">
                                    <InboxIcon className="w-20 h-20" />
                                    <p className="text-4xl font-bold">{Translate("no_result_title")}</p>
                                    <span>{Translate("no_result_description")}</span>
                                </div>
                            </div>):
                            (<Tab.Group>
                                <Tab.List className="border-b flex items-center">
                                    <Tab className="focus:outline-none">
                                        {({ selected }) => (
                                            <div className={`focus:outline-none flex items-center gap-2 px-3 py-3 font-medium border-b-2 ${selected ? " border-primary text-primary" : "border-transparent text-gray-400"}`}>
                                                <DocumentMagnifyingGlassIcon className="w-5 h-5" />
                                                <span className="whitespace-nowrap">{Translate("Transaction details")}</span>
                                            </div>
                                        )}

                                    </Tab>
                                    <Tab className="focus:outline-none">
                                        {({ selected }) => (
                                            <div className={`focus:outline-none flex items-center gap-2 px-3 py-3 font-medium border-b-2 ${selected ? " border-primary text-primary" : "border-transparent text-gray-400"}`}>
                                                <ChartBarIcon className="w-5 h-5 rotate-90" />
                                                <span className="whitespace-nowrap">{Translate("Summary")}</span>
                                            </div>
                                        )}
                                    </Tab>

                                </Tab.List>
                                <Tab.Panels as="div" className="bg-white w-full">
                                    <Tab.Panel>

                                        <table className="w-full text-xs table-auto">
                                            <thead>
                                                <tr className="bg-gray-100 sticky top-0">
                                                    <th className="text-left p-2">
                                                        <div className="flex">
                                                            <span>{Translate("Period")}</span>
                                                            {(!(filterRequest?.order || "").startsWith("transaction_period_year")) && <ChevronUpDownIcon className="cursor-pointer w-4 h-4" onClick={() => {
                                                                handleOrder("transaction_period_year_low_to_high")
                                                            }} />}
                                                            {filterRequest.order === "transaction_period_year_low_to_high" && <ChevronUpIcon className="cursor-pointer w-4 h-4" onClick={() => {
                                                                handleOrder("transaction_period_year_high_to_low")
                                                            }} />}
                                                            {filterRequest.order === "transaction_period_year_high_to_low" && <ChevronDownIcon className="cursor-pointer w-4 h-4" onClick={() => {
                                                                handleOrder("")
                                                            }} />}
                                                        </div>
                                                    </th>
                                                    <th className="text-left p-2">
                                                        <div className="flex">
                                                            <span>{Translate("Land type")}</span>
                                                            {(!(filterRequest?.order || "").startsWith("land_type")) && <ChevronUpDownIcon className="cursor-pointer w-4 h-4" onClick={() => {
                                                                handleOrder("land_type_a_z")
                                                            }} />}
                                                            {filterRequest.order === "land_type_a_z" && <ChevronUpIcon className="cursor-pointer w-4 h-4" onClick={() => {
                                                                handleOrder("land_type_z_a")
                                                            }} />}
                                                            {filterRequest.order === "land_type_z_a" && <ChevronDownIcon className="cursor-pointer w-4 h-4" onClick={() => {
                                                                handleOrder("")
                                                            }} />}
                                                        </div>
                                                    </th>
                                                    <th className="text-left p-2">
                                                        <div className="flex">
                                                            <span>{Translate("Station")}</span>
                                                            {(!(filterRequest?.order || "").startsWith("nearest_station")) && <ChevronUpDownIcon className="cursor-pointer w-4 h-4" onClick={() => {
                                                                handleOrder("nearest_station_a_z")
                                                            }} />}
                                                            {filterRequest.order === "nearest_station_a_z" && <ChevronUpIcon className="cursor-pointer w-4 h-4" onClick={() => {
                                                                handleOrder("nearest_station_z_a")
                                                            }} />}
                                                            {filterRequest.order === "nearest_station_z_a" && <ChevronDownIcon className="cursor-pointer w-4 h-4" onClick={() => {
                                                                handleOrder("")
                                                            }} />}
                                                        </div>
                                                    </th>
                                                    <th className="p-2">
                                                        <div className="flex justify-end">
                                                            <span>{Translate("Distance")}</span>
                                                            {(!(filterRequest?.order || "").startsWith("distance")) && <ChevronUpDownIcon className="cursor-pointer w-4 h-4" onClick={() => {
                                                                handleOrder("distance_low_to_high")
                                                            }} />}
                                                            {filterRequest.order === "distance_low_to_high" && <ChevronUpIcon className="cursor-pointer w-4 h-4" onClick={() => {
                                                                handleOrder("distance_high_to_low")
                                                            }} />}
                                                            {filterRequest.order === "distance_high_to_low" && <ChevronDownIcon className="cursor-pointer w-4 h-4" onClick={() => {
                                                                handleOrder("")
                                                            }} />}
                                                        </div>
                                                    </th>
                                                    <th className="p-2 ">
                                                        <div className="flex justify-end">
                                                            <span>{Translate("Total transaction")}</span>
                                                            {(!(filterRequest?.order || "").startsWith("price")) && <ChevronUpDownIcon className="cursor-pointer w-4 h-4" onClick={() => {
                                                                handleOrder("price_low_to_high")
                                                            }} />}
                                                            {filterRequest.order === "price_low_to_high" && <ChevronUpIcon className="cursor-pointer w-4 h-4" onClick={() => {
                                                                handleOrder("price_high_to_low")
                                                            }} />}
                                                            {filterRequest.order === "price_high_to_low" && <ChevronDownIcon className="cursor-pointer w-4 h-4" onClick={() => {
                                                                handleOrder("")
                                                            }} />}
                                                        </div>
                                                    </th>
                                                    <th className="p-2 text-right">
                                                        <div className="flex justify-end">
                                                            <span>{Translate("Area Size")}</span>
                                                            {(!(filterRequest?.order || "").startsWith("area")) && <ChevronUpDownIcon className="cursor-pointer w-4 h-4" onClick={() => {
                                                                handleOrder("area_low_to_high")
                                                            }} />}
                                                            {filterRequest.order === "area_low_to_high" && <ChevronUpIcon className="cursor-pointer w-4 h-4" onClick={() => {
                                                                handleOrder("area_high_to_low")
                                                            }} />}
                                                            {filterRequest.order === "area_high_to_low" && <ChevronDownIcon className="cursor-pointer w-4 h-4" onClick={() => {
                                                                handleOrder("")
                                                            }} />}
                                                        </div>
                                                    </th>
                                                    <th className="p-2 text-right">
                                                        <div className="flex justify-end">
                                                            <span>{Translate("Price/m²")}</span>
                                                            {(!(filterRequest?.order || "").startsWith("price_sqm")) && <ChevronUpDownIcon className="cursor-pointer w-4 h-4" onClick={() => {
                                                                handleOrder("price_sqm_low_to_high")
                                                            }} />}
                                                            {filterRequest.order === "price_sqm_low_to_high" && <ChevronUpIcon className="cursor-pointer w-4 h-4" onClick={() => {
                                                                handleOrder("price_sqm_high_to_low")
                                                            }} />}
                                                            {filterRequest.order === "price_sqm_high_to_low" && <ChevronDownIcon className="cursor-pointer w-4 h-4" onClick={() => {
                                                                handleOrder("")
                                                            }} />}
                                                        </div>
                                                    </th>
                                                    <th className="p-2 text-right">
                                                        <div className="flex justify-end">
                                                            <span>{Translate("Front road (m)")}</span>
                                                        </div>
                                                    </th>
                                                    <th className="p-2 text-right">
                                                        <div className="flex justify-end">
                                                            <span>{Translate("Road Direction")}</span>
                                                        </div>
                                                    </th>
                                                    <th className="">&nbsp;</th>
                                                </tr>
                                            </thead>
                                            <tbody className="divide-y">
                                                {transactions.items.map((t) => (
                                                    <tr key={t.id}>
                                                        <td className="p-2">{t.transaction_period_year}/{t.transaction_period_quarter}</td>
                                                        <td className="p-2">{t.land_type}</td>
                                                        <td className="p-2">{t.nearest_station}</td>
                                                        <td className="p-2 text-right">{t.distance} mins</td>
                                                        <td className="p-2 text-right">
                                                            {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: "JPY" }).format(Number(t.transaction_price))}
                                                        </td>
                                                        <td className="p-2 text-right">{t.area.toLocaleString()}m²</td>
                                                        <td className="p-2 text-right">

                                                            {new Intl.NumberFormat('ja-JP', { style: 'currency', currency: "JPY" }).format(Number(Math.round(t.transaction_price / t.area)))}
                                                        </td>
                                                        <td className="p-2 text-right">{t.frontage_road_breadth}m</td>
                                                        <td className="p-2 text-right">{t.frontage_road_direction !== "NULL" ? t.frontage_road_direction : ""}</td>
                                                        <td className="flex items-center justify-center">
                                                            <button className="p-2" onClick={(e) => { setSelectedDetail(t); setIsDetailPresented(true) }}>
                                                                <InformationCircleIcon className="cursor-pointer w-4 h-4" />
                                                            </button>
                                                        </td>

                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        {transactions.total_page !== 0 && (<div id="pagination" className="px-2 py-2 border-t border-b">
                                            <div className="flex items-center w-full">
                                                <div className="">
                                                    <p className="text-sm text-gray-600">
                                                        {transactions.from} – {transactions.to} of {new Intl.NumberFormat('en-US').format(Number(transactions.total_item ? transactions.total_item : 0))}
                                                    </p>
                                                </div>

                                                <div className="ml-auto flex items-center justify-between">
                                                    <div className="">
                                                        <nav className="relative z-0 inline-flex space-x-4 items-center  text-sm" aria-label="Pagination">
                                                            {transactions.current_page !== 1 && (
                                                                <button
                                                                    className="focus:outline-none rounded px-1 py-0.5 bg-gray-200 relative inline-flex items-center"
                                                                    aria-hidden="true" onClick={handleBack}>
                                                                    <span className="sr-only">{Translate("Back")}</span>
                                                                    <ChevronLeftIcon className="w-5 h-5" />
                                                                </button>)}
                                                            {/* {Array.from({ length: transactions.total_page }, (_, i) => i + 1).map((i) => (<button
                                                                className={`focus:outline-none ${transactions.current_page === i ? "text-primary" : "text-gray-400"}`}
                                                                onClick={() => {
                                                                    handlePage(i)
                                                                }}>
                                                                <span className="font-medium">{i}</span>
                                                            </button>))} */}
                                                            {transactions.current_page !== transactions.total_page && (
                                                                <button
                                                                    className="focus:outline-none rounded px-1 py-0.5 bg-gray-200 relative inline-flex items-center"
                                                                    aria-hidden="true" onClick={handleNext}>
                                                                    <span className="sr-only">{Translate("Next")}</span>
                                                                    <ChevronRightIcon className="w-5 h-5" />
                                                                </button>)}
                                                        </nav>
                                                    </div>
                                                </div>


                                            </div>
                                        </div>)}
                                    </Tab.Panel>
                                    <Tab.Panel className="">
                                        {tab && <div className="">

                                            {/* <div className="flex items-center border-b text-sm overflow-x-auto overflow-hidden">
                                                {stats.trade_nearby_station_stats.map((stationStats) => (
                                                    <>
                                                        <div key={stationStats.nearest_station} className={`flex-none cursor-pointer px-2 py-2 border-b-2 font-medium ${tab === stationStats.nearest_station ? "border-primary" : "border-transparent"}`}
                                                            onClick={(e) => {
                                                                if (e.target.classList.contains("border-primary")) {
                                                                    console.log("no action")
                                                                    return
                                                                }
                                                                setFilterStationRequestCallback(stationStats.nearest_station)
                                                            }}>
                                                            {stationStats.nearest_station}
                                                        </div>
                                                    </>
                                                ))}
                                            </div> */}

                                            <TransactionSummary {...props} selected={tab} summary={summary} />

                                        </div>
                                        }
                                    </Tab.Panel>
                                </Tab.Panels>
                            </Tab.Group>)}


                        </div>

                    </div>
                    {/* right panel */}

                    <Transition
                        appear
                        show={isDataFilterPresented}
                        as={Fragment}
                        enter="ease duration-300 transition transform"
                        enterFrom="opacity-0 translate-x-full"
                        enterTo="opacity-100 translate-x-0"
                        leave="ease duration-200 transition transform"
                        leaveFrom="opacity-100 translate-x-0"
                        leaveTo="translate-x-full"
                    >
                        <div className="z-50 bg-white absolute top-0 right-0 border-l w-96 flex-none h-full flex flex-col  overflow-hidden overflow-y-auto">
                            <div className="font-medium px-4 py-4 border-b flex items-center">
                                <p className="">{Translate("Filters")}</p>
                                <div className="ml-auto">
                                    <button onClick={() => { setIsDataFilterPresented(false) }}>
                                        <XMarkIcon className="w-6 h-6 text-gray-600" />
                                    </button>
                                </div>
                            </div>
                            <div className="flex flex-col gap-4 p-4  text-sm">
                                <TradeFilter {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} onChange={setFilterRequestCallback} name="land_type" options={filters} />
                                <TradeFilter {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} onChange={setFilterRequestCallback} name="zone_area" options={filters} />
                                <TradeFilter {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} onChange={setFilterRequestCallback} name="city_plan_zone" options={filters} />
                                <TradeFilter {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} onChange={setFilterRequestCallback} name="landshape" options={filters} />
                                <TradeFilter {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} onChange={setFilterRequestCallback} name="layout" options={filters} />
                                <TradeFilter {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} onChange={setFilterRequestCallback} name="usage" options={filters} />
                                <TradeFilter {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} onChange={setFilterRequestCallback} name="building_structure" options={filters} />
                                <TradeFilter {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} onChange={setFilterRequestCallback} name="frontage_direction" options={filters} />
                            </div>
                        </div>
                    </Transition>


                    {/*end right panel */}
                </div>
            </main>
            <TransactionDetail {...props} data={selectedDetail} isPresented={isDetailPresented} onClose={() => { setIsDetailPresented(false) }} />

            {isLoading && (<ScreenLoading {...props} />)}
        </div>
    );
}