export default function TradeFilter(props) {
    const {Translate, name, filterRequest, onChange, setFilterRequest, options} = props
    if (!options[name] || options[name].length < 2) {
        return (<></>)
    }
    return (
    <div className="flex flex-col gap-2">
        <div className="font-medium ">
            <span className="mr-2">{Translate(name)}</span>
            <span className="cursor-pointer underline underline-offset-1 text-xs text-gray-400" onClick={() => {
            setFilterRequest((d) => {
                return {...d, [name]: [] }
            })
            onChange()

        }}>deselect</span>
        </div>
        <div className="flex flex-col flex-wrap gap-x-4 gap-y-2 ">
            {options[name].map((option) => {
                // console.log("option.key", option, option.key)
                if (typeof option === 'string') {
                    return (<label key={option} className="flex items-start gap-2 text-xs break-all">
                    <input type="checkbox" name={name} checked={filterRequest[name].includes(option)} 
                        onChange={(e) => {
                            if (e.target.checked) {
                                setFilterRequest((d) => {
                                    d[name].push(option)
                                    return {...d, [name]: d[name] }
                                })
                            } else {
                                setFilterRequest((d) => {
                                    const index = d[name].indexOf(option)
                                    d[name].splice(index, 1)
                                    return {...d, [name]: d[name] }
                                })
                            }
                            onChange()
                        }} className="focus:outline-none focus:ring-0 appearance-none rounded" />
                        <p className="">{option === "" ? "no options" :option}</p>
                    </label>)
                }
                return (<label key={option.key} className="flex items-start gap-2 text-xs break-all">
                <input type="checkbox" name={name} checked={name === "layout" ? filterRequest[name].filter((i) => {
                    if (option.key === "") return i === ""
                    return i.includes(option.key)
                }).length:filterRequest[name].includes(option.key)} 
                    onChange={(e) => {
                        if (e.target.checked) {
                            setFilterRequest((d) => {
                                d[name].push(option.key)
                                return {...d, [name]: d[name] }
                            })
                        } else {
                            setFilterRequest((d) => {
                                const index = d[name].indexOf(option.key)
                                d[name].splice(index, 1)
                                return {...d, [name]: d[name] }
                            })
                        }
                        onChange()
                    }} className="focus:outline-none focus:ring-0 appearance-none rounded" />
                    <p className="">{option.key === "" ? "no options" :option.key} ({option.value})</p>
                </label>)
            })}
        </div>
    </div>)
}