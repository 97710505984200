import clsx from "clsx";
import React, {Fragment, useEffect, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import TransactionSummary from "../TradeExplore/TransactionSummary";

export default function SellTransactionModal(props) {
    const {isOpen, setIsOpen, Translate, summary, station, minutes } = props
    
    function closeModal() {
        setIsOpen(false)
    }
    return (
    <>
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[50]" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300 "
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-100"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25"></div>
                </Transition.Child>

                <div className="fixed top-0 left-0 inset-0 w-full h-full outline-none">
                    <div className="flex items-center justify-end h-full">
                        <Transition.Child
                            as={Fragment}
                            enter="ease duration-300 transition transform"
                    enterFrom="opacity-0 translate-x-full"
                    enterTo="opacity-100 translate-x-0"
                    leave="ease duration-200 transition transform"
                    leaveFrom="opacity-100 translate-x-0"
                    leaveTo="translate-x-full"
                        >
                            <Dialog.Panel className="border-none shadow-lg relative flex flex-col lg:w-4/5 w-full h-full pointer-events-auto bg-white bg-clip-padding  outline-none text-current">
                                <Dialog.Title
                                    as="div"
                                    className="flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md"
                                >
                                    <h3 className='text-lg font-medium leading-6 text-gray-900'>
                                        {Translate("sell_transaction_modal_transaction_history")}
                                    </h3>
                                    <button type="button" id="close" onClick={closeModal}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </Dialog.Title>
                                <div className="relative flex h-full overflow-y-auto">
                                    <TransactionSummary {...props} className={"flex flex-col overflow-hidden overflow-y-auto relative w-full"} selected={station} summary={summary} minutes={minutes} />
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    </>)
}
