import clsx from "clsx";
import React, {Fragment, useEffect, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';
import InputField from "../../Components/StepWizard/InputField";
import MoneyInputField from "../../Components/StepWizard/MoneyInputField";
import NumberInputField from "../../Components/StepWizard/NumberInputField";
import TextAreaField from "../../Components/StepWizard/TextAreaField";
import { submitProposal } from "../../../validators/proposal"
import { useHistory, useParams } from 'react-router-dom';
import * as api from '../../../services/agent'

export default function ProposalModal(props) {
    const {isOpen, setIsOpen, Translate, data, requestId } = props
    let history = useHistory();


    function closeModal() {
        setIsOpen(false)
    }

    const validators = useForm({
        resolver: yupResolver(submitProposal),
        defaultValues: data
    })
    const { control, formState: { errors }, clearErrors, handleSubmit } = validators

    function handleSubmitProposal() {
        handleSubmit((d) => {
            alert(3)
            console.log("data is", d)
            api.submitProposal(requestId, d)
                .then((res) => {
                    // window.location.reload()
                })
                .catch((err) => {
                    console.log("error", err)
                })
        }, (err) => {
            console.log("handleSubmit error", err)
        })()
    }

    return (
    <>
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[50]" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300 "
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-100"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25"></div>
                </Transition.Child>

                <div className="fixed top-0 left-0 inset-0 w-full h-full outline-none">
                    <div className="flex items-center justify-end h-full">
                        <Transition.Child
                            as={Fragment}
                            enter="ease duration-300 transition transform"
                    enterFrom="opacity-0 translate-x-full"
                    enterTo="opacity-100 translate-x-0"
                    leave="ease duration-200 transition transform"
                    leaveFrom="opacity-100 translate-x-0"
                    leaveTo="translate-x-full"
                        >
                            <Dialog.Panel className="border-none shadow-lg relative flex flex-col lg:w-4/5 w-full h-full pointer-events-auto bg-white bg-clip-padding  outline-none text-current">
                                <Dialog.Title
                                    as="div"
                                    className="flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md"
                                >
                                    <h3 className='text-lg font-medium leading-6 text-gray-900'>
                                        {Translate("proposal_modal_send_proposal")}
                                    </h3>
                                    <button type="button" id="close" onClick={closeModal}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </Dialog.Title>
                                <div className="relative flex-1  overflow-hidden">
                                    <div className="grid grid-cols-1 text-sm rounded-t-md bg-white h-full overflow-y-auto pb-8">
                                        <div className="px-6 space-y-2">
                                            <div className="grid grid-cols-3 mt-4">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Appraisal Value")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <div className="grid grid-cols-2 items-center gap-2">
                                                        <div className="">
                                                            <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Min")}</label>
                                                            <MoneyInputField
                                                                control={control}
                                                                errors={errors} clearErrors={clearErrors}
                                                                required={true}
                                                                placeholder={"50,000,000"}
                                                                name="min_price.amount"
                                                                id="min-appraisal-value"
                                                                className=""
                                                                />
                                                        </div>
                                                        <div className="">
                                                            <label htmlFor="" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Max")}</label>
                                                            <MoneyInputField
                                                                control={control}
                                                                errors={errors} clearErrors={clearErrors}
                                                                required={true}
                                                                placeholder={"50,000,000"}
                                                                name="max_price.amount"
                                                                id="max-appraisal-value"
                                                                className=""
                                                                />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Commission Rate")} (%)</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <NumberInputField control={control}
                                                        errors={errors} clearErrors={clearErrors}
                                                        type="text"
                                                        data-type="float"
                                                        name="commission"
                                                        id="commission-rate"
                                                        placeholder="3"
                                                        required />
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-3">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700 mb-2 lg:mb-0">{Translate("Remarks")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <TextAreaField
                                                        control={control} errors={errors} clearErrors={clearErrors}
                                                        required rows="6"
                                                        id="remarks" name="remarks"
                                                        placeholder={Translate("Describe about this sell request")}
                                                    />
                                                </div>
                                            </div>
                                            {/* <div className="">
                                                <table className="table-auto w-full border">
                                                    <thead className="bg-gray-200">
                                                        <tr className="border-b">
                                                            <th className="p-2"></th>
                                                            <th className="p-2">Min</th>
                                                            <th className="p-2">Max</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr className="border-b">
                                                            <td className="p-2">Sale Price</td>
                                                            <td className="p-2 text-right">proposal.MinPrice</td>
                                                            <td className="p-2 text-right">proposal.MaxPrice</td>
                                                        </tr>
                                                        <tr className="border-b">
                                                            <td className="p-2">Brokerage fee</td>
                                                            <td className="p-2 text-right">proposal.MinBrokerageFee</td>
                                                            <td className="p-2 text-right">proposal.MaxBrokerageFee</td>
                                                        </tr>
                                                        <tr className="border-b">
                                                            <td className="p-2">Revenue Stamp</td>
                                                            <td className="p-2 text-right">proposal.MinRevenueStamp</td>
                                                            <td className="p-2 text-right">proposal.MaxRevenueStamp</td>
                                                        </tr>
                                                        <tr className="border-b">
                                                            <td className="p-2">Transfer Tax</td>
                                                            <td className="p-2 text-right">proposal.MinTransferTax</td>
                                                            <td className="p-2 text-right">proposal.MaxTransferTax</td>
                                                        </tr>
                                                        <tr className="border-b">
                                                            <td className="p-2">Take home amount</td>
                                                            <td className="p-2 text-right">proposal.MinTakeHomeAmount</td>
                                                            <td className="p-2 text-right">proposal.MaxTakeHomeAmount</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div> */}
                                        </div>
                                        
                                    </div>
                                </div>
                                <div className="mt-auto flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                                    <div className="ml-auto">
                                        <button type="button" className="btn btn-primary" onClick={handleSubmitProposal}>{Translate("Submit")}</button>
                                    </div>
                                </div>
                            
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    </>)
}
