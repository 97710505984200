import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";

export default function WizardFooter(props) {
    const { steps, useStep, handleNext, handleBack, handlePublish, handleDraft, resetData, Translate } = props
    const { step, totalStep } = useStep
    return (
        <footer className="mt-auto flex-none items-center w-full bg-gray-200">
            <hr />
            <div className="py-2 px-6 bg-white">
                <p className="text-xs">
                    {Translate("This information will be displayed publicly on the website once you published.")}
                </p>
            </div>
            <div className="bg-slate-200 border-t px-6 py-2 flex items-center justify-between ">
                <button id="reset-form" type="button" className="btn bg-red-600 text-white " onClick={e => resetData()}>
                    {Translate("Reset")}
                </button>
                <div className="ml-auto flex">
                    {step === 0 ? null:<button id="save-as-draft" type="button" className="btn bg-gray-600 text-white mr-2" onClick={handleDraft}>
                        {Translate("Save as Draft")}
                    </button>}
                    <div className="flex items-center gap-2">
                        {step === 0 ? null :
                            (<button id="back-bottom" type="button" className=" px-2 py-2 rounded bg-gray-600 text-white flex items-center" onClick={handleBack}>
                                <ChevronLeftIcon className="w-6 h-6 mr-1" />
                                {Translate(steps[step - 1] ? steps[step - 1].name : "")}
                            </button>)
                        }
                        {step === totalStep ?
                            <button id="publish" type="button" className="btn btn-primary" onClick={handlePublish}>{Translate("Publish")}</button>
                            :
                            <button id="next" type="button" className="px-2 py-2 bg-primary text-white rounded flex items-center" onClick={handleNext}>
                                {Translate(steps[step + 1] ? steps[step + 1].name : "")} 
                                <ChevronRightIcon className="ml-1 w-6 h-6" />
                            </button>
                        }
                    </div>
                </div>
            </div>
        </footer>
    )
}