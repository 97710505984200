import { useEffect, useState } from 'react'
import moment from "moment"
import { Translate } from "../../translate/Translate";
import { Link } from "react-router-dom";
import ActivityPropertyPriceChange from "./ActivityPropertyPriceChange";
import ActivityNormal from "./ActivityNormal";
import ActivityFavorite from "./ActivityFavorite";
import ActivityChangeStatus from "./ActivityChangeStatus";
import ActivityMessage from "./ActivityMessage";
import ActivityMessageAgent from "./ActivityMessageAgent";
import ActivityTour from "./ActivityTour";

export default function ActivityCard(props) {
    const { activity } = props;

    function buildCard(activity) {
        switch (activity.event) {
            case "price_change_property":
                return <ActivityPropertyPriceChange key={activity.id} activity={activity}></ActivityPropertyPriceChange>
            case "favorite_property":
            case "unfavorite_property":
                return <ActivityFavorite key={activity.id} activity={activity}></ActivityFavorite>
            case "change_status_property":
                return <ActivityChangeStatus key={activity.id} activity={activity}></ActivityChangeStatus>
            case "new_message":
            case "reply_from_user_offer":
            case "contact_company":
            case "new_comment_property":
            case "new_customer":
                return <ActivityMessage key={activity.id} activity={activity}></ActivityMessage>
            case "reply_mail":
            case "mark_as_reply_mail":
            case "reply_from_agent_offer":
            case "auto_reply_mail":
                return <ActivityMessageAgent key={activity.id} activity={activity}></ActivityMessageAgent>
            case "online_consultation":
            case "tour":
                return <ActivityTour key={activity.id} activity={activity}></ActivityTour>
            default:
                return <ActivityNormal key={activity.id} activity={activity}></ActivityNormal>
        }
    }

    return buildCard(activity)
}