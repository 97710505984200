
import React, {useState,useRef, useEffect, Fragment, useCallback, useMemo} from "react"
import SideNavBar from "../SideNavBar";
import { Chart,Colors } from 'chart.js';
import * as api from '../../services/agent'
import * as mapbox from '../../../mapbox/api'
import { MAPBOX_TOKEN } from '../../../constants'
import ReactMapGL, {
    Marker,
    // FlyToInterpolator,
    Popup,
    NavigationControl,
    FullscreenControl,
    ScaleControl,
    GeolocateControl,
    Source,
    Layer,
} from 'react-map-gl';
import { Line, Bar, Bubble } from 'react-chartjs-2';
import Geocoder from "../../../v2/views/Components/Geocoder"
import FilterItem from "../Components/TradeExplore/FilterItem"
import FilterRangeItem from "../Components/TradeExplore/FilterRangeItem"
import { debounce } from "lodash";
import {
    geolocateStyleNoPadding,
    fullscreenControlStyleNoPadding,
    navStyleNoPadding,
    scaleControlStyleNoPadding,
} from '../../../mapbox/style'

Chart.register(Colors)

const filterOptions = {
    land_type: [
        "中古マンション等",
        "宅地(土地)",
        "宅地(土地と建物)",
        "林地",
        "農地"    
    ], 
    city_plan_zone: [
        "商業地域",
        "工業地域",
        "工業専用地域",
        "市街化区域及び市街化調整区域外の都市計画区域",
        "市街化調整区域",
        "準住居地域",
        "準工業地域",
        "準都市計画区域",
        "第１種中高層住居専用地域",
        "第２種中高層住居専用地域",
        "第１種低層住居専用地域",
        "第２種低層住居専用地域",
        "第１種住居地域",
        "第２種住居地域",
        "近隣商業地域",
        "都市計画区域外",
    ], 
    transaction_period_year: ["2022","2021","2020","2019","2018","2017"], 
    transaction_period_quarter: [1,2,3,4], 
    layout: [
        "スタジオ",
        "１Ｋ/１Ｌ/１ＤＫ/１ＬＫ/１Ｒ/１Ｋ＋Ｓ/１Ｌ＋Ｓ/１ＬＤＫ/１Ｒ＋Ｓ/１ＤＫ＋Ｓ/１ＬＤ＋Ｓ/１ＬＫ＋Ｓ/１ＬＤＫ＋Ｋ/１ＬＤＫ＋Ｓ",
        "２Ｋ/２Ｌ/２ＤＫ/２ＬＫ/２Ｋ＋Ｓ/２Ｌ＋Ｓ/２ＬＤＫ/２ＤＫ＋Ｓ/２ＬＤ＋Ｓ/２ＬＫ＋Ｓ/２ＬＤＫ＋Ｋ/２ＬＤＫ＋Ｓ",
        "３Ｋ/３ＤＫ/３ＬＫ/３ＬＤＫ/３ＤＫ＋Ｓ/３ＬＤＫ＋Ｋ/３ＬＤＫ＋Ｓ",
        "４Ｋ/４ＤＫ/４ＬＤＫ/４ＤＫ＋Ｓ/４ＬＤＫ＋Ｓ",
        "５Ｋ/５ＤＫ/５ＬＤＫ/５ＬＤＫ＋Ｓ",
        "６ＤＫ/６ＬＤＫ",
        "７ＬＤＫ/７ＬＤＫ＋Ｓ",
        "メゾネット",
        "オープンフロア",
        "no_layout",
    ],
    landshape: [
        "unknown",
        "不整形",
        "台形",
        "ほぼ台形",
        "ほぼ整形",
        "正方形",
        "ほぼ正方形",
        "袋地等",
        "長方形",
        "ほぼ長方形",
    ],
    zone_area: [
        "住宅地",
        "商業地",
        "宅地見込地",
        "工業地",
    ],
    usage: [
        "店舗",
        "共同住宅",
        "事務所",
        "住宅",
        "作業場",
        "工場",
        "その他",
        "駐車場",
        "倉庫",
    ],
    building_structure: [
        "no_structure",
        "ＲＣ",
        "ＳＲＣ",
        "木造",
        "軽量鉄骨造",
        "ブロック造",
        "鉄骨造",
    ],
    frontage_direction: [
        "no_frontage",
        "北",
        "北東",
        "北西",
        "南",
        "南東",
        "南西",
        "接面道路無",
        "東",
        "西",
    ],
    is_renovated: [
        "unknown","改装済", "未改装"
    ],
}
export default function Trade(props) {
    const {Translate} = props
    const mapRef = useRef()
    function getCursor({ isHovering, isDragging }) {
        console.log("getCursor !!!")
        return isDragging ? 'grabbing' : isHovering ? 'pointer' : 'default';
    }
    const [viewport, setViewport] = useState({
        latitude: 36.3418518,
        longitude: 138.6179432,
        zoom: 9
    });
    const [transactions, setTransactions] = useState([])
    const [walkingPath, setWalkingPath] = useState(null)
    const [stats, setStats] = useState({
        trade_area_stats: [],
        trade_nearby_station_sqm_distance_stats: [],
        trade_price_sqm_per_land_type: [],
        trade_nearby_station_stats: [],
        trade_price_sqm_stats: [],
        trade_stats: []
    })

    const [filterRequest, setFilterRequest] = useState({
        postal_code: "",
        land_type: [...filterOptions.land_type],
        zone_area: [...filterOptions.zone_area],
        city_plan_zone: [...filterOptions.city_plan_zone],
        transaction_period_year: [...filterOptions.transaction_period_year],
        is_renovated: [...filterOptions.is_renovated],
        landshape: [...filterOptions.landshape],
        layout: [...filterOptions.layout],
        building_structure: [...filterOptions.building_structure],
        frontage_direction: [...filterOptions.frontage_direction],
        usage: [...filterOptions.usage],
        build_year: {min:1946, max:2023},
        distance: {min:1, max:180},
        area: {min:1, max:5000},
        price_per_sqm: {min:1, max:300000},
        price: {min:1000, max:30000000000},
    })

    const [selectedGeocoderResult, setSelectedGeocoderResult] = React.useState(null)
    const onGeocoderResult = (result) => {
        setSelectedGeocoderResult(result)
        console.log("onGeocoderResult", result)
    }
    
    useEffect(() => {
        if (selectedGeocoderResult && selectedGeocoderResult?.features?.length > 0) {
            const [feature] = selectedGeocoderResult.features
            const postcodeCtx = feature.properties.context.find((c) => c.layer === "postcode")
            if (postcodeCtx) {
                const zipcode = postcodeCtx.name.replace("-", "")
                setFilterRequest((data) => ({...data, postal_code: zipcode}))
                
                mapbox.isochrone({
                    lat: feature.geometry.coordinates[1],
                    lng: feature.geometry.coordinates[0]
                }).then((res) => {
                    console.log("mapbox.isochrone", res)
                    setWalkingPath(res)
                })
            }
            mapRef.current?.flyTo({ center: feature.geometry.coordinates, duration: 250, zoom:  13});

        }
    }, [selectedGeocoderResult])

    const fetchData = useCallback(
        debounce((filterRequest) => {
            filterRequest.request_datas = ["stats", "transaction"]
            api.fetchTradeTransaction(filterRequest)
                .then((res) => {
                    console.log("fetchTradeTransaction", res)
                    setTransactions(res.data.transaction)
                    setStats(res.data.stats)
                });
            // api.fetchTradeStats(filterRequest)
            //     .then((res) => {
            //         console.log("fetchTradeStats", res)
            //         setStats(res.data)
            //     });
            // api.fetchTradeTransaction(filterRequest)
            //     .then((res) => {
            //         console.log("fetchTradeTransaction", res)
            //         setTransactions(res.data)
            //     });
                
        }, 2000)
    , [])
        

    useEffect(() => {
        if (filterRequest.postal_code.length !== 0) {
            fetchData(filterRequest)
        }
    }, [filterRequest])

    const onViewportChange = (evt) => {
        setViewport({...evt.viewState})
    }

    const pricePerSqmDistribution = useMemo(() => {
        const obj = {
            labels: [], // ["2017","2018", "2019","2020", "2021", "2022"], 
            datasets: [
                {
                    label: '',
                    data: [], //[100,100,100,100,100,100],
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                }
            ]
        }
        return ([...stats.trade_price_sqm_stats]).reduce((o, item) => {
            o.labels.push((item.min + item.max) / 2)
            o.datasets[0].data.push(item.transactions)
            return o
        }, obj)
    }, [stats.trade_price_sqm_stats])
    const areaDistribution = useMemo(() => {
        const obj = {
            labels: [], // ["2017","2018", "2019","2020", "2021", "2022"], 
            datasets: [
                {
                    label: '',
                    data: [], //[100,100,100,100,100,100],
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                }
            ]
        }
        return ([...stats.trade_area_stats]).reduce((o, item) => {
            o.labels.push((item.min + item.max) / 2)
            o.datasets[0].data.push(item.transactions)
            return o
        }, obj)
    }, [stats.trade_area_stats])
    const lineChartData = useMemo(() => {
        const obj = {
            labels: [], // ["2017","2018", "2019","2020", "2021", "2022"], 
            datasets: [
                {
                    label: '',
                    data: [], //[100,100,100,100,100,100],
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                }
            ]
        }
        
        return ([...stats.trade_stats]).sort((x,y) => {
            if (x.transaction_period_year === y.transaction_period_year)  {
                return x.transaction_period_quarter - y.transaction_period_quarter
            }
            return x.transaction_period_year - y.transaction_period_year
        }).reduce((o, item) => {
            o.labels.push(`${item.transaction_period_year}-${item.transaction_period_quarter}`)
            o.datasets[0].data.push(item.transactions)
            return o
        }, obj)
    }, [stats.trade_stats])
    const nearbyData = useMemo(() => {
        const obj = {
            labels: ["5 minutes","15 minutes","20 minutes","30 minutes"], // ["2017","2018", "2019","2020", "2021", "2022"], 
            datasets: []
        }
        
        return ([...stats.trade_nearby_station_stats]).reduce((o, item) => {
            o.datasets.push({
                label: item.nearest_station,
                data: [
                    item.within_5_minutes,
                    item.within_15_minutes,
                    item.within_20_minutes,
                    item.within_30_minutes,
                ]
            })
            return o
        }, obj)
    }, [stats.trade_nearby_station_stats])
    const distancePerPrice = useMemo(() => {

        const datasets = ([...stats.trade_nearby_station_stats]).map((s) => s.nearest_station).reduce((obj, key) => ({...obj, [key]: {
            label: key,
            data: []
        }  }),{})

        const distancePriceMap = {}
        
        for (const data of stats.trade_nearby_station_sqm_distance_stats) {

            if (!distancePriceMap[data.nearest_station]) {
                distancePriceMap[data.nearest_station] = {}
            }

            if (!distancePriceMap[data.nearest_station][data.distance]) {
                distancePriceMap[data.nearest_station][data.distance] = {}
            }
            if (!distancePriceMap[data.nearest_station][data.distance][data.price]) {
                distancePriceMap[data.nearest_station][data.distance][data.price] = 1
            } else {
                distancePriceMap[data.nearest_station][data.distance][data.price]++
            }
        }

        let stations = Object.keys(distancePriceMap)
        for (const station of stations) {
            let distances = Object.keys(distancePriceMap[station])
            distances.sort()
    
            for (const distance of distances) {
                let prices = Object.keys(distancePriceMap[station][distance])
                prices.sort()
                for (const price of prices) {
                    datasets[station].data.push({
                        x: distance,
                        y: price,
                        r: distancePriceMap[station][distance][price],
                    })
                }
            }
        }

        
        

        console.log("bubble dataset", Object.keys(datasets).map((key) => datasets[key]))
        return {
            datasets: Object.keys(datasets).map((key) => datasets[key])
        }
    }, [stats.trade_nearby_station_sqm_distance_stats])

    const pricePerLandType = useMemo(() => {

        const datasets = ([
            "中古マンション等",
            "宅地(土地)",
            "宅地(土地と建物)",
            "林地",
            "農地"    
        ]).reduce((obj, key) => ({...obj, [key]: {
            label: key,
            data: []
        }  }),{})

        for (const data of stats.trade_price_sqm_per_land_type) {
            console.log("data is", data)
            datasets[data.land_type].data.push({
                x: data.land_type,
                y: data.price,
                r: data.count,
            })
        }
        console.log("bubble dataset", Object.keys(datasets).map((key) => datasets[key]))
        return {
            datasets: Object.keys(datasets).filter((key) => datasets[key].data.length > 0).map((key) => datasets[key])
        }
    }, [stats.trade_price_sqm_per_land_type])
    
    const categoryOptions = {
        scales: {
            x: {
                type: 'category',
            }
        }

    }

    const barChartOptions = {
        type: 'bar',
        options: {
            interaction: {
                mode: 'nearest',
                axis: 'x',
                intersect: false
            },
            maintainAspectRatio: false,
            bezierCurve: true,
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: true,
                    displayColors: false,
                    position: 'average',
                    bodyFont: { weight: "bold", size: 16 },
                    callbacks: {
                        label: function (context) {
                            let value = ""

                            if (context.parsed.y !== null) {
                                value = new Intl.NumberFormat('en-US').format(context.parsed.y);
                            }
                            return value + " " + context.dataset.label;
                        }
                    }
                }
            },
            scales: {
                x: {
                    type: "linear",
                    offset: false,
                    gridLines: {
                        offsetGridLines: false
                    },
                    title: {
                        display: true,
                    }
                },
                y: {
                    display: true,
                    title: {
                        display: false,
                    },
                    beginAtZero: true,
                    grid: {
                        drawBorder: false,
                        display: true,
                    }
                }
            }
        }
    }
    const lineChartOption = {
        type: 'line',
        options: {
            interaction: {
                mode: 'nearest',
                axis: 'x',
                intersect: false
            },
            maintainAspectRatio: false,
            bezierCurve: true,
            responsive: true,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    enabled: true,
                    displayColors: false,
                    position: 'average',
                    bodyFont: { weight: "bold", size: 16 },
                    callbacks: {
                        label: function (context) {
                            let value = ""

                            if (context.parsed.y !== null) {
                                value = new Intl.NumberFormat('en-US').format(context.parsed.y);
                            }
                            return value + " " + context.dataset.label;
                        }
                    }
                }
            },
            scales: {
                x: {
                    display: true,
                    beginAtZero: true,
                    title: {
                        display: true
                    },
                    grid: {
                        drawBorder: true,
                        display: false,
                        borderColor: 'rgba(20, 90, 255, 0.2)',
                    },
                },
                y: {
                    display: true,
                    title: {
                        display: false,
                    },
                    beginAtZero: true,
                    grid: {
                        drawBorder: false,
                        display: true,
                    }
                }
            }

        }
    };

    const walkingPathStyle = {
        id:"walking-path", 
        type:"fill",
        paint: {
            'fill-color': ['get', 'fill'],
            // 'fill-outline-color': ['get', 'fill'],
            'fill-opacity': ['get', 'fill-opacity'],
        }
    }

    

    return (
        <div className="w-full flex bg-slate-50">
            <SideNavBar {...props} active="/" isPresented={true} />
            <div className="flex flex-col w-full">
                <div className="bg-white">
                    <div className="w-full container mx-auto lg:px-8 px-4">

                        <div className="py-8">
                            <p className="text-3xl font-bold">{Translate("Trade Transaction")}</p>
                            <div className="w-1/2 flex flex-col pt-4">
                                {/* <button className="ml-2 p-1">〒</button>
                                <input id="search-address" type="text" className="w-full border-0 focus:outline-none focus:ring-0 focus:border-none" /> */}
                                <Geocoder {...props}  onResult={onGeocoderResult}></Geocoder>
                            </div>
                        </div>

                        {/* search, sort and filter area */}
                        <div className="mb-6 flex lg:flex-row flex-col gap-x-8 gap-y-4 flex-wrap">
                            <FilterItem {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} name="land_type" all={true} options={filterOptions} />
                            <FilterItem {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} name="zone_area" all={true} options={filterOptions} />
                            <FilterItem {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} name="city_plan_zone" all={true} options={filterOptions} />
                            <FilterItem {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} name="landshape" all={true} options={filterOptions} />
                            <FilterItem {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} name="is_renovated" all={true} options={filterOptions} />
                            <FilterItem {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} name="landshape" all={true} options={filterOptions} />
                            <FilterItem {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} name="layout" all={true} options={filterOptions} />
                            <FilterItem {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} name="usage" all={true} options={filterOptions} />
                            <FilterItem {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} name="building_structure" all={true} options={filterOptions} />
                            <FilterItem {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} name="frontage_direction" all={true} options={filterOptions} />
                            <FilterItem {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} name="transaction_period_year" all={true} options={filterOptions} />
                            <FilterRangeItem {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} name="build_year" min='1939' max="2023" step="1" />
                            <FilterRangeItem {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} name="distance" min='0' max="180" step="5" suffix={"minutes"}  />
                            <FilterRangeItem {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} name="area" min='0' max="5000" step="5" suffix={"sqm"} />
                            <FilterRangeItem {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} name="price_per_sqm" min='1' max="500000" step="1000" suffix={"yen/sqm"} />
                            <FilterRangeItem {...props} filterRequest={filterRequest} setFilterRequest={setFilterRequest} name="price" min='10000' max="5000000000" step="10000" suffix={"yen"} />
                            

                        </div>
                        <div className="pb-8 flex items-center gap-4 w-full">
                            
                        </div>
                        {/* search, sort and filter area */}
                    </div>
                </div>
                <div className="lg:flex w-full max-w-screen-2xl mx-auto">

                    {/* start middle column */}
                    <div className="px-4 lg:px-8 flex-1 flex flex-col gap-4 relative pt-6">
                        
                        <div className="w-full h-80 rounded-lg shadow">
                            <div className="w-full h-full relative">
                                <ReactMapGL
                                    ref={mapRef}
                                    cursor={getCursor}
                                    mapStyle="mapbox://styles/apisitviila/ckwqdi01c0pjz14pdgapgheaw"
                                    mapboxAccessToken={MAPBOX_TOKEN}
                                    {...viewport}
                                    onMove={onViewportChange}
                                    style={{ width: "100%", height: "100%" }}
                                >
                                    {selectedGeocoderResult && selectedGeocoderResult?.features.map((feature) => (
                                        <Fragment key={feature.properties.id}>
                                            <Marker
                                                latitude={feature.geometry.coordinates[1]}
                                                longitude={feature.geometry.coordinates[0]}
                                            >

                                                <svg className="animate-bounce" width="20" height="48" viewBox="0 0 20 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path opacity="0.3" d="M10 32C14.9706 32 19 29.7614 19 27C19 24.2386 14.9706 22 10 22C5.02944 22 1 24.2386 1 27C1 29.7614 5.02944 32 10 32Z" fill="#C4C4C4" />
                                                    <g opacity="0.3">
                                                        <g opacity="0.3">
                                                            <path opacity="0.3" fillRule="evenodd" clipRule="evenodd" d="M10 32C15 32 19 29.8 19 27C19 24.2 15 22 10 22C5 22 1 24.2 1 27C1 29.8 5 32 10 32Z" fill="#BFBFBF" />
                                                        </g>
                                                    </g>
                                                    <path d="M19.2499 10.4C19.1337 12.2242 18.6361 14.0037 17.7892 15.6235C16.8522 17.554 15.767 19.409 14.5433 21.1718C13.3604 22.9087 12.1771 24.4502 11.2892 25.5577C10.8454 26.1113 10.4757 26.5561 10.2171 26.8623C10.1327 26.9623 10.0601 27.0475 10.0007 27.1168C9.94069 27.0468 9.86819 26.9604 9.78339 26.859C9.52469 26.5502 9.15499 26.1019 8.71109 25.5443C7.82319 24.4289 6.63969 22.8779 5.45679 21.1343C4.23519 19.3683 3.14995 17.5117 2.21049 15.5808C1.3669 13.9749 0.869336 12.21 0.749887 10.4C0.700513 7.89475 1.64648 5.47212 3.38042 3.6632C5.11436 1.85428 7.49479 0.806665 9.99989 0.75C12.505 0.806665 14.8854 1.85428 16.6194 3.6632C18.3533 5.47212 19.2993 7.89475 19.2499 10.4V10.4Z" fill="#4264FB" stroke="#314CCD" strokeWidth="0.5" />
                                                    <path d="M13.5502 10.0002C13.5502 10.7023 13.342 11.3887 12.9519 11.9725C12.5618 12.5563 12.0074 13.0113 11.3587 13.28C10.71 13.5487 9.99626 13.619 9.30763 13.482C8.61899 13.345 7.98644 13.0069 7.48997 12.5104C6.99349 12.0139 6.65539 11.3814 6.51841 10.6928C6.38143 10.0041 6.45173 9.29035 6.72042 8.64167C6.98912 7.99299 7.44413 7.43856 8.02792 7.04848C8.61172 6.6584 9.29807 6.4502 10.0002 6.4502C10.4664 6.44999 10.9282 6.54167 11.359 6.72C11.7898 6.89833 12.1812 7.15981 12.5109 7.4895C12.8406 7.81919 13.1021 8.21062 13.2804 8.64142C13.4587 9.07222 13.5504 9.53394 13.5502 10.0002Z" fill="white" stroke="#314CCD" strokeWidth="0.5" />
                                                </svg>
                                            </Marker>
                                        </Fragment>
                                    ))}

                                    {walkingPath ? <Source id="walking-path" type="geojson" data={walkingPath}>
                                        <Layer {...walkingPathStyle} />
                                    </Source> : null}
                                    <ScaleControl position="bottom-right" style={scaleControlStyleNoPadding} />
                                    <FullscreenControl position="bottom-right" style={fullscreenControlStyleNoPadding} />
                                    <NavigationControl position="bottom-right" style={navStyleNoPadding} />
                                    <GeolocateControl position="bottom-right" style={geolocateStyleNoPadding} />
                                </ReactMapGL>
                                

                            </div>
                            
                        </div>
                        
                        <div className="grid lg:grid-cols-4 gap-4">
                            <div className="lg:col-span-2 rounded-lg shadow bg-white p-4">
                                <div className="flex items-center">
                                    <p className="font-bold">Trade Transactions</p>
                                </div>
                                <div className="flex w-full">
                                    <div className=" w-2/3">
                                        <Bar options={lineChartOption.options} data={lineChartData}></Bar>
                                    </div>

                                    <div className="flex flex-col w-full p-2">
                                        {stats.trade_stats.map((t, i) => (<div className="flex items-start  rounded">
                                            <div className="">{t.transaction_period_year}/{t.transaction_period_quarter}</div>
                                            <div className="ml-auto">{t.transactions}</div>
                                        </div>))}
                                        <div className="flex items-start  rounded">
                                            <div className="font-bold">Total</div>
                                            <div className="ml-auto">{stats.trade_stats.reduce((total, item) => total + item.transactions, 0)}</div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="lg:col-span-2 rounded-lg shadow bg-white p-4">
                                <div className="flex items-center">
                                    <p className="font-bold">Nearby Station</p>
                                </div>
                                <div className="flex w-full">
                                    <div className=" w-2/3">
                                        <Line options={lineChartOption.options} data={nearbyData}></Line>
                                    </div>

                                    <div className="flex flex-col w-full p-2">
                                        {([...stats.trade_nearby_station_stats]).sort((x,y) => y.nearby_properties - x.nearby_properties).map((t, i) => (<div className="flex items-start  rounded">
                                            <div className="">{t.nearest_station}</div>
                                            <div className="ml-auto">{t.nearby_properties}</div>
                                        </div>))}
                                    </div>
                                </div>
                            </div>
                            {/* <div className="rounded-lg shadow bg-white p-4">
                                <div className="flex items-center mb-4">
                                        <p className="text-sm font-semibold text-slate-500">Land and Building</p>
                                        <div className="ml-auto">
                                        </div>
                                </div>
                                <div className="flex items-center gap-2">
                                    <div className="rounded-full bg-blue-100 p-2">
                                        <HomeIcon className="w-6 h-6 text-blue-600" />
                                    </div>
                                    <div>
                                        <div className="text-2xl font-bold">100</div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                        <div className="grid lg:grid-cols-4 gap-4">
                            <div className="lg:col-span-2 rounded-lg shadow bg-white p-4">
                                <div className="flex items-center">
                                    <p className="font-bold">Price per sqm distribution</p>
                                </div>
                                <div className="w-full">
                                    <Bar options={barChartOptions.options} data={pricePerSqmDistribution} />
                                </div>
                            </div>
                            <div className="lg:col-span-2 rounded-lg shadow bg-white p-4">
                                <div className="flex items-center">
                                    <p className="font-bold">Area size (sqm2) distribution</p>
                                </div>
                                <div className="w-full">
                                    <Bar options={barChartOptions.options} data={areaDistribution} />
                                </div>
                            </div>
                        </div>
                        <div className="grid lg:grid-cols-4 gap-4">
                            <div className="lg:col-span-2 rounded-lg shadow bg-white p-4">
                                <div className="flex items-center">
                                    <p className="font-bold">Distance per price sqm</p>
                                </div>
                                <div className="w-full">
                                    <Bubble data={distancePerPrice} />
                                </div>
                            </div>
                            <div className="lg:col-span-2 rounded-lg shadow bg-white p-4">
                                <div className="flex items-center">
                                    <p className="font-bold">price per land type</p>
                                </div>
                                <div className="w-full">
                                    <Bubble options={categoryOptions} data={pricePerLandType} />
                                </div>
                            </div>
                        </div>
                        <div className="rounded-lg shadow bg-white p-4">
                            <table className="w-full divide-y divide-gray-200 table-auto">
                                <thead className="bg-gray-100">
                                    <tr>
                                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">Year</th>
                                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">Land Type</th>
                                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">City Plan Zone</th>
                                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">Nearest Station</th>
                                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">Distance</th>
                                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">Landshape</th>
                                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">Area</th>
                                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">Floor Area</th>
                                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">Transaction Price</th>
                                        <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-600 uppercase tracking-wider">Transaction Price per m2</th>
                                        <th className="pr-6 py-3 bg-gray-50">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200 text-xs">
                                    {transactions.map((t) => (<tr key={t.id}>
                                        <td className="px-6 py-4 whitespace-no-wrap font-medium">{t.transaction_period_year}/{t.transaction_period_quarter}</td>
                                        <td className="px-6 py-4 whitespace-no-wrap font-medium">{t.land_type}</td>
                                        <td className="px-6 py-4 whitespace-no-wrap font-medium">{t.city_plan_zone}</td>
                                        <td className="px-6 py-4 whitespace-no-wrap font-medium">{t.nearest_station}</td>
                                        <td className="px-6 py-4 whitespace-no-wrap font-medium">{t.distance}</td>
                                        <td className="px-6 py-4 whitespace-no-wrap font-medium">{t.landshape}</td>
                                        <td className="px-6 py-4 whitespace-no-wrap font-medium">{t.area}</td>
                                        <td className="px-6 py-4 whitespace-no-wrap font-medium">{t.floor_area}</td>
                                        <td className="px-6 py-4 whitespace-no-wrap font-medium">{t.transaction_price}</td>
                                        <td className="px-6 py-4 whitespace-no-wrap font-medium">{Math.round(t.transaction_price / t.area)}</td>
                                    </tr>))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    {/* ends middle column */}

                    {/* starts right column */}
                    <div className="w-96 pr-8 hidden lg:block ">
                        <div className="sticky top-6">
                            <div className="rounded-lg shadow bg-white p-4">
                                
                            </div>
                        </div>
                    </div>
                    {/* ends right column */}
                </div>

            </div>
        </div>
    )
}