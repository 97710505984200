import { Menu } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'
import moment from 'moment'
import { useEffect } from 'react'

export default function DateRange(props) {
    const {onChange, dateRange, since, Translate} = props

    useEffect(() => {

    }, [])
    

    return (<Menu>
        <Menu.Button className="rounded-md flex items-center justify-center bg-slate-100 px-2 py-1">
            <span className={`inline-flex text-xs leading-5 font-medium rounded-full text-slate-600`}>
                {Translate(dateRange.label)}
            </span>
            <ChevronDownIcon
            className={`ml-1 h-4 w-4`}
            aria-hidden="true"
            />
        </Menu.Button>
        <Menu.Items className="absolute mt-1 overflow-hidden z-10 origin-top-right rounded-md shadow bg-white focus:outline-none">
            <div className="flex flex-col">
                <Menu.Item onClick={() => {
                    onChange({
                        from: moment().add(-13, 'days').format("YYYY-MM-DD"),
                        to: moment().format("YYYY-MM-DD"),
                        label: "Last 7 days",
                    })
                }}>
                    {({active}) =>(
                    <button className={`group flex w-full items-center px-2 py-1 text-xs font-medium ${active ? "bg-slate-100":""}`}>{Translate("Last 7 days")}</button>
                )}</Menu.Item>
                <Menu.Item onClick={() => {
                    onChange({
                        from: moment().add(-59, 'days').format("YYYY-MM-DD"),
                        to: moment().format("YYYY-MM-DD"),
                        label: "Last 30 days",
                    })
                }}>
                    {({active}) =>(
                    <button className={`group flex w-full items-center px-2 py-1 text-xs font-medium ${active ? "bg-slate-100":""}`}>{Translate("Last 30 days")}</button>
                )}</Menu.Item>
                <Menu.Item onClick={() => {
                    onChange({
                        from: moment().add(-89, 'days').format("YYYY-MM-DD"),
                        to: moment().format("YYYY-MM-DD"),
                        label: "Last 45 days",
                    })
                }}>
                    {({active}) =>(
                    <button className={`group flex w-full items-center px-2 py-1 text-xs font-medium ${active ? "bg-slate-100":""}`}>{Translate("Last 45 days")}</button>
                )}</Menu.Item>
                {/* <Menu.Item onClick={() => {
                    onChange({
                        from: moment(since).format("YYYY-MM-DD"),
                        to: moment().format("YYYY-MM-DD"),
                        label: "Overall",
                    })
                }}>
                    {({active}) =>(
                    <button className={`group flex w-full items-center px-2 py-1 text-xs font-medium ${active ? "bg-slate-100":""}`}>{Translate("Overall")}</button>
                )}</Menu.Item>
                 */}
            </div>
        </Menu.Items>
    </Menu>)
}