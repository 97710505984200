import { Translate } from "../../../translate/Translate";

export default function RadioField(props) {
    const { name, data, register} = props
    
    return (<label className="inline-flex items-start">
        <input type="radio" 
            name={name} 
            key={`${name}-${data}`}
            id={`${name}-${data}`}
            value={data} 
            {...register(name)}
             />
        <span className="ml-2">{Translate(data)}</span>
    </label>)
}