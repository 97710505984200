import React from "react"
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { Popover, Transition } from '@headlessui/react'
import { usePopper } from 'react-popper'


export default function Select(props) {
    const { selected, options, title, icon, onSelect } = props;

    let [referenceElement, setReferenceElement] = React.useState()
    let [popperElement, setPopperElement] = React.useState()
    let { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: 'bottom',
        modifiers: [{
            name: 'offset',
            options: {
                offset: [0, 5],
            },
        }]
    })

    return (

        <Popover>
            <Popover.Button ref={setReferenceElement} className="focus:outline-none">

                <div className="h-12 w-full flex items-center justify-center gap-2 bg-slate-200 rounded px-4 cursor-pointer text-slate-500">
                    {icon &&
                        <div className="flex-none">
                            {icon}
                        </div>
                    }
                    <p className="font-medium leading-none whitespace-nowrap ">{title}</p>
                    <ChevronDownIcon className="w-5 h-5" />
                </div>

            </Popover.Button>

            <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
            >
                <Popover.Panel
                    className="bg-white rounded-lg shadow-lg w-56 ring-1 ring-slate-200"
                    ref={setPopperElement}
                    style={styles.popper}
                    {...attributes.popper}
                >
                    {({ close }) => (
                     <>
                    {
                        options.map((item, index) => (
                            <button type="button" className='px-4 py-2 hover:bg-slate-100 w-full flex items-start' key={`item-${index}`} onClick={(e) => {onSelect(item);close()}}>
                                <p>{item.label}</p>
                            </button>
                        ))
                    }
                    </>
                    )}
                </Popover.Panel>
            </Transition>
        </Popover>

    )
}