import RangeSlider from "react-range-slider-input";

export default function FilterRangeItem(props) {
    const {Translate, name, filterRequest, setFilterRequest, min, max, step, suffix} = props
    return (<div className="flex flex-col">
        <div className="font-medium mb-2">{Translate(name)}</div>
        <div className="flex w-full m-auto items-center justify-center p-8">
            <p>{min}</p>
            <RangeSlider 
                min={min}
                max={max}
                step={step} 
                value={[
                    filterRequest[name].min, 
                    filterRequest[name].max,
                ]}
                onInput={([min, max]) => {
                    setFilterRequest((data) => ({...data, [name]: {
                        min,
                        max
                    } }))
                }} />
            <p>{max}</p>
        </div>
        <div className="grid grid-cols-3">
            <div className="w-full">
                <div className="text-center">
                    <label for="" className="block text-sm font-medium leading-6 text-gray-700">Min</label>
                    <label for="" className="block text-sm font-medium leading-6 text-gray-700">{filterRequest[name].min} {suffix}</label>
                </div>
            </div>
            <div className="w-full">
            </div>
            <div className="w-full">
                <div className="text-center">
                    <label for="" className="block text-sm font-medium leading-6 text-gray-700">Max</label>
                    <label for="" className="block text-sm font-medium leading-6 text-gray-700">{filterRequest[name].max} {suffix}</label>
                </div>
            </div>
        </div>
    </div>)
}