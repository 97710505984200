import React from "react"
import SideNavBar from "../SideNavBar";
import TopSearchbar from "../TopSearchbar";
import { Translate } from "../../translate/Translate";
import OffersByProperty from "./OffersByProperty";
import * as api from '../../services/agent'
import * as Account from '../../../account';

export default function OfferList(props) {
    const [account] = React.useState(Account.getUserAccount());
    const [data, setData] = React.useState([]);

    function loadOffers() {
        api.offers()
            .then(data => {
                console.log(data);
                setData(data.items);
            })
            .catch(error => {
                console.log(error);
            })
    }

    const listProperty = () => {
        if (data === null) {
            return []
        }
        return data
    }


    //uncomment this to fetch data when api is deployed
    React.useEffect(() => {
        loadOffers()
    }, [])

    return (

        <div className="w-full flex bg-slate-50">
            <SideNavBar {...props} isPresented={true} />
            <div className="flex flex-col w-full relative">

                <div className="bg-white">
                    <div className="w-full container mx-auto lg:px-8 px-4">

                        <div className="py-8">
                            <p className="text-3xl font-bold">{Translate("Offers")}</p>
                        </div>

                    </div>
                </div>
                <div className="lg:p-8 p-4 flex items-center justify-center container mx-auto">

                    <div className="grid grid-cols-1 space-y-8">
                        {
                            listProperty().map((property, index) => (
                                <OffersByProperty account={account} key={property.id} property={property}></OffersByProperty>
                            ))
                        }

                        {(data === null || data.length === 0) ?
                            <div className="w-full h-full pt-24">
                            <svg className="mx-auto" width="377" height="262" viewBox="0 0 377 262" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clipPath="url(#clip0)">
                                    <path d="M71.5227 56.2453C83.4142 56.2453 93.0542 46.5948 93.0542 34.6903C93.0542 22.7858 83.4142 13.1353 71.5227 13.1353C59.6312 13.1353 49.9912 22.7858 49.9912 34.6903C49.9912 46.5948 59.6312 56.2453 71.5227 56.2453Z" fill="#FF6584" />
                                    <path d="M176.281 115.562C176.281 166.423 146.078 184.181 108.822 184.181C71.5649 184.181 41.3623 166.423 41.3623 115.562C41.3623 64.7022 108.822 0 108.822 0C108.822 0 176.281 64.7022 176.281 115.562Z" fill="#F2F2F2" />
                                    <path d="M106.364 176.401L107.055 133.836L135.808 81.1762L107.164 127.159L107.474 108.019L127.29 69.9208L107.556 102.955L108.115 68.5319L129.335 38.2006L108.203 63.1189L108.552 0L106.358 83.5581L106.538 80.111L84.9642 47.0519L106.193 86.728L104.182 125.172L104.123 124.152L79.251 89.3621L104.047 127.757L103.796 132.564L103.751 132.637L103.771 133.032L98.6714 230.568H105.485L106.303 180.188L131.038 141.888L106.364 176.401Z" fill="#3F3D56" />
                                    <path d="M377 136.393C377 178.025 352.278 192.561 321.781 192.561C291.285 192.561 266.562 178.025 266.562 136.393C266.562 94.7617 321.781 41.7998 321.781 41.7998C321.781 41.7998 377 94.7617 377 136.393Z" fill="#F2F2F2" />
                                    <path d="M319.77 186.193L320.335 151.351L343.871 108.247L320.424 145.885L320.678 130.219L336.899 99.0331L320.745 126.073L321.203 97.8964L338.572 73.0687L321.274 93.4659L321.56 41.7998L319.765 110.196L319.912 107.375L302.253 80.3139L319.629 112.791L317.984 144.259L317.935 143.424L297.576 114.947L317.873 146.375L317.667 150.31L317.63 150.369L317.647 150.692L313.472 230.53H319.05L319.719 189.292L339.966 157.942L319.77 186.193Z" fill="#3F3D56" />
                                    <path d="M186.581 238.625C289.627 238.625 373.162 234.398 373.162 229.184C373.162 223.969 289.627 219.742 186.581 219.742C83.5352 219.742 0 223.969 0 229.184C0 234.398 83.5352 238.625 186.581 238.625Z" fill="#3F3D56" />
                                    <path d="M300.245 246.4C317.894 246.4 332.202 245.676 332.202 244.783C332.202 243.89 317.894 243.166 300.245 243.166C282.596 243.166 268.288 243.89 268.288 244.783C268.288 245.676 282.596 246.4 300.245 246.4Z" fill="#3F3D56" />
                                    <path d="M184.605 262C202.255 262 216.562 261.276 216.562 260.383C216.562 259.49 202.255 258.766 184.605 258.766C166.956 258.766 152.648 259.49 152.648 260.383C152.648 261.276 166.956 262 184.605 262Z" fill="#3F3D56" />
                                    <path d="M96.8732 250.998C137.196 250.998 169.883 249.344 169.883 247.303C169.883 245.263 137.196 243.608 96.8732 243.608C56.551 243.608 23.8633 245.263 23.8633 247.303C23.8633 249.344 56.551 250.998 96.8732 250.998Z" fill="#3F3D56" />
                                    <path d="M32.6619 217.325C47.3405 217.325 59.2398 205.412 59.2398 190.718C59.2398 176.023 47.3405 164.111 32.6619 164.111C17.9833 164.111 6.08398 176.023 6.08398 190.718C6.08398 205.412 17.9833 217.325 32.6619 217.325Z" fill="#2F2E41" />
                                    <path d="M28.3794 211.55L20.3057 211.455L20.1352 225.936L28.209 226.032L28.3794 211.55Z" fill="#2F2E41" />
                                    <path d="M44.5269 211.741L36.4531 211.646L36.2827 226.127L44.3564 226.223L44.5269 211.741Z" fill="#2F2E41" />
                                    <path d="M33.5895 226.264C33.6059 224.869 30.6069 223.702 26.8911 223.659C23.1753 223.615 20.1497 224.71 20.1332 226.105C20.1168 227.5 23.1158 228.666 26.8316 228.71C30.5475 228.754 33.5731 227.659 33.5895 226.264Z" fill="#2F2E41" />
                                    <path d="M49.7408 226.117C49.7573 224.722 46.7583 223.556 43.0425 223.512C39.3266 223.468 36.301 224.564 36.2846 225.959C36.2682 227.354 39.2672 228.52 42.983 228.564C46.6988 228.608 49.7244 227.512 49.7408 226.117Z" fill="#2F2E41" />
                                    <path d="M33.4137 193.084C38.4304 193.084 42.4973 189.013 42.4973 183.991C42.4973 178.968 38.4304 174.897 33.4137 174.897C28.3969 174.897 24.3301 178.968 24.3301 183.991C24.3301 189.013 28.3969 193.084 33.4137 193.084Z" fill="white" />
                                    <path d="M33.4136 187.022C35.0858 187.022 36.4415 185.665 36.4415 183.991C36.4415 182.317 35.0858 180.959 33.4136 180.959C31.7414 180.959 30.3857 182.317 30.3857 183.991C30.3857 185.665 31.7414 187.022 33.4136 187.022Z" fill="#3F3D56" />
                                    <path d="M7.17344 165.672C5.14078 156.026 12.1148 146.386 22.7503 144.14C33.3858 141.894 43.6554 147.893 45.688 157.539C47.7207 167.185 40.6489 170.65 30.0134 172.896C19.3779 175.142 9.20609 175.319 7.17344 165.672Z" fill="#6C63FF" />
                                    <path d="M73.2042 205.561C73.2042 224.111 62.1895 230.588 48.6011 230.588C48.2863 230.588 47.972 230.584 47.6583 230.577C47.0287 230.564 46.4051 230.534 45.789 230.492C33.5257 229.623 23.998 222.813 23.998 205.561C23.998 187.708 46.7873 165.181 48.4992 163.514L48.5022 163.511C48.5681 163.446 48.6011 163.415 48.6011 163.415C48.6011 163.415 73.2042 187.012 73.2042 205.561Z" fill="#6C63FF" />
                                    <path d="M47.7043 227.75L56.7028 215.163L47.6819 229.132L47.6579 230.577C47.0283 230.564 46.4047 230.534 45.7886 230.492L46.7585 211.933L46.751 211.789L46.7675 211.762L46.8589 210.009L37.8154 196.005L46.8874 208.695L46.9084 209.067L47.6414 195.045L39.8991 180.574L47.7358 192.584L48.4988 163.514L48.5018 163.415V163.511L48.3744 186.434L56.0823 177.346L48.3429 188.409L48.1391 200.964L55.3358 188.915L48.1091 202.811L47.9952 209.79L58.4432 193.021L47.9562 212.226L47.7043 227.75Z" fill="#3F3D56" />
                                    <path d="M239.701 217.037C254.38 217.037 266.279 205.125 266.279 190.43C266.279 175.736 254.38 163.823 239.701 163.823C225.022 163.823 213.123 175.736 213.123 190.43C213.123 205.125 225.022 217.037 239.701 217.037Z" fill="#2F2E41" />
                                    <path d="M241.969 211.578L234.251 213.952L238.501 227.795L246.219 225.421L241.969 211.578Z" fill="#2F2E41" />
                                    <path d="M257.405 206.831L249.687 209.205L253.936 223.048L261.654 220.674L257.405 206.831Z" fill="#2F2E41" />
                                    <path d="M245.724 228.392C249.276 227.299 251.824 225.332 251.414 223.999C251.005 222.665 247.793 222.47 244.241 223.563C240.689 224.656 238.141 226.623 238.551 227.957C238.96 229.29 242.172 229.485 245.724 228.392Z" fill="#2F2E41" />
                                    <path d="M261.061 223.321C264.613 222.229 267.16 220.262 266.751 218.928C266.342 217.595 263.13 217.4 259.578 218.492C256.026 219.585 253.478 221.552 253.888 222.886C254.297 224.219 257.509 224.414 261.061 223.321Z" fill="#2F2E41" />
                                    <path d="M238.368 192.887C243.385 192.887 247.451 188.816 247.451 183.794C247.451 178.772 243.385 174.7 238.368 174.7C233.351 174.7 229.284 178.772 229.284 183.794C229.284 188.816 233.351 192.887 238.368 192.887Z" fill="white" />
                                    <path d="M238.368 186.825C240.04 186.825 241.396 185.468 241.396 183.794C241.396 182.12 240.04 180.763 238.368 180.763C236.695 180.763 235.34 182.12 235.34 183.794C235.34 185.468 236.695 186.825 238.368 186.825Z" fill="#3F3D56" />
                                    <path d="M207.796 174.36C202.921 165.794 206.626 154.484 216.07 149.097C225.515 143.711 237.123 146.288 241.997 154.854C246.872 163.42 241.193 168.879 231.749 174.266C222.304 179.652 212.671 182.926 207.796 174.36Z" fill="#6C63FF" />
                                    <path d="M192.301 202.165C192.301 219.193 182.19 225.138 169.716 225.138C169.427 225.138 169.139 225.135 168.851 225.128C168.273 225.116 167.7 225.088 167.135 225.05C155.878 224.252 147.132 218.001 147.132 202.165C147.132 185.777 168.051 165.097 169.623 163.567L169.626 163.564C169.686 163.505 169.716 163.476 169.716 163.476C169.716 163.476 192.301 185.138 192.301 202.165Z" fill="#6C63FF" />
                                    <path d="M168.893 222.533L177.153 210.978L168.872 223.802L168.85 225.128C168.272 225.116 167.7 225.088 167.134 225.05L168.025 208.014L168.018 207.882L168.033 207.857L168.117 206.248L159.815 193.393L168.143 205.041L168.162 205.383L168.835 192.511L161.728 179.228L168.922 190.252L169.622 163.567L169.625 163.476V163.564L169.508 184.607L176.583 176.265L169.479 186.42L169.292 197.944L175.898 186.884L169.265 199.64L169.16 206.047L178.751 190.653L169.124 208.283L168.893 222.533Z" fill="#3F3D56" />
                                    <path d="M281.117 209.266C281.117 227.815 270.103 234.292 256.514 234.292C256.199 234.292 255.885 234.289 255.571 234.282C254.942 234.268 254.318 234.238 253.702 234.196C241.439 233.327 231.911 226.517 231.911 209.266C231.911 191.413 254.7 168.885 256.412 167.218L256.415 167.215C256.481 167.151 256.514 167.119 256.514 167.119C256.514 167.119 281.117 190.717 281.117 209.266Z" fill="#6C63FF" />
                                    <path d="M255.618 231.455L264.617 218.868L255.596 232.837L255.572 234.282C254.942 234.269 254.319 234.239 253.703 234.197L254.673 215.638L254.665 215.494L254.682 215.467L254.773 213.714L245.729 199.71L254.801 212.4L254.822 212.772L255.555 198.75L247.813 184.279L255.65 196.289L256.413 167.219L256.416 167.12V167.216L256.288 190.139L263.996 181.052L256.257 192.114L256.053 204.669L263.25 192.62L256.023 206.516L255.909 213.495L266.357 196.726L255.87 215.931L255.618 231.455Z" fill="#3F3D56" />
                                    <path d="M241.389 206.358C242.376 204.274 237.801 200.032 231.169 196.883C224.538 193.735 218.362 192.872 217.375 194.956C216.387 197.04 220.963 201.282 227.594 204.43C234.225 207.579 240.401 208.442 241.389 206.358Z" fill="#2F2E41" />
                                    <path d="M36.8315 203.923C37.6887 201.782 32.861 197.83 26.0486 195.097C19.2361 192.363 13.0187 191.883 12.1615 194.024C11.3043 196.165 16.1319 200.116 22.9444 202.85C29.7568 205.583 35.9743 206.064 36.8315 203.923Z" fill="#2F2E41" />
                                </g>
                                <defs>
                                    <clipPath id="clip0">
                                        <rect width="377" height="262" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                            : null
                        }
                    </div>

                </div>
            </div>
        </div>
    );
}