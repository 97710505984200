import { EnvelopeIcon, PencilIcon } from "@heroicons/react/24/outline";
import { ChatAltIcon, HeartIcon } from "@heroicons/react/24/solid";
import React from "react"
import AppContext from "../../../AppContext"
import ViilaLogo from "./ViilaLogo";

export default function ActivityIcons(props) {

    const { event } = props;
    const events = {
        "favorite_property": {
            icon: <HeartIcon className="w-5 h-5 text-red-500" />,
            bgColor: "bg-red-500/10"
        },
        "new_message": {
            icon: <EnvelopeIcon className="w-5 h-5 text-indigo-500" />,
            bgColor: "bg-indigo-500/10"
        },
        "change_status_property": {
            icon: <PencilIcon className="w-5 h-5 text-amber-500" />,
            bgColor: "bg-amber-500/10"
        }
    }
    const buildIcon = () => {
        const activity = events[event];

        if (activity) {
            return (
                <div className={`w-12 h-12 flex items-center justify-center rounded-full ${activity.bgColor}`}>
                    {activity.icon}
                </div>
            )
        }

        return (
            <div className={`w-12 h-12 flex items-center justify-center rounded-full bg-slate-400/10`}>
                <ViilaLogo className="w-4 h-4 fill-current text-slate-400"/>
            </div>
        )
    }
    return buildIcon()
}