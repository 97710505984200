import { useEffect, useState } from "react"
import useCountDown from "../hooks/useCountdown";

export default function Countdown(props) {
    // const [countDown, setCountDown] = useState("")
    // Set the date we're counting down to
    const { from, hour } = props
    const [days, hours, minutes, seconds] = useCountDown(new Date(from).getTime() + hour * 60 * 60 * 1000)
    // useEffect(() => {
    //     console.log("setTimeout")
    //     setTimeout(() => {

    //     })
    // })

    // // Update the count down every 1 second
    // var x = setInterval(function () {

    //     // Get today's date and time
    //     var now = new Date().getTime();

    //     // Find the distance between now and the count down date
    //     var distance = countDownDate - now;

    //     // Time calculations for days, hours, minutes and seconds
    //     var days = Math.floor(distance / (1000 * 60 * 60 * 24));
    //     var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    //     var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    //     var seconds = Math.floor((distance % (1000 * 60)) / 1000);

    //     // Display the result in the element with id="demo"
    //     setCountDown(days + "d " + hours + "h " + minutes + "m " + seconds + "s ")

    //     // If the count down is finished, write some text
    //     if (distance < 0) {
    //         clearInterval(x);
    //         setCountDown("EXPIRED");
    //     }
    // }, 1000);
    if (days + hours + minutes + seconds <= 0) {
        return "";
    }

    return (
        props.language == "ja" ?
            <>
                {days ? days + "日" : ""} {hours ? hours + "時間" : ""} {minutes ? minutes + "分" : ""} {seconds ? seconds + "秒" : ""}
            </> :
            <>
                {days ? days + "d" : ""} {hours ? hours + "h" : ""} {minutes ? minutes + "m" : ""} {seconds ? seconds + "s" : ""}
            </>

    )
}