
import React from "react"
import { Translate } from "../../translate/Translate";
export default function SelectedPropertyRow(props) {
    const {
        onRemovedFromCollection,
        property
    } = props

    const formatCurrency = (amount, currency) => {
        return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: currency }).format(Number(amount))
    }

    const onRemoveClicked = () => {
        onRemovedFromCollection(property)
    }
    return (
        <div className="w-full flex py-2">
            <div className="w-24 h-24 rounded overflow-hidden border flex-none">
                {
                    property.images.length > 0 ?
                        <img className="h-24 w-24  object-cover" src={property.images[0].url} />
                        :
                        <div className="bg-gray-200 h-24 w-24"></div>
                }
            </div>
            <div className="pl-4 flex flex-col truncate overflow-ellipsis">
                <div className="truncate overflow-ellipsis">
                    <p className="font-medium mb-1 text-sm truncate overflow-ellipsis">{property.name}</p>
                    <p className="font-medium text-gray-500 text-sm mb-1">{formatCurrency(property.price.amount, property.price.currency)}</p>
                    <p className="text-xs text-gray-500">{Translate(property.property_type)}</p>
                </div>
                <div className="mt-auto">
                    <button onClick={onRemoveClicked} className="text-xs font-medium text-gray-600">{Translate("Remove")}</button>
                </div>
            </div>
        </div>
    );
}