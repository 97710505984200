import React, {Fragment, useEffect, useState} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import EssentialForm from "./StepWizard/EssentialForm"
import EditAreaForm from "./StepWizard/EditAreaForm"
import AddressForm from "./StepWizard/AddressForm"
import OverviewForm from "./StepWizard/OverviewForm"
import StepPhotos from "../../views/Properties/StepPhotos"
import PropertyDetailForm from "./StepWizard/PropertyDetailForm"
import PropertyMaterialForm from "./StepWizard/PropertyMaterialForm"
import ChangeStatusForm from "./StepWizard/ChangeStatusForm"
import { useForm } from "react-hook-form"
import { yupResolver } from '@hookform/resolvers/yup';
import {
    stepEssential,
    stepAddress,
    stepPropertyStory,
    stepPropertyDetail,
    changeStatusSchema,
    changeSoldStatusSchema,
    editAreaSchema,
} from "../../validators/property"

export default function EditModal(props) {
    const {isOpen, setIsOpen, generateVideo, section, Translate, updateProperty, data, originalStatus} = props

    const labels = {
        "area": "Edit area",
        "address": "Edit address",
        "info": "Edit property info",
        "photo": "Edit photos",
        "overview": "Edit overview",
        "detail": "Edit property details",
        "status" : "Property status",
        "sold_status" : "Property status",
    }

    const validators = {
        "area": useForm({
            resolver: yupResolver(editAreaSchema),
            mode: "onBlur",
            reValidateMode: 'onBlur',
            defaultValues: data
        }), 
        "address": useForm({
            resolver: yupResolver(stepAddress),
            mode: "onBlur",
            reValidateMode: 'onBlur',
            defaultValues: data
        }), 
        "info": useForm({
            resolver: yupResolver(stepEssential),
            defaultValues: data
        }), 
        "overview": useForm({
            resolver: yupResolver(stepPropertyStory),
            mode: "onBlur",
            reValidateMode: 'onBlur',
            defaultValues: data
        }), 
        "material": useForm({
            defaultValues: data
        }), 
        "detail": useForm({
            resolver: yupResolver(stepPropertyDetail),
            mode: "onBlur",
            reValidateMode: 'onBlur',
            defaultValues: data
        }), 
        "status": useForm({
            resolver: yupResolver(changeStatusSchema),
            mode: "onBlur",
            reValidateMode: 'onBlur',
            defaultValues: data
        }), 
        "sold_status": useForm({
            resolver: yupResolver(changeSoldStatusSchema),
            mode: "onBlur",
            reValidateMode: 'onBlur',
            defaultValues: data
        }), 
        
    }

    const steps = {
        "area": {
            view: (<EditAreaForm {...props} useFormReturn={validators["area"]} />),
            useFormReturn: validators["area"],
        },
        "address": {
            view: (<AddressForm {...props} useFormReturn={validators["address"]} />),
            useFormReturn: validators["address"],
        },
        "info": {
            view: (<EssentialForm {...props} useFormReturn={validators["info"]} />),
            useFormReturn: validators["info"],
        },
        "photo": {
            view: (<StepPhotos {...props} generateVideo={generateVideo} />),
        },
        "overview": {
            view: (<OverviewForm {...props} useFormReturn={validators["overview"]} />),
            useFormReturn: validators["overview"],
        },
        "detail": {
            view: (<PropertyDetailForm {...props} useFormReturn={validators["detail"]} />),
            useFormReturn: validators["detail"],
        },
        "material": {
            view: (<PropertyMaterialForm {...props} useFormReturn={validators["material"]} />),
            useFormReturn: validators["material"],
        },
        "status": {
            view: (<ChangeStatusForm {...props} originalStatus={originalStatus} useFormReturn={validators["status"]} />),
            useFormReturn: validators["status"],
        },
        "sold_status": {
            view: (<ChangeStatusForm {...props} originalStatus={originalStatus} useFormReturn={validators["sold_status"]} />),
            useFormReturn: validators["sold_status"],
        }
    }


    function closeModal() {
        setIsOpen(false)
    }

    function handleUpdateProperty() {
        if (!steps[section].useFormReturn) {
            updateProperty(data)
            return
        }
        const { getValues, handleSubmit } = steps[section].useFormReturn
        handleSubmit((d) => {
            updateProperty({ ...data, ...d })
        }, (err) => {
            console.log("handleSubmit error", err)
        })()
    }

    useEffect(() => {
        if (section != "") {
            if (steps[section].useFormReturn) {
                const {reset} = steps[section].useFormReturn
                reset({...data})
            }
        }
    }, [])

    return (
    <>
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-[50]" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300 "
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-100"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25"></div>
                </Transition.Child>

                <div className="fixed top-0 left-0 inset-0 w-full h-full outline-none">
                    <div className="flex items-center justify-end h-full">
                        <Transition.Child
                            as={Fragment}
                            enter="ease duration-300 transition transform"
                    enterFrom="opacity-0 translate-x-full"
                    enterTo="opacity-100 translate-x-0"
                    leave="ease duration-200 transition transform"
                    leaveFrom="opacity-100 translate-x-0"
                    leaveTo="translate-x-full"
                        >
                            <Dialog.Panel className="border-none shadow-lg relative flex flex-col lg:w-4/5 w-full h-full pointer-events-auto bg-white bg-clip-padding  outline-none text-current">
                                <Dialog.Title
                                    as="div"
                                    className="flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md"
                                >
                                    <h3 className='text-lg font-medium leading-6 text-gray-900'>
                                        {Translate(labels[section])} · {data.property_id}
                                    </h3>
                                    <button type="button" id="close" onClick={closeModal}>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </Dialog.Title>
                                <div className="relative flex-1 overflow-hidden">
                                    {section!== ""? steps[section].view: null}
                                </div>
                                <div className="mt-auto flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                                    <div className="ml-auto">
                                        <button id="update" type="button" className={data.status == "delete" ? "btn bg-red-600 text-white" : "btn btn-primary"} onClick={handleUpdateProperty}>{Translate(data.status == "delete" ? "Delete" : "Update")}</button>
                                    </div>
                                </div>
                            
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    </>)
}
