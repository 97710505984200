import React, { useCallback } from "react"
import SideNavBar from "../SideNavBar";
import SettingsNavBar from "./SettingsNavBar";
import { useDropzone } from 'react-dropzone'
import * as api from '../../services/agent'
import Alert from '../Shared/Alert'
import * as Constants from '../../../constants'
import * as Account from '../../../account'

export default function SettingsProfile(props) {
    const {Translate} = props
    const [showAlert, setShowAlert] = React.useState(false);
    const [languageList, setLanguageList] = React.useState("");
    const bh = {
        remark: "",
        monday: {
            open: "08:00",
            close: "18:00",
            checked: false,
        },
        tuesday: {
            open: "08:00",
            close: "18:00",
            checked: false,
        },
        wednesday: {
            open: "08:00",
            close: "18:00",
            checked: false,
        },
        thursday: {
            open: "08:00",
            close: "18:00",
            checked: false,
        },
        friday: {
            open: "08:00",
            close: "18:00",
            checked: false,
        },
        saturday: {
            open: "08:00",
            close: "18:00",
            checked: false,
        },
        sunday: {
            open: "08:00",
            close: "18:00",
            checked: false,
        },
    }
    const [profile, setProfile] = React.useState({
        email: "",
        first_name: "",
        last_name: "",
        phone: "",
        image_url: "",
        company: {
            name: "",
            url: "",
            image_url: "",
            cover_image_url: "",
            full_address: "",
            service_areas: [],
            business_hours: bh,
        },
        agent: {
            info: "",
            license: "",
            service_languages: [],
            agent_data: {
                place_id: "",
            }
        },
    });

    function loadProfile() {
        api.profile()
            .then(response => {
                if (response.agent.user_id) {
                    if (response.company.business_hours == null) {
                        response.company.business_hours = bh
                    }
                    if (Object.keys(response.company.business_hours).length === 0 && response.company.business_hours.constructor === Object) {
                        response.company.business_hours = bh
                    }
                }
                setProfile(response);

                if (response.agent.user_id && response.agent.service_languages)
                    setLanguageList(response.agent.service_languages.join(", "))
            })
            .catch(error => {
                console.log(error)
            })
    }

    React.useEffect(() => {
        loadProfile()
    }, [])

    const onDrop = useCallback((acceptedFiles, fileRejections) => {
        acceptedFiles.map((file) => {
            //add fill temp
            let image = {
                url: URL.createObjectURL(file),

            }

            var reader = new FileReader();
            reader.onload = function (event) {
                var imageData = event.target.result;
                var base64Data = imageData.split("base64,")[1];
                var fileName = "" //for new filename -  file.name.replace(/\.[^/.]+$/, "")
                api.uploadImage(base64Data, fileName)
                    .then(response => {
                        let imageUrl = response.data;
                        let company = profile.company
                        company.image_url = imageUrl
                        profile.image_url = imageUrl
                        profile.company = company
                        setProfile(profile)
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
            reader.readAsDataURL(file);
        });

        fileRejections.forEach((file) => {
            file.errors.forEach((err) => {
                if (err.code === "file-too-large") {
                    alert("file size is exceed limit (10MB)")
                }
            })
        })
    }, [profile])



    const onDropCover = useCallback((acceptedFiles, fileRejections) => {
        acceptedFiles.map((file) => {
            //add fill temp
            let image = {
                url: URL.createObjectURL(file),

            }
            var reader = new FileReader();
            reader.onload = function (event) {
                var imageData = event.target.result;
                var base64Data = imageData.split("base64,")[1];
                var fileName = "" //for new filename -  file.name.replace(/\.[^/.]+$/, "")
                api.uploadImage(base64Data, fileName)
                    .then(response => {
                        let imageUrl = response.data;
                        let company = profile.company
                        company.cover_image_url = imageUrl
                        profile.company = company
                        setProfile(profile)
                    })
                    .catch(error => {
                        console.log(error);
                    })
            }
            reader.readAsDataURL(file);
        });

        fileRejections.forEach((file) => {
            file.errors.forEach((err) => {
                if (err.code === "file-too-large") {
                    alert("file size is exceed limit (10MB)")
                }
            })
        })
    }, [profile])

    const dropProfile = useDropzone({
        onDrop: onDrop,
        accept: 'image/jpeg, image/png',
        multiple: false,
        maxSize: 10 * 1024 * 1024, // 10MB
    });

    const dropCover = useDropzone({
        onDrop: onDropCover,
        accept: 'image/jpeg, image/png',
        multiple: false,
        maxSize: 10 * 1024 * 1024, // 10MB
    });


    const handleLanguageList = (e) => {
        setLanguageList(e.target.value);
        let splitted = e.target.value.split(",")
        profile.agent.service_languages = [];
        for (var i = 0; i < splitted.length; i++) {
            profile.agent.service_languages.push(splitted[i].trim())
        }
        setProfile(profile)
    }

    const handleDataChange = (e) => {
        var { name, value } = e.target

        //if name is set like "agent.license" then we assign it like below
        if (name.includes(".")) {
            let fields = name.split(".")
            let object = fields[0];
            let fieldName = fields[1];
            setProfile({ ...profile, [object]: { ...profile[object], [fieldName]: value } })
            return;
        }
        setProfile({ ...profile, [name]: value })
    }

    const updateProfile = (e) => {
        e.preventDefault();

        var account = Account.getUserAccount()
        account.user.first_name = profile.first_name
        account.user.last_name = profile.last_name
        account.user.image_url = profile.image_url
        Account.updateAccount(account)

        api.updateProfile(profile)
            .then(response => {
                loadProfile()
                setShowAlert(true)
            })
            .catch(error => {

            })
    }

    function string_to_slug(str) {
        str = str.replace(/^\s+|\s+$/g, ""); // trim
        str = str.toLowerCase();

        return str;
    }

    const changeArea = (area) => {
        if (!area)
            return

        let service_areas = [...(profile.company.service_areas ? profile.company.service_areas : [])]
        let found = service_areas.indexOf(area)
        if (found === -1) {
            service_areas.push(area);
        } else {
            service_areas.splice(found, 1);
        }
        setProfile({ ...profile, company: { ...profile.company, service_areas: service_areas } })
    }

    const OpenCloseSelector = (props) => {
        return (
            <div className="flex">
                <div className="inline-flex items-center w-24">
                    <input id={`business_hour_${props.dow}`} name={`business_hour_${props.dow}`} type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                        checked={props.day.checked} onChange={(e) => {
                            profile.company.business_hours[props.dow].checked = e.target.checked
                            setProfile({ ...profile, company: { ...profile.company, business_hours: { ...profile.company.business_hours } } })
                        }} />
                    <label htmlFor={`business_hour_${props.dow}`} className="ml-2 block">{Translate(props.dow)}</label>
                </div>
                <div className="ml-4">
                    <div className="inline-flex items-center">
                        <select className="rounded py-1 text-sm" value={props.day.open} onChange={(e) => {
                            var { name, value } = e.target
                            profile.company.business_hours[props.dow].open = value
                            setProfile({ ...profile, company: { ...profile.company, business_hours: { ...profile.company.business_hours } } })
                        }}>
                            <option>07:00</option>
                            <option>08:00</option>
                            <option>09:00</option>
                            <option>10:00</option>
                            <option>11:00</option>
                            <option>12:00</option>
                            <option>13:00</option>
                            <option>14:00</option>
                            <option>15:00</option>
                            <option>16:00</option>
                            <option>17:00</option>
                            <option>18:00</option>
                            <option>19:00</option>
                            <option>20:00</option>
                        </select>
                        <div className="px-2"> – </div>
                        <select className="rounded py-1 text-sm" value={props.day.close} onChange={(e) => {
                            var { name, value } = e.target
                            profile.company.business_hours[props.dow].close = value
                            setProfile({ ...profile, company: { ...profile.company, business_hours: { ...profile.company.business_hours } } })
                        }}>
                            <option>07:00</option>
                            <option>08:00</option>
                            <option>09:00</option>
                            <option>10:00</option>
                            <option>11:00</option>
                            <option>12:00</option>
                            <option>13:00</option>
                            <option>14:00</option>
                            <option>15:00</option>
                            <option>16:00</option>
                            <option>17:00</option>
                            <option>18:00</option>
                            <option>19:00</option>
                            <option>20:00</option>
                        </select>
                    </div>
                </div>
            </div>

        )
    }
    return (


        <div className="w-full flex bg-slate-50">
            <SideNavBar {...props} isPresented={true} />
            <div className="flex flex-col w-full relative">

                <div className="bg-white">
                    <div className="w-full container mx-auto lg:px-8 px-4">

                        <div className="py-8">
                            <p className="text-3xl font-bold">{Translate("Settings")}</p>
                        </div>

                    </div>
                </div>
                <div className="lg:p-8 p-4 flex items-center container mx-auto">
                    {/* content starts here */}


                    <div className="w-full">
                        <div className="mb-4">

                            <p className="text-2xl font-semibold">{Translate("Profile")}</p>
                        </div>

                        <SettingsNavBar active="profile"></SettingsNavBar>

                        <div className="flex w-full shadow rounded-lg bg-white overflow-hidden">
                            <form className="w-full" onSubmit={updateProfile} >
                                <div className="grid grid-cols-1 divide-y text-sm">
                                    {Account.getUserAccount() && Account.getUserAccount().user && (Account.getUserAccount().user.role == 'owner' || Account.getUserAccount().user.role == 'admin') ?
                                        <div className="col-span-1 p-4 lg:p-8">
                                            <div className="flex">
                                                <div>
                                                    <p className="text-xl font-medium">{Translate("Agent Profile")}</p>
                                                    <p className="text-sm text-gray-500">{Translate("This information will be displayed publicly")}</p>
                                                </div>
                                                <div className="ml-auto py-2 flex justify-end">
                                                    <a id="btn-view-on-villa" className="btn text-sm rounded bg-blue-600 text-white inline-flex items-center"
                                                        target="_blank"
                                                        href={`${Constants.BASE_URL}/agents/${profile.agent.agent_id}/${string_to_slug(profile.company.name)}`}>
                                                        <svg className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                                        </svg>
                                                        <span className="ml-2">{Translate("View on Viila")}</span>
                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                        : null}
                                    <div className="col-span-1 py-4 lg:py-4 px-6 ">
                                        <div className="grid grid-cols-3 items-center">
                                            <div className="col-span-3 lg:col-span-1">
                                                <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Cover")}</p>
                                            </div>
                                            <div className="col-span-3 lg:col-span-2">
                                                {
                                                    profile.company.cover_image_url !== "" ?

                                                        <div className="w-full h-40 lg:h-96 relative">
                                                            <img className="lazyload z-0 w-full h-36 object-cover" src={profile.company.cover_image_url} />
                                                            <div {...dropCover.getRootProps()} className="mt-2">
                                                                <input className="" {...dropCover.getInputProps()} />
                                                                <p><button type="button" className="focus:outline-none text-indigo-600 font-medium">{Translate("Change photo")}</button></p>

                                                            </div>
                                                        </div>
                                                        :
                                                        <div {...dropCover.getRootProps()} className="w-full flex items-center text-center mx-auto h-28 border-dashed border-2 rounded border-gray-400 bg-white">
                                                            <div className="mx-auto text-xs">

                                                                <svg className="mx-auto mb-2 fill-current text-gray-700" width="27" height="23" viewBox="0 0 27 23" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M2.33789 16.3145C2.67969 18.2969 3.86133 19.1172 5.88281 18.7559L6.07812 18.7266V19.166C6.07812 21.1777 7.09375 22.1934 9.14453 22.1934H23.3828C25.4238 22.1934 26.4492 21.1777 26.4492 19.166V9.2832C26.4492 7.27148 25.4238 6.25586 23.3828 6.25586H21.2637L20.6875 3.04297C20.3359 1.07031 19.1543 0.230469 17.1426 0.591797L3.11914 3.07227C1.09766 3.42383 0.287109 4.58594 0.628906 6.57812L2.33789 16.3145ZM3.87109 15.9531L2.19141 6.38281C2.01562 5.39648 2.45508 4.78125 3.42188 4.60547L17.3965 2.14453C18.3438 1.97852 18.9785 2.39844 19.1543 3.39453L19.6621 6.25586H9.14453C7.09375 6.25586 6.07812 7.26172 6.07812 9.2832V17.125L5.63867 17.2031C4.68164 17.3789 4.05664 16.9492 3.87109 15.9531ZM7.65039 9.37109C7.65039 8.35547 8.1875 7.82812 9.16406 7.82812H23.3633C24.3301 7.82812 24.877 8.35547 24.877 9.37109V17.1348L21.3418 13.7949C20.9219 13.4043 20.4141 13.209 19.8965 13.209C19.3594 13.209 18.8906 13.3945 18.4414 13.7852L14.1152 17.6328L12.377 16.0703C11.9668 15.709 11.5273 15.5137 11.0684 15.5137C10.6484 15.5137 10.2383 15.6992 9.83789 16.0605L7.65039 17.9551V9.37109ZM12.5332 14.0391C13.7832 14.0391 14.7988 13.0137 14.7988 11.7539C14.7988 10.5137 13.7832 9.47852 12.5332 9.47852C11.2832 9.47852 10.2578 10.5137 10.2578 11.7539C10.2578 13.0137 11.2832 14.0391 12.5332 14.0391Z" />
                                                                </svg>
                                                                <input className="w-full h-full" {...dropCover.getInputProps()} />
                                                                <p><button type="button" className="focus:outline-none text-indigo-600 font-medium">{Translate("Upload files")}</button> {Translate("or drag and drop")}</p>
                                                                <p className="text-xs">{Translate("JPG or PNG up to 10MB")}</p>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-span-1 py-4 lg:py-4 px-6 ">
                                        <div className="grid grid-cols-3 items-center">
                                            <div className="col-span-3 lg:col-span-1">
                                                <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Photo")}</p>
                                            </div>
                                            <div className="col-span-3 lg:col-span-2">
                                                {
                                                    profile.image_url !== "" ?
                                                        <div >
                                                            <img className="object-cover w-36 h-36 rounded" src={profile.image_url} />
                                                            <div {...dropProfile.getRootProps()} className="mt-2">
                                                                <input className="" {...dropProfile.getInputProps()} />
                                                                <p><button type="button" className="focus:outline-none text-indigo-600 font-medium">{Translate("Change photo")}</button></p>

                                                            </div>
                                                        </div>
                                                        :
                                                        <div {...dropProfile.getRootProps()} className="w-full flex items-center text-center mx-auto h-28 border-dashed border-2 rounded border-gray-400 bg-white">
                                                            <div className="mx-auto text-xs">

                                                                <svg className="mx-auto mb-2 fill-current text-gray-700" width="27" height="23" viewBox="0 0 27 23" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M2.33789 16.3145C2.67969 18.2969 3.86133 19.1172 5.88281 18.7559L6.07812 18.7266V19.166C6.07812 21.1777 7.09375 22.1934 9.14453 22.1934H23.3828C25.4238 22.1934 26.4492 21.1777 26.4492 19.166V9.2832C26.4492 7.27148 25.4238 6.25586 23.3828 6.25586H21.2637L20.6875 3.04297C20.3359 1.07031 19.1543 0.230469 17.1426 0.591797L3.11914 3.07227C1.09766 3.42383 0.287109 4.58594 0.628906 6.57812L2.33789 16.3145ZM3.87109 15.9531L2.19141 6.38281C2.01562 5.39648 2.45508 4.78125 3.42188 4.60547L17.3965 2.14453C18.3438 1.97852 18.9785 2.39844 19.1543 3.39453L19.6621 6.25586H9.14453C7.09375 6.25586 6.07812 7.26172 6.07812 9.2832V17.125L5.63867 17.2031C4.68164 17.3789 4.05664 16.9492 3.87109 15.9531ZM7.65039 9.37109C7.65039 8.35547 8.1875 7.82812 9.16406 7.82812H23.3633C24.3301 7.82812 24.877 8.35547 24.877 9.37109V17.1348L21.3418 13.7949C20.9219 13.4043 20.4141 13.209 19.8965 13.209C19.3594 13.209 18.8906 13.3945 18.4414 13.7852L14.1152 17.6328L12.377 16.0703C11.9668 15.709 11.5273 15.5137 11.0684 15.5137C10.6484 15.5137 10.2383 15.6992 9.83789 16.0605L7.65039 17.9551V9.37109ZM12.5332 14.0391C13.7832 14.0391 14.7988 13.0137 14.7988 11.7539C14.7988 10.5137 13.7832 9.47852 12.5332 9.47852C11.2832 9.47852 10.2578 10.5137 10.2578 11.7539C10.2578 13.0137 11.2832 14.0391 12.5332 14.0391Z" />
                                                                </svg>
                                                                <input className="w-full h-full" {...dropProfile.getInputProps()} />
                                                                <p><button type="button" className="focus:outline-none text-indigo-600 font-medium">{Translate("Upload files")}</button> {Translate("or drag and drop")}</p>
                                                                <p className="text-xs">{Translate("JPG or PNG up to 10MB")}</p>
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    {Account.getUserAccount() && Account.getUserAccount().user && (Account.getUserAccount().user.role == 'owner' || Account.getUserAccount().user.role == 'admin') ?
                                        <div className="col-span-1 py-4 lg:py-4 px-6 ">
                                            <div className="grid grid-cols-3 items-center">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Viila Agent ID")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <p className="text-gray-600">{profile.agent.agent_id}</p>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                    <div className="col-span-1 py-4 lg:py-4 px-6 ">
                                        <div className="grid grid-cols-3 items-center">
                                            <div className="col-span-3 lg:col-span-1">
                                                <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Email")}</p>
                                            </div>
                                            <div className="col-span-3 lg:col-span-2">
                                                <p className="text-gray-600">{profile.email}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {Account.getUserAccount() && Account.getUserAccount().user && (Account.getUserAccount().user.role == 'owner' || Account.getUserAccount().user.role == 'admin') ?
                                        <div className="col-span-1 py-4 lg:py-4 px-6  ">
                                            <div className="grid grid-cols-3 items-top">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Info")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <textarea required name="agent.info" value={profile.agent.info} onChange={handleDataChange} rows="3" placeholder={Translate("Short introduction")} className="rounded w-full" />
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                    {Account.getUserAccount() && Account.getUserAccount().user && (Account.getUserAccount().user.role == 'owner' || Account.getUserAccount().user.role == 'admin') ?
                                        <div className="col-span-1 py-4 lg:py-4 px-6  ">
                                            <div className="grid grid-cols-3 items-top">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Company")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">


                                                    <div className="grid grid-cols-2 gap-4 mb-2">
                                                        <div className="col-span-2">
                                                            <label htmlFor="company.name" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Company name")}</label>
                                                            <input required name="company.name" value={profile.company.name} onChange={handleDataChange} type="text" placeholder="" className="rounded w-full" />
                                                        </div>

                                                        <div className="col-span-1">
                                                            <label htmlFor="company.url" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Website URL")}</label>
                                                            <input name="company.url" value={profile.company.url} onChange={handleDataChange} type="text" placeholder="https://" className="rounded w-full" />
                                                        </div>
                                                        <div className="col-span-1">
                                                            <label htmlFor="phone" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Phone")}</label>
                                                            <input name="phone" value={profile.phone} onChange={handleDataChange} type="text" placeholder="" className="rounded w-full" />
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                    {Account.getUserAccount() && Account.getUserAccount().user && (Account.getUserAccount().user.role == 'owner' || Account.getUserAccount().user.role == 'admin') ?
                                        <div className="col-span-1 py-4 lg:py-4 px-6  ">
                                            <div className="grid grid-cols-3 items-top">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Service areas")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <div className="grid grid-cols-2 gap-4 mb-2 items-top">
                                                        <div className="col-span-1">
                                                            <div className="relative">
                                                                {props.prefectures.map(({ id, name }, index) => (

                                                                    <div className="inline-flex items-center w-1/3">
                                                                        <input id={`open_prefectures_${id}`} name={`open_prefectures_${id}`} type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                                                            checked={profile.company.service_areas ? profile.company.service_areas.findIndex(e => e == id) > -1 : false}
                                                                            onChange={(e) => changeArea(id)} />
                                                                        <label htmlFor={`open_prefectures_${id}`} className="ml-2 block">{Translate(id)}</label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <div className="col-span-1 border-l-2">
                                                            <div className="relative ml-4">
                                                                {props.areas.filter(a => props.prefectures.findIndex(p => p.id == a.id) == -1).map(({ id, name }, index) => (

                                                                    <div className="inline-flex items-center w-1/3">
                                                                        <input id={`open_areas_${id}`} name={`open_areas_${id}`} type="checkbox" className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                                                                            checked={profile.company.service_areas ? profile.company.service_areas.findIndex(e => e == id) > -1 : false}
                                                                            onChange={(e) => changeArea(id)} />
                                                                        <label htmlFor={`open_areas_${id}`} className="ml-2 block">{Translate(id)}</label>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-3 items-top">
                                                <div className="col-span-3 lg:col-span-1">

                                                </div>
                                                
                                                <div className="col-span-3 lg:col-span-2">
                                                    <div className="grid grid-cols-2 gap-4 mb-2 items-center">


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                    {Account.getUserAccount() && Account.getUserAccount().user && (Account.getUserAccount().user.role == 'owner' || Account.getUserAccount().user.role == 'admin') ?
                                        <div className="col-span-1 py-4 lg:py-4 px-6  ">
                                            <div className="grid grid-cols-3 items-top">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Business hours")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <div className="">
                                                        <div className="flex flex-col space-y-2">
                                                            <OpenCloseSelector day={profile.company.business_hours.monday} dow={"monday"}></OpenCloseSelector>
                                                            <OpenCloseSelector day={profile.company.business_hours.tuesday} dow={"tuesday"}></OpenCloseSelector>
                                                            <OpenCloseSelector day={profile.company.business_hours.wednesday} dow={"wednesday"}></OpenCloseSelector>
                                                            <OpenCloseSelector day={profile.company.business_hours.thursday} dow={"thursday"}></OpenCloseSelector>
                                                            <OpenCloseSelector day={profile.company.business_hours.friday} dow={"friday"}></OpenCloseSelector>
                                                            <OpenCloseSelector day={profile.company.business_hours.saturday} dow={"saturday"}></OpenCloseSelector>
                                                            <OpenCloseSelector day={profile.company.business_hours.sunday} dow={"sunday"}></OpenCloseSelector>
                                                        </div>
                                                    </div>
                                                    <div>


                                                        <div className="mt-4">
                                                            <label htmlFor="company.business_hours.remark" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Remark")}</label>
                                                            <input name="company.business_hours.remark" value={profile.company.business_hours.remark} onChange={(e) => {
                                                                var { name, value } = e.target
                                                                setProfile({ ...profile, company: { ...profile.company, business_hours: { ...profile.company.business_hours, remark: value } } })

                                                            }} type="text" placeholder="" className="rounded w-full" />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                    {Account.getUserAccount() && Account.getUserAccount().user && (Account.getUserAccount().user.role == 'owner' || Account.getUserAccount().user.role == 'admin') ?
                                        <div className="col-span-1 py-4 lg:py-4 px-6  ">
                                            <div className="grid grid-cols-3 items-center">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Agent License number")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <input required name="agent.license" value={profile.agent.license} onChange={handleDataChange} type="text" placeholder="" className="rounded w-full" />
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                    <div className="col-span-1 py-4 lg:py-4 px-6  ">
                                        <div className="grid grid-cols-3 items-center">
                                            <div className="col-span-3 lg:col-span-1">
                                                <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("User name")}</p>
                                            </div>
                                            <div className="col-span-3 lg:col-span-2">

                                                <div className="grid grid-cols-2 gap-4 mb-2">
                                                    <div className="col-span-1">
                                                        <label htmlFor="first_name" className="block text-sm font-medium leading-6 text-gray-700">{Translate("First name")}</label>
                                                        <input required name="first_name" value={profile.first_name} onChange={handleDataChange} id="property-price" type="text" placeholder="" className="rounded w-full" />
                                                    </div>
                                                    <div className="col-span-1">
                                                        <label htmlFor="last_name" className="block text-sm font-medium leading-6 text-gray-700">{Translate("Last name")}</label>
                                                        <input required name="last_name" value={profile.last_name} onChange={handleDataChange} type="text" placeholder="" className="rounded w-full" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {Account.getUserAccount() && Account.getUserAccount().user && (Account.getUserAccount().user.role == 'owner' || Account.getUserAccount().user.role == 'admin') ?
                                        <div className="col-span-1 py-4 lg:py-4 px-6  ">
                                            <div className="grid grid-cols-3 items-center">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Languages")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <input required name="languageList" value={languageList} onChange={handleLanguageList} type="text" placeholder="" className="rounded w-full" />
                                                    <p className="mt-2 text-gray-600 text-xs">{Translate("Wider your potential buyers by letting them know the languages you speak. This information will be listed in listing page.")}</p>
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                    {Account.getUserAccount() && Account.getUserAccount().user && (Account.getUserAccount().user.role == 'owner' || Account.getUserAccount().user.role == 'admin') ?
                                        <div className="col-span-1 py-4 lg:py-4 px-6  ">
                                            <div className="grid grid-cols-3 items-center">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Google Place ID")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <input name="profile.agent.agent_data.place_id" value={profile.agent.agent_data.place_id} onChange={(e) => {
                                                        var { name, value } = e.target
                                                        var agent = profile.agent
                                                        agent.agent_data.place_id = value
                                                        setProfile({ ...profile, agent: agent })
                                                    }} type="text" placeholder="" className="rounded w-full" />
                                                </div>
                                            </div>
                                        </div>
                                        : null}
                                    {Account.getUserAccount() && Account.getUserAccount().user && (Account.getUserAccount().user.role == 'owner' || Account.getUserAccount().user.role == 'admin') ?
                                        <div className="col-span-1 py-4 lg:py-4 px-6  ">
                                            <div className="grid grid-cols-3 items-center">
                                                <div className="col-span-3 lg:col-span-1">
                                                    <p className="text-sm font-medium text-gray-700  mb-2 lg:mb-0">{Translate("Address")}</p>
                                                </div>
                                                <div className="col-span-3 lg:col-span-2">
                                                    <input name="profile.agent.agent_data.place_id" value={profile.company.full_address} onChange={(e) => {
                                                        var { name, value } = e.target
                                                        var company = profile.company
                                                        company.full_address = value
                                                        setProfile({ ...profile, company: company })
                                                    }} type="text" placeholder="" className="rounded w-full" />
                                                </div>
                                            </div>
                                        </div>
                                        : null}

                                    <div className="col-span-1 py-4 lg:py-4 px-6  ">
                                        {Account.getUserAccount() && Account.getUserAccount().user && (Account.getUserAccount().user.role == 'owner' || Account.getUserAccount().user.role == 'admin') ?
                                            <button disabled={profile.first_name.trim().length === 0 || profile.last_name.trim().length === 0 || profile.company.name.trim().length === 0 || profile.agent.license.trim().length === 0} type="submit" className="btn btn-primary disabled:opacity-50">{Translate("Save changes")}</button> :
                                            <button disabled={profile.first_name.trim().length === 0 || profile.last_name.trim().length === 0} type="submit" className="btn btn-primary disabled:opacity-50">{Translate("Save changes")}</button>
                                        }
                                    </div>
                                </div>
                            </form>
                            <Alert {...props} isPresented={showAlert} dismiss={(e) => { setShowAlert(false) }}></Alert>
                        </div>
                    </div>
                    {/* content ends here */}
                </div>
            </div>
        </div>
    )
}